import React from "react";
import Navbar from "../components/Navbar";
import Stripes from "../components/Stripes";
import LandingPageFiltration from "../components/filters/LandingPageFiltration";
import HighlightedAuctionsBanner from "../components/additionals/HighlightedAuctionsBanner";
import HighlightedAuctions from "../components/homepage_auctions/HomepageAuctions";


// import bmw from '../../assets/images/bmw.jpg';
function Home() {

    return (
    <div className="App">
      {/*<Navbar color={navbar_color}/>*/}
        <Navbar/>
        <Stripes/>
        {/*TODO Other filtration, specifics in Figma. */}
        {/*<LandingPageFiltration/>*/}
        <div className={`w-1/2 mx-auto h-full`}>
            <p className={`text-center text-[28px] mt-3`}>W przyszłości planujemy stworzyć sekcję klasyków! Ogłoszenia klasyczne będą się odpowiednio różniły i nie będą się mieszały z innymi ogłoszeniami!</p>
        </div>
    </div>
    );
}

export default Home;
