import React, {useEffect, useState} from "react";
import Navbar from "../components/Navbar";
import Stripes from "../components/Stripes";
import Footer from "../components/Footer";
import {useLocation} from "react-router-dom";
import {animateScroll as scroll} from "react-scroll";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import { handleAuctionColor } from "../components/cards/CarAuctionsCard";

function PriceList() {

    const location = useLocation();

    useEffect(() => {
            scroll.scrollToTop({
                duration: 300,
            });
    }, [location]);

    const DESCRIPTION = "Cennik dla klientów Indywidualnych oraz Biznesowych, dokładamy wszelkich starań aby zadowlić wszystkich naszych klientów, którzy postanawiaja umieścić ogłoszenie swojego pojazdu w naszym serwisie.";

    return (
    <div className="App">
      {/*<Navbar color={navbar_color}/>*/}
        <Navbar/>
        <Stripes/>

        <Helmet
        onChangeClientState={(newState) => {
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
        metaDescription.setAttribute('content', DESCRIPTION || '');
        }
        }}>
            <title>Cennik serwisu Autkotarg.pl</title>
        </Helmet>

        <div className={``}>
            <div className={`w-[98%] xsm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 bg-base-100 rounded-[20px] mt-10 mx-auto geometric-shadow-gray transition duration-300`}>
                <h1 className={`text-center text-[24px] md:text-[26px] pt-5 pb-3`}>Cennik strony</h1>
                <hr className={`pb-3 w-11/12 mx-auto mt-2`}/>
                <div className={`mx-auto w-[85%] md:w-full pb-3`}>
                    <h1 className={`text-[20px] text-center pb-3`}>Cennik serwisu { process.env.REACT_APP_SERVICE_TERMS_NAME } dla klientów Indywidualnych</h1>
                    {/*<hr className={`pb-3 w-11/12 mx-auto`}/>*/}
                    {/* <div className={`grid my-3 grid-cols-3 w-full md:w-11/12 mx-auto divide-2 divide-x outline outline-1 outline-gray-200 rounded-[20px]`}> */}
                    <div className={`grid my-3 grid-cols-1 md:grid-cols-3 gap-x-3 gap-y-3 w-full md:w-11/12 mx-auto`}>
                        <div className={`mx-auto flex flex-col w-full gap-y-2 py-2 text-main_gray_dark rounded-[14px] geometric-shadow-gray duration-300 transition ${handleAuctionColor(true, false, true)} border-[3px]`}>
                            <p className={`text-center text-black font-[500]`}>Pakiet zwykły</p>
                            <p className={`text-center`}>0 PLN</p>
                            <p className={`text-center`}>0 Punktów podbić</p>
                            <p className={`text-center`}>Zwykła oprawa graficzna</p>
                            <p className={`text-center`}>30 dni aktywności ogłoszenia</p>
                        </div>
                        <div className={`mx-auto flex flex-col w-full gap-y-2 py-2 text-main_gray_dark rounded-[14px] duration-300 transition ${handleAuctionColor(true, true, false)}`}>
                            <p className={`text-center text-black font-[500]`}>Pakiet 'Wyróżnij się'</p>
                            <p className={`text-center`}>16 PLN</p>
                            <p className={`text-center`}>5 Punktów podbić</p>
                            <p className={`text-center`}>'Zielonkawa' oprawa graficzna</p>
                            <p className={`text-center`}>30 dni aktywności ogłoszenia</p>
                        </div>
                        <div className={`mx-auto flex flex-col w-full gap-y-2 py-2 text-main_gray_dark rounded-[14px] duration-300 transition ${handleAuctionColor(true, true, true)}`}>
                            <p className={`text-center text-black font-[500]`}>Pakiet 'Karta Prezesa'</p>
                            <p className={`text-center`}>24 PLN</p>
                            <p className={`text-center`}>10 Punktów podbić</p>
                            <p className={`text-center`}>Czerwona oprawa graficzna</p>
                            <p className={`text-center`}>30 dni aktywności ogłoszenia</p>
                        </div>
                    </div>
                    <h1 className={`text-[20px] text-center pb-3 mt-10`}>Cennik serwisu { process.env.REACT_APP_SERVICE_TERMS_NAME } dla klientów biznesowych</h1>
                    <p className={`text-center text-main_gray_dark mb-3`}>Jeśli chcesz zawrzeć z nami współpracę prosimy o kontakt pod adresem e-mail: wspolpraca@autkotarg.pl</p>
                </div>
            </div>
        </div>

        <Footer/>
    </div>
    );
}

export default PriceList;
