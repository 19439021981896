import React, {useEffect, useState} from "react";
import Navbar from "../components/Navbar";
import Stripes from "../components/Stripes";
import Footer from "../components/Footer";
import {useLocation} from "react-router-dom";
import {animateScroll as scroll} from "react-scroll";
import {Link} from "react-router-dom";
import {useForm} from "react-hook-form";
import axiosInstance from "../api/axios";
import {Helmet} from "react-helmet";

function Contact() {

    const {
        register,
        control,
        handleSubmit,
        setValue,
        clearErrors,
        setError,
        getValues,
        formState: { errors},

    } = useForm();

    const location = useLocation();
    const [isDataPosted, setIsDataPosted] = useState("not_send")
    const [submitButtonClassName, setSubmitButtonClassName] = useState('bg-base-100 text-main_green hover:text-white geometric-shadow-green border-[1px] border-main_green hover:bg-main_green')

    useEffect(() => {
            scroll.scrollToTop({
                duration: 300,
            });
    }, [location]);

    const onSubmit = (e) => {
        console.log(e)
        axiosInstance.post('/contact/', e, { withCredentials : true })
            .then((data) => {
                // console.log("POST request successfull");
                setIsDataPosted(true)
                setSubmitButtonClassName('bg-main_green text-white border-[1px] border-main_green')
                console.log(data);
            }).catch((error) => {
                setIsDataPosted(false)
                setSubmitButtonClassName('bg-red-600 text-white border-[1px] border-red-600')
                console.log(error);
        })
    }

    const onError = () => {
        console.error("Blank fields!")
    }

    const DESCRIPTION = "Potrzebujesz się z nami skontaktować w sprawie Biznesowej, Technicznej, bądź w sprawie występującego problemu przy dodawaniu ogłoszenia? Użyj naszego formularza kontaktowego, postaramy się jak najszybciej Ci odpowiedzieć!"

    return (
    <div className="App">
      {/*<Navbar color={navbar_color}/>*/}
        <Navbar/>
        <Stripes/>

        <Helmet
        onChangeClientState={(newState) => {
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
        metaDescription.setAttribute('content', DESCRIPTION || '');
        }
        }}>
            <title>Formularz kontaktowy - Autkotarg.pl</title>
        </Helmet>

        <div className={``}>
            <div className={`w-[95%] xsm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 2xl:w-6/12 bg-base-100 rounded-[20px] mt-5 md:mt-10 pb-6 mx-auto geometric-shadow-gray transition duration-300`}>
                <h1 className={`text-center text-[24px] md:text-[26px] pt-5 pb-3`}>Formularz kontaktowy</h1>
                <hr className={`pb-3 w-11/12 mx-auto mt-1`}/>

                <form onSubmit={handleSubmit(onSubmit, onError)} className={`w-full grid grid-cols-1 gap-x-3 gap-y-3`}>
                    <div className={`w-11/12 mx-auto`}>
                        <div className={`flex justify-between`}>
                            <label htmlFor="email" className={`pl-3 truncate`}>Email kontaktowy*</label>
                            <p className={`text-red-700 font-semibold pr-3`}>
                                { errors.email && 'Uzupełnij!' }
                            </p>
                        </div>
                        <input type="email"
                               placeholder={'Twój email'}
                               className={`placeholder-main_orange focus:outline-none focus:ring-1 focus:ring-main_orange transition duration-300 focus:border-main_orange border-1 w-full border-main_orange rounded-[20px] pl-3 pt-2 pb-2`}
                               id={'email'}
                               maxLength={255}
                               {...register('email', {required: true})}
                        />
                    </div>

                    <div className={`w-11/12 mx-auto`}>
                        <div className={`flex justify-between`}>
                            <label htmlFor="topic" className={`pl-3 truncate`}>Temat*</label>
                            <p className={`text-red-700 font-semibold pr-3`}>
                                { errors.topic && 'Uzupełnij!' }
                            </p>
                        </div>
                        <input type="text"
                               placeholder={'Temat, np. Problem z dodawaniem ogłoszenia'}
                               className={`placeholder-main_orange focus:outline-none focus:ring-1 focus:ring-main_orange transition duration-300 focus:border-main_orange border-1 w-full border-main_orange rounded-[20px] pl-3 pt-2 pb-2`}
                               id={'topic'}
                               {...register('topic', {required: true})}
                               maxLength={255}
                        />
                    </div>

                    <div className={`w-11/12 mx-auto`}>
                        <div className={`flex justify-between`}>
                            <label htmlFor="message" className={`pl-3 truncate`}>Wiadomość*</label>
                            <p className={`text-red-700 font-semibold pr-3`}>
                                { errors.message && 'Uzupełnij!' }
                            </p>
                        </div>

                        <textarea
                            {...register('message', {required: true})}

                            className={`placeholder-main_orange h-80 focus:outline-none focus:ring-1 focus:ring-main_orange transition duration-300 focus:border-main_orange border-1 w-full border-main_orange rounded-[20px] pl-3 pt-2 pb-2`}
                            type="text"
                            placeholder={"Wiadomość"}
                            autoComplete={"false"}
                            id={`message`}
                            name={`message`}
                            maxLength={5000}
                        />
                    </div>

                    <button disabled={isDataPosted !== "not_send"} className={`w-1/2 md:w-1/4 mx-auto pt-3 transition duration-200 pb-3 rounded-full ${submitButtonClassName}`} type="submit">{ isDataPosted === "not_send" ? ('Wyślij wiadomość') : ( isDataPosted ? ('Wysłano!') : ('Błąd!') )}</button>
                </form>
            </div>
        </div>

        <Footer/>
    </div>
    );
}

export default Contact;
