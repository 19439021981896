import axios from "axios";
import {useNavigate} from "react-router-dom";

// Define the interceptor logic
let refresh = false;

console.log(process.env.REACT_APP_HTTP)

// const apiUrl = `https://${process.env.REACT_APP_BACKEND_IP}`;
const apiUrl = process.env.REACT_APP_HTTP
    ? `http://${process.env.REACT_APP_BACKEND_IP}`
    : `https://${process.env.REACT_APP_BACKEND_IP}`;

console.log(`https://${process.env.REACT_APP_BACKEND_IP}`, apiUrl)

const axiosInstance = axios.create({
    baseURL: apiUrl,
    // headers: {'Authorization': `Bearer ${localStorage.getItem('access_token')}`}
})

// console.log("REACT_APP_BACKEND_IP:", process.env.REACT_APP_BACKEND_IP);

// axios.defaults.baseURL = `${process.env.REACT_APP_BACKEND_IP}`;
// axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`

axiosInstance.interceptors.response.use(
(response) => {
            // console.log(response.data);
            // TODO if it is 401 then get new access token.
            return response;
          },
async (error) => {
            console.log(error.response.status, error.response, refresh, !refresh, "AXIOS ERROR")
            if (error.response.status === 401 || !refresh) {
              refresh = true;

              const refreshToken = localStorage.getItem('refresh_token');
              console.log(refreshToken)

              if (refreshToken) {
                try {

                  const refreshUrl = process.env.REACT_APP_HTTP
                    ? `http://${process.env.REACT_APP_BACKEND_IP}/user/auth/token/refresh/`
                    : `https://${process.env.REACT_APP_BACKEND_IP}/user/auth/token/refresh/`;


                  const response = await axios.post(
                    refreshUrl,
                    // `https://${process.env.REACT_APP_BACKEND_IP}/user/auth/token/refresh/`,
                    {
                        refresh: refreshToken
                      },
                    {
                      headers: {
                        'Content-Type': 'application/json'
                      },
                      withCredentials: true
                    }
                  );

                  console.log("After sending POST request.", response)

                  if (response.status === 200) {
                      console.log(response)
                      console.log(response.data)
                      console.log(response.data['access'], 'New access_token!')
                      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data['access']}`;

                      localStorage.setItem('access_token', response.data['access']);

                      // error.config.defaults.headers.common['Authorization'] =`Bearer ${response.data['access']}`;
                      console.log(error.config)
                      //  TODO It does return a error axios request, return a good '.config'.

                      const newRequestConfig = {
                        ...error.config,
                        headers: {
                            ...error.config.headers,
                            'Authorization': `Bearer ${response.data['access']}`
                        }
                      };

                      return axiosInstance(newRequestConfig);
                  } else if (response.status === 401) {
                      localStorage.clear()
                      axiosInstance.defaults.headers.common['Authorization'] = null;
                      // window.location.href = "/logowanie"

                      //  TODO It does return a error axios request, return a good '.config'.
                      return axiosInstance(error.config);
                  }


                } catch (refreshError) {
                  // Handle refresh token error here
                  console.error('Refresh token error:', refreshError);
                  localStorage.removeItem('access_token');

                  axiosInstance.defaults.headers.common['Authorization'] = null;
                  // const navigate = useNavigate()
                  // navigate('/logowanie')

                  window.location.href = '/logowanie'
                  return axiosInstance(error.config);
                }
              }
            }
            refresh = false;
            return Promise.reject(error);
  }
);


axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('access_token');
    // console.log(localStorage.getItem('refresh_token'), "Refresh Token")
    // console.log(token, "Access Token")

    if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
        // console.log(config.headers["Authorization"])
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


// axiosInstance.interceptors.request.use(
//
// )

// Export the Axios instance with the interceptor logic
export default axiosInstance;