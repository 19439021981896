import React, {useEffect, useState, useContext, useRef} from "react";
import Navbar from "../components/Navbar";
import Stripes from "../components/Stripes";
import axiosInstance from "../api/axios";
import {Link, useLocation, useSearchParams} from "react-router-dom";
import Footer from "../components/Footer";
import ReCAPTCHA from "react-google-recaptcha";
import {useForm} from "react-hook-form";
import {Helmet} from "react-helmet";

// import bmw from '../../assets/images/bmw.jpg';
function Register() {

    const { register, handleSubmit, formState: {errors} } = useForm();

    const location = useLocation();
    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ email, setEmail ] = useState('');
    const [ password1, setPassword1 ] = useState('');
    const [ password2, setPassword2 ] = useState('');
    const [ isTheSame, setIsTheSame ] = useState(null);
    const [ submitButtonDisabled, setSubmitButtonDisabled ] = useState(true);
    const [ isVerifyEmailSended, setIsVerifyEmailSended ] = useState(false);
    const [ isSecondVerifyEmailSended, setIsSecondVerifyEmailSended] = useState(false);
    const [ isError, setIsError ] = useState(false);
    const [ isRegisterLoading, setIsRegisterLoading ] = useState(false);
    const [ startedVerifyEmailSending, setStartedVerifyEmailSending ] = useState(false);
    const [ passwordError, setPasswordError ] = useState(null);
    const captchaRef = useRef(null);
    const [ sendedEmailCount, setSendedEmailCount ] = useState(0);

    useEffect(() => {
        if (password1 !== password2) {
            setIsTheSame(false);
            setPasswordError('Hasła nie są takie same!')
        } else if ((password1.length > 1 && password1.length < 8) && (password2.length > 1 && password2.length < 8) && (password1 === password2) ) {
            setIsTheSame(false);
            setPasswordError('Hasło ma mniej niż 8 znaków')
        } else {
            if (password1.length >= 8 && password2.length >= 8 ) {
                setSubmitButtonDisabled(true);
                setIsTheSame(true);
            }
        }
    }, [password1, password2])

    const submit = async (e) => {
        setIsRegisterLoading(true);
        // e.preventDefault();

        // const token = captchaRef.current.getValue();
        // captchaRef.current.reset();
        // console.log(token);

        const user = {
            email: email,
            password1: password1,
            password2: password2,
          };

        await axiosInstance.post('/user/auth/registration/', user ,{
            headers: {
                'Content-Type': 'application/json'
        }}, {withCredentials: true})
            .then((data) => {
                if (data.status === 201) {
                    setIsVerifyEmailSended(true);
                }
                console.log(data)
                localStorage.clear();
            })
            .catch((e) => {
                setIsError(true);
                // console.log("hehe")
            });


        // localStorage.setItem('access_token', data.access);
        // localStorage.setItem('refresh_token', data.refresh);
        // axios.defaults.headers.common['Authorization'] = `Bearer ${data['access_token']}`;
        // window.location.href = '/'

    }


    const resendVerificationEmail = async () => {
        console.log(email);
        setStartedVerifyEmailSending(true);
        setSendedEmailCount(sendedEmailCount + 1);

        if (sendedEmailCount <= 2) {
            const user = {
                email: email
            }

            await axiosInstance.post('/user/auth/registration/resend-email/',
                user,
                {
                    headers: {'Content-Type': 'application/json'}
                },
                {
                    withCredentials: true
                }
            ).then((data) => {
                if (data.status === 200) {
                    setIsSecondVerifyEmailSended(true);
                }
                console.log(data)
            })
            .catch((e) => {
                console.log(e)
            })
        } else {
            return null;
        }

    }

    const DESCRIPTION = "Zarejestruj aby móc w pełni korzystać z możliwości serwisu Autkotarg.pl! Dodawaj ogłoszenia, dodawaj inne ogłoszenia do polubionych, miej dostęp do panelu administracyjnego i nie tylko!"

    return (
    <div className="">
        <Navbar/>
        <Stripes/>

        {/*<Helmet onChangeClien>*/}
        {/*    <meta name="description" content="Zarejestruj aby móc w pełni korzystać z możliwości serwisu Autkotarg.pl! Dodawaj ogłoszenia, dodawaj inne ogłoszenia do polubionych, miej dostęp do panelu administracyjnego i nie tylko!" />*/}
        {/*</Helmet>*/}
        <Helmet
        onChangeClientState={(newState) => {
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
        metaDescription.setAttribute('content', DESCRIPTION || '');
        }
        }}>
                <title>Zarejestruj się w naszym serwisie - Autkotarg.pl</title>
        </Helmet>

        <div className={`w-full h-full mb-7`}>
            { isVerifyEmailSended === false ? (

            <form onSubmit={handleSubmit(submit)} className={`w-11/12 md:w-8/12 lg:w-7/12 xl:w-1/3 bg-base-100 mx-auto mt-9 rounded-[20px] pb-6 shadow-sm geometric-shadow-gray transition duration-300`}>
                <h1 className={`text-[28px] text-center pt-4`}>Rejestracja</h1>
                <div className={`flex flex-col w-11/12 md:w-4/5 mx-auto pt-1`}>
                    <label className={`text-[18px] pl-3`}>E-mail</label>
                    <input
                        type="email"
                        className="h-[41px] transition duration-200 block border-0 appearance-none w-full rounded-[30px] bg-base-200 focus:ring focus:ring-1 focus:ring-slate-600 px-3 py-2 pr-8 leading-tight"
                        placeholder={'Przykładowy@email.com'}
                        value={email}
                        autoComplete={"email"}
                        name={"email"}
                        required={true}
                        onChange={e => setEmail(e.target.value)}
                        minLength={8}
                        maxLength={200}
                    />
                </div>
                <div className={`flex flex-col w-11/12 md:w-4/5 mx-auto mt-1`}>
                    <label className={`text-[18px] pl-3`}>Hasło</label>
                    <input
                        id="password1"
                        type="password"
                        className="h-[41px] transition duration-200 block border-0 appearance-none w-full rounded-[30px] bg-base-200 focus:ring focus:ring-1 focus:ring-slate-600 px-3 py-2 pr-8 leading-tight"
                        placeholder={'Hasło'}
                        value={password1}
                        required={true}
                        onChange={e => setPassword1(e.target.value)}
                        minLength={8}
                        maxLength={200}
                        autoComplete={'new-password'}
                    />
                </div>
                <div className={`flex flex-col w-11/12 md:w-4/5 mx-auto mt-1`}>
                    <label className={`text-[18px] pl-3`}>Potwierdź hasło</label>
                    <input
                        id="password2"
                        type="password"
                        minLength={8}
                        className="h-[41px] transition duration-200 block border-0 appearance-none w-full rounded-[30px] bg-base-200 focus:ring focus:ring-1 focus:ring-slate-600 px-3 py-2 pr-8 leading-tight"
                        placeholder={'Potwierdź hasło'}
                        value={password2}
                        required={true}
                        autoComplete={`new-password`}
                        onChange={e => setPassword2(e.target.value)}
                    />
                </div>

                {/*<div className={`mx-auto w-full mt-5`} align={`center`}>*/}
                {/*    <ReCAPTCHA*/}
                {/*        sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTCHA_PUBLIC_KEY}`}*/}
                {/*        ref={captchaRef}*/}
                {/*    />*/}
                {/*</div>*/}

                <div className={`flex flex-col md:flex-row gap-x-2 mt-3 mx-auto w-10/12 md:w-3/5 pt-1`}>
                    {/*<ReCAPTCHA*/}
                    {/*    sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTCHA_PUBLIC_KEY}`}*/}
                    {/*    ref={captchaRef}*/}
                    {/*/>*/}
                    <input
                        type="checkbox"
                        name={'terms'}
                        id={'terms'}
                        { ...register('terms', { required: true})}
                        className={`rounded-full md:my-5 w-6 h-6 mx-auto md:mx-0 mb-3 md:mb-0 md:ml-0 transition duration-50 my-auto border-main_green text-main_green shadow-sm focus:border-main_green focus:ring focus:ring-offset-0 focus:ring-main_green focus:ring-opacity-30`}
                    />
                    {/*<label htmlFor="terms" className={`text-center`}>Oświadczam, że zapoznałem się z <Link to={`/regulamin`} className={`underline text-main_green`}>Regulaminem</Link> korzystania z serwisu oraz, zapoznałem się z <Link to={`/prywatnosc`} className={`underline text-main_green`}>Polityką prywatności</Link>. <br/>Rejestując się w serwisie Autkotarg.pl akceptuję ich treść.</label>*/}
                    <label htmlFor="terms" className={`text-center`}>Oświadczam, że zapoznałem się z <Link to={`/regulamin`} className={`underline text-main_green`}>Regulaminem</Link> korzystania z serwisu oraz akceptuję jego treść.</label>
                </div>

                <div className={`flex flex-col md:flex-row gap-x-2 mt-3 mx-auto w-10/12 md:w-3/5 pt-1`}>
                    {/*<ReCAPTCHA*/}
                    {/*    sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTCHA_PUBLIC_KEY}`}*/}
                    {/*    ref={captchaRef}*/}
                    {/*/>*/}
                    <input
                        type="checkbox"
                        name={'privacy'}
                        id={'privacy'}
                        { ...register('privacy', { required: true})}
                        className={`rounded-full md:my-5 w-6 h-6 mx-auto md:mx-0 mb-3 md:mb-0 md:ml-0 transition duration-50 my-auto border-main_green text-main_green shadow-sm focus:border-main_green focus:ring focus:ring-offset-0 focus:ring-main_green focus:ring-opacity-30`}
                    />
                    <label htmlFor="privacy" className={`text-center`}>Oświadczam, że zapoznałem się z <Link to={`/prywatnosc`} className={`underline text-main_green`}>Polityką Prywatności</Link> jaką prowadzi serwis { process.env.REACT_APP_SERVICE_FULL_NAME.toLowerCase().trim().replace(/\s/g, '') }.pl i akceptuję jej treść<br/></label>
                </div>


                { isTheSame === false && (
                    <div className={`text-red-500 mt-3 -mb-2`}>
                        <p className={`text-center`}>{passwordError}</p>
                    </div>
                )}

                { isError === true && (
                    <div className={`text-red-500 mt-3 -mb-2`}>
                        <p className={`text-center`}>E-mail został już użyty do rejestracji!</p>
                        <div className={`mt-1 -mb-1 flex gap-x-2 justify-center mx-auto w-11/12`}>
                                { isSecondVerifyEmailSended === true ? (
                                    <div></div>
                                ) : (
                                    <div>
                                        Nie dostałeś e-maila weryfikującego?
                                        <button className={`text-main_green`} onClick={resendVerificationEmail} type='button'>
                                            Kliknij tutaj
                                        </button>
                                    </div>
                                )}
                        </div>
                    </div>
                )}

                { errors.terms && (
                    <div className={`text-red-500 mt-3 -mb-2 w-[75%] mx-auto`}>
                        <p className={`text-center`}>Aby utworzyć konto musisz zaakceptować regulamin!</p>
                    </div>
                ) }

                { errors.privacy && (
                    <div className={`text-red-500 mt-3 -mb-2 w-[75%] mx-auto`}>
                        <p className={`text-center`}>Aby utworzyć konto musisz zaakceptować politykę prywatności!</p>
                    </div>
                ) }


                <div className={`mx-auto w-2/3 md:w-1/2 mt-5 text-center ${ isTheSame === false ? ('bg-gray-400 disabled') : ('bg-main_red-100 hover:bg-main_red-200') } text-white transition duration-200 rounded-[20px]`}>
                    <button className={`w-full h-full flex items-center p-2.5`} type="submit">
                        { isRegisterLoading && isError === false ? (
                            <svg className="w-5 h-5 absolute animate-spin text-white mx-auto" xmlns="http://www.w3.org/2000/svg"
                             fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        ) : (
                        <div>

                        </div>
                        )}
                        <p className={`mx-auto`}>Zarejestruj się</p>
                    </button>
                </div>

                <div className={`mt-6 text-center`}>
                    <p>Masz już konto? <Link className={`text-main_green`} to={'/logowanie'}>Zaloguj się!</Link></p>
                </div>
            </form>

            ) : (
                <div className={`w-11/12 md:w-8/12 lg:w-7/12 xl:w-6/12 bg-base-100 mx-auto mt-9 rounded-[20px] pb-6 mb-32 hover:shadow-sm transition duration-300`}>
                    <h1 className={`text-[28px] text-center pt-4`}>Potwierdź E-mail</h1>
                    <div className={`w-11/12 flex justify-center mx-auto gap-x-3 mt-3`}>
                        {/*<svg className={``} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">*/}
                        {/*    <path d="M8 11L10 13L14.5 8.5M8.2019 19.6009C8.52965 19.5575 8.86073 19.6464 9.12182 19.8475L10.3251 20.7708C10.7228 21.0764 11.2761 21.0764 11.6727 20.7708L12.9215 19.812C13.1548 19.6331 13.4492 19.5542 13.7403 19.5931L15.3024 19.7986C15.799 19.8642 16.2779 19.5875 16.4701 19.1242L17.0712 17.6709C17.1834 17.3987 17.3989 17.1832 17.6711 17.0709L19.1243 16.4698C19.5876 16.2787 19.8643 15.7988 19.7987 15.3021L19.601 13.7966C19.5576 13.4688 19.6465 13.1377 19.8476 12.8766L20.7709 11.6733C21.0764 11.2755 21.0764 10.7222 20.7709 10.3256L19.812 9.07672C19.6332 8.84339 19.5543 8.54896 19.5932 8.25785L19.7987 6.69568C19.8643 6.19902 19.5876 5.72015 19.1243 5.52793L17.6711 4.92684C17.3989 4.81462 17.1834 4.59907 17.0712 4.32685L16.4701 2.87356C16.279 2.41024 15.799 2.13358 15.3024 2.19913L13.7403 2.40468C13.4492 2.44468 13.1548 2.36579 12.9226 2.18802L11.6738 1.22916C11.2761 0.923613 10.7228 0.923613 10.3262 1.22916L9.07738 2.18802C8.84407 2.36579 8.54965 2.44468 8.25856 2.40691L6.69647 2.20136C6.19984 2.1358 5.721 2.41246 5.52879 2.87578L4.92884 4.32907C4.81552 4.60018 4.59998 4.81573 4.32889 4.92906L2.87568 5.52904C2.41238 5.72126 2.13574 6.20013 2.20129 6.69679L2.40683 8.25897C2.4446 8.55007 2.36572 8.8445 2.18796 9.07672L1.22915 10.3256C0.923617 10.7233 0.923617 11.2767 1.22915 11.6733L2.18796 12.9222C2.36683 13.1555 2.44571 13.4499 2.40683 13.741L2.20129 15.3032C2.13574 15.7999 2.41238 16.2787 2.87568 16.471L4.32889 17.0721C4.60109 17.1843 4.81663 17.3998 4.92884 17.672L5.5299 19.1253C5.721 19.5887 6.20096 19.8653 6.69758 19.7998L8.2019 19.6009Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
                        {/*</svg>*/}
                        <p className={`text-center`}>Wysłaliśmy na <b>{email}</b> wiadomość z linkiem aktywującym konto. Jeśli jej nie widzisz to sprawdź w folderze Spam</p>
                    </div>
                    { isSecondVerifyEmailSended === false ? (
                        <div>
                        { startedVerifyEmailSending === false ? (
                            <div className={`mt-4 flex gap-x-2 justify-center`}>
                                Nie dostałeś e-maila?
                                <button className={`text-main_green`} onClick={resendVerificationEmail} type='button' disabled={startedVerifyEmailSending}>
                                    Kliknij tutaj
                                </button>
                            </div>
                            ) : (
                                <div className="flex justify-center h-12 mb-6">
                                    <span className="circle animate-loader"></span>
                                    <span className="circle animate-loader animation-delay-200"></span>
                                    <span className="circle animate-loader animation-delay-400"></span>
                                </div>
                        )}
                        </div>
                    ) : (
                        <div className={`mt-4 flex gap-x-2 justify-center`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <path d="M6.5 11L9.5 14L15.5 8M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#367E18" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            Wysłano ponownie e-mail!

                        </div>
                    )}
                </div>
            )}


        </div>

        <Footer/>


    </div>
    );
}

export default Register;
