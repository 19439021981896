import React from "react";

const brandOptions = [
    { label: "Abarth", value: "Abarth" },
    { label: "Acura", value: "Acura" },
    { label: "Aixam", value: "Aixam" },
    { label: "Alfa Romeo", value: "Alfa Romeo" },
    { label: "Alpine", value: "Alpine" },
    { label: "Aro", value: "Aro" },
    { label: "Asia", value: "Asia" },
    { label: "Aston Martin", value: "Aston Martin" },
    { label: "Audi", value: "Audi" },
    { label: "Austin", value: "Austin" },
    { label: "Autobianchi", value: "Autobianchi" },
    { label: "BAC", value: "BAC" },
    { label: "Baic", value: "Baic" },
    { label: "Bentley", value: "Bentley" },
    { label: "BMW-ALPINA", value: "BMW-ALPINA" },
    { label: "BMW", value: "BMW" },
    { label: "Bolloré", value: "Bolloré" },
    { label: "Brilliance", value: "Brilliance" },
    { label: "Bugatti", value: "Bugatti" },
    { label: "Buick", value: "Buick" },
    { label: "Cadillac", value: "Cadillac" },
    { label: "Casalini", value: "Casalini" },
    { label: "Caterham", value: "Caterham" },
    { label: "Chatenet", value: "Chatenet" },
    { label: "Chevrolet", value: "Chevrolet" },
    { label: "Chrysler", value: "Chrysler" },
    { label: "Citroën", value: "Citroën" },
    { label: "Cupra", value: "Cupra" },
    { label: "Dacia", value: "Dacia" },
    { label: "Daewoo", value: "Daewoo" },
    { label: "Daihatsu", value: "Daihatsu" },
    { label: "DeLorean", value: "DeLorean" },
    { label: "DFSK", value: "DFSK" },
    { label: "DKW", value: "DKW" },
    { label: "Dodge", value: "Dodge" },
    { label: "DR MOTOR", value: "DR MOTOR" },
    { label: "DS Automobiles", value: "DS Automobiles" },
    { label: "Eagle", value: "Eagle" },
    { label: "FAW", value: "FAW" },
    { label: "Ferrari", value: "Ferrari" },
    { label: "Fiat", value: "Fiat" },
    { label: "Ford", value: "Ford" },
    { label: "Galloper", value: "Galloper" },
    { label: "Gaz", value: "Gaz" },
    { label: "Geely", value: "Geely" },
    { label: "Genesis", value: "Genesis" },
    { label: "GMC", value: "GMC" },
    { label: "Grecav", value: "Grecav" },
    { label: "GWM", value: "GWM" },
    { label: "Hammer", value: "Hammer" },
    { label: "Holden", value: "Holden" },
    { label: "Honda", value: "Honda" },
    { label: "Hummer", value: "Hummer" },
    { label: "Hyundai", value: "Hyundai" },
    { label: "Infiniti", value: "Infiniti" },
    { label: "Inny", value: "Inny" },
    { label: "Isuzu", value: "Isuzu" },
    { label: "Iveco", value: "Iveco" },
    { label: "Jaguar", value: "Jaguar" },
    { label: "Jeep", value: "Jeep" },
    { label: "John Deere", value: "John Deere" },
    { label: "Kaipan", value: "Kaipan" },
    { label: "Kia", value: "Kia" },
    { label: "KTM", value: "KTM" },
    { label: "Lada", value: "Lada" },
    { label: "Lamborghini", value: "Lamborghini" },
    { label: "Lancia", value: "Lancia" },
    { label: "Land Rover", value: "Land Rover" },
    { label: "LEVC", value: "LEVC" },
    { label: "Lexus", value: "Lexus" },
    { label: "Ligier", value: "Ligier" },
    { label: "Lincoln", value: "Lincoln" },
    { label: "Lotus", value: "Lotus" },
    { label: "LTI", value: "LTI" },
    { label: "LuAZ", value: "LuAZ" },
    { label: "MAN", value: "MAN" },
    { label: "Maserati", value: "Maserati" },
    { label: "Maxus", value: "Maxus" },
    { label: "Maybach", value: "Maybach" },
    { label: "Mazda", value: "Mazda" },
    { label: "McLaren", value: "McLaren" },
    { label: "Mercedes-Benz", value: "Mercedes-Benz" },
    { label: "Mercury", value: "Mercury" },
    { label: "MG", value: "MG" },
    { label: "Microcar", value: "Microcar" },
    { label: "MINI", value: "MINI" },
    { label: "Mitsubishi", value: "Mitsubishi" },
    { label: "Nissan", value: "Nissan" },
    { label: "NSU", value: "NSU" },
    { label: "Nysa", value: "Nysa" },
    { label: "Oldsmobile", value: "Oldsmobile" },
    { label: "Oltcit", value: "Oltcit" },
    { label: "Opel", value: "Opel" },
    { label: "Peugeot", value: "Peugeot" },
    { label: "Piaggio", value: "Piaggio" },
    { label: "Plymouth", value: "Plymouth" },
    { label: "Polestar", value: "Polestar" },
    { label: "Polonez", value: "Polonez" },
    { label: "Pontiac", value: "Pontiac" },
    { label: "Porsche", value: "Porsche" },
    { label: "Proton", value: "Proton" },
    { label: "RAM", value: "RAM" },
    { label: "Rayton Fissore", value: "Rayton Fissore" },
    { label: "Renault", value: "Renault" },
    { label: "Rolls-Royce", value: "Rolls-Royce" },
    { label: "Rover", value: "Rover" },
    { label: "Saab", value: "Saab" },
    { label: "Santana", value: "Santana" },
    { label: "Saturn", value: "Saturn" },
    { label: "Seat", value: "Seat" },
    { label: "Seres", value: "Seres" },
    { label: "Shuanghuan", value: "Shuanghuan" },
    { label: "Skoda", value: "Skoda" },
    { label: "Skywell", value: "Skywell" },
    { label: "Smart", value: "Smart" },
    { label: "SsangYong", value: "SsangYong" },
    { label: "Subaru", value: "Subaru" },
    { label: "Suzuki", value: "Suzuki" },
    { label: "Syrena", value: "Syrena" },
    { label: "Talbot", value: "Talbot" },
    { label: "Tarpan", value: "Tarpan" },
    { label: "Tata", value: "Tata" },
    { label: "Tatra", value: "Tatra" },
    { label: "Tavria", value: "Tavria" },
    { label: "Tesla", value: "Tesla" },
    { label: "Toyota", value: "Toyota" },
    { label: "Trabant", value: "Trabant" },
    { label: "Triumph", value: "Triumph" },
    { label: "Uaz", value: "Uaz" },
    { label: "Vanderhall", value: "Vanderhall" },
    { label: "Vauxhall", value: "Vauxhall" },
    { label: "VELEX", value: "VELEX" },
    { label: "Volkswagen", value: "Volkswagen" },
    { label: "Volvo", value: "Volvo" },
    { label: "Warszawa", value: "Warszawa" },
    { label: "Wartburg", value: "Wartburg" },
    { label: "Wołga", value: "Wołga" },
    { label: "Yugo", value: "Yugo" },
    { label: "Zaporożec", value: "Zaporożec" },
    { label: "Zastava", value: "Zastava" },
    { label: "Żuk", value: "Żuk" },
];

const fuelTypeOptions = [
    { label: "Benzyna", value: "Benzyna"},
    { label: "Diesel", value: "Diesel"},
    { label: "Hybryda", value: "Hybryda"},
    { label: "Wodór", value: "Wodór"},
    { label: "Elektryczny", value: "Elektryczny"},
    { label: "Benzyna+LPG", value: "Benzyna+LPG"},
    { label: "Benzyna+CNG", value: "Benzyna+CNG"},
    { label: "Etanol", value: "Etanol"},
]

const colorOptions = [
    { label: 'Żółty', value: 'Żółty' },
    { label: 'Złoty', value: 'Złoty' },
    { label: 'Zielony', value: 'Zielony' },
    { label: 'Szary', value: 'Szary' },
    { label: 'Srebrny', value: 'Srebrny' },
    { label: 'Pomarańczowy', value: 'Pomarańczowy' },
    { label: 'Niebieski', value: 'Niebieski' },
    { label: 'Inny kolor', value: 'Inny kolor' },
    { label: 'Granatowy', value: 'Granatowy' },
    { label: 'Fioletowy', value: 'Fioletowy' },
    { label: 'Czerwony', value: 'Czerwony' },
    { label: 'Czarny', value: 'Czarny' },
    { label: 'Brązowy', value: 'Brązowy' },
    { label: 'Bordowy', value: 'Bordowy' },
    { label: 'Błękitny', value: 'Błękitny' },
    { label: 'Biały', value: 'Biały' },
    { label: 'Beżowy', value: 'Beżowy' },
]

const doorsOptions = [
    { label: '2 drzwi', value: '2'},
    { label: '3 drzwi', value: '3'},
    { label: '4 drzwi', value: '4'},
    { label: '5 drzwi', value: '5'},
    { label: '6 drzwi', value: '6'},
]


const seatsAmountOptions = [
    { label: '2 siedzenia', value: '2'},
    { label: '3 siedzenia', value: '3'},
    { label: '4 siedzenia', value: '4'},
    { label: '5 siedzeń', value: '5'},
    { label: '6 siedzeń', value: '6'},
    { label: '7 siedzeń', value: '7'},
    { label: '8 siedzeń', value: '8'},
    { label: '9 siedzeń', value: '9'},
]

const capacityFromOptions = [
    { label: '50 cm3', value: '50' },
    { label: '125 cm3', value: '125' },
    { label: '250 cm3', value: '250' },
    { label: '500 cm3', value: '500' },
    { label: '600 cm3', value: '600' },
    { label: '700 cm3', value: '700' },
    { label: '800 cm3', value: '800' },
    { label: '900 cm3', value: '900' },
    { label: '1000 cm3', value: '1000' },
    { label: '1250 cm3', value: '1250' },
    { label: '1500 cm3', value: '1500' },
    { label: '1800 cm3', value: '1800' },
    { label: '1900 cm3', value: '1900' },
    { label: '2000 cm3', value: '2000' },
    { label: '2200 cm3', value: '2200' },
    { label: '2400 cm3', value: '2400' },
    { label: '2500 cm3', value: '2500' },
    { label: '2600 cm3', value: '2600' },
    { label: '2800 cm3', value: '2800' },
    { label: '3000 cm3', value: '3000' },
    { label: '3500 cm3', value: '3500' },
    { label: '4000 cm3', value: '4000' },
    { label: '5000 cm3', value: '5000' },
    { label: '6000 cm3', value: '6000' },
    { label: '7000 cm3', value: '7000' },
]

const capacityToOptions = [
    { label: '50 cm3', value: '50' },
    { label: '125 cm3', value: '125' },
    { label: '250 cm3', value: '250' },
    { label: '500 cm3', value: '500' },
    { label: '600 cm3', value: '600' },
    { label: '700 cm3', value: '700' },
    { label: '800 cm3', value: '800' },
    { label: '900 cm3', value: '900' },
    { label: '1000 cm3', value: '1000' },
    { label: '1250 cm3', value: '1250' },
    { label: '1500 cm3', value: '1500' },
    { label: '1800 cm3', value: '1800' },
    { label: '1900 cm3', value: '1900' },
    { label: '2000 cm3', value: '2000' },
    { label: '2200 cm3', value: '2200' },
    { label: '2400 cm3', value: '2400' },
    { label: '2500 cm3', value: '2500' },
    { label: '2600 cm3', value: '2600' },
    { label: '2800 cm3', value: '2800' },
    { label: '3000 cm3', value: '3000' },
    { label: '3500 cm3', value: '3500' },
    { label: '4000 cm3', value: '4000' },
    { label: '5000 cm3', value: '5000' },
    { label: '6000 cm3', value: '6000' },
    { label: '7000 cm3', value: '7000' },
]

const powerFromOptions = [
    { label: '75 km', value: '75' },
    { label: '100 km', value: '100' },
    { label: '150 km', value: '150' },
    { label: '200 km', value: '200' },
    { label: '250 km', value: '250' },
    { label: '300 km', value: '300' },
    { label: '350 km', value: '350' },
    { label: '400 km', value: '400' },
    { label: '500 km', value: '500' },
    { label: '600 km', value: '600' },
]

const powerToOptions = [
    { label: '75 km', value: '75' },
    { label: '100 km', value: '100' },
    { label: '150 km', value: '150' },
    { label: '200 km', value: '200' },
    { label: '250 km', value: '250' },
    { label: '300 km', value: '300' },
    { label: '350 km', value: '350' },
    { label: '400 km', value: '400' },
    { label: '500 km', value: '500' },
    { label: '600 km', value: '600' },
]

const filtrationOptions = [
    { value:"", label: "Sortuj według"},
    { value:"highlighted", label: "Wyróżnione"},
    { value:"newest", label: "Najnowsze"},
    { value:"price_asc", label: "Cena rosnąco"},
    { value:"price_desc", label: "Cena malejąco"},
    { value:"mileage_asc", label: "Przebieg rosnąco"},
    { value:"mileage_desc", label: "Przebieg malejąco"},
    { value:"power_asc", label: "Moc rosnąco"},
    { value:"power_desc", label: "Moc malejąco"},
]


const transmissionOptions = [
    { label: "4x4 Dołączany automatycznie", value: "4x4 Dołączany automatycznie"},
    { label: "4x4 Dołączany ręcznie", value: "4x4 Dołączany ręcznie"},
    { label: "4x4 Stały", value: "4x4 Stały"},
    { label: "Na przednie koła", value: "Na przednie koła"},
    { label: "Na tylne koła", value: "Na tylne koła"},
]


const carStatusOptions = [
    { label: "Pokaż samochody nowe.", value: "is_new=true"},
    { label: "Pokaż samochody używane.", value: "is_new=false"},
    { label: "Pokaż oferty z numerem VIN", value: "vin=true"},
    { label: "Ma numer rejestracyjny", value: "is_register_plate=true"},
    { label: "Zarejestrowany w Polsce", value: "registered_in_poland=true"},
    { label: "Pierwszy właściciel", value: "first_owner=true"},
    { label: "Bezwypadkowy", value: "accident=false"},
    { label: "Wypadkowy", value: "accident=true"},
    { label: "Serwisowany w ASO", value: "serviced_in_aso=true"},
    { label: "Zarejestrowany jako zabytek", value: "registered_as_classic=true"},
    { label: "Tuning", value: "tuning=true"},
    { label: "Homologacja ciężarowa", value: "truck_approval=true"},
    { label: "Kierownica po prawej stronie, tzw. Anglik", value: "is_right_side=true"},
    { label: "Posiada DPF/GPF (Filtr cząstek stałych)", value: "is_DPF=true"},
]

const gearboxOptions = [
    { label: "Manualna", value: "Manualna"},
    { label: "Automatyczna", value: "Automatyczna"},
]


const carDamageOption = [
    { label: "Uszkodzony", value: "true"},
    { label: "Nieuszkodzony", value: "false"},
]

const bodyTypeOptions = [
    { label: "Auta małe", value: "Auta małe"},
    { label: "Auta miejskie", value: "Auta miejskie"},
    { label: "Coupe", value: "Coupe"},
    { label: "Kabriolet", value: "Kabriolet"},
    { label: "Kombi", value: "Kombi"},
    { label: "Kompakt", value: "Kompakt"},
    { label: "Minivan", value: "Minivan"},
    { label: "Sedan", value: "Sedan"},
    { label: "SUV", value: "SUV"},
]

const countryOptions = [
    { label: "Austria", value: "Austria" },
    { label: "Belgia", value: "Belgia" },
    { label: "Białoruś", value: "Białoruś" },
    { label: "Bułgaria", value: "Bułgaria" },
    { label: "Chorwacja", value: "Chorwacja" },
    { label: "Czechy", value: "Czechy" },
    { label: "Dania", value: "Dania" },
    { label: "Estonia", value: "Estonia" },
    { label: "Finlandia", value: "Finlandia" },
    { label: "Francja", value: "Francja" },
    { label: "Grecja", value: "Grecja" },
    { label: "Hiszpania", value: "Hiszpania" },
    { label: "Holandia", value: "Holandia" },
    { label: "Inny", value: "Inny" },
    { label: "Irlandia", value: "Irlandia" },
    { label: "Islandia", value: "Islandia" },
    { label: "Japonia", value: "Japonia" },
    { label: "Kanada", value: "Kanada" },
    { label: "Korea", value: "Korea" },
    { label: "Liechtenstein", value: "Liechtenstein" },
    { label: "Litwa", value: "Litwa" },
    { label: "Luksemburg", value: "Luksemburg" },
    { label: "Łotwa", value: "Łotwa" },
    { label: "Monako", value: "Monako" },
    { label: "Niemcy", value: "Niemcy" },
    { label: "Norwegia", value: "Norwegia" },
    { label: "Polska", value: "Polska" },
    { label: "Rosja", value: "Rosja" },
    { label: "Rumunia", value: "Rumunia" },
    { label: "Słowacja", value: "Słowacja" },
    { label: "Słowenia", value: "Słowenia" },
    { label: "Stany Zjednoczone", value: "Stany Zjednoczone" },
    { label: "Szwajcaria", value: "Szwajcaria" },
    { label: "Szwecja", value: "Szwecja" },
    { label: "Turcja", value: "Turcja" },
    { label: "Ukraina", value: "Ukraina" },
    { label: "Węgry", value: "Węgry" },
    { label: "Wielka Brytania", value: "Wielka Brytania" },
    { label: "Włochy", value: "Włochy" },
]

const equipmentOptions = {
    'Audio i multimedia': {
        'Apple CarPlay': false,
        'Android Auto': false,
        'Interfejs Bluetooth': false,
        'Radio': false,
        'Zestaw głośnomówiący': false,
        'Gniazdo USB dowolnego typu': false,
        'Ładowanie bezprzewodowe telefonu': false,
        'Nawigacja satelitarna': false,
        'System nagłośnienia': false,
        'Wyświetlacz typu Head-Up display': false,
        'Ekran dotykowy': false,
        'Głosowe sterowanie funkcjami pojazdu': false,
        'Dostęp do internetu': false,
    },
    'Komfort': {'Klimatyzacja automatyczna': false,
        'Klimatyzacja automatyczna dwustrefowa': false,
        'Klimatyzacja automatyczna - 3 strefowa': false,
        'Klimatyzacja automatyczna - 4+ strefowa': false,
        'Klimatyzacja manualna': false,
        'Hardtop': false,
        'Dach z tworzywa sztucznego': false,
        'Dach materiałowy': false,
        'Rolety na bocznych szybach elektryczne': false,
        'Rolety na bocznych szybach ręczne': false,
        'Dach panoramiczny': false,
        'Drugi szyberdach elektryczny': false,
        'Drugi szyberdach ręczny': false,
        'Szyberdach elektryczny': false,
        'Szyberdach ręczny': false,
        'Tapicerka Alcantara': false,
        'Tapicerka częściowo skórzana': false,
        'Tapicerka materiałowa': false,
        'Tapicerka welurowa': false,
        'Tapicerka skórzana': false,
        'Tylna osłona przeciwwietrzna (Windstop)': false,
        'Tapicerka': false,
        'Elektrycznie ustawiany fotel kierowcy': false,
        'Elektrycznie ustawiany fotel pasażera': false,
        'Podgrzewany fotel kierowcy': false,
        'Podgrzewany fotel pasażera': false,
        'Regul. elektr. podparcia lędźwiowego - kierowca': false,
        'Regul. elektr. podparcia lędźwiowego - pasażer': false,
        'Fotele przednie wentylowane': false,
        'Fotele przednie z funkcją masażu': false,
        'Siedzenie z pamięcią ustawienia': false,
        'Sportowe fotele - przód': false,
        'Ogrzewane siedzenia tylne': false,
        'Fotele tylne wentylowane': false,
        'Fotele tylne z funkcją masażu': false,
        'Podłokietniki - przód': false,
        'Podłokietniki - tył': false,
        'Kierownica skórzana': false,
        'Kierownica sportowa': false,
        'Kierownica ze sterowaniem radia': false,
        'Kolumna kierownicy regulowana elektrycznie': false,
        'Kierownica wielofunkcyjna': false,
        'Kierownica ogrzewana': false,
        'Zmiana biegów w kierownicy': false,
        'Dźwignia zmiany biegów wykończona skórą': false,
        'Cyfrowy kluczyk': false,
        'Keyless entry': false,
        'Keyless Go': false,
        'Uruchamianie silnika bez użycia kluczyków': false,
        'Ogrzewanie postojowe': false,
        'Automatyczna kontrola ogrzewania': false,
        'Czujnik deszczu': false,
        'Podgrzewana przednia szyba': false,
        'Wycieraczki': false,
        'Elektryczne szyby przednie': false,
        'Elektryczne szyby tylne': false,
        'Przyciemniane tylne szyby': false,
        'Roleta szyby tylnej regulowana elektrycznie': false,
        'Dach otwierany elektrycznie': false,
        'Dach otwierany elektrycznie pilotem': false,
    },
    'Samochody elektryczne': {
        'System odzyskiwania energii': false,
        'Funkcja szybkiego ładowania': false,
        'Kabel do ładowania': false,
    },
    'Systemy wspomagania kierowcy': {
        'Kontrola odległości z przodu (przy parkowaniu)': false,
        'Kontrola odległości z tyłu (przy parkowaniu)': false,
        'Park Assistant - asystent parkowania': false,
        'Niezależny system parkowania': false,
        'Kamera panoramiczna 360': false,
        'Kamera parkowania tył': false,
        'Lusterka boczne ustawiane elektrycznie': false,
        'Podgrzewane lusterka boczne': false,
        'Lusterka boczne składane elektrycznie': false,
        'Kamera w lusterku bocznym': false,
        'Asystent (czujnik) martwego pola': false,
        'Aktywny asystent zmiany pasa ruchu': false,
        'Lane assist - kontrola zmiany pasa ruchu': false,
        'Kontrola odległości od poprzedzającego pojazdu': false,
        'Ogranicznik prędkości': false,
        'Asystent hamowania - Brake Assist': false,
        'Asystent pokonywania zakrętów': false,
        'Kontrola trakcji': false,
        'Automatyczna kontrola zjazdu ze stoku': false,
        'Wspomaganie ruszania pod górę- Hill Holder': false,
        'Aktywne rozpoznawanie znaków ograniczenia prędkości': false,
        'System rozpoznawania znaków drogowych': false,
        'Asystent zapobiegania kolizjom na skrzyzowaniu': false,
        'Autonomiczny system kierowania': false,
        'Asystent świateł drogowych': false,
        'Lampy doświetlające zakręt': false,
        'Oświetlenie adaptacyjne': false,
        'Dynamiczne światła doświetlające zakręty': false,
        'Czujnik zmierzchu': false,
        'Spryskiwacze reflektorów': false,
        'Światła do jazdy dziennej': false,
        'Światła do jazdy dziennej LED': false,
        'Lampy przeciwmgielne': false,
        'Lampy przeciwmgielne w technologii LED': false,
        'Lampy tylne w technologii LED': false,
        'Oświetlenie drogi do domu': false,
        'Oświetlenie wnętrza LED': false,
        'System Start/Stop': false,
        'Elektroniczna kontrola ciśnienia w oponach': false,
        'Elektryczny hamulec postojowy': false,
        'Wspomaganie kierownicy': false,
        'Blokada mechanizmu różnicowego': false,
        'Regulowany dyferencjał centralny': false,
        'Asystent jazdy w korku': false,
    },
    'Dodatkowe wyposażenie': {
        'Felgi aluminiowe 15': false,
        'Felgi aluminiowe 16': false,
        'Felgi aluminiowe 17': false,
        'Felgi aluminiowe 18': false,
        'Felgi aluminiowe 19': false,
        'Felgi aluminiowe 20': false,
        'Felgi aluminiowe 21': false,
        'Felgi aluminiowe od 22': false,
        'Felgi stalowe': false,
        'Opony letnie': false,
        'Opony off-road': false,
        'Opony wielosezonowe': false,
        'Opony zimowe': false,
        'Opony runflat': false,
        'Zawieszenie komfortowe': false,
        'Elektroniczna regul. charakterystyki zawieszenia': false,
        'Zawieszenie sportowe': false,
        'Zawieszenie regulowane': false,
        'Zawieszenie powietrzne': false,
        'Zawieszenie hydropneumatyczne': false,
        'Hamulce z kompozytów ceramicznych': false,
        'Orurowanie przednie': false,
        'Zewnętrzne oklejenie': false,
        'Filtr cząstek stałych': false,
    },
    'Systemy bezpieczeństwa': {
        'ABS': false,
        'ESP': false,
        'Elektroniczny system rozdziału siły hamowania': false,
        'System wspomagania hamowania': false,
        'Asystent hamowania awaryjnego w mieście': false,
        'System hamowania awaryjnego dla ochrony pieszych': false,
        'Aktywny asystent hamowania awaryjnego': false,
        'System ostrzegający o możliwej kolizji': false,
        'System ochrony słuchu w przypadku kolizji': false,
        'System chroniący przed kolizja, tylny': false,
        'System chroniący przed kolizja, boczny': false,
        'Alarm ruchu poprzecznego z tyłu pojazdu': false,
        'System emitujący dźwięk silnika (AVAS)': false,
        'System wykrywania zmęczenie kierowcy': false,
        'System rekomendacji przerw podczas trasy': false,
        'Aktywny system monitorowania kondycji kierowcy': false,
        'Asystent pasa ruchu': false,
        'System minimalizujący skutki kolizji': false,
        'System powiadamiania o wypadku': false,
        'Poduszka powietrzna kierowcy': false,
        'Poduszka powietrzna pasażera': false,
        'Poduszka kolan kierowcy': false,
        'Poduszka kolan pasażera': false,
        'Kurtyny powietrzne - przód': false,
        'Poduszka powietrzna centralna': false,
        'Poduszki powietrzne czołowe - tył': false,
        'Boczna poduszka powietrzna kierowcy': false,
        'Boczne poduszki powietrzne - przód': false,
        'Kurtyny powietrzne - tył': false,
        'Boczne poduszki powietrzne - tył': false,
        'Kurtyna powietrzna z tyłu, poprzeczna': false,
        'Poduszka powietrzna pasów bezpieczeństwa': false,
        'Isofix (punkty mocowania fotelika dziecięcego)': false,
        'System zabezpieczający podczas dachowania': false,
    }
}

export {
    brandOptions,
    bodyTypeOptions,
    carDamageOption,
    carStatusOptions,
    fuelTypeOptions,
    countryOptions,
    equipmentOptions,
    colorOptions,
    doorsOptions,
    seatsAmountOptions,
    gearboxOptions,
    transmissionOptions,
    capacityFromOptions,
    capacityToOptions,
    powerFromOptions,
    powerToOptions,
    filtrationOptions,
}