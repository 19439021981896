import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const Carousel = ({ images, id }) => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };


  const handleAuctionClick = (auctionId) => {
        navigate(`/auta_osobowe/aukcja/${auctionId}`);
  }

  return (
        <div className="w-full relative rounded-[25px] lg:rounded-[20px] md:rounded-[30px] object-cover h-[250px] mx-auto">
          <div className="w-full h-full hover:cursor-pointer" onClick={(e) => handleAuctionClick(id)}>
              {images.map((image, index) => (
                  <div
                      key={index}
                      // className={`${
                      //   index === currentIndex
                      //     ? 'opacity-100 transition-opacity duration-500 ease-in-out'
                      //     : 'opacity-0 transition-opacity duration-500 ease-in-out'
                      // } w-full md:h-[258px] lg:h-[250px]`}
                      className={`${
                        index === currentIndex 
                            ? 'opacity-100'
                            : 'opacity-0'
                      } transition-opacity duration-300 ease-in-out absolute w-full h-full blur-wrapper rounded-[25px] lg:rounded-[20px] md:rounded-[30px]`}
                    >
                  {/*<img*/}
                  {/*  src={image}*/}
                  {/*  alt={`Image ${index + 1}`}*/}
                  {/*  className="w-full rounded-[25px] lg:rounded-[20px] md:rounded-[30px] object-cover h-[250px] mx-auto"*/}
                  {/*/>*/}
                      <img src={image} alt={`Zdjęcie aukcji na Autkotarg.pl`} className={`w-full image-blur-wrapper rounded-[20px] lg:rounded-[20px] md:rounded-[30px] absolute object-cover h-[250px] mx-auto z-0`} />
                      <img src={image} alt={`Zdjęcie aukcji na Autkotarg.pl`} className={`w-full rounded-[25px] lg:rounded-[20px] md:rounded-[30px] object-contain h-[250px] mx-auto z-20 backdrop-blur-md`} />
                </div>
              ))}
          </div>

        <button
        className="absolute top-1/2 left-2 transform -translate-y-1/2 text-white p-2 rounded-full z-0"
        onClick={goToPrevSlide}
        >
              <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"
                   aria-label="carousel arrow previous icon" role="img" height="32" width="32" className="text-white">
                  <title>Poprzednie zdjęcie</title>
                  <g filter="url(#a)">
                      <path d="M21.993 4 10 16.036 22 28" stroke="#fff" strokeWidth="2" strokeLinecap="round"
                            strokeLinejoin="round"></path>
                  </g>
                  <defs>
                      <filter id="a" x="-1" y="0" width="34" height="34" filterUnits="userSpaceOnUse"
                              colorInterpolationFilters="sRGB">
                          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                         result="hardAlpha"></feColorMatrix>
                          <feOffset dy="1"></feOffset>
                          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
                          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"></feColorMatrix>
                          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
                          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
                      </filter>
                  </defs>
              </svg>
        </button>

        <button
        className="absolute top-1/2 right-2 transform -translate-y-1/2 text-white p-2 rounded-full z-0"
        onClick={goToNextSlide}
        >
          <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="carousel arrow next icon"
               role="img" height="32" width="32" className="text-white"><title>Następne zdjęcie</title>
              <g filter="url(#a)">
                  <path d="M10.007 4 22 16.036 10 28" stroke="#fff" strokeWidth="2" strokeLinecap="round"
                        strokeLinejoin="round"></path>
              </g>
              <defs>
                  <filter id="a" x="-1" y="0" width="34" height="34" filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                      <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                     result="hardAlpha"></feColorMatrix>
                      <feOffset dy="1"></feOffset>
                      <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
                      <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"></feColorMatrix>
                      <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
                      <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
                  </filter>
              </defs>
          </svg>
        </button>
    </div>
  );
};

export default Carousel;