import React, {useEffect, useState} from "react";
import Navbar from "../components/Navbar";
import Stripes from "../components/Stripes";
import Footer from "../components/Footer";
import {useLocation, useSearchParams} from "react-router-dom";
import axiosInstance from "../api/axios";
import {Helmet} from "react-helmet";

function ConfirmEmail() {
    const [isLoading, setIsLoading ] = useState(true);
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isError, setIsError] = useState(false);
    const [isStatus200, setIsStatus200] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [count, setCount] = useState(3);

    useEffect(() => {
        const key = searchParams.get('key')

        const data = {
            key: key
        }

        axiosInstance.post(`/user/account-confirm-email/${key}/`,
            data,
            {
                withCredentials: true
            })
            .then((data) => {
                console.log(data)
                if (data.status === 200) {
                    console.log('status 200')
                    setIsLoading(false);
                    setIsStatus200(true);
                    setRedirect(true);
                }
            })
            .catch((e) => {
                setIsError(true);
                setIsLoading(false)
            })

    }, [location])

    useEffect(() => {
        if (redirect) {
            const timer = setInterval(() => {
                if (count > 0) {
                    setCount(count -1);
                } else {
                    clearInterval(timer);
                    window.location.href = '/logowanie'
                }
            }, 1000);


            return () => {
                clearInterval(timer);
            }

        }
    }, [count, redirect])

    const DESCRIPTION = "Potwierdzenie E-maila"

    return (
    <div className="App">
      {/*<Navbar color={navbar_color}/>*/}
        <Navbar/>
        <Stripes/>

        <Helmet
        onChangeClientState={(newState) => {
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
        metaDescription.setAttribute('content', DESCRIPTION || '');
        }
        }}>
            <title>Potwierdzenie E-maila - Autkotarg.pl</title>
        </Helmet>

        <div className={`w-full h-full mb-7`}>
            <div className={`w-11/12 md:w-1/2 lg:w-1/3 bg-base-100 mx-auto mt-9 rounded-[20px] pb-6 mb-24 shadow-sm geometric-shadow-gray transition duration-300`}>
                <h1 className={`text-[28px] text-center pt-4`}>Potwierdzanie E-mail w toku.</h1>

                { isLoading ? (
                    <div className="flex justify-center mt-10">
                        <span className="circle animate-loader"></span>
                        <span className="circle animate-loader animation-delay-200"></span>
                        <span className="circle animate-loader animation-delay-400"></span>
                    </div>
                ) : (
                    <div>
                        { isError && (
                            <div className={`text-center text-[20px] mx-auto w-11/12 pt-5`}>
                                <p>Przepraszamy nie udało się potwierdzić E-maila.</p>
                            </div>
                        )}

                        { isStatus200 && (
                            <div className={``}>
                                <div className={`text-center flex gap-x-2 mx-auto justify-center`}>
                                    <svg className={`pt-2 w-8 h-8`}
                                         xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                        <path d="M6.5 11L9.5 14L15.5 8M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#367E18" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <p className={`text-[20px] pt-1`}>Udało się potwierdzić e-mail!</p>
                                </div>
                                <div className={`text-center`}>
                                    <p className={`text-[20px] pt-2`}>Automatyczne przekierowanie do logowania za: {count}</p>
                                </div>
                            </div>
                        )}

                    </div>
                )}


            </div>
        </div>


        <Footer/>
    </div>
    );
}

export default ConfirmEmail;
