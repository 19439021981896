import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./pages/Home";
import CarAuctions from "./pages/CarAuctions";
import HomeClassics from "./pages/HomeClassics";
import CarAuctionPage from "./components/auction_pages/CarAuctionPage";
import CarAuctionComponent from "./components/auction_pages/auction_components/CarAuctionComponent";
import Login from "./pages/Login";
import Register from "./pages/Register";
import CreateAuction from "./pages/CreateAuction";
import ConfirmEmail from "./pages/ConfirmEmail";
import ConfirmResetPassword from "./pages/ConfirmResetPassword";
import SendConfirmationEmail from "./pages/SendConfirmResetPassword";
import SendConfirmResetPassword from "./pages/SendConfirmResetPassword";
import {Account, UserWishes, Settings, YourPayments, Success, Error} from "./pages/Account";
import CreateNormalAuction from "./pages/CreateNormalAuction";
import CarAuctionAdminPage from "./components/auction_pages/CarAuctionAdminPage";
import NormalAuctionPayment from "./pages/NormalAuctionPayment";
import Terms from "./pages/Terms";
import PriceList from "./pages/PriceList";
import Contact from "./pages/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AboutUs from "./pages/AboutUs";
import { SearchByProvince, SearchByCity } from "./pages/FooterSearch";
import { SeeMoreUserAuctions } from './pages/SeeMoreUserAuctions';
// import {UserWishes} from "./components/auction_pages/CarAuctionAdminPage";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route path="/klasyczne" element={<HomeClassics/>}/>
        {/*<Route path="/auta_osobowe" element={<CarAuctions/>}>*/}
        {/*  <Route path={"aukcja/:id"} element={<CarAuctionComponent/>}/>*/}
        {/*</Route>*/}
        {/*<Route path="/auta_osobowe">*/}
        {/*/!*<Route path="/auta_osobowe" element={<CarAuctions/>}>*!/*/}
        {/*  <Route index element={<CarAuctions />}/>*/}
        {/*  <Route path="aukcja/:id" element={<CarAuctionPage />} />*/}
        {/*</Route>*/}

        <Route path="/auta_osobowe">
          <Route index element={<CarAuctions />} />
          <Route path="aukcja/:id" element={<CarAuctionPage />} />
        </Route>
        <Route path="/aukcje_uzytkownika/:id" element={<SeeMoreUserAuctions />} />

        <Route path="/regulamin">
          <Route index element={<Terms />} />
          <Route path={"cennik"} element={<PriceList />} />
        </Route>

        <Route path="/prywatnosc">
            <Route index element={<PrivacyPolicy/>} />
        </Route>

        <Route path="/kontakt">
          <Route index element={<Contact />} />
        </Route>

        <Route path={"/o_nas"} element={<AboutUs/>}/>

        <Route path={"/samochody_osobowe_w"}>
          <Route index element={<SearchByProvince/>}/>
          <Route path=":province" element={<SearchByCity/>}/>
        </Route>

        {/*<Route path="/auta_osobowe/aukcja/:id" element={<CarAuctionPage/>}/>*/}
          <Route path="/dodaj_ogloszenie" element={<CreateAuction/>} />
          <Route path="/dodaj_zwykle_ogloszenie" element={<CreateNormalAuction/>} />
          {/*<Route path="/motocykle" element={<MotorcycleAuctions/>}/>*/}
          {/*<Route path="/auta_klasyczne" element={<ClassicCarAuctions/>}/>*/}
          {/*<Route path="/auta_dostawcze" element={<DeliveryCarAuctions/>}/>*/}
          <Route path="/logowanie" element={<Login/>}/>
          <Route path="/rejestracja" element={<Register/>}/>
          <Route path="/potwierdz_email" element={<ConfirmEmail/>}/>
          <Route path="/resetuj_haslo" element={<ConfirmResetPassword/>}/>
          <Route path="/email_resetuj_haslo" element={<SendConfirmResetPassword/>}/>
        <Route path="/konto">
          <Route index element={<Account/>}/>
          <Route path={"sukces"} element={<Success/>}/>
          <Route path={"blad"} element={<Error/>}/>
          <Route path={"obserwowane"} element={<UserWishes/>}/>
          <Route path={"ustawienia"} element={<Settings/>}/>
          <Route path="platnosci" element={<YourPayments/>}/>
          {/*<Route path={"ustawienia"} element={<Settings/>}/>*/}
          <Route path="auta_osobowe/:id" element={<CarAuctionAdminPage/>}/>
          <Route path="auta_osobowe/platnosc/:id" element={<NormalAuctionPayment/>}/>
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
