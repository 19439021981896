import React, {useEffect, useState} from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import CarAuctionComponent from "./auction_components/CarAuctionComponent";
import Stripes from "../Stripes";


const CarAuctionPage = () => {

  return (
      <div>
        <Navbar/>
        <Stripes/>
        <CarAuctionComponent/>
        <Footer/>
      </div>
  );
};

export default CarAuctionPage;