import React from "react";

const Stripes = (props) => {

    return (
        <div className={`pt-2 shadow-sm`}>
            <div className={`stripe-1`}></div>
            <div className={`stripe-2`}></div>
            <div className={`stripe-3`}></div>

        </div>
    );
}

export default Stripes;