import React, {useEffect, useState} from "react";
import Navbar from "../components/Navbar";
import Stripes from "../components/Stripes";
import Footer from "../components/Footer";
import {useLocation} from "react-router-dom";
import {animateScroll as scroll} from "react-scroll";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

function Terms() {

    const location = useLocation();

    useEffect(() => {
            scroll.scrollToTop({
                duration: 300,
            });
    }, [location]);

    const DESCRIPTION = "Regulamin określa zasady korzystania z naszego serwisu dla użytkowników publikujących Ogłoszenia na naszej platformie"

    return (
    <div className="App">
      {/*<Navbar color={navbar_color}/>*/}
        <Navbar/>
        <Stripes/>

        <Helmet
        onChangeClientState={(newState) => {
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
        metaDescription.setAttribute('content', DESCRIPTION || '');
        }
        }}>
            <title>Regulamin serwisu Autkotarg.pl</title>
        </Helmet>

        <div className={``}>
            <div className={`w-[94%] xsm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 bg-base-100 rounded-[20px] mt-10 pb-6 mx-auto geometric-shadow-gray transition duration-300`}>
                <h1 className={`text-center text-[24px] md:text-[26px] pt-5 pb-3`}>Regulamin strony</h1>
                <hr className={`pb-3 w-11/12 mx-auto mt-2`}/>
                <div className={`mx-auto w-[85%] md:w-full`}>
                    <h1 className={`text-[20px] text-center pb-3`}>Regulamin serwisu { process.env.REACT_APP_SERVICE_TERMS_NAME } dla klientów Indywidualnych</h1>
                    <hr className={`pb-3 w-11/12 mx-auto`}/>
                    <ol className={`w-11/12 mx-auto text-[16px] list-decimal flex flex-col gap-y-3 md:gap-y-1`}>
                        <li className={`font-[500] pt-1 w-11/12 md:w-full md:ml-3`}>Wstęp</li>
                        <p>Regulamin określa zasady, według których { process.env.REACT_APP_OWNER } świadczy usługi dla użytkowników Internetu, umożliwiając im publikację Ogłoszeń na platformie { process.env.REACT_APP_SERVICE_TERMS_NAME }. Określa również zasady dotyczące dostępu i korzystania z zasobów Serwisu { process.env.REACT_APP_SERVICE_TERMS_NAME }.</p>


                        <li className={`font-[500] pt-1 w-11/12 md:w-full md:ml-3`}>Definicje</li>
                        <ol start={1} className={`list-decimal w-11/12 mx-auto flex flex-col gap-y-3 md:gap-y-1`}>
                            <li>AUTKOTARG - Prowadzony przez { process.env.REACT_APP_OWNER} motoryzacyjny serwis ogłoszeniowy, dostępny pod domeną autkotarg.pl</li>
                            <li>Ogłoszenie - stworzone przez Użytkownika ogłoszenie dotyczące sprzedaży ogłaszanego przedmiotu, zamieszczane w serwisie AUTKOTARG poprzez wcześniejsze utworzenie Konta Użytkownika oraz stworzenie ogłoszenia za pomocą odpowiedniego formularza. Utworzenie ogłoszenia niejedna się z aktywacją (emitowaniem) ogłoszenia.</li>
                            <li>Użytkownik - osoba fizyczna, która ukończyła 18 lat i posiada pełną zdolność do czynności prawnych, osoba prawna oraz jednostka organizacyjna nieposiadająca osobowości prawnej, ale mogąca we własnym imieniu nabywać prawa i zaciągać zobowiązania w związku z opublikowanym przez siebie Ogłoszeniem, którzy w trybie przewidzianym w Regulaminie zawarli z { process.env.REACT_APP_OWNER} umowę w przedmiocie emisji Ogłoszenia w Serwisie AUTKOTARG i korzystają z usług świadczonych w ramach Serwisu AUTKOTARG.</li>
                            <li>Konto użytkownika - Konto stworzone w serwisie AUTKOTARG przez Użytkownika poprzez formularz rejestrujący konto. Tworząc konto w serwisie AUTKOTARG Użytkownik oświadcza, że zapoznał się regulaminem serwisu AUTKOTARG oraz akceptuje jego treść. Do stworzonego konta przypisywany jest indywidualny adres e-mail który umożliwia późniejsze logowanie się do konta przy pomocy nadawanego hasła przez użytkownika.</li>
                            <li>Panel administracyjny - Każde Konto Użytkownika posiada własny Panel Administracyjny w którym jest możliwość przeglądania wszystkich ogłoszeń stworzonych przez Użytkownika. W panelu administracyjnym przedstawiane są ogłoszenia aktywowane (emitowane) jak i nieaktywowane. Z jego poziomu Użytkownik może edytować wybrane ogłoszenie w polach wybranych przez AUTKOTARG. Z poziomu Panelu Administracyjnego jest możliwość aktywowania emisji ogłoszenia.</li>
                            <li>Cennik - Zestawienie cen przedmiotów ustalanych przez { process.env.REACT_APP_OWNER}, za usługi świadczone na Serwisie AUTKOTARG.</li>
                            <li>Przedmiot ogłoszenia - pojedynczy pojazd samochodowy dla którego jest przypisane Ogłoszenie.</li>
                            <li>Regulamin - niniejszy Regulamin</li>
                        </ol>

                        <li className={`font-[500] pt-1 w-11/12 md:w-full md:ml-3`}>Ogólne warunki korzystania z Serwisu { process.env.REACT_APP_SERVICE_TERMS_NAME }</li>
                        <ol start={1} className={`list-decimal w-11/12 mx-auto flex flex-col gap-y-3 md:gap-y-1`}>
                            <li>Każdy Użytkownik serwisu AUTKOTARG ma możliwość utworzenia ogłoszenia, chyba, że łamie on zasady korzystania i Regulamin serwisu AUTKOTARG.</li>
                            <li>Ogłoszenie w serwisie AUTKOTARG umieszcza Użytkownik, poprzez formularz tworzący Ogłoszenie uzupełniając wcześniej wszystkie wymagane pola.</li>
                            <li>Umieszczenie ogłoszenia na serwisie AUTKOTARG nie skutkuje jego natychmiastową aktywacją (emisją). Aby aktywować ogłoszenie, istnieje opcja opłacenia aktywacji lub skorzystania z kodu bonusowego.</li>
                            <li>Ogłoszenie może tylko i wyłącznie dotyczyć jednego Przedmiotu Ogłoszenia, definitywnie zakazanym jest wstawianie kilku Przedmiotów Ogłoszenia do jednego Ogłoszenia.</li>
                            <li>Edycja ogłoszenia przez Użytkownika jest dozwolona w wybranych polach przez AUTKOTARG. Edycja tych pól jest dostępna w Panelu Administracyjnym użytkownika po wcześniejszym wybraniu aukcji.</li>
                            <li>Statystyki Ogłoszeń pokazywane w Panelu Administracyjnym pełnią jedynie formę informacyjną i nie powinny być traktowane jako pełna reprezentacja rzeczywistych wyników. Ich celem jest dostarczenie użytkownikom ogólnego obrazu i informacji na temat aktywności oraz efektywności Ogłoszenia. Należy pamiętać, że statystyki te są przybliżone i mogą być podatne na różnice wynikające z wielu czynników, takich jak zmiany algorytmów, ustawień użytkownika czy błędów technicznych. AUTKOTARG nie ponosi odpowiedzialności za ewentualne konsekwencje wynikające z interpretacji statystyk. Zalecamy użytkownikom zachowanie zdrowego rozsądku i uwzględnienie innych czynników, które mogą wpływać na efektywność Ogłoszenia. Statystyki są narzędziem pomocniczym, a ich interpretacja powinna być traktowana jako ogólne wsparcie dla podejmowania decyzji. Zalecamy regularne monitorowanie statystyk w celu śledzenia ogólnej dynamiki, jednak zalecamy ostrożność przed ścisłym opieraniem się na nich przy podejmowaniu kluczowych decyzji. Administracja zastrzega sobie prawo do zmiany sposobu prezentacji statystyk oraz ich definicji bez wcześniejszego powiadomienia Użytkowników.</li>
                            <li>Zabronionym jest tworzenie kilku ogłoszeń dla tego samego Przedmiotu Ogłoszenia.</li>
                            <li>W ogłoszeniu należy jednoznacznie określić rzeczywistą i pełną cenę samochodu. Podczas tworzenia ogłoszenia, Użytkownik może wybrać typ ceny spośród opcji: Brutto, Netto, Negocjacja, Faktura. W przypadku ogłoszeń dotyczących samochodów osobowych, cena nie może być niższa niż 1000 złotych brutto (tysiąc złotych). Wprowadzanie ceny w formie raty lub ceny wykupu w przypadku umów leasingowych jest kategorycznie zabronione.</li>
                            <li>Czas aktywowania (emisji) Ogłoszenia wynosi 30 dni. Proces aktywowania Ogłoszenia jest opisany w Regulaminie. Po 30 dniach od zaksięgowania wpłaty (Czy też użyciu kodu bonusowego) Ogłoszenie zostaje dezaktywowane, będzie ono dalej dostępne do zobaczenia w Panelu Administracyjnym Użytkownika. Po dezaktywacji Ogłoszenia istnieje możliwość aktywowania ponownie tego samego Ogłoszenia.</li>
                            <li>Ogłoszenie powinno znajdować się w odpowiedniej kategorii Ogłoszeń. Kiedy Użytkownik zamieści Ogłoszenie w nieodpowiednim miejscu, AUTKOTARG zastrzega sobie możliwość usunięcia lub zmiany kategorii Ogłoszenia, bez wcześniejszego powiadamiania Użytkownika</li>
                            <li>Treść Ogłoszenia musi być zgodna z rzeczywistym stanem oraz obowiązującymi przepisami prawnymi. Przedstawione Ogłoszenie powinno precyzyjnie odzwierciedlać rzeczywiste zamiary dotyczące sprzedaży Przedmiotu ogłoszenia, określając jednocześnie cenę zgodnie z podaną w Ogłoszeniu kwotą. Wszelkie informacje dotyczące przedmiotu ogłoszenia, w tym między innymi cena, powinny być zgodne z danymi zawartymi w formularzu, który został wypełniony podczas dodawania ogłoszenia przez Użytkownika.</li>
                            <li>W stworzonym przez użytkownika Ogłoszeniu należy zawrzeć wyłącznie informacje dotyczące przedmiotu, o którym jest mowa w ogłoszeniu. W treści zamieszczonych zdjęć zabronione jest umieszczanie adresów e-mail, adresów stron internetowych, numerów telefonów, identyfikatorów komunikatorów internetowych oraz znaków wodnych lub innych oznaczeń identyfikujących użytkownika.</li>
                            <li>Użytkownik pełni odpowiedzialność za wszelkie treści (zarówno tekstowe, jak i fotograficzne), jakie publikuje, jednocześnie deklarując i gwarantując, że są one zgodne z rzeczywistym stanem Przedmiotu ogłoszenia oraz z obowiązującymi przepisami prawa. Ponadto, Użytkownik zapewnia, że opublikowane treści nie naruszają Regulaminu Serwisu AUTKOTARG ani interesów Tomasza Nowaka, a także nie kolidują z prawami osób trzecich, włączając w to prawa autorskie. { process.env.REACT_APP_OWNER} nie ponosi odpowiedzialności za działania podejmowane przez Użytkowników, zwłaszcza jeśli chodzi o prawdziwość, rzetelność i zgodność z przepisami prawa treści przez nich publikowanych.</li>
                            <li>Zabronione jest dodawanie Ogłoszeń nie mających związku z motoryzacją bądź nie mających związku z tematyką serwisu AUTKOTARG.</li>
                            <li>Zabronione jest dodawanie Ogłoszenia w serwisie AUTKOTARG zawierające wszelakie informacje o zamiarze kupna samochodu bądź zamiany.</li>
                            <li>Zabronionym jest znaczne zaniżanie wartości Przedmiotu Ogłoszenia.</li>
                            <li>Zabroniona jest manipulacja słowami kluczowymi w Ogłoszeniu, jak i podawanie informacji wprowadzających w błąd.</li>
                            <li>Zabronionym jest tworzenie Ogłoszenia w charakterze licytacji. </li>
                            <li>Zabrania się tworzenia ogłoszeń, które zawierają treści powszechnie uznane za obraźliwe, działania mające znamiona nieuczciwej konkurencji, naruszające dobre obyczaje, prawa autorskie, bądź inne prawa własności intelektualnej, szkodzące AUTKOTARG lub innym podmiotom. Ograniczenia obejmują także treści promocyjno-reklamowe lub ogłoszeniowe, linki lub logotypy prowadzące do innych stron internetowych niż AUTKOTARG, a także wszelkie działania wprowadzające w błąd.</li>
                            <li>Użytkownik upoważnia { process.env.REACT_APP_OWNER}, w okresie aktywacji (emisji) Ogłoszenia w serwisie AUTKOTARG i w celu promocji Ogłoszenia, do publikacji Ogłoszenia w wszelakich mediach społecznościowych.</li>
                            <li>Pobieranie, ściąganie, czy też praktyki "scrapowania" ogłoszeń wymaga zawsze wyraźnej zgody od Tomasza Nowaka i musi być zgodne z postanowieniami Regulaminu. Nadmierne i systematyczne obciążanie Serwisu AUTKOTARG zapytaniami (requestami) może skutkować późniejszą odpowiedzialnością za dokonane czyny.</li>
                            <li>Użytkownik tworząc konto w serwisie AUTKOTARG oświadcza, iż zapoznał się z Regulaminem i akceptuje jego treść. </li>
                            <li>AUTKOTARG zastrzega sobie pełną dowolność edytowania, zmiany kategorii czy też usunięcia ogłoszenia jeśli narusza ono ogólne warunki korzystania z Serwisu AUTKOTARG, bez wcześniejszego powiadamiania Użytkownika.</li>

                        </ol>

                        <li className={`font-[500] pt-1 w-11/12 md:w-full md:ml-3`}>Tworzenie Ogłoszeń i ich późniejsza edycja</li>
                        <ol start={1} className={`list-decimal w-11/12 mx-auto flex flex-col gap-y-3 md:gap-y-1`}>
                            <li>Aby stworzyć Ogłoszenie w serwisie AUTKOTARG Użytkownik musi wypełnić odpowiedni formularz dostępny w serwisie AUTKOTARG. Do stworzenia Ogłoszenia Użytkownik musi uzupełnić co najmniej wszystkie wymagane pola w formularzu. Do stworzenia Ogłoszenia wymagane jest wcześniejsze stworzenie konta w serwisie AUTKOTARG.</li>
                            <li>Użytkownik może umieścić za pomocą wyżej wymienionego formularzu od 1 do 45 zdjęć Przedmiotu Ogłoszenia w formacie określonym w formularzu.</li>
                            <li><b className={`font-[500]`}>Aktywacja Ogłoszenia następuje:</b></li>
                            <ol start={1} className={`list-decimal w-11/12 mx-auto flex flex-col gap-y-3 md:gap-y-1`}>
                                <li>Z chwilą zaksięgowania wpłaty poprzez przelew online obsługiwany przez Stripe.</li>
                                <li>Z chwilą użycia kodu bonusowego.</li>
                            </ol>
                            <li>Aktywacja Ogłoszenia (Emisja) trwa 30 dni, z chwilą minięcia 30 dnia aktywacji, Ogłoszenie zostaje dezaktywowane i będzie ono dostępne tylko do zobaczenia przez Użytkownika w Panelu Administracyjnym.</li>
                            <li>Po aktywacji Ogłoszenia użytkownik dostanie informację odnośnie aktywacji Ogłoszenia w Panelu administracyjnym po wejściu w aktywowane wcześniej Ogłoszenie. Również zostanie dostarczony e-mail z potwierdzeniem aktywacji Ogłoszenia, w przypadku problemów technicznych Użytkownik jest proszony o kontakt drogą e-mailową pod adresem pomoc@autkotarg.pl</li>
                            <li>W przypadku gdy Użytkownik chce kupić ponownie emisję Ogłoszenia na 30 dni, wystarczy aktywować Ogłoszenie tak jak zostało to opisane w punkcie 3.3 Regulaminu.</li>
                            <li>Użytkownik może edytować Ogłoszenie w wybranych polach przez AUTKOTARG w Panelu Administracyjnym.</li>
                            <li>Użytkownik może dobrowolnie usunąć Ogłoszenie (gdy jest aktywne lub nie jest aktywne). Usunięcie Ogłoszenia skutkuje jego permanentnym usunięciem, którego nie da się odwrócić.</li>
                            <li>W przypadku zakończenia czy też usunięcia Ogłoszenia przed upłynięciem 30 dni emisji nie przysługuje zwrot opłaty za niewykorzystane dni emisji Ogłoszenia.</li>
                            <li>Użytkownik może wybrać pakiet emisji Ogłoszenia, każdy z pakietów różni się ilością punktów 'podbić', które są uwzględnianie przy sortowaniu względem 'Wyróżnione'. Szczegóły pakietów zostały opisane w Cenniku.</li>
                            <li><b className={`font-[500]`}>Dezaktywacja Ogłoszenia następuje gdy:</b></li>
                            <ol start={1} className={`list-decimal w-11/12 mx-auto flex flex-col gap-y-3 md:gap-y-1`}>
                                <li>Mija czas aktywnych dni / emisji Ogłoszenia</li>
                                <li>Rozwiązanie umowy przez obie strony, w przypadku Użytkownika, przy wcześniejszym wysłaniu odpowiedniego oświadczenia na adres e-mail pomoc@autkotarg.pl</li>
                                <li>AUTKOTARG zastrzega sobie możliwość natychmiastowego zakończenia umowy z Użytkownikiem w sytuacji, gdy ten nie przestrzega ustalonych postanowień Regulaminu.</li>
                                <li>Kończy się czas trwania umowy</li>
                            </ol>
                        </ol>

                        <li className={`font-[500] pt-1 w-11/12 md:w-full md:ml-3`}>Cennik</li>
                        <ol start={1} className={`list-decimal w-11/12 mx-auto flex flex-col gap-y-3 md:gap-y-1`}>
                            <div className={`grid my-3 grid-cols-3 w-full mx-auto divide-2 divide-x outline outline-1 outline-gray-200 rounded-[20px]`}>
                                <div className={`mx-auto flex flex-col w-full gap-y-2 py-2`}>
                                    <p className={`text-center`}>Pakiet zwykły</p>
                                    <p className={`text-center`}>0 PLN</p>
                                </div>
                                <div className={`mx-auto flex flex-col w-full gap-y-2 py-2`}>
                                    <p className={`text-center`}>Pakiet 'Wyróżnij się'</p>
                                    <p className={`text-center`}>16 PLN</p>
                                </div>
                                <div className={`mx-auto flex flex-col w-full gap-y-2 py-2`}>
                                    <p className={`text-center`}>Pakiet 'Karta Prezesa'</p>
                                    <p className={`text-center`}>24 PLN</p>
                                </div>
                            </div>

                            <li>Użytkownik może kupić wybrany pakiet dla każdego Ogłoszenia nie łamiącego ogólnych warunków korzystania z serwisu AUTKOTARG.</li>
                            <li>Wyróżnione są trzy pakiety aktywacji Ogłoszenia</li>
                            <ol start={1} className={`list-decimal w-11/12 mx-auto flex flex-col gap-y-3 md:gap-y-1`}>
                                <li>Pakiet "zwykły" zawiera:</li>
                                <ol start={1} className={`list-decimal w-11/12 mx-auto flex flex-col gap-y-3 md:gap-y-1`}>
                                    <li>Aktywowanie (emisję) Ogłoszenia na 30 dni.</li>
                                    <li>Adekwatną oprawę graficzną do wybranego pakietu, w przypadku tego pakietu Ogłoszenie będzie wyglądało standardowo. (Na stronie zamówienia pakietu Ogłoszenia Użytkownik może zobaczyć podgląd karty Ogłoszenia).</li>
                                    <li>0 punktów podbić.</li>
                                </ol>
                                <li>Pakiet "Wyróżnij się" zawiera:</li>
                                <ol start={1} className={`list-decimal w-11/12 mx-auto flex flex-col gap-y-3 md:gap-y-1`}>
                                    <li>- Aktywowanie (emisję) ogłoszenia na 30 dni.</li>
                                    <li>- Adekwatną oprawę graficzną do wybranego pakietu, w przypadku pakietu "Wyróżnij się" Ogłoszenie będzie miało inną oprawę graficzną. (Na stronie zamówienia pakietu Ogłoszenia Użytkownik może zobaczyć podgląd karty Ogłoszenia).</li>
                                    <li>- 5 punktów podbić.</li>
                                </ol>
                                <li >Pakiet</li>
                                <ol start={1} className={`list-decimal w-11/12 mx-auto flex flex-col gap-y-3 md:gap-y-1`}>
                                    <li>Aktywowanie ogłoszenia na 30 dni.</li>
                                    <li>Adekwatną oprawę graficzną do wybranego pakietu, w przypadku pakietu "Karta Prezesa" Ogłoszenie będzie miało inną oprawę graficzną. (Na stronie zamówienia pakietu Ogłoszenia Użytkownik może zobaczyć podgląd karty Ogłoszenia).</li>
                                    <li>10 punktów podbić.</li>
                                </ol>
                            </ol>
                            <li>Punkty podbić są uwzględniane przy sortowaniu względem "Wyróżnionych"</li>
                            <li>Na stronie głównej AUTKOTARG wyświetlane są ogłoszenia samochodów osobowych, które są posortowane według punktów podbić w kolejności malejącej. W przypadku gdy wszystkie ogłoszenia na stronie głównej mają tyle samo punktów podbić, kolejność ogłoszeń jest losowa.</li>
                        </ol>
                        <li className={`font-[500] pt-1 w-11/12 md:w-full md:ml-3`}>Opłaty</li>
                        <ol start={1} className={`list-decimal w-11/12 mx-auto flex flex-col gap-y-3 md:gap-y-1`}>
                            <li>Usługi świadczone w serwisie AUTKOTARG na rzecz osób zamieszczających ogłoszenia są płatne. Szczegółowe informacje dotyczące opłat można znaleźć w Cenniku (Załącznik nr. 1)</li>
                            <li>Reklamacja</li>
                            <ol start={1} className={`list-decimal w-11/12 mx-auto flex flex-col gap-y-3 md:gap-y-1`}>
                                <li>Użytkownik ma prawo zgłaszać reklamacje w przypadku niewykonania bądź nienależytego wykonania przez serwis AUTKOTARG usług świadczonych w ramach serwisu AUTKOTARG.</li>
                                <li>Reklamacje należy zgłaszać AUTKOTARG drogą e-mailową pod adresem reklamacje@autkotarg.pl</li>
                                <li>Aby zgłosić reklamację, prosimy podać niezbędne dane Użytkownika, takie jak: imię, nazwisko, adres do korespondencji, adres e-mail powiązany z kontem Użytkownika w serwisie AUTKOTARG, numer telefonu, oraz identyfikator Ogłoszenia, do którego odnosi się reklamacja. Dodatkowo, prosimy o przedstawienie szczegółów uzasadniających reklamację.</li>
                                <li>Jeżeli podane dane w reklamacji przez Użytkownika wymagają uzupełnienia, AUTKOTARG zwróci się do Użytkownika o wskazanie dodatkowych informacji w podanym przez AUTKOTARG terminie.</li>
                                <li>Rozpatrzenie reklamacji nastąpi w terminie nie przekraczającym 30 dni od momentu otrzymania zgłoszenia reklamacyjnego.</li>
                                <li>Informacja dotycząca rozstrzygnięcia reklamacji zostanie przekazana Użytkownikowi poprzez wiadomość e-mail na adres podany w zgłoszonej reklamacji lub na adres korespondencyjny przypisany do serwisu AUTKOTARG.</li>
                            </ol>
                        </ol>

                        <li className={`font-[500] pt-1 w-11/12 md:w-full md:ml-3`}>Zasady odpowiedzialności</li>
                        <ol start={1} className={`list-decimal w-11/12 mx-auto flex flex-col gap-y-3 md:gap-y-1`}>
                            <li>AUTKOTARG nie ponosi odpowiedzialności za niedopełnienie lub nienależyte wykonanie umów zawartych przez Ogłoszeniodawcę w związku z ogłoszeniem. Ponadto, nie jest odpowiedzialny za konsekwencje działań podejmowanych przez Ogłoszeniodawcę i osoby trzecie, które są sprzeczne z postanowieniami Regulaminu. AUTKOTARG nie ponosi także odpowiedzialności za stan techniczny, bezpieczeństwo ani legalność oferowanych przedmiotów w ogłoszeniach. Nie gwarantuje również prawdziwości i rzetelności informacji podawanych przez Ogłoszeniodawcę, a także nie jest odpowiedzialny za zdolność sprzedających i kupujących do zrealizowania transakcji.</li>
                            <li>W przypadku naruszenia przez Użytkownika postanowień Regulaminu, { process.env.REACT_APP_OWNER} ma prawo podjąć różnorodne środki, zależnie od charakteru i rozmiaru naruszenia. To może obejmować konieczność przedstawienia dodatkowej dokumentacji potwierdzającej dane Użytkownika lub potwierdzenia przez Użytkownika spełnienia zobowiązań wobec { process.env.REACT_APP_OWNER}, w tym uregulowania wszelkich zaległych płatności. Ponadto, { process.env.REACT_APP_OWNER} ma prawo wysłać Użytkownikowi ostrzeżenie za pośrednictwem poczty elektronicznej, tymczasowo lub na stałe zawiesić publikację ogłoszenia lub ograniczyć funkcjonalność Konta Ogłoszeniodawcy w zakresie określonych usług w ramach Serwisu { process.env.REACT_APP_OWNER}. Ponadto, rejestracja Konta Ogłoszeniodawcy w Serwisie { process.env.REACT_APP_OWNER} może być warunkowana uzyskaniem wcześniejszej pisemnej zgody od strony { process.env.REACT_APP_OWNER}.</li>
                            <li>AUTKOTARG zastrzega sobie prawo do modyfikacji aktywnego bądź nieaktywnego Ogłoszenia w każdym zakresie bez wcześniejszego powiadamiania Użytkownika. - AUTKOTARG nie odpowiada za:</li>
                            <ol start={1} className={`list-decimal w-11/12 mx-auto flex flex-col gap-y-3 md:gap-y-1`}>
                                <li>Brak zainteresowania Przedmiotem Ogłoszenia</li>
                                <li>Brak zainteresowania Ogłoszeniem</li>
                                <li>Realizację obowiązków wynikających z rękojmi i gwarancji dotyczących przedmiotu ogłoszenia.</li>
                                <li>Oświadczenia składane przez Użytkownika drugiej stronie transakcji.</li>
                                <li>Działanie systemów teleinformatycznych lub urządzeń niezależnych od AUTKOTARG.</li>
                                <li>Działanie siły wyższej</li>
                            </ol>
                            <li>Wszystkie treści, grafiki, układy, znaki towarowe oraz inne informacje dostępne na stronie internetowej serwisu AUTKOTARG są objęte wyłącznymi prawami AUTKOTARG lub Użytkowników. Te elementy podlegają ochronie na podstawie prawa autorskiego, praw własności intelektualnej, w tym zarejestrowanych znaków towarowych, oraz praw do baz danych, co gwarantuje im pełną ochronę prawną.</li>
                        </ol>

                        <li className={`font-[500] pt-1 w-11/12 md:w-full md:ml-3`}>Zmiany w Regulaminie</li>
                        <ol start={1} className={`list-decimal w-11/12 mx-auto flex flex-col gap-y-3 md:gap-y-1`}>
                            <li>AUTKOTARG zastrzega sobie prawo do modyfikacji cennika i regulaminu. Informacje dotyczące wprowadzonych zmian oraz ich terminów obowiązywania będą udostępniane Użytkownikom poprzez stronę internetową serwisu AUTKOTARG lub za pośrednictwem środków elektronicznych.</li>
                            <li>Nowe postanowienia Regulaminu i Cennika stają się skuteczne nie krócej niż po 7 dniach od ich opublikowania na stronie internetowej AUTKOTARG. Usługi, które zostały aktywowane przez Ogłoszeniodawców przed wejściem w życie tych zmian, będą kontynuowane na dotychczasowych warunkach.</li>
                            <li>Jeżeli Ogłoszeniodawca nie zgadza się z wprowadzonymi zmianami w Regulaminie, powinien przesłać odpowiednie oświadczenie na adres e-mail: pomoc@autkotarg.pl. Takie oświadczenie skutkuje natychmiastowym rozwiązaniem umowy z AUTKOTARG, z zastrzeżeniem, że umowa ulegnie rozwiązaniu po upływie okresu emisji wszystkich dotychczasowych Ogłoszeń.</li>
                        </ol>

                        <li className={`font-[500] pt-1 w-11/12 md:w-full md:ml-3`}>Postanowienia końcowe</li>
                        <ol start={1} className={`list-decimal w-11/12 mx-auto flex flex-col gap-y-3 md:gap-y-1`}>
                            <li>Użytkownik, tworząc ogłoszenie w serwisie AUTKOTARG, powierza Tomaszowi Nowakowi zadanie publikowania ogłoszeń, w tym ich modyfikacji, w różnych mediach, takich jak prasa, telewizja, sieć internetowa, a także na banerach i billboardach. AUTKOTARG ma prawo umieścić znak wodny na zdjęciach dołączonych do ogłoszenia.</li>
                            <li>Regulamin jest dostępny online na stronie Serwisu AUTKOTARG oraz, w przypadkach wymaganych przez odpowiednie przepisy prawne, przesyłany Ogłoszeniodawcom drogą poczty elektronicznej na ich adres e-mail.</li>
                            <li>Wszelkie konflikty wynikłe z usług świadczonych w ramach Serwisu AUTKOTARG będą rozpatrywane przez właściwe polskie sądy powszechne.</li>
                            <li>Umowa pomiędzy Użytkownikiem będącym klientem a Tomaszem Nowakiem, dotycząca usług świadczonych w ramach Serwisu AUTKOTARG zgodnie z postanowieniami Regulaminu, podlega prawu polskiemu.</li>
                        </ol>
                    </ol>

                </div>

                <hr className={`w-11/12 mx-auto mt-6`}/>
                <h1 className={`text-center text-[24px] md:text-[26px] pt-5 pb-3`}>Załączniki</h1>
                <hr className={`pb-3 w-11/12 mx-auto mt-2`}/>
                <div className={`mx-auto w-[85%] md:w-full`}>
                    <h1 className={`text-[20px] text-center pb-3`}>Załączniki do regulaminu serwisu { process.env.REACT_APP_SERVICE_TERMS_NAME }.</h1>
                    <hr className={`pb-3 w-11/12 mx-auto`}/>
                </div>
                <div className={`mx-auto w-[85%] md:w-full`}>
                    <ol className={`w-11/12 mx-auto text-[16px] list-decimal flex flex-col gap-y-3 md:gap-y-1`}>
                        <li className={`font-[500] pt-1 w-11/12 md:w-full md:ml-3`}>Załączniki</li>
                        <ol start={1} className={`list-decimal w-11/12 mx-auto flex flex-col gap-y-3 md:gap-y-1`}>
                            <li className={``}><Link to={'/regulamin/cennik'} className={`underline text-main_green`}>Załącznik nr. 1 - Cennik</Link></li>
                            <li className={``}><Link to={'/prywatnosc'} className={`underline text-main_green`}>Załącznik nr. 2 - Polityka prywatności</Link></li>
                        </ol>
                    </ol>
                </div>
            </div>
        </div>

        <Footer/>
    </div>
    );
}

export default Terms;
