import React, { useState, useEffect } from 'react';
import home from "../../pages/Home";
import {Link} from "react-router-dom";
import HighlightedAuctionsCard from "./components/HomepageAuctionsCard";
// import axios from "axios";
import axiosInstance from "../../api/axios";

// function handleAuctionColor(active, highlighted, homepage_highlight) {
//     // console.log(active, highlighted, homepage_highlight);
//
//     let combinedClassName = '';
//
//     if (active === true && highlighted === false && homepage_highlight === false) {
//         combinedClassName += 'bg-base-100';
//     }
//     if (active === true && highlighted === true && homepage_highlight === false) {
//         combinedClassName += 'bg-[#F5FFF1] border-[#367E18] border-[3px]';
//     }
//     if (active === true && highlighted === true && homepage_highlight === true) {
//         combinedClassName += 'bg-[#FFF0EB] border-[#CC6B49] border-[3px]';
//     }
//
//     return combinedClassName;
// }

const HighlightedAuctions = (props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log('Fetching homepage auctions data from API')
        axiosInstance.get(`/homepage_auctions/normal`, {withCredentials: true})
          .then(response => response.data)
          .then(data => {
            setData(data);
            setLoading(false);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            setLoading(false);
          });
    }, []);

    if (loading) {
      return (
            // <div className="flex justify-center">
            //         <span className="circle animate-loader"></span>
            //         <span className="circle animate-loader animation-delay-200"></span>
            //         <span className="circle animate-loader animation-delay-400"></span>
            // </div>
            <div className="h-[326px]"></div>
      );
    }


    return (
                <div className={``}>
                    {/*<HighlightedAuctionsCard data={data}/>*/}

                    {data.results && data.results.length > 0 ? (
                        <div>
                            <HighlightedAuctionsCard data={data} divClassName="mt-4 w-full mx-auto 2xl:w-4/5 xl:w-11/12 lg:w-[96%] md:w-11/12 sm:w-full grid 3xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-x-1 gap-y-4 md:gap-4 skeleton"/>

                            { data.results.length <= 25 && (
                                <div className={`w-full md:w-1/2 mx-auto flex flex-col mb-14 mt-20 md:mt-16 skeleton`}>
                                    <p className={`text-[22px] md:text-[26px] w-11/12 text-center mx-auto`}>Ogłoś się na naszym portalu aukcyjnym!</p>
                                    <p className={`text-[20px] text-main_gray_dark md:text-[22px] mx-1 mx-auto text-center mt-2 w-11/12`}>Co należy zrobić aby twoje ogłoszenie się pojawiło na naszej stronie?</p>
                                    <hr className={`my-3 w-[90%] mx-auto`}/>
                                    <ul className={`mx-auto list-disc text-[18px] md:text-[20px] text-main_gray_dark w-[75%] flex flex-col`}>
                                        <li>Załóż konto</li>
                                        <li>Stwórz ogłoszenie</li>
                                        <li>Aktywuj ogłoszenie poprzez kod bonusowy bądź płatność</li>
                                    </ul>
                                    <p className={`text-[20px] text-main_gray_dark md:text-[22px] mx-auto text-center w-11/12 md:w-full mt-4`}>To wszystko, serdecznie witamy na pokładzie!</p>
                                </div>
                            )}

                            <div className={`mt-9 mb-2 flex justify-center mx-auto bg-[#F5FFF1] rounded-[13px] w-11/12 md:w-5/12`}>
                                <Link to={'/auta_osobowe?page=1'} className={`p-2.5 text-center w-full mx-auto rounded-[13px] bg-[#F5FFF1] border-[#367E18] border-[2px] geometric-shadow-green-sm transition duration-300
                        focus:outline-none focus:border-[#367E18] text-[20px] ring-0 focus:ring-0`}>Zobacz wszystkie aukcje!</Link>
                            </div>
                        </div>
                        ) : (
                            data.results && data.results.length === 0 ? (
                                <div className={`w-full md:w-1/2 mx-auto flex flex-col mb-32 mt-20 skeleton`}>
                                    <p className={`text-[22px] md:text-[26px] w-11/12 text-center mx-auto`}>Ogłoś się na naszym portalu aukcyjnym!</p>
                                    <p className={`text-[20px] text-main_gray_dark md:text-[22px] mx-1 mx-auto text-center mt-2 w-11/12`}>Co należy zrobić aby twoje ogłoszenie się pojawiło na naszej stronie?</p>
                                    <hr className={`my-3 w-[90%] mx-auto`}/>
                                    <ul className={`mx-auto list-disc text-[18px] md:text-[20px] text-main_gray_dark w-[75%] flex flex-col`}>
                                        <li>Załóż konto</li>
                                        <li>Stwórz ogłoszenie</li>
                                        <li>Aktywuj ogłoszenie poprzez kod bonusowy bądź płatność</li>
                                    </ul>
                                    <p className={`text-[20px] text-main_gray_dark md:text-[22px] mx-auto text-center w-11/12 md:w-full mt-4`}>To wszystko, serdecznie witamy na pokładzie!</p>
                                </div>

                            ) : (
                                // <div className="flex justify-center skeleton">
                                //         <span className="circle animate-loader"></span>
                                //         <span className="circle animate-loader animation-delay-200"></span>
                                //         <span className="circle animate-loader animation-delay-400"></span>
                                // </div>
                                <div></div>
                            )
                        )
                    }

                </div>

    );
}

export default HighlightedAuctions;
