import React, {useEffect, useState} from "react";
import Navbar from "../components/Navbar";
import Stripes from "../components/Stripes";
import Footer from "../components/Footer";
import {Link, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import axiosInstance from "../api/axios";
import {animateScroll as scroll} from "react-scroll";
import HighlightedAuctionsCard from "../components/homepage_auctions/components/HomepageAuctionsCard";
import {formatTimestamp} from "../components/auction_pages/auction_components/CarAuctionComponent";
import {handleLogout} from "../components/Navbar";
import {Helmet} from "react-helmet";
import { handleAuctionColor } from "../components/cards/CarAuctionsCard";



function SeeMoreUserAuctions() {

    const location = useLocation();
    const { id } = useParams();

    useEffect(() => {
        // window.scrollTo(0, 0);

        scroll.scrollToTop({
            duration: 300,
        });
    }, [location]);

    const [ searchParams, setSearchParams ] = useSearchParams()
    const [ auctionsData, setAuctionsData ] = useState('');
    const [ isLoaded, setIsLoaded ] = useState(false);

    useEffect(() => {
        console.log(id)
        
        if (searchParams.get('page') === null) {
            searchParams.set('page', 1);
            setSearchParams(searchParams);
        }
        
        axiosInstance.get(`/passenger/auctions/get_user_auctions/${id}?page=${searchParams.get('page')}`, {withCredentials: true})
            .then((response) => {
            console.log(response);
            setAuctionsData(response.data)
            setIsLoaded(true);
            }).catch((error) => {
            console.log(error)
        })
            
    }, [searchParams])

    const handleNextPage = (e) => {
        const page = parseInt(searchParams.get('page'))
        searchParams.set('page', page + 1)
        setSearchParams(searchParams)
        
    }

    const handlePreviousPage = (e) => {
        const page = parseInt(searchParams.get('page'))
        searchParams.set('page', page - 1)
        setSearchParams(searchParams)

    }

    const DESCRIPTION = `Sprawdź wszystkie ogłoszenia użytkownika ${auctionsData.business_name !== null ? (`${auctionsData.business_name}`) : ('')} na Autko targ!`;

    return (

        <div className="App">
      {/*<Navbar color={navbar_color}/>*/}
        <Navbar/>
        <Stripes/>
        <Helmet
            onChangeClientState={(newState) => {
            const metaDescription = document.querySelector('meta[name="description"]');
            if (metaDescription) {
                metaDescription.setAttribute('content', DESCRIPTION || '');
            }
        }}
        >
            <title>Ogłoszenia Użytkownika {auctionsData.business_name !== null ? (`${auctionsData.business_name}`) : ('')} - Autkotarg.pl</title>
        </Helmet>
        <div className={`w-full sm:w-11/12 md:w-11/12 h-full mb-7 flex flex-col md:flex-row mx-auto gap-x-3`}>
            <div className={`w-full lg:w-11/12 xl:w-11/12 mx-auto mt-5 pb-5`}>
                { isLoaded ? (
                        <div>
                            <div className={`mb-5 py-3.5 relative rounded-[20px] w-[97%] mx-auto md:w-full flex flex-col lg:flex-row gap-x-2 justify-center shadow-sm transition duration-300 ${handleAuctionColor(true, true, false)}`}>
                                <p className={`text-[20px] md:text-[22px] lg:text-[24px] text-black text-center`}>Ogłoszenia Użytkownika:</p>
                                <p className={`text-[20px] md:text-[22px] lg:text-[24px]  text-black text-center font-[500]`}>{auctionsData.business_name}</p>

                                { auctionsData.show_user_social_media && (
                                    <div className="flex justify-center mx-auto gap-x-1.5 relative lg:absolute right-0 top-0 md:top-0 lg:right-3">
                                        { auctionsData.website_href && (
                                            <a title="Strona użytkownika" href={`${auctionsData.website_href}`}>
                                                <svg className='w-10 h-10 mx-auto mt-3 ' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22M12 2C9.49872 4.73835 8.07725 8.29203 8 12C8.07725 15.708 9.49872 19.2616 12 22M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22M2.50002 9H21.5M2.5 15H21.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </a>
                                        )}
                                        { auctionsData.instagram_href && (
                                            <a title='Instagram sprzedającego' className='mx-auto' href={`${auctionsData.instagram_href}`}>
                                                <svg className='w-10 h-10 mt-3 mx-auto' xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
                                                    <path fill="#707070" d="M 16 3 C 8.8324839 3 3 8.8324839 3 16 L 3 34 C 3 41.167516 8.8324839 47 16 47 L 34 47 C 41.167516 47 47 41.167516 47 34 L 47 16 C 47 8.8324839 41.167516 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.086484 5 45 9.9135161 45 16 L 45 34 C 45 40.086484 40.086484 45 34 45 L 16 45 C 9.9135161 45 5 40.086484 5 34 L 5 16 C 5 9.9135161 9.9135161 5 16 5 z M 37 11 A 2 2 0 0 0 35 13 A 2 2 0 0 0 37 15 A 2 2 0 0 0 39 13 A 2 2 0 0 0 37 11 z M 25 14 C 18.936712 14 14 18.936712 14 25 C 14 31.063288 18.936712 36 25 36 C 31.063288 36 36 31.063288 36 25 C 36 18.936712 31.063288 14 25 14 z M 25 16 C 29.982407 16 34 20.017593 34 25 C 34 29.982407 29.982407 34 25 34 C 20.017593 34 16 29.982407 16 25 C 16 20.017593 20.017593 16 25 16 z"></path>
                                                </svg>
                                            </a>
                                        )}
                                        { auctionsData.facebook_href && (
                                            <a title='Facebook sprzedającego' className='mx-auto' href={`${auctionsData.facebook_href}`}>
                                                <svg className='w-10 h-10 mt-3 mx-auto' xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0,0,256,256">
                                                    <g fill="#707070" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0"><g transform="scale(5.12,5.12)"><path d="M9,4c-2.74952,0 -5,2.25048 -5,5v32c0,2.74952 2.25048,5 5,5h16.83203c0.10799,0.01785 0.21818,0.01785 0.32617,0h5.67383c0.10799,0.01785 0.21818,0.01785 0.32617,0h8.8418c2.74952,0 5,-2.25048 5,-5v-32c0,-2.74952 -2.25048,-5 -5,-5zM9,6h32c1.66848,0 3,1.33152 3,3v32c0,1.66848 -1.33152,3 -3,3h-8v-14h3.82031l1.40039,-7h-5.2207v-2c0,-0.55749 0.05305,-0.60107 0.24023,-0.72266c0.18718,-0.12159 0.76559,-0.27734 1.75977,-0.27734h3v-5.63086l-0.57031,-0.27149c0,0 -2.29704,-1.09766 -5.42969,-1.09766c-2.25,0 -4.09841,0.89645 -5.28125,2.375c-1.18284,1.47855 -1.71875,3.45833 -1.71875,5.625v2h-3v7h3v14h-16c-1.66848,0 -3,-1.33152 -3,-3v-32c0,-1.66848 1.33152,-3 3,-3zM32,15c2.07906,0 3.38736,0.45846 4,0.70117v2.29883h-1c-1.15082,0 -2.07304,0.0952 -2.84961,0.59961c-0.77656,0.50441 -1.15039,1.46188 -1.15039,2.40039v4h4.7793l-0.59961,3h-4.17969v16h-4v-16h-3v-3h3v-4c0,-1.83333 0.46409,-3.35355 1.28125,-4.375c0.81716,-1.02145 1.96875,-1.625 3.71875,-1.625z"></path></g></g>
                                                </svg>
                                            </a>
                                        )}
                                    </div>
                                )}
                            </div>
                            <HighlightedAuctionsCard data={auctionsData} divClassName={`mt-4 w-full mx-auto 2xl:w-full xl:w-full lg:w-full md:w-full sm:w-full grid 3xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-x-1 gap-y-4 md:gap-4`}/>
                            { (auctionsData.previous !== null) | (auctionsData.next !== null) ? (

                                <div className={`bg-base-100 rounded-[20px] flex flex-row shadow-sm geometric-shadow-gray transition duration-300 md:flex-row ${ auctionsData.previous !== null ? ('justify-between') : ('justify-end') } mt-5 py-3 px-1`}>
                                        <div onClick={handlePreviousPage} className={`flex gap-x-1 ${ auctionsData.previous !== null ? '' : 'hidden'} cursor-pointer`}>
                                            <svg className={`my-auto ml-4`}
                                                xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M7.70679 15.6651C7.51926 15.8795 7.26495 16 6.99979 16C6.73462 16 6.48031 15.8795 6.29279 15.6651L0.292786 8.80182C0.105315 8.58731 0 8.29641 0 7.99309C0 7.68978 0.105315 7.39888 0.292786 7.18437L6.29279 0.3211C6.48139 0.112733 6.73399 -0.00256299 6.99619 4.32416e-05C7.25838 0.00264948 7.5092 0.12295 7.6946 0.335034C7.88001 0.547119 7.98518 0.834018 7.98746 1.13394C7.98974 1.43386 7.88894 1.72281 7.70679 1.93854L3.41379 6.84922H14.9998C15.265 6.84922 15.5194 6.96973 15.7069 7.18425C15.8944 7.39877 15.9998 7.68972 15.9998 7.99309C15.9998 8.29647 15.8944 8.58742 15.7069 8.80194C15.5194 9.01646 15.265 9.13697 14.9998 9.13697H3.41379L7.70679 14.0476C7.89426 14.2622 7.99957 14.5531 7.99957 14.8564C7.99957 15.1597 7.89426 15.4506 7.70679 15.6651Z" fill="#777777"/>
                                            </svg>
                                            <button className={`text-[16px] md:text-[18px] text-main_gray_dark text-center md:text-left ml-1 md:ml-3 truncate hidden md:block`}>Poprzednia strona</button>
                                        </div>
                                        <div onClick={handleNextPage} className={`flex ${ auctionsData.next !== null ? '' : 'hidden'} cursor-pointer`}>
                                            <button className={`text-[16px] md:text-[18px] text-main_gray_dark text-center md:text-left mr-1 md:mr-3 truncate hidden md:block`}>Następna strona</button>
                                            <svg className={`my-auto mr-4`}
                                                xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M10.3664 0.334912C10.6008 0.120468 10.9187 0 11.2502 0C11.5816 0 11.8995 0.120468 12.1339 0.334912L19.634 7.19818C19.8684 7.41269 20 7.70359 20 8.00691C20 8.31022 19.8684 8.60112 19.634 8.81563L12.1339 15.6789C11.8982 15.8873 11.5824 16.0026 11.2546 16C10.9269 15.9974 10.6134 15.8771 10.3816 15.665C10.1499 15.4529 10.0184 15.166 10.0155 14.8661C10.0127 14.5661 10.1387 14.2772 10.3664 14.0615L15.7327 9.15078H1.25002C0.918492 9.15078 0.600545 9.03027 0.366121 8.81575C0.131698 8.60123 0 8.31028 0 8.00691C0 7.70353 0.131698 7.41258 0.366121 7.19806C0.600545 6.98354 0.918492 6.86303 1.25002 6.86303H15.7327L10.3664 1.95236C10.132 1.73785 10.0004 1.44695 10.0004 1.14363C10.0004 0.840319 10.132 0.549421 10.3664 0.334912Z" fill="#777777"/>
                                            </svg>
                                        </div>
                                </div>
                            ) : (
                                <div className="hidden"></div>
                            ) }
                        </div>
                    ) : (
                        <div>
                            <div className={`mb-5 py-3.5 rounded-[20px] w-[97%] mx-auto md:w-full flex flex-col md:flex-row gap-x-2 justify-center shadow-sm transition duration-300 ${handleAuctionColor(true, true, false)}`}>
                                <p className={`text-[20px] md:text-[22px] lg:text-[24px] text-center`}>Ogłoszenia Użytkownika:</p>
                            </div>
                            <div>
                                    <div className="flex justify-center w-full h-full mt-14">
                                        <span className="circle animate-loader"></span>
                                        <span className="circle animate-loader animation-delay-200"></span>
                                        <span className="circle animate-loader animation-delay-400"></span>
                                    </div>
                            </div>
                        </div>
                    )}
            </div>

        </div>

        <Footer/>
    </div>

    )
}

export {SeeMoreUserAuctions};