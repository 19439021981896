import React, {useEffect} from "react";
import Navbar from "../components/Navbar";
import Stripes from "../components/Stripes";
import LandingPageFiltration from "../components/filters/LandingPageFiltration";
import HighlightedAuctionsBanner from "../components/additionals/HighlightedAuctionsBanner";
import HighlightedAuctions from "../components/homepage_auctions/HomepageAuctions";
import Footer from "../components/Footer";
import {Link, useLocation} from "react-router-dom";
import {animateScroll as scroll} from "react-scroll";
import {Helmet} from "react-helmet";


// import bmw from '../../assets/images/bmw.jpg';
function CreateAuction() {

    const location = useLocation();

    useEffect(() => {
            scroll.scrollToTop({
                duration: 300,
            });
    }, [location]);

    useEffect(() => {
        if (localStorage.getItem('access_token') === null) {
            window.location.href = '/logowanie'
        }

    }, [])


    return (
    <div className="App">
      {/*<Navbar color={navbar_color}/>*/}
        <Navbar/>
        <Stripes/>
        <Helmet
        // onChangeClientState={(newState) => {
        // const metaDescription = document.querySelector('meta[name="description"]');
        // if (metaDescription) {
        // metaDescription.setAttribute('content', DESCRIPTION || '');
        // }
        // }}
        >
            <title>Wybierz jakie chcesz stworzyć ogłoszenie - Autkotarg.pl</title>
        </Helmet>
        <p className={`mt-5 text-[22px] md:text-[28px] w-full mx-1 text-center font-[400]`}>Wybierz jakie chcesz stworzyć ogłoszenie</p>
        <div className={`mt-3 pt-3 bg-base-100 w-11/12 md:w-9/12 lg:w-8/12 xl:w-7/12 mx-auto rounded-[20px] shadow-sm geometric-shadow-gray transition duration-300`}>
            <div className={`flex flex-col gap-y-5 md:gap-y-0 md:flex-row gap-x-3 px-5 py-5 mb-32`}>
                <Link to={`/dodaj_zwykle_ogloszenie`} className={`w-10/12 text-main_green mx-auto rounded-[20px] border border-main_green text-center px-4 py-2 hover:bg-main_green hover:text-white mt-[-8px] transition duration-200`}>
                    Ogłoszenie zwykłe
                </Link>
                {/*<Link to={`/dodaj_klasyczne_ogloszenie`} className={`w-10/12 text-main_green mx-auto rounded-[20px] border border-main_green text-center px-4 py-2 hover:bg-main_green hover:text-white mt-[-8px] transition duration-200`}>*/}
                {/*    Ogłoszenie klasyczne*/}
                {/*</Link>*/}

                <div className={`w-10/12 text-main_gray_dark mx-auto rounded-[20px] border border-main_gray_dark bg-base-200 text-center px-4 py-2 hover:text-black mt-[-8px] transition duration-200`}>
                    Ogłoszenie dla klasyka
                </div>
                <div className={`w-10/12 text-main_gray_dark mx-auto rounded-[20px] border border-main_gray_dark bg-base-200 text-center px-4 py-2 hover:text-black mt-[-8px] transition duration-200`}>
                    Ogłoszenie dla motocykla
                </div>

            </div>
        </div>

        <Footer/>
    </div>
    );
}

export default CreateAuction;
