import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { handleAuctionColor } from '../cards/CarAuctionsCard';

const HighlightedAuctionsBanner = (props) => {

    const [ isAuth, setIsAuth ] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('access_token') !== null ) {
            setIsAuth(true);
        }
    }, [isAuth])


    return (
        // <div className={``}>
        //     <div className={`mt-6 mb-6 mx-auto bg-base-100 rounded-[30px] 3xl:w-9/12 xl:w-4/5 lg:w-11/12 sm:w-11/12 w-[97%] shadow-sm geometric-shadow-gray transition duration-300`}>
        //         <div className={`pt-4`}>
        //             <p className={`text-[22px] md:text-[28px] text-center`}>Najważniejsze to szukać!</p>
        //         </div>
        //         <div className={`pt-3 flex justify-center pb-5 md:pb-4`}>
        //             {/*<button className="my-auto px-8 border border-main_red-100 bg-base-100 text-main_red-100 hover:bg-main_red-200 hover:border-main_red-200 hover:text-white py-2 px-4 rounded-[20px]">*/}
        //             {/*                Zobacz wszystkie wyróznione oferty*/}
        //             {/*</button>*/}
        //             <Link to={"/auta_osobowe?page=1&search=highlighted"} className={"my-auto w-11/12 md:w-auto px-8 border border-main_red-100 bg-base-100 text-main_red-100 hover:bg-main_red-200 hover:border-main_red-200 hover:text-white py-2 px-4 rounded-[20px] text-center transition duration-200"}>
        //                 Zobacz wszystkie oferty!
        //             </Link>
        //         </div>
        //     </div>
        // </div>



        // <div className={`w-full -mb-8 xsm:mb-0`}>
        //         <div className={`flex flex-col justify-between my-6 mx-auto rounded-[30px] w-full transition duration-300 ${handleAuctionColor(true, true, true)} border-dotted h-full`}>
        //             <div className={`pt-4`}>
        //                 <p className={`w-10/12 mx-auto text-[22px] md:text-[24px] text-center`}>Najważniejsze to szukać!</p>
        //                 <div className='w-11/12 xsm:-mb-10 mb-0 text-center text-main_gray_dark mx-auto py-3 md:text-[18px]'>
        //                     <p>Zobacz ogłoszenia wszystkich samochodów osobowych na Autkotargu!</p>
        //                 </div>
        //             </div>
        //             <div className={`pb-5 w-full flex justify-center`}>
        //                 {/*<button className="my-auto px-8 border border-main_red-100 bg-base-100 text-main_red-100 hover:bg-main_red-200 hover:border-main_red-200 hover:text-white py-2 px-4 rounded-[20px]">*/}
        //                 {/*                Zobacz wszystkie wyróznione oferty*/}
        //                 {/*</button>*/}
        //                 <Link to={"/auta_osobowe?page=1&search=highlighted"} className={"border-[1px] bg-white text-[#CC6B49] hover:text-white hover:bg-main_red-100 border-[#CC6B49] hover:border-main_red-100 py-2 px-8 md:px-10 rounded-[10px] text-center transition duration-300"}>
        //                     Zobacz oferty
        //                 </Link>
        //             </div>
        //         </div>
        //     </div>

        <div className={`flex flex-col xsm:flex-row gap-x-3 mx-auto 3xl:w-9/12 xl:w-4/5 lg:w-11/12 md:w-4/5 w-11/12 -mb-2 xsm:mb-12 skeleton`}>
            {/* <div className='bg-white reveal-car-info-panel h-[100px] hover:h-[1000px]'>
                <p>Hello</p>
            </div> */}

            <div className={`w-full -mb-8 xsm:mb-0`}>
            <div className={`flex flex-col justify-between my-6 mx-auto rounded-[30px] w-full transition duration-300 bg-white border-[3px] border-white geometric-shadow-gray shadow-sm h-full`}>
                {/* <div className={`flex flex-col justify-between my-6 mx-auto rounded-[30px] w-full transition duration-300 bg-white geometric-shadow-gray border-gray-400 border-[3px] border-dotted h-full`}> */}
                {/* <div className={`flex flex-col justify-between my-6 mx-auto rounded-[30px] w-full transition duration-300 ${handleAuctionColor(true, true, true)} border-dotted h-full`}></div> */}
                    <div className={`pt-4`}>
                        <p className={`w-10/12 mx-auto text-[22px] md:text-[24px] text-center skeleton`}>Najważniejsze to szukać!</p>
                        <div className='w-11/12 xsm:-mb-10 mb-0 text-center text-main_gray_dark mx-auto py-3 md:text-[18px] skeleton-200'>
                            <p>Zobacz ogłoszenia wszystkich samochodów osobowych na Autkotargu!</p>
                        </div>
                    </div>
                    <div className={`pb-5 w-full flex justify-center`}>
                        {/*<button className="my-auto px-8 border border-main_red-100 bg-base-100 text-main_red-100 hover:bg-main_red-200 hover:border-main_red-200 hover:text-white py-2 px-4 rounded-[20px]">*/}
                        {/*                Zobacz wszystkie wyróznione oferty*/}
                        {/*</button>*/}
                        <Link to={"/auta_osobowe?page=1&search=highlighted"} className={"border-[1px] bg-white text-main_red-100 hover:text-white hover:bg-main_red-100 border-main_red-100 hover:border-main_red-200 py-2 px-8 md:px-10 rounded-[20px] text-center transition duration-300 skeleton-200"}>
                            Zobacz oferty
                        </Link>
                    </div>
                </div>
            </div>
        
            <div className={`w-full`}>
                <div className={`my-6 flex flex-col justify-between mx-auto rounded-[30px] w-full transition duration-300 ${handleAuctionColor(true, true, false)} border-dotted h-full`}>
                    <div className={`pt-4`}>
                        <p className={`w-10/12 mx-auto text-[22px] md:text-[24px] text-center text-black skeleton`}>Aktywuj Ogłoszenie za darmo!</p>
                        <div className='mb-0 xsm:-mb-12 w-11/12 text-center mx-auto py-3 md:text-[18px] skeleton-200'>
                            {/* <p>Z kodem bonusowym <b className='font-[400] text-main_green decoration-solid'>AUTKOTARG</b> aktywuj podstawowy pakiet ogłoszenia całkowicie za darmo!</p> */}
                            <p>Podstawowy pakiet ogłoszenia twojego samochodu na naszej stronie jest całkowiecie za darmo!</p>
                        </div>
                    </div>
                    <div className={`pb-5 w-full flex justify-center mx-auto`}>
                        {/*<button className="my-auto px-8 border border-main_red-100 bg-base-100 text-main_red-100 hover:bg-main_red-200 hover:border-main_red-200 hover:text-white py-2 px-4 rounded-[20px]">*/}
                        {/*                Zobacz wszystkie wyróznione oferty*/}
                        {/*</button>*/}
                        {
                            isAuth ? (
                                <Link to={`/dodaj_ogloszenie`} className={`border-[1px] border-[#367E18] px-8 md:px-10 py-2 bg-white rounded-full transition duration-300 text-[#367E18] hover:bg-main_green hover:text-white`}>
                                    Stwórz ogłoszenie
                                </Link>
                            ) : (
                                <Link to={{pathname: `/logowanie`, search: `?return_url=dodaj_ogloszenie`}} className={`border-[1px] border-[#367E18] px-8 md:px-10 py-2 bg-white rounded-[20px] transition duration-300 text-[#367E18] hover:bg-main_green hover:text-white skeleton-200`}>
                                    Stwórz ogłoszenie
                                </Link>
                            )
                        }
                    </div>
                </div>
            </div>
            
            
        </div>

    );
}

export default HighlightedAuctionsBanner;
