import React, { useState } from 'react';
import {Link} from "react-router-dom";

const CookiesBanner = () => {
  const [acceptedCookies, setAcceptedCookies] = useState(
    localStorage.getItem('acceptedCookies') === 'true'
  );

  const handleAcceptCookies = () => {
    localStorage.setItem('acceptedCookies', 'true');
    setAcceptedCookies(true);
  };

  if (acceptedCookies) {
    return null; // Don't render the banner if cookies are accepted
  }

  return (
    <div className={'w-full h-full'}>
        <div className={`fixed bottom-3 w-full flex z-50`}>
            <div className={'w-full md:w-[98%] geometric-shadow-green-sm p-5 border-2 bg-[#F5FFF1] border-[#367E18] mx-1 md:mx-auto rounded-[20px] relative panel-content'}>
                <div className={`w-full h-full flex flex-row justify-between gap-x-3 md:gap-x-0`}>
                  <div className={`text-center md:text-left my-auto`}>Ta strona używa Cookies, Zapoznaj się z <Link className={`text-main_green underline`} to={'/prywatnosc'}>Polityką prywatności.</Link></div>
                  <button className={`border-[1px] border-[#367E18] bg-white px-2 md:px-8 py-2 rounded-[10px] geometric-shadow-green transition duration-300 text-main_green hover:bg-main_green hover:text-white`} onClick={handleAcceptCookies}>Akceptuję</button>
                </div>
            </div>
        </div>
    </div>
  );
};

export default CookiesBanner;