import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {GoogleOAuthProvider} from "@react-oauth/google";
import axios from "./api/axios";

// if (process.env.REACT_APP_DEBUG === 'production') {
//     console.log = () => {}
// }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      // <React.StrictMode>
        <App />
      // </React.StrictMode>
);


