import React, {useState} from "react";
import Navbar from "../components/Navbar";
import Stripes from "../components/Stripes";
import Footer from "../components/Footer";
import axiosInstance from "../api/axios";
import {useForm} from "react-hook-form";
import {useLocation} from "react-router-dom";
import {animateScroll as scroll} from "react-scroll";
import {useEffect} from "react";


function SendConfirmationEmail() {

    const { register, handleSubmit, formState: { errors }} = useForm()

    const [email, setEmail] = useState('');
    const [startSendResetPasswordEmail, setStartSendResetPasswordEmail] = useState(false);
    const [isSended, setIsSended] = useState(false);

    const location = useLocation();

    useEffect(() => {
            scroll.scrollToTop({
                duration: 300,
            });
    }, [location]);

    const submit = async (e) => {
        e.preventDefault();

        setStartSendResetPasswordEmail(true);

        const user = {
            email: email
        }

        await axiosInstance.post('/user/auth/password/reset/',
            user,
            {
                headers: {'Content-Type': 'application/json'}
            },
            {
                withCredentials: true
            }
        )
        .then((data) => {
            if (data.status === 200) {
                console.log(data)

                setStartSendResetPasswordEmail(false);
                setIsSended(true);
                // setErrorMessage('');
            }
        })
        .catch((e) => {
            console.log(e)
        })

    }

    return (
    <div className="App">
      {/*<Navbar color={navbar_color}/>*/}
        <Navbar/>
        <Stripes/>


            <div className={`w-full h-full mb-7`}>
                <form className={`w-11/12 md:w-1/2 lg:w-1/3 bg-base-100 mx-auto mt-9 rounded-[20px] pb-6 shadow-sm geometric-shadow-gray transition duration-300`} onSubmit={submit}>
                    <h1 className={`text-[28px] text-center pt-4`}>Resetuj hasło</h1>
                        <div className={`flex flex-col w-11/12 md:w-4/5 mx-auto pt-1`}>
                        <label className={`flex flex-row justify-between`}><p className={`text-[18px] pl-3 `}>E-mail</p></label>
                        <input
                            autoComplete={"email"}
                            type="email"
                            className="h-[41px] transition duration-200 block border-0 appearance-none w-full rounded-[30px] bg-base-200 focus:ring focus:ring-1 focus:ring-slate-600 px-3 py-2 pr-8 leading-tight"
                            placeholder={'Przykładowy@email.com'}
                            value={email}
                            name="email"
                            required={true}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </div>

                    <div className={`mx-auto w-1/2 mt-5 text-center h-10 flex items-center ${isSended ? (`bg-[#15a11e]`) : (`bg-main_red-100 hover:bg-main_red-200`)} text-white transition duration-200 rounded-[20px] relative`}>
                        
                        { startSendResetPasswordEmail ? (
                        <svg className="w-5 h-5 absolute right-3 top-2.5 animate-spin text-white mx-auto" xmlns="http://www.w3.org/2000/svg"
                        fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                    strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        ) : (
                        <div>

                        </div>
                        )}
                        <button className={`w-full h-full`} type="submit" disabled={isSended || startSendResetPasswordEmail}>
                            {isSended ? (<p>Wysłano! &#x2713;</p>) : ('Wyślij e-mail')}
                        </button>
                    </div>
                </form>
            </div>
        <Footer/>
    </div>
    );
}

export default SendConfirmationEmail;
