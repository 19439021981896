import React, {useEffect, useState, useContext} from "react";
import Navbar from "../components/Navbar";
import Stripes from "../components/Stripes";
import axiosInstance from "../api/axios";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import Footer from "../components/Footer";
import {animateScroll as scroll} from "react-scroll";
import logo from "../assets/images/logo.png";
import {Helmet} from "react-helmet";

// import bmw from '../../assets/images/bmw.jpg';
function Login() {

    const location = useLocation();
    const navigate = useNavigate();
    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ error, setError ] = useState(false)
    const [ errorMessage, setErrorMessage ] = useState('')
    const [ notVerifiedEmail, setNotVerifiedEmail ] = useState(false)
    const [ verifyEmailSended, setVerifyEmailSended ] = useState(false)
    const [ startSendEmail, setStartSendEmail ] = useState(false);
    const [ startSendResetPasswordEmail, setStartSendResetPasswordEmail] = useState(false);
    const [ resetPasswordEmailSended, setResetPasswordEmailSended ] = useState(false);
    const [ tempEmail, setTempEmail ] = useState('')
    const [isSubmiting, setIsSubmiting] = useState(false)

    const submit = async e => {
        e.preventDefault();

        setIsSubmiting(true)
        setVerifyEmailSended(false)

        const user = {
            email: email,
            password: password
          };

        await axiosInstance.post('/user/auth/login/', user, {
            headers: {
                'Content-Type': 'application/json'
            }
        }, {withCredentials: true})
            .then((data) => {
                console.log(data)
                // localStorage.clear();
                if (process.env.REACT_APP_HTTP) {
                    console.log(data.data)
                    localStorage.setItem('access_token', data.data.access);
                    localStorage.setItem('refresh_token', data.data.refresh);
                    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${data.data.access}`;
                } else {
                    console.log("HTTPS")
                    localStorage.setItem('access_token', data.data.access);
                    localStorage.setItem('refresh_token', data.data.refresh);
                    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${data.data.access}`;
                }
                // console.log(localStorage.getItem('access_token'), "XD",localStorage.getItem('refresh_token'), 'Siemanko! Logujemy!')
                if (searchParams.get('return_url') !== null) {
                    navigate(`/${searchParams.get('return_url')}`)
                } else {
                    navigate('/konto')
                }
            })
            .catch((error) => {
                if (error.response) {
                    // console.log(error.response.data, "błont")
                    console.log(error.response.data.non_field_errors)
                    const response_message = error.response.data.non_field_errors;
                    if (response_message[0] === 'Unable to log in with provided credentials.') {
                        setErrorMessage('Błąd: Podano zły E-mail bądź hasło')
                        setNotVerifiedEmail(false);
                        setResetPasswordEmailSended(false);
                    } else if (response_message[0] === 'E-mail is not verified.') {
                        setErrorMessage('Błąd: E-mail nie został zweryfikowany.');
                        setNotVerifiedEmail(true);
                    } else {
                        setErrorMessage('Błąd')
                    }

                    setError(true);
                }
        })
        
        setIsSubmiting(false)

        // console.log(data)
        // localStorage.clear();
        // localStorage.setItem('access_token', data.access);
        // localStorage.setItem('refresh_token', data.refresh);
        // axios.defaults.headers.common['Authorization'] = `Bearer ${data['access_token']}`;
        // window.location.href = '/'

    }

    const resendVerificationEmail = async () => {
        console.log(email);
        setStartSendEmail(true);
        setTempEmail(email);

        const user = {
            email: email
        }

        await axiosInstance.post('/user/auth/registration/resend-email/',
            user,
            {
                headers: {'Content-Type': 'application/json'}
            },
            {
                withCredentials: true
            }
        ).then((data) => {
            if (data.status === 200) {
                setVerifyEmailSended(true);
                setError(false)
                setStartSendEmail(false);
            }
        })


    }

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const sendResetPasswordEmail = async () => {

        setStartSendResetPasswordEmail(true);
        setTempEmail(email);

        const user = {
            email: email
        }

        await axiosInstance.post('/user/auth/password/reset/',
            user,
            {
                headers: {'Content-Type': 'application/json'}
            },
            {
                withCredentials: true
            }
        )
        .then((data) => {
            if (data.status === 200) {
                console.log(data)
                setResetPasswordEmailSended(true);
                setStartSendResetPasswordEmail(false);
                // setErrorMessage('');
            }
        })
        .catch((e) => {
            console.log(e)
        })

    }

    useEffect(() => {
        // window.scrollTo(0, 0);

        scroll.scrollToTop({
            duration: 300,
        });
    }, [location]);

    const DESCRIPTION = "Zaloguj się do naszego serwisu, aby swobodnie móc dodawać i oglądać ogłoszenia samochodów i nie tylko!";

    return (
    <div className="">
        <Navbar/>
        <Stripes/>

        <Helmet
        onChangeClientState={(newState) => {
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
        metaDescription.setAttribute('content', DESCRIPTION || '');
        }
        }}>
            <title>Zaloguj się w naszym serwisie - Autkotarg.pl</title>
            {/*<meta name={"description"} content={"Motoryzacjyny portal aukcyjny dla szukających swojego wymarzonego auta!"}/>*/}
            <meta property={'og:image'} content={`/${logo}`}/>
            {/*<link rel="shortcut icon" type="image/png" href={favicon} />*/}
        </Helmet>

        <div className={`w-full h-full mb-7`}>
            <form className={`w-11/12 md:w-2/3 lg:w-1/3 bg-base-100 mx-auto mt-9 rounded-[20px] pb-6 shadow-sm geometric-shadow-gray transition duration-300`} onSubmit={submit}>
                <h1 className={`text-[28px] text-center pt-4`}>Logowanie</h1>
                <div className={`flex flex-col w-11/12 md:w-4/5 mx-auto pt-1`}>
                    <label className={`text-[18px] pl-3`}>E-mail</label>
                    <input
                        type="email"
                        className="h-[41px] transition duration-200 block border-0 appearance-none w-full rounded-[30px] bg-base-200 focus:ring focus:ring-1 focus:ring-slate-600 px-3 py-2 pr-8 leading-tight"
                        placeholder={'Przykładowy@email.com'}
                        value={email}
                        required={true}
                        onChange={e => setEmail(e.target.value)}
                        autoComplete={"email"}
                        name={"email"}
                    />
                </div>
                <div className={`flex flex-col w-11/12 md:w-4/5 mx-auto mt-1`}>
                    <label className={`text-[18px] pl-3`}>Hasło</label>
                    <input
                        className="h-[41px] transition duration-200 block border-0 appearance-none w-full rounded-[30px] bg-base-200 focus:ring focus:ring-1 focus:ring-slate-600 px-3 py-2 pr-8 leading-tight"
                        placeholder={'Hasło'}
                        value={password}
                        type={'password'}
                        autoComplete={`current-password`}
                        required={true}
                        onChange={e => setPassword(e.target.value)}
                    />
                </div>

                { error && (
                    <div>
                        <div className={`text-red-500 mt-3 -mb-2 w-11/12 md:w-4/5 mx-auto`}>
                            { resetPasswordEmailSended ? (
                                <div></div>
                            ) : (
                                <p className={`text-center`}>{errorMessage}</p>
                            )}
                        </div>

                        { notVerifiedEmail && (
                            <div>
                            { startSendEmail ? (
                                <div className="flex justify-center h-12 mb-11 -mt-2 w-11/12 md:w-4/5 mx-auto">
                                    <span className="circle animate-loader"></span>
                                    <span className="circle animate-loader animation-delay-200"></span>
                                    <span className="circle animate-loader animation-delay-400"></span>
                                </div>
                                ) : (
                                <div className={`mt-5 -mb-1 flex-col md:flex md:gap-x-2 justify-center w-11/12 md:w-4/5 mx-auto`}>
                                    <p className={`text-center`}>Nie dostałeś e-maila weryfikującego?</p>
                                    <button className={`text-main_green mx-auto w-full`} onClick={resendVerificationEmail} type='button'>
                                        Kliknij tutaj
                                    </button>
                                </div>
                                )}
                            </div>
                        )}

                        { errorMessage === 'Błąd: Podano zły E-mail bądź hasło' && (
                            <div>
                                { resetPasswordEmailSended ? (
                                  <div className={`mt-6 -mb-1 text-center flex flex-col gap-x-2 justify-center w-11/12 md:w-4/5 mx-auto`}>
                                      <p>Wysłano pomyślnie na <b>{tempEmail}</b> <br/> E-mail resetujący hasło.</p>
                                      <svg className={`mx-auto mt-3 h-6 w-6`} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                        <path d="M6.5 11L9.5 14L15.5 8M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#367E18" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                      </svg>
                                  </div>
                                ) : (
                                    <div>
                                    { startSendResetPasswordEmail ? (

                                          <div className="flex justify-center h-12 mb-11 -mt-2 w-11/12 md:w-4/5 mx-auto">
                                            <span className="circle animate-loader"></span>
                                            <span className="circle animate-loader animation-delay-200"></span>
                                            <span className="circle animate-loader animation-delay-400"></span>
                                          </div>
                                        ) : (
                                            <div>
                                            </div>
                                        ) }
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}

                { verifyEmailSended && (
                    <div className={`mt-4 -mb-1 text-center flex flex-col gap-x-2 justify-center w-11/12 md:w-4/5 mx-auto`}>
                        <p>Wysłano pomyślnie na <b>{tempEmail}</b> <br/> E-mail aktywujący konto.</p>
                        <svg className={`mx-auto mt-3 h-6 w-6`} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                            <path d="M6.5 11L9.5 14L15.5 8M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#367E18" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                )}


                <div className={`mx-auto w-1/2 mt-5 text-center bg-main_red-100 hover:bg-main_red-200 text-white transition duration-200 rounded-[20px]`}>
                    <button className={`w-full h-full p-2.5`} type="submit" disabled={isSubmiting}>
                        Zaloguj się
                    </button>
                </div>

                <div className={`mt-5 text-center`}>
                    <p>Nie masz konta? <Link className={`text-main_green`} to={'/rejestracja'}> Zarejestruj się!</Link></p>
                </div>
                <div className={`mt-1 -mb-1 flex gap-x-2 justify-center w-11/12 md:w-4/5 mx-auto`}>
                    Zapomniałeś hasła?
                    {/*<button className={`text-main_green`} onClick={sendResetPasswordEmail} type='button'>*/}
                    {/*    Kliknij tutaj*/}
                    {/*</button>*/}
                    <Link className={`text-main_green`} to={'/email_resetuj_haslo'}>Kliknij tutaj</Link>
                </div>
            </form>
        </div>

        <Footer/>


    </div>
    );
}

export default Login;
