import React, {useEffect} from "react";
import Navbar from "../components/Navbar";
import Stripes from "../components/Stripes";
import LandingPageFiltration from "../components/filters/LandingPageFiltration";
import HighlightedAuctionsBanner from "../components/additionals/HighlightedAuctionsBanner";
import HighlightedAuctions from "../components/homepage_auctions/HomepageAuctions";
import Footer from "../components/Footer";
import {useLocation} from "react-router-dom";
import {animateScroll as scroll} from "react-scroll";
import {Helmet} from "react-helmet";
import logo from "../assets/images/logo.png";

// import bmw from '../../assets/images/bmw.jpg';
function Home() {

    const location = useLocation();

    useEffect(() => {
        // window.scrollTo(0, 0);

        scroll.scrollToTop({
            duration: 300,
        });
    }, [location]);

    const DESCRIPTION = "Ogłoszenia samochodowe, ogłoszenia klasyków czy też ogłoszenia youngtimerów! Zobacz jakie samochody możesz kupić w Polsce!";

    return (
    <div className="App">
      {/*<Navbar color={navbar_color}/>*/}
        <Helmet
        onChangeClientState={(newState) => {
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
        metaDescription.setAttribute('content', DESCRIPTION || '');
        }
        }}>
            <title>Autkotarg.pl - Motoryzacyjny portal ogłoszeniowy!</title>
            {/*<meta name={"description"} content={"Motoryzacjyny portal aukcyjny dla szukających swojego wymarzonego auta!"}/>*/}
            <meta property={'og:image'} content={`/${logo}`}/>
            {/*<link rel="shortcut icon" type="image/png" href={favicon} />*/}
        </Helmet>
        <Navbar/>
        <Stripes/>
        <LandingPageFiltration/>
        <HighlightedAuctionsBanner/>
        <HighlightedAuctions/>
        <Footer/>
    </div>
    );
}

export default Home;
