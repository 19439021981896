import React, {useEffect, useState} from "react";
import Navbar from "../components/Navbar";
import Stripes from "../components/Stripes";
import Footer from "../components/Footer";
import {Link, useLocation, useSearchParams} from "react-router-dom";
import axiosInstance from "../api/axios";

function ConfirmResetPassword() {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [isTheSame, setIsTheSame] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [isPasswordChangeLoading, setIsPasswordChangeLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [succesfull, setSuccesfull] = useState(false);

    useEffect(() => {
        if (password1 !== password2) {
            setIsTheSame(false);
            setPasswordError('Hasła nie są takie same!')
        } else if ((password1.length > 1 && password1.length < 8) && (password2.length > 1 && password2.length < 8) && (password1 === password2) ) {
            setIsTheSame(false);
            setPasswordError('Hasło ma mniej niż 8 znaków')
        } else {
            if (password1.length >= 8 && password2.length >= 8 ) {
                setIsTheSame(true);
            }
        }
    }, [password1, password2])

    const submit = async e => {
        e.preventDefault();
        setIsPasswordChangeLoading(true);

        const uid = searchParams.get('uid')
        const token = searchParams.get('token')

        const user = {
            new_password1: password1,
            new_password2: password2,
            uid: uid,
            token: token
        };

        await axiosInstance.post('/user/auth/password/reset/confirm/', user, {
            headers: {
                'Content-Type': 'application/json'
            }
        }, {withCredentials: true})
            .then((data) => {
                setIsPasswordChangeLoading(false);
                if (data.status === 200) {
                    setSuccesfull(true);
                }
            })
            .catch((error) => {
                console.log(error);
                setIsError(true);
            })

    }


    return (
    <div className="App">
      {/*<Navbar color={navbar_color}/>*/}
        <Navbar/>
        <Stripes/>

        <div className={`w-full h-full mb-32`}>
            { succesfull ? (
                <div className={`w-11/12 md:w-1/2 lg:w-1/3 bg-base-100 mx-auto mt-9 rounded-[20px] pb-6 shadow-sm geometric-shadow-gray transition duration-300`}>
                    <div className={`flex mx-auto justify-center gap-x-2`}>
                        <svg className={`w-8 h-8 mt-5`} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                            <path d="M6.5 11L9.5 14L15.5 8M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#367E18" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <h1 className={`text-[28px] text-center pt-4`}>Sukces!</h1>
                    </div>
                    <p className={`pt-2 mx-auto text-[22px] text-center`}>Udało się ustawić nowe hasło!</p>
                    <div className={`justify-center flex pt-1`}>
                        <Link className={`text-main_green text-[22px] mb-1`} to={'/logowanie'}>Zaloguj się!</Link>
                    </div>
                </div>
            ) : (

                <div>
                    { isError ? (
                        <div className={`w-11/12 md:w-1/2 lg:w-1/3 bg-base-100 mx-auto mt-9 rounded-[20px] pb-6 hover:shadow-sm transition duration-300`}>
                            <div className={`flex mx-auto justify-center gap-x-2`}>
                                <svg className={`w-8 h-8 mt-5`} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                    <path d="M14 8L8 14M8 8L14 14M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#E22B2B" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round"/>
                                </svg>
                                <h1 className={`text-[28px] text-center pt-4`}>Błąd!</h1>
                            </div>
                            <p className={`pt-2 mx-auto text-[22px] text-center`}>Niestety nie udało się zresetować hasła, spróbuj ponownie.</p>
                        </div>
                    ) : (
                        <form className={`w-11/12 md:w-1/2 lg:w-1/3 bg-base-100 mx-auto mt-9 rounded-[20px] pb-6 hover:shadow-sm transition duration-300`} onSubmit={submit}>
                            <h1 className={`text-[28px] text-center pt-4`}>Resetuj hasło</h1>
                            <div className={`flex flex-col w-11/12 md:w-4/5 mx-auto pt-1`}>
                                <label className={`text-[18px] pl-3`}>Hasło</label>
                                <input
                                    type="password"
                                    className="h-[41px] transition duration-200 block border-0 appearance-none w-full rounded-[30px] bg-base-200 focus:ring focus:ring-1 focus:ring-slate-600 px-3 py-2 pr-8 leading-tight"
                                    placeholder={'Hasło'}
                                    minLength={8}
                                    value={password1}
                                    required={true}
                                    onChange={e => setPassword1(e.target.value)}
                                />
                            </div>
                            <div className={`flex flex-col w-11/12 md:w-4/5 mx-auto mt-1`}>
                                <label className={`text-[18px] pl-3`}>Potwierdź hasło</label>
                                <input
                                    type="password"
                                    minLength={8}
                                    className="h-[41px] transition duration-200 block border-0 appearance-none w-full rounded-[30px] bg-base-200 focus:ring focus:ring-1 focus:ring-slate-600 px-3 py-2 pr-8 leading-tight"
                                    placeholder={'Potwierdź hasło'}
                                    value={password2}
                                    required={true}
                                    onChange={e => setPassword2(e.target.value)}
                                />
                            </div>

                            { isTheSame === false && (
                                <div className={`text-red-500 mt-3 -mb-2`}>
                                    <p className={`text-center`}>{passwordError}</p>
                                </div>
                            )}

                            <div className={`mx-auto w-1/2 mt-5 text-center h-10 ${ isTheSame === false ? ('bg-gray-400 disabled') : ('bg-main_red-100 hover:bg-main_red-200') } text-white transition duration-200 rounded-[20px]`}>
                                <button className={`w-full h-full flex items-center gap-x-3`} disabled={setIsPasswordChangeLoading} type="submit">
                                    { isPasswordChangeLoading && isError === false ? (
                                    <svg className="w-5 h-5 -mr-5 md:-mr-11 animate-spin text-white mx-auto" xmlns="http://www.w3.org/2000/svg"
                                         fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                                strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor"
                                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    ) : (
                                    <div>

                                    </div>
                                    )}
                                    <p className={`mx-auto`}>Zatwierdź</p>
                                </button>
                            </div>


                        </form>
                    )}
                </div>
            )}
        </div>


        <Footer/>
    </div>
    );
}

export default ConfirmResetPassword;
