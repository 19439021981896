import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import axiosInstance from "../api/axios";


export const handleLogout = () => {
        (async () => {
         try {
           const {data} = await
                 axiosInstance.post('user/auth/logout/',{
                    // refresh_token:localStorage.getItem('refresh_token')
                 } ,{headers: {'Content-Type': 'application/json'}},
                 {withCredentials: true});

           // localStorage.clear();
           localStorage.removeItem('access_token')
           localStorage.removeItem('refresh_token')

           axiosInstance.defaults.headers.common['Authorization'] = null;
           window.location.href = '/logowanie'
           } catch (e) {
             console.log('logout not working', e)
           }
         })();
}


const Navbar = (props) => {
    const [ nav, setNav ] = useState(false);
    const [ isAuth, setIsAuth ] = useState(false);

    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true)

    const handleScroll = () => {
        const screenWidth = window.innerWidth;
        const currentScrollPos = window.scrollY;

        if (screenWidth < 1024) {
            if(currentScrollPos > prevScrollPos){
                setVisible(false)
            } else {
                setVisible(true)
            }
    
            setPrevScrollPos(currentScrollPos)
        }
    }

    useEffect( () => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll)
    })

    useEffect(() => {
        if (localStorage.getItem('access_token') !== null ) {
            setIsAuth(true);
        }
    }, [isAuth])

    const showNavbar = () => {
        console.log(!nav);
        setNav(!nav)
    }

    return (

        // <nav className={`top-0 z-20 h-20 bg-base-100 mx-auto px-20 rounded-b-lg w-full `}>
        // <nav className={`z-20 h-[90px] md:h-[109px] bg-base-100 mx-auto px-8 lg:px-20 rounded-b-lg w-full fixed transition-transform transform duration-300 md:top-0 ${visible ? 'top-0' : ''} sticky lg:block lg:relative border-b border-1 lg:border-0`}>
            <nav className={`z-20 h-[90px] md:h-[109px] bg-base-100 mx-auto px-8 lg:px-20 rounded-b-lg w-full fixed lg:top-0 ${visible ? 'top-0 transition-all duration-300 ease-in-out' : '-top-24 md:-top-32 transition-all duration-300 ease-in-out'} sticky lg:block lg:relative border-b border-1 lg:border-0`}>
{/*                <nav*/}
{/*                  className={`transition ease-linear duration-700 border-gray-900 bg-gray-50 flex border-b w-full fixed z-50 ${*/}
{/*                    visible ? 'top-0' : ''}`*/}
{/*                }*/}
{/*>*/}
                <div className={`flex justify-between lg:grid md:grid-cols-3 justify-center py-7 md:py-10 max-w-full`}>
                      <div className="font-[400] text-3xl">
                          <Link className={'truncate text-[36px] logo-font'} to={"/"}>{ process.env.REACT_APP_SERVICE_FULL_NAME }</Link>
                      </div>
                      <div className={`lg:hidden h-full w-24 -mx-4`} onClick={showNavbar}>
                          <svg className={`mx-auto`}
                              xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 14" fill="none">
                            <path d="M1 7H19M1 1H19M1 13H19" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                      </div>


                      <div className={`text-center font-[500] pt-1.5 hidden lg:block`}>
                          {/*<Link to={"/klasyczne"}>YoungTimery / Klasyki</Link>*/}
                      </div>
                          { isAuth ? (
                            <div className={`text-main_green lg:flex lg:justify-end pt-1.5 hidden lg:block gap-x-4`}>
                                {/*<button onClick={handleLogout} className={`text-right mb-2.5`}>*/}
                                {/*    Wyloguj się*/}
                                {/*</button>*/}
                                <Link to={'/konto'} className={`text-right mb-2.5 mt-[2px]`}>
                                    Konto
                                </Link>
                                <Link to={`/dodaj_ogloszenie`} className={`rounded-[20px] border border-main_green text-center px-4 py-2.5 hover:bg-main_green hover:text-white mt-[-8px] transition duration-200`}>
                                    Dodaj ogłoszenie
                                </Link>
                            </div>
                          ) : (
                            <div className={`text-main_green lg:flex lg:justify-end pt-1.5 hidden lg:block`}>
                              <Link to={`/logowanie`} className={`text-right pr-5`}>
                                    Zaloguj się
                              </Link>
                              <Link to={{pathname: `/logowanie`, search: `?return_url=dodaj_ogloszenie`}} className={`rounded-[20px] border border-main_green text-center px-4 py-2 hover:bg-main_green hover:text-white mt-[-8px] transition duration-200`}>
                                    Dodaj ogłoszenie
                              </Link>
                            </div>
                          )}
                          {/*my-auto px-8 bg-main_red hover:bg-blue-700 text-white py-2 px-4 rounded-[20px]*/}

                </div>

                <div className={nav ? ('fixed left-0 top-0 bg-base-100 border-r-[2px] border-main_grey_dark h-full w-[100%] z-50 ease-in-out duration-500') : ('ease-in-out duration-500 fixed left-[-100%] top-0 bg-base-100 h-full w-[70%] z-50')}>
                    <div className={`flex justify-between lg:grid md:grid-cols-3 justify-center py-7 md:py-9 max-w-full border-b border-1 border-b-main_gray_dark`}>
                        <div className="font-[400] text-[36px] pl-7 logo-font">
                          <Link to={"/"}>{ process.env.REACT_APP_SERVICE_FULL_NAME }</Link>
                        </div>
                        <div className={`h-full w-24 -mx-4`} onClick={showNavbar}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 14 14" fill="none">
                                <path d="M13 1L1 13M1 1L13 13" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                        </div>
                    </div>

                    {/*<div className={`lg:hidden h-full absolute right-11 top-11`} onClick={showNavbar}>*/}
                    {/*      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 14 14" fill="none">*/}
                    {/*            <path d="M13 1L1 13M1 1L13 13" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
                    {/*      </svg>*/}
                    {/*</div>*/}

                    { isAuth ? (
                        <ul className={`flex flex-col gap-y-5 mt-8`}>
                            {/*<Link to={`/klasyczne`} className={`w-11/12 text-main_red-100 mx-auto rounded-[20px] border border-main_red-100 text-center px-4 py-2 hover:border-main_red-200 hover:bg-main_red-200 hover:text-white mt-[-8px] transition duration-200`}>*/}
                            {/*    Auta klasyczne / YoungTimery*/}
                            {/*</Link>*/}

                            <Link to={`/`} className={`w-11/12 text-main_red-100 mx-auto rounded-[20px] border border-main_red-100 text-center px-4 py-2 hover:border-main_red-200 hover:bg-main_red-200 hover:text-white mt-[-8px] transition duration-200`}>
                                Strona główna
                            </Link>

                            <Link to={`/dodaj_ogloszenie`} className={`w-11/12 text-main_green mx-auto rounded-[20px] border border-main_green text-center px-4 py-2 hover:bg-main_green hover:text-white mt-[-8px] transition duration-200`}>
                                Dodaj ogłoszenie
                            </Link>

                            <Link to={`/konto`} className={`w-11/12 text-main_green mx-auto rounded-[20px] border border-main_green text-center px-4 py-2 hover:bg-main_green hover:text-white mt-[-8px] transition duration-200`}>
                                Konto
                            </Link>

                            <Link to={`/regulamin`} className={`w-11/12 text-main_green mx-auto rounded-[20px] border border-main_green text-center px-4 py-2 hover:bg-main_green hover:text-white mt-[-8px] transition duration-200`}>
                                Regulamin strony
                            </Link>

                            <Link to={`/prywatnosc`} className={`w-11/12 text-main_green mx-auto rounded-[20px] border border-main_green text-center px-4 py-2 hover:bg-main_green hover:text-white mt-[-8px] transition duration-200`}>
                                Polityka prywatności
                            </Link>

                            <button onClick={handleLogout} className={`w-11/12 text-main_green mx-auto rounded-[20px] border border-main_green text-center px-4 py-2 hover:bg-main_green hover:text-white mt-[-8px] transition duration-200`}>
                                Wyloguj się
                            </button>
                        </ul>

                    ) : (
                        <ul className={`flex flex-col gap-y-5 mt-8`}>
                            {/*<Link to={`/klasyczne`} onClick={showNavbar} className={`w-11/12 text-main_red-100 mx-auto rounded-[20px] border border-main_red-100 text-center px-4 py-2 hover:border-main_red-200 hover:bg-main_red-200 hover:text-white mt-[-8px] transition duration-200`}>*/}
                            {/*    Auta klasyczne / YoungTimery*/}
                            {/*</Link>*/}

                            <Link to={{pathname: `/logowanie`, search: `?return_url=dodaj_ogloszenie`}} onClick={showNavbar} className={`w-11/12 text-main_green mx-auto rounded-[20px] border border-main_green text-center px-4 py-2 hover:bg-main_green hover:text-white mt-[-8px] transition duration-200`}>
                                Dodaj ogłoszenie
                            </Link>


                            <Link to={`/logowanie`} onClick={showNavbar} className={`w-11/12 text-main_green mx-auto rounded-[20px] border border-main_green text-center px-4 py-2 hover:bg-main_green hover:text-white mt-[-8px] transition duration-200`}>
                                Zaloguj się / zarejestruj
                            </Link>

                            <Link to={`/regulamin`} className={`w-11/12 text-main_green mx-auto rounded-[20px] border border-main_green text-center px-4 py-2 hover:bg-main_green hover:text-white mt-[-8px] transition duration-200`}>
                                Regulamin strony
                            </Link>

                            <Link to={`/prywatnosc`} className={`w-11/12 text-main_green mx-auto rounded-[20px] border border-main_green text-center px-4 py-2 hover:bg-main_green hover:text-white mt-[-8px] transition duration-200`}>
                                Polityka prywatności
                            </Link>
                        </ul>
                    )}

                </div>

            </nav>
    );
}

export default Navbar;
