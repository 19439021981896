import Navbar from "../components/Navbar";
import Stripes from "../components/Stripes";
import Footer from "../components/Footer";
import {Link, useLocation, useParams} from "react-router-dom";
import axiosInstance from "../api/axios";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import HighlightedAuctionsCard from "../components/homepage_auctions/components/HomepageAuctionsCard";
import {handleAuctionColor} from "../components/cards/CarAuctionsCard";
import {animateScroll as scroll} from "react-scroll";


const Payment = ({id, auctionData}) => {
    const [ selectedOption, setSelectedOption ] = useState(null);
    const [ selectedOptionDict, setSelectedOptionDict ] = useState(null);
    const [ isChecked, setIsChecked ] = useState(false);
    const [ paymentLoading, setPaymentLoading ] = useState(false);

    const location = useLocation();

    useEffect(() => {
        // window.scrollTo(0, 0);

        scroll.scrollToTop({
            duration: 300,
        });
    }, [location]);

    const {
        register,
        control,
        handleSubmit,
        setValue,
        clearErrors,
        setError,
        getValues,
        formState: { errors },

    } = useForm();

    const handleRadioChange = (id) => {
        // console.log(id, radioOptions)
        if (id >= 0 && id <= radioOptions.length) {
            setSelectedOption(id);
            setSelectedOptionDict(radioOptions[id - 1]);
            // console.log(radioOptions[id - 1]);
            setIsChecked(true);
            setValue('radioGroup', radioOptions[id - 1]);
        } else {
            // console.log("Error");
        }
    };

    const radioOptions = [
        {
            id: 1,
            key: 1,
            label: 'Option 1',
            description: "Pakiet zwykły",
            detailedDescription: (
                <p>
                    <b className="font-[500]">Pakiet zwykły aktywuję twoją aukcję.</b> Użytkownicy serwisu będą mogli zobaczyć ofertę twojego pojazdu! W tym pakiecie otrzymujesz schludną oprawę graficzną aukcji. Sprawdź również inne karty oraz ich benefity!
                </p>
            ),
            price: '0',
            detailedPrice: '0.00',
            days: 30,
        },
        {
            id: 2,
            key: 2,
            label: 'Option 2',
            description: "Pakiet - 'Wyróżnij się!'",
            detailedDescription: (
                <p>
                  Karta typu <b className="font-[500]">'Wyróżnij się' aktywuję twoją aukcję oraz dodaje jej unikatową oprawę graficzną.</b> Twoja aukcja dostaje również <b className="font-[500]">5&nbsp;punktów podbić</b>, dzięki temu będziesz wyświetlany nad Kartami zwykłymi (Przy filtrowaniu względem 'Wyróżnione')
                </p>
            ),
            price: '16',
            detailedPrice: '16.00',
            days: 30,
        },
        {
            id: 3,
            key: 3,
            label: 'Option 3',
            description: "Pakiet Karty Prezesa",
            detailedDescription: (
                <p>
                    <b>Karta Prezesa</b> - przede wszystkim wybór tej karty zapewnia Ci <b className="font-[500]">aktywowanie oraz specjalną oprawę graficzną ogłoszenia</b> jak i również <b className="font-[500]">10&nbsp;punktów podbicia aukcji</b> - Dzięki temu twoja karta będzie się wyświetlać wyżej niż zielona oraz biała karta (Przy filtrowaniu względem 'Wyróżnione')
                </p>
            ),
            price: '24',
            detailedPrice: '24.00',
            days: 30,
        },
    ];

    const onPaymentSubmit = (e) => {
        console.log(e)

        // if (e.radioGroup.id)
        console.log(e.radioGroup.id)
        if (e.radioGroup.id === 1) {
            setPaymentLoading(true);
            
            // If policy of our company changes then post API for stripe payment.
            // axiosInstance.post(`/passenger/auctions/user/activate_payment/${id}`)
            //     .then((data) => {
            //         console.log(data)
            //         const paymentLink = data.data

            //         window.location.href = paymentLink
            //     }).catch((error) => {
            //         console.log(error)
            // })

            axiosInstance.post('/bonus_codes/activate', {
                id: id,
                code: 'AUTKOTARG'
            }, {withCredentials: true}).then((data) => {
                window.location.href = `/konto/sukces`
            }).catch((error) => {
                if (error.response.status === 400) {
                    setError('code', {message: "Wykorzystano kod!"})
                } else {
                    setError('code', {message: "Zły kod!"})
                }
            })

        } else if (e.radioGroup.id === 2) {
            setPaymentLoading(true);
            console.log("Activation + highlight payment.")

            axiosInstance.post(`/passenger/auctions/user/activate_and_highlight_payment/${id}`)
                .then((data) => {
                    console.log(data)
                    const paymentLink = data.data

                    window.location.href = paymentLink
                }).catch((error) => {
                    console.log(error)
            })

        } else if (e.radioGroup.id === 3) {
            setPaymentLoading(true);
            console.log("Big highlight + activation payment.")

            axiosInstance.post(`/passenger/auctions/user/all_option_payment/${id}`)
                .then((data) => {
                    console.log(data)
                    const paymentLink = data.data

                    window.location.href = paymentLink
                }).catch((error) => {
                    console.log(error)
            })
        }
    }

    const onPaymentError = (e) => {
        console.log("Dont work")
    }

    const handleRadioAuctionCardColor = (is_active, is_highlighted, is_homepage_highlighted) => {

        if (is_active && !is_highlighted && !is_homepage_highlighted) {
            // First option
            // return `bg-base-100 border-base-100 border-[3px] peer-checked:border-base-100 peer-checked:ring-[6px] peer-checked:ring-indigo-300 shadow-md hover:shadow-lg`

            // Second option with more shadow with selected color
            // return `bg-base-100 border-base-100 border-[3px] peer-checked:border-base-100 peer-checked:ring-[6px] peer-checked:ring-indigo-300/90 peer-checked:shadow-2xl peer-checked:shadow-indigo-500/90 shadow-around transition duration-300`

            // Third option with more 'retro vibe'
            return `bg-base-100 border-base-100 border-[3px] peer-checked:border-base-100 peer-checked:ring-[6px] peer-checked:ring-indigo-300/90 peer-checked:shadow-2xl peer-checked:shadow-indigo-500/90 shadow-around transition duration-300 text-main_gray_dark stroke-main_gray_dark`
        } else if (is_active && is_highlighted && !is_homepage_highlighted) {
            // First option
            // return `bg-[#F5FFF1] border-[#367E18] border-[3px] peer-checked:border-[#367E18] peer-checked:ring-[6px] peer-checked:ring-indigo-300 shadow-md hover:shadow-lg`

            // Second option more futuristic.
            // return `bg-[#F5FFF1] border-[#367E18] border-[3px] peer-checked:border-[#367E18] peer-checked:ring-[6px] peer-checked:ring-green-300/90 peer-checked:shadow-2xl peer-checked:shadow-green-500/80 shadow-md hover:shadow-lg`

            // Third option more 'retro vibe'
            return `bg-[#F5FFF1] border-[#367E18] border-[3px] peer-checked:border-[#367E18] peer-checked:ring-[6px] peer-checked:ring-green-300/90 peer-checked:shadow-2xl peer-checked:shadow-green-500/80 shadow-md hover:shadow-lg text-[#566D4E] stroke-[#566D4E] fill-[#566D4E]`
        } else if (is_active && is_highlighted && is_homepage_highlighted) {
            // First option
            // return `bg-[#FFF0EB] border-[#CC6B49] border-[3px] peer-checked:border-[#CC6B49] peer-checked:ring-[6px] peer-checked:ring-indigo-300 shadow-md hover:shadow-lg`

            // Second option more futuristic
            return `bg-[#FFF0EB] border-[#CC6B49] border-[3px] peer-checked:border-[#CC6B49] peer-checked:ring-[6px] peer-checked:ring-red-300/90 peer-checked:shadow-2xl peer-checked:shadow-red-500/80 shadow-md hover:shadow-lg text-[#854C3D] stroke-[#854C3D] fill-[#854C3D]`

            // Third option more 'retro vibe'
            // return `bg-[#FFF0EB] border-[#CC6B49] border-[3px] peer-checked:geometric-shadow-red-sm`
        }

        return null
    }

    return (
        <form onSubmit={handleSubmit(onPaymentSubmit, onPaymentError)}>
            <div className={`w-[96%] mx-auto grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-x-6 gap-y-6`}>
                {radioOptions.map((option, index) => (
                    <div className={``} key={option.key}>
                        <label className={`rounded-[20px] hover:cursor-pointer ${selectedOption === option.id ? ('') : ('')}`}>
                            <input
                                  { ...register('radioGroup', {required: true})}
                                  type="radio"
                                  id={option.id}
                                  checked={selectedOption === option.id}
                                  onChange={() => handleRadioChange(option.id)}
                                  className="hidden peer"
                                  key={option.id}
                            />
                            <div key={auctionData.id} className={`${handleRadioAuctionCardColor(true, option.id === 2 || option.id === 3, option.id === 3)} relative rounded-[30px] w-full max-w-[660px] mx-auto pb-3 transition duration-300`}>
                                <div className={`w-full mx-auto`}>
                                    <div className={`rounded-[20px] mt-2 mx-2 h-full relative shadow-sm`}>

                                        <div className={`bg-base-200 hover:cursor-pointer rounded-[20px] h-80 sm:h-80 max-h-96 md:h-80 lg:h-80 xl:h-96 z-10 relative blur-wrapper`}>

                                            {auctionData.images && (Array.isArray(auctionData.images) ? <img src={auctionData.images[0].url} alt={auctionData.title} className={`w-full brightness-100 rounded-[20px] image-blur-wrapper object-cover absolute mx-auto z-0 h-full`} /> : <img src={auctionData.images.url} alt={auctionData.title} className={`w-full brightness-50 rounded-[20px] image-blur-wrapper object-cover brightness-100 absolute mx-auto z-0 h-full`} />)}
                                            {auctionData.images && (Array.isArray(auctionData.images) ? <img src={auctionData.images[0].url} alt={auctionData.title} className={`w-full rounded-[20px] saturate-150 object-scale-down backdrop-blur-md absolute mx-auto z-20 h-full`} /> : <img src={auctionData.images.url} alt={auctionData.title} className={`w-full rounded-[20px] saturate-150 object-scale-down backdrop-blur-md absolute mx-auto z-20 h-full`} />)}

                                        </div>
                                    </div>

                                    <div className={`w-full mx-auto`}>
                                    {/*<div className={`w-full mx-auto`}>*/}
                                        <div className={`mx-4`}>
                                            {/* Brand, model and generation */}
                                            <div className={`text-[20px] font-[500] pt-2 text-black`}>
                                                {/*{auctionData.brand.name} {auctionData.version !== null ? `${auctionData.version.version}` : ""} {auctionData.generation !== null ? `${auctionData.generation.generation}`: ``}*/}

                                                { option.description }

                                            </div>
                                            <div className={``}>
                                                {/*{auctionData.title}*/}
                                                Tu pojawi się <Link to={`/konto/auta_osobowe/${id}`} className={`text-main_green underline`}>Twój</Link> tytuł ogłoszenia
                                            </div>
                                            {/* Info about auctionData. */}
                                            <div className={`flex justify-start md:gap-2 xl:gap-1 mx-auto`}>
                                                <p className={`truncate`}>Rok</p>
                                                <div className={`my-auto px-1 md:px-2 lg:px-1`}>
                                                    <svg className="stroke-none" xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                                                        <circle cx="2.5" cy="2.5" r="2.5" fill="#BB2205"/>
                                                    </svg>
                                                </div>
                                                <p className={`truncate`}>Kilometry</p>
                                                {/*<p className={`truncate`}>{Math.round(auctionData.mileage / 1000)}k km</p>*/}
                                                <div className={`my-auto px-1 md:px-2 lg:px-1`}>
                                                    <svg className="stroke-none" xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                                                        <circle cx="2.5" cy="2.5" r="2.5" fill="#BB2205"/>
                                                    </svg>
                                                </div>
                                                <p className={`truncate`}>Pojemność</p>
                                                <div className={`my-auto px-1 md:px-2 lg:px-1`}>
                                                    <svg className="stroke-none" xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                                                        <circle cx="2.5" cy="2.5" r="2.5" fill="#BB2205"/>
                                                    </svg>
                                                </div>
                                                <p className={`truncate`}>Paliwo</p>
                                        </div>
                                        {/* Information about location */}
                                        <div className={`pt-1 pb-3 mb-8 flex justify-start gap-2 `}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M14.7142 13.8806L11.1783 17.4164C11.0237 17.5712 10.8401 17.694 10.638 17.7778C10.4359 17.8616 10.2192 17.9047 10.0004 17.9047C9.78164 17.9047 9.56499 17.8616 9.36288 17.7778C9.16077 17.694 8.97714 17.5712 8.82251 17.4164L5.28585 13.8806C4.35353 12.9482 3.71863 11.7603 3.46142 10.4671C3.20421 9.17395 3.33625 7.83352 3.84085 6.61536C4.34544 5.39721 5.19993 4.35604 6.29625 3.62351C7.39257 2.89098 8.68149 2.5 10 2.5C11.3185 2.5 12.6075 2.89098 13.7038 3.62351C14.8001 4.35604 15.6546 5.39721 16.1592 6.61536C16.6638 7.83352 16.7958 9.17395 16.5386 10.4671C16.2814 11.7603 15.6465 12.9482 14.7142 13.8806Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M12.5 9.1665C12.5 9.82955 12.2366 10.4654 11.7678 10.9343C11.2989 11.4031 10.663 11.6665 10 11.6665C9.33696 11.6665 8.70107 11.4031 8.23223 10.9343C7.76339 10.4654 7.5 9.82955 7.5 9.1665C7.5 8.50346 7.76339 7.86758 8.23223 7.39874C8.70107 6.9299 9.33696 6.6665 10 6.6665C10.663 6.6665 11.2989 6.9299 11.7678 7.39874C12.2366 7.86758 12.5 8.50346 12.5 9.1665Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>

                                            <p>Miasto (Województwo)</p>

                                        </div>
                                    </div>
                                    </div>
                                        <div className={`flex justify-between absolute bottom-0 w-full px-4 pb-1.5`}>
                                            <div className={`text-main_red-100 font-[500] text-[20px] my-auto grow flex flex-row justify-between`}>
                                                <p>Cena za aukcję:</p>
                                                <p className={`text-end`}>
                                                    { option.price !== null && (`${option.price} `) }&nbsp;PLN
                                                </p>
                                            </div>
                                    </div>
                                </div>

                                {/*<div className={`mt-5 w-full rounded-[20px] p-3 transition duration-300 ${handleRadioButtonColor(true, option.id === 2 || option.id === 3, option.id === 3)}`}>*/}
                                {/*    /!*{option.label}*!/*/}
                                {/*    <p>Wybieram</p>*/}
                                {/*</div>*/}
                            </div>
                        </label>
                    </div>
                ))}
            </div>

            <div className={`w-full mt-11`}>
                <hr className={`mt-3 w-[97%] mx-auto`}/>
                <div className={`mx-auto`}>
                    <h2 className={`mt-3 mx-3 mb-3 text-center md:text-left md:mx-4 text-[22px] font-[400]`}>Podsumowanie</h2>
                </div>
                <div className={`w-[95%] rounded-[20px] border-2 mx-auto border-main_red-100 mb-8`}>
                    <p className={`text-[20px] mx-2 text-center mt-2 mb-2`}>Szczegóły wybranego Pakietu</p>
                    { selectedOptionDict !== null ? (
                        <hr className={`w-[97%] mx-auto bg-main_gray_dark mb-3`}/>
                    ) : (
                        ''
                    )}
                    <div className={`w-11/12 mx-auto text-center transition duration-300`}>{ selectedOptionDict !== null ? selectedOptionDict.detailedDescription : '' }</div>
                    { selectedOptionDict !== null ? (
                        <div className={`w-[95%] rounded-[20px] mx-auto flex flex-col text-[16px] mt-5`}>
                            <hr className={`bg-main_gray_dark`}/>
                            <div className={`flex flex-row rounded-t-[20px] py-2 justify-between`}>
                                <div className={`mx-5`}>
                                    Pakiet
                                </div>
                                <div className={`mx-5`}>
                                    { selectedOptionDict.description }
                                </div>
                            </div>
                            <div className={`flex flex-row rounded-b-[20px] py-2 justify-between`}>
                                <div className={`mx-5`}>
                                    Dni aktywności ogłoszenia
                                </div>
                                <div className={`mx-5`}>
                                    { selectedOptionDict.days } dni
                                </div>
                            </div>
                            <div className={`flex flex-row justify-between py-2`}>
                                <div className={`mx-5`}>
                                    Kwota do zapłacenia (zawiera VAT)
                                </div>
                                <div className={`mx-5 font-[500]`}>
                                    { selectedOptionDict.detailedPrice } PLN
                                </div>
                            </div>
                            <hr className={`bg-main_gray_dark mb-5`}/>
                        </div>
                    ) : (
                        <div className={`w-[95%] rounded-[20px] text-center mx-auto flex flex-col text-main_gray_dark text-[17px] mt-2`}>
                            <hr className={`bg-main_gray_dark mt-0 mb-3`}/>
                            <p>Wybierz interesujący Ciebie wygląd!</p>
                            <hr className={`bg-main_gray_dark mt-3 mb-3`}/>
                        </div>
                    )}

                    { !paymentLoading ? (
                        <div className={`mb-5 mx-auto flex flex-col md:flex-row justify-between w-11/12`}>
                            <div className={`w-11/12 md:w-1/2 text-gray-500 text-center mb-5 md:mb-0 mx-auto`}>
                                Aby otrzymać fakturę za dokonaną płatność napisz do nas na e-mail: faktury@autkotarg.pl
                            </div>
                            <button type={"submit"} disabled={!isChecked} title={!isChecked ? ("Wybierz aukcję!") : ('')} className={`rounded-[20px] py-2.5 px-6 transition duration-300 ${ isChecked ? "bg-main_red-100 text-white hover:bg-main_red-200" : "bg-gray-200 text-main_gray_dark"}`}>{selectedOption === 1 ? ('Aktywuj aukcję') : ('Przejdź do płatności')}</button>
                        </div>
                    ) : (
                        <div className="flex justify-center">
                            <span className="circle animate-loader"></span>
                            <span className="circle animate-loader animation-delay-200"></span>
                            <span className="circle animate-loader animation-delay-400"></span>
                        </div>
                    )}
                </div>



            </div>

        </form>
    )
}

const BonusCode = ({ id }) => {

    const {
        register,
        control,
        handleSubmit,
        setValue,
        clearErrors,
        setError,
        getValues,
        formState: { errors },

    } = useForm();

    const [isBonusCodeActivate, setIsBonusCodeActivate] = useState(false);

    const onSubmitBonusCode = async (e) => {
        setIsBonusCodeActivate(true)
        console.log(e)
        axiosInstance.post('/bonus_codes/activate', {
            id: id,
            code: e.code
        }, {withCredentials: true}).then((data) => {
            // console.log(data)
            window.location.href = `/konto/sukces`
        }).catch((error) => {
            if (error.response.status === 400) {
                setError('code', {message: "Wykorzystano kod!"})
            } else {
                setError('code', {message: "Zły kod!"})
            }
        })

        setIsBonusCodeActivate(false);
    }

    const onErrorBonusCode = (e) => {
        console.log(e)
        setError('code', {message: "Podaj kod!"})
    }


    return (
        <div className={`w-full lg:w-4/12 mx-auto bg-base-100 rounded-[20px] h-1/2 shadow-sm geometric-shadow-gray transition duration-300`}>
            <h2 className={`mt-3 mx-3 mb-3 text-center md:text-left md:mx-3 text-[20px] md:text-[22px] font-[400]`}>Masz kod bonusowy? Użyj go poniżej!</h2>
            <hr className={`w-11/12 mx-auto mb-2`}/>
            <form className={`w-11/12 mx-auto`} onSubmit={handleSubmit(onSubmitBonusCode, onErrorBonusCode)}>
                <div className={`flex flex-col`}>
                    <div className={`flex justify-between`}>
                        <label className={`ml-3 truncate`} htmlFor="code">Kod bonusowy</label>
                        <p className={`text-red-700 font-semibold pr-3`}>
                                { errors.code && errors.code.message }
                        </p>
                    </div>
                    <input
                        className={`mt-0.5 placeholder-main_red-100 focus:outline-none focus:ring-1 focus:ring-main_red-100 transition duration-300 focus:border-main_red-100 border-1 w-full border-main_red-100 rounded-[20px] pl-3 pt-2 pb-2`}
                        { ...register('code', {required: true})}
                        type="text"
                        id={'code'}
                        name={'code'}
                        placeholder={'Kod'}
                        // onChange={(e) => setIsBonusCode(e.target.value)}
                    />
                </div>
                <div className={`flex w-full justify-end mx-auto`}>
                    <button type={'submit'} disabled={isBonusCodeActivate} className={`rounded-[20px] mt-3 px-5 py-2.5 mb-4 transition duration-300 bg-main_red-100 text-white hover:bg-main_red-200`}>
                        Użyj kodu!
                    </button>
                </div>
            </form>
        </div>
    )
}

const NormalAuctionPayment = () => {

    const [ selectedOption, setSelectedOption ] = useState(null);
    const [ auctionData, setAuctionData ] = useState(null);
    const [ isLoaded, setIsLoaded ] = useState(false);
    const [ isBonusCode, setIsBonusCode ] = useState(false);
    const [ isAuctionActive, setIsAuctionActive ] = useState(false);
    const { id } = useParams();


    useEffect(() => {
        if (auctionData !== null) {
            setIsAuctionActive(auctionData.is_active)
        }
        console.log(auctionData)
    }, [auctionData])

    const {
        register,
        control,
        handleSubmit,
        setValue,
        clearErrors,
        setError,
        getValues,
        formState: { errors },

    } = useForm();

    useEffect(() => {
        if (localStorage.getItem('access_token') === null) {
            window.location.href = '/logowanie'
        }
    }, [])

    const fetchAuctionData = async (id) => {
        try {
            if (id !== null) {
                // Fetching auction data.
                // const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/passenger/auctions/specific/${id}`)
                const response = await axiosInstance.get(`passenger/auctions/user/specific/${id}`, {withCredentials: true})

                // console.log(response)

                if (response.status !== 200) {
                    throw new Error("Network response was not okay in fetching auction.")
                }

                // const data = await response.json();
                const data = response.data;

                setAuctionData(data)

                document.title = `Platność za aukcję - Autkotarg.pl`;

                setIsLoaded(true);

            } else {
                setIsLoaded(false);
            }
        } catch (error) {
            // console.log(error)
        }

        // console.log(auctionData);
    }

    useEffect(() => {
        fetchAuctionData(id);
    }, [])


    return (
        <div>
            <Navbar/>
            <Stripes/>
            <h1 className={`text-[22px] md:text-[26px] font-[500] w-[97%] text-center md:text-left mt-3 md:w-11/12 mx-auto`}>Płatność za aukcję</h1>
            <div className={`w-[97%] mx-auto gap-x-5 gap-y-5 mt-5 md:w-11/12 flex flex-col lg:flex-row`}>
                {/*Hello {id}*/}


                <BonusCode id={id}/>

                <div className={`w-full md:6/12 mx-auto bg-base-100 rounded-[20px] shadow-sm geometric-shadow-gray transition duration-300`}>
                    <h1 className={`mt-3 mx-3 mb-3 text-center md:text-left md:mx-4 text-[20px] md:text-[24px] font-[400]`}>Aktywowanie aukcji: <br className={`block md:hidden`}/> <Link to={`/konto/auta_osobowe/${id}`} className={`text-main_green underline`}>{ isLoaded ? `${auctionData.title}` : ''}</Link></h1>
                    <h2 className={`mt-3 mx-3 mb-3 text-center md:text-left md:mx-6 text-[18px] md:text-[20px] font-[400]`}>Wybierz wygląd aukcji</h2>
                    <hr className={`mx-auto w-[98%] mb-4`}/>
                    { isAuctionActive && (
                        <div className={`border-[1px] w-11/12 mx-auto bg-base-100 border-main_green mb-4 rounded-[20px] flex flex-col md:flex-row gap-x-3 py-2 mx-1 justify-center mt-2 hover:shadow-sm transition duration-300`}>
                            <p className={`text-center mx-2 font-[500] my-auto`}>Uwaga ogłoszenie jest już aktywowane!</p>
                        </div>
                    ) }
                    {/*<div className={`w-[96%] mx-auto grid grid-cols-1 md:grid-cols-3`}>*/}
                    {/*    <div className={`w-full`}>*/}
                    {/*        <p>Aukcja zwykła</p>*/}
                    {/*    </div>*/}
                    {/*    <div className={`w-full`}>*/}
                    {/*        <p className={`text-main_green`}>Aukcja lepsza</p>*/}
                    {/*    </div>*/}
                    {/*    <div className={`w-full`}>*/}
                    {/*        <p className={`text-main_red-100`}>Aukcja najlepsza</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    { isLoaded ? (
                        <Payment id={id} auctionData={auctionData}/>
                    ) : (
                        <div className="flex justify-center">
                            <span className="circle animate-loader"></span>
                            <span className="circle animate-loader animation-delay-200"></span>
                            <span className="circle animate-loader animation-delay-400"></span>
                        </div>
                    )}
                </div>

            </div>

            <Footer/>
        </div>
    )
}

export default NormalAuctionPayment;