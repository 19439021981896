import React, {useEffect, useState} from 'react';
import {Link, useNavigate, Outlet, Navigate} from "react-router-dom";
import Carousel from "./images/CardCarousel";
import axiosInstance from "../../api/axios";
import { debounce } from 'lodash';

function handleAuctionColor(active, highlighted, homepage_highlight) {
    // console.log(active, highlighted, homepage_highlight);

    let combinedClassName = '';

    if (active === false) {
        combinedClassName += 'bg-base-100 border-base-100 border-[3px] geometric-shadow-gray text-main_gray_dark stroke-main_gray_dark fill-main_gray_dark'
        return combinedClassName;
    }
    if (active === true && highlighted === false && homepage_highlight === false) {
        // Old card.
        combinedClassName += 'bg-base-100 border-base-100 border-[3px] geometric-shadow-gray text-main_gray_dark stroke-main_gray_dark fill-main_gray_dark';

        // New card
        // combinedClassName += 'bg-base-100 border-main_gray_dark border-[1px]';
        return combinedClassName;
    }
    if (active === true && highlighted === true && homepage_highlight === false) {
        combinedClassName += 'bg-[#F5FFF1] border-[#367E18] border-[3px] geometric-shadow-green text-[#566D4E] stroke-[#566D4E] fill-[#566D4E]';
        // combinedClassName += 'bg-[#F5FFF1] border-[#367E18] border-[3px] shadow shadow-green-500/40 hover:shadow-green-500/70 hover:shadow-md';
        return combinedClassName;
    }
    if (active === true && highlighted === true && homepage_highlight === true) {
        // combinedClassName += 'bg-[#FFF0EB] border-[#CC6B49] border-[3px] shadow shadow-red-400/40 hover:shadow-red-400/70 hover:shadow-md';
        combinedClassName += 'bg-[#FFF0EB] border-[#CC6B49] border-[3px] geometric-shadow-red text-[#854C3D] stroke-[#854C3D] fill-[#854C3D]';
        return combinedClassName;
    }

    return combinedClassName;
}

const CarAuctionsCard = (props) => {


    const { carsData } = props
    const navigate = useNavigate();
    const [wishes, setWishes] = useState({});
    const [prevIdWish, setPrevIdWish] = useState('');
    const [countOneAuctionWishes, setCountOneAuctionWishes] = useState(0);

    const handleAuctionClick = (auctionId) => {
        navigate(`/auta_osobowe/aukcja/${auctionId}`);
    }

    useEffect(() => {
        const wishDict = {}
        console.log(carsData);

        try {
            carsData.results.forEach((item) => {
                wishDict[`${item.id}`] = item.wish
            })

            // console.log(wishDict);
            setWishes(wishDict);
        } catch (e) {
            console.log(e)
        }

    }, [])

    // const makeWish = async (id, wish) => {
    //     // TODO Make an limit to click this button.
    //     console.log(id, wish)
    //
    //     // if (prevIdWish === id && countOneAuctionWishes <= 6) {
    //     //     setPrevIdWish(id);
    //     //     setCountOneAuctionWishes(countOneAuctionWishes + 1)
    //     //     console.log(countOneAuctionWishes)
    //     // }
    //
    //
    //     if (wish === null) {
    //         navigate('/logowanie')
    //     } else if (wish === false) {
    //         await axiosInstance.post(`/wishlist/car_wish/${id}`)
    //             .then((data) => {
    //                 console.log('Follow z suckesem!')
    //             })
    //             .catch((e) => {
    //                 console.log('Błąd przy folllow')
    //         })
    //         console.log("Follow")
    //
    //         setWishes({...wishes, [id]: !wish})
    //
    //     } else if (wish === true) {
    //         await axiosInstance.delete(`/wishlist/car_wish/${id}`)
    //             .then((data) => {
    //                 console.log('Usunieto follow')
    //             })
    //             .catch((e) => {
    //                 console.log('Bład przy usuwaniu follow')
    //         })
    //         console.log("Unfollow")
    //
    //         setWishes({...wishes, [id]: !wish})
    //     }
    //
    //     setPrevIdWish(id);
    //     // console.log(wishes)
    // }

    const makeWish = debounce(async (id, wish) => {
        // console.log(id, wish)

        if (wish === null) {
            navigate(`/logowanie?return_url=auta_osobowe/aukcja/${id}`)
        } else if (wish === false) {
            axiosInstance.post(`/wishlist/car_wish/${id}`)
                .then((data) => {
                    // console.log('Follow z suckesem!', new Date().getSeconds(), new Date().getMilliseconds())
                })
                .catch((e) => {
                    // console.log('Błąd przy folllow')
            })
            // console.log("Follow")


            // console.log(wishes)
            setWishes({...wishes, [id]: !wish})

        } else if (wish === true) {
            axiosInstance.delete(`/wishlist/car_wish/${id}`)
                .then((data) => {
                    // console.log('Usunieto follow', new Date().getSeconds(), new Date().getMilliseconds())
                })
                .catch((e) => {
                    // console.log('Bład przy usuwaniu follow')
            })
            // console.log("Unfollow")

            // console.log(wishes)
            setWishes({...wishes, [id]: !wish})
        }

        setPrevIdWish(id);
    }, 200)


    return (

        <div className={`mt-9 md:mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-y-4 md:gap-2 md:gap-y-3 lg:gap-4 lg:grid-cols-1 xl:grid-cols-1 mx-auto w-full md:w-11/12 lg:w-10/12 xl:w-10/12 2xl:w-9/12 3xl:w-8/12`}>
            { carsData.results && carsData.results.length > 0 ? (
                carsData.results.map(car => (
                        <div key={car.id} className={`${handleAuctionColor(car.is_active, car.highlighted, car.homepage_highlight)} flex flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row
                         rounded-[30px] lg:rounded-[20px] mx-1 lg:h-[266px] transition duration-300 skeleton-50`}>
                            <div className={`w-full lg:w-6/12 xl:w-5/12 2xl:w-4/12 pt-[5px] pr-[4px] pl-[4px] md:h-[258px] lg:h-[250px]`}>
                                {/*<div>*/}
                                {/*    {car.image && (Array.isArray(car.image) && car.image.length > 1 ?*/}
                                {/*        (*/}
                                {/*            <div className={`w-full`}>*/}
                                {/*                <Carousel images={car.image}/>*/}
                                {/*            </div>*/}
                                {/*        )*/}
                                {/*        :*/}
                                {/*        (*/}
                                {/*            car.image !== null ?*/}
                                {/*                (*/}
                                {/*                    <img src={car.image} alt={car.title} className={`w-full rounded-[30px] lg:rounded-[20px] md:rounded-[25px] object-cover h-[250px] mx-auto z-20`} />*/}
                                {/*                ) : (*/}
                                {/*                    <p>Siemanko</p>*/}
                                {/*                )*/}
                                {/*        )*/}
                                {/*    )}*/}

                                { car.image ? (
                                    Array.isArray(car.image) && car.image.length > 1 ? (
                                            <div className={`w-full`}>
                                                <Carousel images={car.image} id={car.id}/>
                                            </div>
                                    ) : (
                                        <div className={`relative blur-wrapper rounded-[25px] hover:cursor-pointer`} onClick={(e) => handleAuctionClick(car.id)}>
                                            <img src={car.image} alt={car.title} className={`w-full image-blur-wrapper rounded-[25px] lg:rounded-[20px] md:rounded-[30px] absolute object-cover h-[250px] mx-auto z-0`} />
                                            <img src={car.image} alt={car.title} className={`w-full rounded-[25px] lg:rounded-[20px] md:rounded-[30px] object-contain h-[250px] mx-auto z-20 backdrop-blur-md`} />
                                        </div>
                                    )
                                ) : (
                                    <svg className={`w-full rounded-[30px] lg:rounded-[20px] md:rounded-[25px] object-cover h-[250px] mx-auto z-20 bg-base-200`}></svg>
                                )}


                                {/*</div>*/}
                            </div>

                            {/*<Link*/}
                            {/*    className={`w-full h-full`}*/}
                            {/*    to={`aukcja/${car.id}`}>*/}


                            <div className={`w-full h-full`}>
                                {/* TODO BETTER LINK TO*/}
                                <div className={`flex flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row justify-between h-full`}>
                                    <Link to={`aukcja/${car.id}`} className={`text-left pl-4 pt-2 grow`}>
                                    {/*<div className={`text-left pl-4 pt-2`}>*/}
                                        <p className={`font-[500] text-[24px] truncate text-black`}>{car.brand.name} {car.version !== null ? `${car.version.version}` : ""} {car.generation !== null ? `${car.generation.generation}`: ``}</p>
                                        <p className={`pt-1 md:pt-1  break-words line-clamp-2`}>{car.title}</p>
                                        <div className={`flex justify-start gap-1.5 md:gap-2 lg:gap-2 `}>
                                            <p className={`truncate`}>{car.production_date}</p>
                                            <div className={`my-auto`}>
                                                <svg className='stroke-none' xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                                                    <circle cx="2.5" cy="2.5" r="2.5" fill="#BB2205"/>
                                                </svg>
                                            </div>

                                            <p className={`truncate`}>{car.mileage} km</p>
                                            <div className={`my-auto`}>
                                                <svg className='stroke-none' xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                                                    <circle cx="2.5" cy="2.5" r="2.5" fill="#BB2205"/>
                                                </svg>
                                            </div>
                                            <p className={`truncate`}>{car.capacity} cm3</p>
                                            <div className={`my-auto`}>
                                                <svg className='stroke-none' xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                                                    <circle cx="2.5" cy="2.5" r="2.5" fill="#BB2205"/>
                                                </svg>
                                            </div>
                                            <p className={`truncate`}>{car.fuel_type.type}</p>
                                            
                                            <div className={`my-auto hidden lg:block`}>
                                                <svg className='stroke-none' xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                                                    <circle cx="2.5" cy="2.5" r="2.5" fill="#BB2205"/>
                                                </svg>
                                            </div>
                                            <p className={`truncate hidden lg:block`}>{car.gearbox && car.gearbox.name}</p>


                                            {/*<div className={`my-auto`}>*/}
                                            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">*/}
                                            {/*        <circle cx="2.5" cy="2.5" r="2.5" fill="#BB2205"/>*/}
                                            {/*    </svg>*/}
                                            {/*</div>*/}
                                            {/*<p className={`truncate`}>{car.gearbox !== null ? (car.gearbox.name) : ('')}</p>*/}

                                        </div>
                                        <div className={`pt-1 pb-3 flex justify-start gap-2 `}>
                                            <svg className='' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M14.7142 13.8806L11.1783 17.4164C11.0237 17.5712 10.8401 17.694 10.638 17.7778C10.4359 17.8616 10.2192 17.9047 10.0004 17.9047C9.78164 17.9047 9.56499 17.8616 9.36288 17.7778C9.16077 17.694 8.97714 17.5712 8.82251 17.4164L5.28585 13.8806C4.35353 12.9482 3.71863 11.7603 3.46142 10.4671C3.20421 9.17395 3.33625 7.83352 3.84085 6.61536C4.34544 5.39721 5.19993 4.35604 6.29625 3.62351C7.39257 2.89098 8.68149 2.5 10 2.5C11.3185 2.5 12.6075 2.89098 13.7038 3.62351C14.8001 4.35604 15.6546 5.39721 16.1592 6.61536C16.6638 7.83352 16.7958 9.17395 16.5386 10.4671C16.2814 11.7603 15.6465 12.9482 14.7142 13.8806Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M12.5 9.1665C12.5 9.82955 12.2366 10.4654 11.7678 10.9343C11.2989 11.4031 10.663 11.6665 10 11.6665C9.33696 11.6665 8.70107 11.4031 8.23223 10.9343C7.76339 10.4654 7.5 9.82955 7.5 9.1665C7.5 8.50346 7.76339 7.86758 8.23223 7.39874C8.70107 6.9299 9.33696 6.6665 10 6.6665C10.663 6.6665 11.2989 6.9299 11.7678 7.39874C12.2366 7.86758 12.5 8.50346 12.5 9.1665Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>

                                            {/*{car.google_location && car.google_location.province ? (*/}
                                            {/*    <p>{car.google_location.province}</p>*/}
                                            {/*) : (*/}
                                            {/*    car.location && car.location.name ? (*/}
                                            {/*        <p>{car.location.name}</p>*/}
                                            {/*    ) : (*/}
                                            {/*        <p>Brak lokalizacji</p>*/}
                                            {/*    )*/}
                                            {/*)}*/}

                                            <p>{car.location !== null ? `${car.location.name}` : ``} {car.location !== null ? `(${car.location.province})` : ''}</p>
                                        </div>

                                    {/*</div>*/}
                                    </Link>
                                    {/*<div className={`w-full`}>*/}
                                    {/*    */}
                                    {/*    */}
                                    {/*    <p className={`text-main_red-100 font-bold text-[20px]`}>{car.price.toLocaleString()} {car.currency.name}</p>*/}
                                    {/*</div>*/}

                                    {/* Daj to jesli beda problemy z ceną oraz obserwowaniem. */}

                                    <div className={`flex flex-row lg:flex-col-reverse justify-between pt-1 md:pt-5 pb-2`}>
                                        {/*<button className={`flex gap-2 pl-4 -mt-1.5 md:mt-0`} onClick={(e) => makeWish(car.id, car.wish)}>*/}
                                        <button className={`flex gap-2 pl-4 -mt-0.5 md:mt-0 lg:mr-5`} onClick={(e) => makeWish(car.id, wishes[car.id])}>
                                            {/* If wish is True then show green heart, create a code that services the observation and removing observation from auction, if user is not logged in then go to login page. */}

                                            {/*{car.wish ? (*/}
                                            {/*    <svg className={``} xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">*/}
                                            {/*        <path d="M6 0.5C9.315 0.5 12 3.1325 12 6.38C12 3.1325 14.685 0.5 18 0.5C21.315 0.5 24 3.1325 24 6.38C24 11.2745 19.149 13.001 12.588 20.792C12.5158 20.8776 12.4257 20.9463 12.3242 20.9935C12.2226 21.0407 12.112 21.0651 12 21.0651C11.888 21.0651 11.7774 21.0407 11.6758 20.9935C11.5743 20.9463 11.4842 20.8776 11.412 20.792C4.851 13.001 0 11.2745 0 6.38C0 3.1325 2.685 0.5 6 0.5Z" fill="#367E18"/>*/}
                                            {/*    </svg>*/}
                                            {/*) : (*/}
                                            {/*    <svg className={``} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">*/}
                                            {/*        <path d="M9 6.15H10H11C11 4.01485 12.7708 2.25 15 2.25C17.2292 2.25 19 4.01485 19 6.15C19 7.76879 18.2442 8.89944 16.5909 10.54C16.1637 10.964 15.6785 11.4194 15.1451 11.9201C13.7143 13.263 11.9367 14.9316 10 17.1922C8.06333 14.9316 6.28565 13.263 4.85493 11.9201C4.3215 11.4194 3.8363 10.964 3.40907 10.54C1.75582 8.89944 1 7.76879 1 6.15C1 4.01485 2.77078 2.25 5 2.25C7.22922 2.25 9 4.01485 9 6.15ZM10.0008 17.748L10.0008 17.748L10.0008 17.748Z" stroke="#367E18" strokeWidth="2"/>*/}
                                            {/*    </svg>*/}
                                            {/*)}*/}

                                            {wishes[car.id] === true ? (
                                                <svg className={``} xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
                                                    <path d="M6 0.5C9.315 0.5 12 3.1325 12 6.38C12 3.1325 14.685 0.5 18 0.5C21.315 0.5 24 3.1325 24 6.38C24 11.2745 19.149 13.001 12.588 20.792C12.5158 20.8776 12.4257 20.9463 12.3242 20.9935C12.2226 21.0407 12.112 21.0651 12 21.0651C11.888 21.0651 11.7774 21.0407 11.6758 20.9935C11.5743 20.9463 11.4842 20.8776 11.412 20.792C4.851 13.001 0 11.2745 0 6.38C0 3.1325 2.685 0.5 6 0.5Z" fill="#367E18"/>
                                                </svg>
                                            ) : (
                                                <svg className={``} xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 20 20" fill="none">
                                                    <path d="M9 6.15H10H11C11 4.01485 12.7708 2.25 15 2.25C17.2292 2.25 19 4.01485 19 6.15C19 7.76879 18.2442 8.89944 16.5909 10.54C16.1637 10.964 15.6785 11.4194 15.1451 11.9201C13.7143 13.263 11.9367 14.9316 10 17.1922C8.06333 14.9316 6.28565 13.263 4.85493 11.9201C4.3215 11.4194 3.8363 10.964 3.40907 10.54C1.75582 8.89944 1 7.76879 1 6.15C1 4.01485 2.77078 2.25 5 2.25C7.22922 2.25 9 4.01485 9 6.15ZM10.0008 17.748L10.0008 17.748L10.0008 17.748Z" stroke="#367E18" strokeWidth="2"/>
                                                </svg>
                                            )}
                                            <div className={`md:mt-[-2px] mt-0`}>
                                                <p className={`text-main_green text-[16px] md:text-[18px]`}>Obserwuj</p>
                                            </div>
                                        </button>
                                        <Link to={`aukcja/${car.id}`} className={`text-main_red-100 font-[500] text-[20px] pr-5 grow text-end`}>
                                        {/*<div className={`text-main_red-100 font-bold text-[20px] pr-5 grow text-end`}>*/}
                                            <p className={`lg:mt-[-10px] mt-[-5px]`}>{car.price.toLocaleString()} PLN</p>
                                        {/*</div>*/}
                                        </Link>
                                    </div>
                                </div>

                            </div>
                            {/*// </Link>*/}

                        </div>
                ))
            ) : (
                <div className={`w-full col-span-3 pb-10 pt-10 h-[400px] md:h-[500px]`}>
                    <p className={'text-[24px] md:text-[30px] lg:text-[34px] mx-8 text-center pt-16'}>Brak aukcji do wyświetlenia, spróbuj innej filtracji! :)</p>
                </div>

            //     <div className={`h-[800px]`}>
            //     <p className={'pt-10 text-[56px] mx-8 text-center'}>Ładuję zawartość...</p>
            // </div>
            )}


        </div>

    );
}

export default CarAuctionsCard;
export { handleAuctionColor }
