import React, { useState, useEffect } from "react";
import {Link, useFetcher, useNavigate} from "react-router-dom";
import axiosInstance from "../../api/axios"

const generateYearOptions = (startYear, endYear) => {
        const options = [];

        for (let year = endYear; year >= startYear; year--) {
            options.push(
                <option key={year} value={year} className={`text-black`}>
                    {year}
                </option>
            );
        }

        return options;
};

const generatePriceOptions = (startPrice, endPrice) => {
    const priceOptions = [];

    for (let price = startPrice; price <= endPrice; price += 1000) {
        if (price >= 3000 && price <= 9000) {
            priceOptions.push(
                <option key={price} value={price} className={`text-black`}>
                    {price.toLocaleString()} PLN
                </option>
            );
        } else if (price >= 10000 && price < 50000) {
            if ((price % 5000) === 0) {
                priceOptions.push(
                    <option key={price} value={price} className={`text-black`}>
                        {price.toLocaleString()} PLN
                    </option>
                );
            }
        } else if (price >= 50000 && price < 150000) {
            if ((price % 10000) === 0) {
                priceOptions.push(
                    <option key={price} value={price} className={`text-black`}>
                        {price.toLocaleString()} PLN
                    </option>
                );
            }
        } else if (price >= 150000 && price < 500000) {
            if ((price % 50000) === 0) {
                priceOptions.push(
                    <option key={price} value={price} className={`text-black`}>
                        {price.toLocaleString()} PLN
                    </option>
                );
            }
        } else if (price >= 500000 && price <= 1000000) {
            if ((price % 100000) === 0) {
                priceOptions.push(
                    <option key={price} value={price} className={`text-black`}>
                        {price.toLocaleString()} PLN
                    </option>
                );
            }
        } else if (price > 1000000) {
            if ((price % 1000000) === 0) {
                priceOptions.push(
                    <option key={price} value={price} className={`text-black`}>
                        {price.toLocaleString()} PLN
                    </option>
                );
            }
        }
    }


    return priceOptions;
}

const generateMileageOptions = (startMileage, endMileage) => {
    const mileageOptions = [];


    for (let mileage = startMileage; mileage <= endMileage; mileage += 1000) {
        if (mileage >= 5000 && mileage <= 20000) {
            if (mileage % 5000 === 0) {
                mileageOptions.push(
                    <option key={mileage} value={mileage} className={`text-black`}>
                        {mileage.toLocaleString()} km
                    </option>
                );
            }
        } else if (mileage > 20000 && mileage <= 50000) {
            if (mileage % 10000 === 0) {
                mileageOptions.push(
                    <option key={mileage} value={mileage} className={`text-black`}>
                        {mileage.toLocaleString()} km
                    </option>
                );
            }
        } else if (mileage > 50000 && mileage <= 200000) {
            if (mileage % 25000 === 0) {
                mileageOptions.push(
                    <option key={mileage} value={mileage} className={`text-black`}>
                        {mileage.toLocaleString()} km
                    </option>
                );
            }
        } else if (mileage > 200000) {
            if (mileage % 50000 === 0) {
                mileageOptions.push(
                    <option key={mileage} value={mileage} className={`text-black`}>
                        {mileage.toLocaleString()} km
                    </option>
                );
            }
        }
    }

    return mileageOptions;
};


const LandingPageFiltration = (props) => {
    const [selectedBrandOption, setBrandSelectedOption] = useState('');
    const [selectedModelOption, setModelSelectedOption] = useState('');
    const [isSelectedModelChanged, setIsSelectedModelChanged] = useState(false)
    const [data, setData] = useState([]);
    const [generationData, setGenerationData] = useState([]);
    const [selectedGeneration, setSelectedGeneration] = useState('');
    const [isSelectedGenerationChanged, setIsSelectedGenerationChanged] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [generationError, setGenerationError] = useState(null);
    const [priceFrom, setPriceFrom] = useState(null);
    const [priceTo, setPriceTo] = useState(2000000);
    const [priceList, setPriceList] = useState([]);
    const [yearFrom, setYearFrom] = useState(null);
    const [yearTo, setYearTo] = useState(2023);
    const [years, setYears] = useState([]);
    const [bodyType, setBodyType] = useState("");
    const [mileageList, setMileageList] = useState([]);
    const [mileageFrom, setMileageFrom] = useState(null);
    const [mileageTo, setMileageTo] = useState(300000);
    const [fuelType, setFuelType] = useState(null);
    const [IsGenerationLoading, setIsGenerationLoading] = useState(false);


    const [isMileageToChanged, setIsMileageToChanged] = useState(false);
    const [isYearToChanged, setIsYearToChanged] = useState(false);
    const [isYearFromChanged, setIsYearFromChanged] = useState(false);
    const [isPriceFromChanged, setIsPriceFromChanged] = useState(false);
    const [isPriceToChanged, setIsPriceToChanged] = useState(false);


    const navigate = useNavigate();



    const handleFuelTypeChange = (event) => {
        setFuelType(event.target.value);
    }


    const handleMileageFromChange = (event) => {
        setMileageFrom(event.target.value);
        console.log(mileageFrom, mileageTo);
    };

    const handleMileageToChange = (event) => {
        setMileageTo(event.target.value);
        setIsMileageToChanged(true);
        console.log(mileageFrom, mileageTo);
    };

    const handleSelectedGenerationChange = (event) => {
        setSelectedGeneration(event.target.value);
        setIsSelectedGenerationChanged(true)
    };

    const handleBodyTypeChange = (event) => {
        setBodyType(event.target.value);
    };

    const handleYearFromChange = (event) => {
        if (event.target.value === "") {
            setYearFrom(1940)
        }
        setYearFrom(event.target.value);
        setIsYearFromChanged(true);
    };

    const handleYearToChange = (event) => {
        if (event.target.value === "") {
            setYearTo(2023)
        }
        setYearTo(event.target.value);
        setIsYearToChanged(true);
    };

    const handlePriceFromChange = (event) => {
        setPriceFrom(event.target.value);
        setIsPriceFromChanged(true);
    };

    const handlePriceToChange = (event) => {
        setPriceTo(event.target.value);
        setIsPriceToChanged(true);
    };

    const handleBrandSelectChange = (event) => {
      setGenerationData([]);
      setModelSelectedOption('')
      if (event.target.value === '') {
          setData([]);
      }

      setBrandSelectedOption(event.target.value);
    };

    const handleModelSelectChange = (event) => {
      setModelSelectedOption(event.target.value);
      setSelectedGeneration('');
      setIsSelectedModelChanged(true)
    };

    const fetchModelData = async () => {
      setIsLoading(true);
      setError(null);

      try {

        const response = await axiosInstance.get(`/car_info/?brand=${selectedBrandOption}`);

        console.log(response);

        if (response.status !== 200) {
          throw new Error('Network response was not ok');
        }

        const data = await response.data;
        setData(data);

      } catch (error) {
        setError('Error fetching data: ' + error.message);
      } finally {
        setIsLoading(false);
      }
      console.log(data);
    };


    const fetchGenerationData = async () => {
      setIsGenerationLoading(true);
      setGenerationError(null);

      try {
        const response = await axiosInstance.get(`/car_info/?brand=${selectedBrandOption}&version=${selectedModelOption}`);
        console.log(response);

        if (response.status !== 200) {
            throw new Error('Network response was not ok.');
        }
        const generationData = await response.data;
        setGenerationData(generationData);


      } catch (error) {
          setGenerationError('Error fetching data: ' + error.message);
      } finally {
          setIsGenerationLoading(false);
      }

    };

    const encodeURIAndReplaceSpace = (str) => {
        // Encode the URI component and replace %20 with +
        return encodeURIComponent(str).replace(/%20/g, '+');
    };


    const handleSearch = (event) => {
        let queryParams = {
            pathname: '/auta_osobowe',
            search: '',
        };

        queryParams.search += `page=1&`
        if (selectedBrandOption) queryParams.search += `brand=${encodeURIAndReplaceSpace(selectedBrandOption)}&`;
        if (selectedModelOption) queryParams.search += `model=${selectedModelOption}&`;
        if (selectedGeneration) queryParams.search += `generation=${selectedGeneration}&`;
        if (bodyType) queryParams.search += `body_type=${bodyType}&`;
        if (yearFrom) queryParams.search += `year_from=${yearFrom}&`;
        if (yearTo && isYearToChanged === true) queryParams.search += `year_to=${yearTo}&`;
        if (mileageFrom) queryParams.search += `mileage_from=${mileageFrom}&`;
        if (mileageTo && isMileageToChanged === true) queryParams.search += `mileage_to=${mileageTo}&`;
        if (fuelType) queryParams.search += `fuel_type=${fuelType}&`;
        if (priceFrom) queryParams.search += `price_from=${priceFrom}&`;
        if (priceTo && isPriceToChanged === true) queryParams.search += `price_to=${priceTo}&`;

        // Remove the trailing '&' if there are any parameters
        if (queryParams.search.endsWith('&')) {
            queryParams.search = queryParams.search.slice(0, -1);
        }

        navigate(queryParams);
    };


    useEffect(() => {
      console.log(selectedModelOption, selectedBrandOption)
      if (selectedBrandOption !== '' && selectedModelOption === '') {
        fetchModelData();

      }
      if (selectedModelOption !== '' && selectedBrandOption !== '') {
        fetchGenerationData();
          console.log("gee")
      }

    }, [selectedBrandOption, selectedModelOption]);


    useEffect(() => {
        const yearOptions = [];
        let yearPlusOne = parseInt(yearFrom) + 1;


        for (let year = yearPlusOne; year <= 2023; year++) {
            yearOptions.push(year);
        }
        setYears(yearOptions)
        console.log(yearFrom, yearTo);

    }, [yearFrom, yearTo]);


    useEffect(() => {
        let pricePlusOne = parseInt(priceFrom) + 1000;
        const priceOptions = generatePriceOptions(pricePlusOne, 2000000);
        setPriceList(priceOptions);
    }, [priceFrom, priceTo])


    useEffect(() => {
        let mileagePlusOne = parseInt(mileageFrom) + 1000;
        const mileageOptions = generateMileageOptions(mileagePlusOne, 300000);
        setMileageList(mileageOptions);
    }, [mileageFrom, mileageTo])

    return (
        <div className={`mt-6 mx-auto items-center 3xl:w-9/12 xl:w-4/5 lg:w-11/12 md:w-4/5 w-[97%] skeleton`}>
            <div className={`sm:h-auto mx-1 bg-base-100 rounded-[20px] shadow-sm geometric-shadow-gray transition duration-300`}>
                <div className={`text-center pt-5`}>
                    <p className={`text-[22px] md:text-[28px] w-11/12 mx-auto truncate skeleton`}>Wybierz czego szukasz</p>
                </div>

                {/*<div className={`w-full overflow-x-auto`}>*/}
                {/*    <div className={`w-full flex flex-row`}>*/}
                {/*        <div>*/}
                {/*            <Link to={`/auta_osobowe`} className={`text-main_red-100`}>Osobowe</Link>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className={`text-sm flex flex-row gap-1.5 md:gap-3 pt-4 justify-center overflow-x-auto no-scrollbar w-full mx-auto`}>*/}
                {/*    <div className={`flex gap-1.5 md:gap-3`}>*/}
                {/*        <a href="#" className={`text-main_red-100 truncate text-center`}>Osobowe</a>*/}
                {/*        <svg className={``} xmlns="http://www.w3.org/2000/svg" width="4" height="20" viewBox="0 0 4 20" fill="none">*/}
                {/*            <path d="M2 0V20" stroke="#BB2205" strokeWidth="4"/>*/}
                {/*        </svg>*/}
                {/*    </div>*/}
                {/*    <div className={`flex gap-1.5 md:gap-3 text-main_gray_dark`}>*/}
                {/*        <a href="#" className={`truncate`}>Motocykle</a>*/}
                {/*        <svg className={``} xmlns="http://www.w3.org/2000/svg" width="4" height="20" viewBox="0 0 4 20" fill="none">*/}
                {/*            <path d="M2 0V20" stroke="#7D7D7D" strokeWidth="4"/>*/}
                {/*        </svg>*/}
                {/*    </div>*/}
                {/*    <div className={`flex gap-1.5 md:gap-3 text-main_gray_dark`}>*/}
                {/*        <a href="#" className={`truncate`}>Auta klasyczne</a>*/}
                {/*        <svg className={``} xmlns="http://www.w3.org/2000/svg" width="4" height="20" viewBox="0 0 4 20" fill="none">*/}
                {/*            <path d="M2 0V20" stroke="#7D7D7D" strokeWidth="4"/>*/}
                {/*        </svg>*/}
                {/*    </div>*/}
                {/*    <div className={`flex gap-1.5 md:gap-3 text-main_gray_dark`}>*/}
                {/*        <a href="#" className={`truncate`}>Ciężarowe</a>*/}

                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className={`text-[14px] mx-auto mt-2 md:mt-0 w-11/12 flex flex-row gap-x-3 md:gap-3 pt-0 md:pt-4 justify-center overflow-x-auto no-scrollbar`}>*/}


                {/* <div className={`text-[14px] mx-auto mt-3 md:-mt-1 w-11/12 flex flex-row gap-x-2 md:gap-3 pt-0 md:pt-4 justify-center overflow-x-auto no-scrollbar pl-0 xsm:pl-0 sm:pl-0`}>
                    <Link to={'/'} className={`rounded-[20px] border-2 py-1 px-2 border-main_red-100 text-center text-main_red-100`}>
                        Osobowe
                    </Link>
                    <div className={`cursor-default rounded-[20px] border-2 border-dotted py-1 px-2 border-gray-400 text-center text-gray-400`}>
                        Klasyczne
                    </div>
                    <div className={`cursor-default rounded-[20px] border-2 border-dotted py-1 px-2 border-gray-400 text-center text-gray-400`}>
                        Motocykle
                    </div>
                </div> */}

                <div className={`grid lg:grid-cols-4 md:grid-cols-1 sm:grid-cols-1 mx-5 gap-x-3 gap-y-1.5 text-center pt-3 md:pt-4`}>
                    {/* FIRST ROW */}
                    {/* Brand */}
                    <div className={`inline-block relative w-full ${ selectedBrandOption !== '' ? ("text-black") : ("text-main_gray_dark") }`}>
                        <label htmlFor="brand" className="block text-left text-black w-full ml-3 mb-1 mt-1.5">Marka pojazdu</label>

                        <select value={selectedBrandOption} onChange={handleBrandSelectChange}
                            id={'brand'}
                            className="h-[41px] hover:cursor-pointer transition duration-200 block border-0 appearance-none w-full rounded-[30px] bg-base-200 focus:ring focus:ring-1 focus:ring-slate-600 px-3 py-2 pr-8 leading-tight">
                            {/*    Without ring on no-focus*/}
                            {/*   With ring on no-focus */}
                            {/*className="h-[41px] transition duration-200 block appearance-none w-full rounded-[30px] bg-base-200 border-0 ring-1 ring-gray-300 focus:ring focus:ring-1 focus:ring-slate-600 px-3 py-2 pr-8 leading-tight">*/}
                            <option className={`text-black`} selected value={''}>Marka pojazdu</option>
                            <option className={`text-black`} value="Abarth">Abarth</option>
                            <option className={`text-black`} value="Acura">Acura</option>
                            <option className={`text-black`} value="Aixam">Aixam</option>
                            <option className={`text-black`} value="Alfa Romeo">Alfa Romeo</option>
                            <option className={`text-black`} value="Alpine">Alpine</option>
                            <option className={`text-black`} value="Aro">Aro</option>
                            <option className={`text-black`} value="Asia">Asia</option>
                            <option className={`text-black`} value="Aston Martin">Aston Martin</option>
                            <option className={`text-black`} value="Audi">Audi</option>
                            <option className={`text-black`} value="Austin">Austin</option>
                            <option className={`text-black`} value="Autobianchi">Autobianchi</option>
                            <option className={`text-black`} value="BAC">BAC</option>
                            <option className={`text-black`} value="Baic">Baic</option>
                            <option className={`text-black`} value="Bentley">Bentley</option>
                            <option className={`text-black`} value="BMW-ALPINA">BMW-ALPINA</option>
                            <option className={`text-black`} value="BMW">BMW</option>
                            <option className={`text-black`} value="Bolloré">Bolloré</option>
                            <option className={`text-black`} value="Brilliance">Brilliance</option>
                            <option className={`text-black`} value="Bugatti">Bugatti</option>
                            <option className={`text-black`} value="Buick">Buick</option>
                            <option className={`text-black`} value="Cadillac">Cadillac</option>
                            <option className={`text-black`} value="Casalini">Casalini</option>
                            <option className={`text-black`} value="Caterham">Caterham</option>
                            <option className={`text-black`} value="Chatenet">Chatenet</option>
                            <option className={`text-black`} value="Chevrolet">Chevrolet</option>
                            <option className={`text-black`} value="Chrysler">Chrysler</option>
                            <option className={`text-black`} value="Citroën">Citroën</option>
                            <option className={`text-black`} value="Cupra">Cupra</option>
                            <option className={`text-black`} value="Dacia">Dacia</option>
                            <option className={`text-black`} value="Daewoo">Daewoo</option>
                            <option className={`text-black`} value="Daihatsu">Daihatsu</option>
                            <option className={`text-black`} value="DeLorean">DeLorean</option>
                            <option className={`text-black`} value="DFSK">DFSK</option>
                            <option className={`text-black`} value="DKW">DKW</option>
                            <option className={`text-black`} value="Dodge">Dodge</option>
                            <option className={`text-black`} value="DR MOTOR">DR MOTOR</option>
                            <option className={`text-black`} value="DS Automobiles">DS Automobiles</option>
                            <option className={`text-black`} value="Eagle">Eagle</option>
                            <option className={`text-black`} value="FAW">FAW</option>
                            <option className={`text-black`} value="Ferrari">Ferrari</option>
                            <option className={`text-black`} value="Fiat">Fiat</option>
                            <option className={`text-black`} value="Ford">Ford</option>
                            <option className={`text-black`} value="Galloper">Galloper</option>
                            <option className={`text-black`} value="Gaz">Gaz</option>
                            <option className={`text-black`} value="Geely">Geely</option>
                            <option className={`text-black`} value="Genesis">Genesis</option>
                            <option className={`text-black`} value="GMC">GMC</option>
                            <option className={`text-black`} value="Grecav">Grecav</option>
                            <option className={`text-black`} value="GWM">GWM</option>
                            <option className={`text-black`} value="Hammer">Hammer</option>
                            <option className={`text-black`} value="Holden">Holden</option>
                            <option className={`text-black`} value="Honda">Honda</option>
                            <option className={`text-black`} value="Hummer">Hummer</option>
                            <option className={`text-black`} value="Hyundai">Hyundai</option>
                            <option className={`text-black`} value="Infiniti">Infiniti</option>
                            <option className={`text-black`} value="Inny">Inny</option>
                            <option className={`text-black`} value="Isuzu">Isuzu</option>
                            <option className={`text-black`} value="Iveco">Iveco</option>
                            <option className={`text-black`} value="Jaguar">Jaguar</option>
                            <option className={`text-black`} value="Jeep">Jeep</option>
                            <option className={`text-black`} value="John Deere">John Deere</option>
                            <option className={`text-black`} value="Kaipan">Kaipan</option>
                            <option className={`text-black`} value="Kia">Kia</option>
                            <option className={`text-black`} value="KTM">KTM</option>
                            <option className={`text-black`} value="Lada">Lada</option>
                            <option className={`text-black`} value="Lamborghini">Lamborghini</option>
                            <option className={`text-black`} value="Lancia">Lancia</option>
                            <option className={`text-black`} value="Land Rover">Land Rover</option>
                            <option className={`text-black`} value="LEVC">LEVC</option>
                            <option className={`text-black`} value="Lexus">Lexus</option>
                            <option className={`text-black`} value="Ligier">Ligier</option>
                            <option className={`text-black`} value="Lincoln">Lincoln</option>
                            <option className={`text-black`} value="Lotus">Lotus</option>
                            <option className={`text-black`} value="LTI">LTI</option>
                            <option className={`text-black`} value="LuAZ">LuAZ</option>
                            <option className={`text-black`} value="MAN">MAN</option>
                            <option className={`text-black`} value="Maserati">Maserati</option>
                            <option className={`text-black`} value="Maxus">Maxus</option>
                            <option className={`text-black`} value="Maybach">Maybach</option>
                            <option className={`text-black`} value="Mazda">Mazda</option>
                            <option className={`text-black`} value="McLaren">McLaren</option>
                            <option className={`text-black`} value="Mercedes-Benz">Mercedes-Benz</option>
                            <option className={`text-black`} value="Mercury">Mercury</option>
                            <option className={`text-black`} value="MG">MG</option>
                            <option className={`text-black`} value="Microcar">Microcar</option>
                            <option className={`text-black`} value="MINI">MINI</option>
                            <option className={`text-black`} value="Mitsubishi">Mitsubishi</option>
                            <option className={`text-black`} value="Nissan">Nissan</option>
                            <option className={`text-black`} value="NSU">NSU</option>
                            <option className={`text-black`} value="Nysa">Nysa</option>
                            <option className={`text-black`} value="Oldsmobile">Oldsmobile</option>
                            <option className={`text-black`} value="Oltcit">Oltcit</option>
                            <option className={`text-black`} value="Opel">Opel</option>
                            <option className={`text-black`} value="Peugeot">Peugeot</option>
                            <option className={`text-black`} value="Piaggio">Piaggio</option>
                            <option className={`text-black`} value="Plymouth">Plymouth</option>
                            <option className={`text-black`} value="Polestar">Polestar</option>
                            <option className={`text-black`} value="Polonez">Polonez</option>
                            <option className={`text-black`} value="Pontiac">Pontiac</option>
                            <option className={`text-black`} value="Porsche">Porsche</option>
                            <option className={`text-black`} value="Proton">Proton</option>
                            <option className={`text-black`} value="RAM">RAM</option>
                            <option className={`text-black`} value="Rayton Fissore">Rayton Fissore</option>
                            <option className={`text-black`} value="Renault">Renault</option>
                            <option className={`text-black`} value="Rolls-Royce">Rolls-Royce</option>
                            <option className={`text-black`} value="Rover">Rover</option>
                            <option className={`text-black`} value="Saab">Saab</option>
                            <option className={`text-black`} value="Santana">Santana</option>
                            <option className={`text-black`} value="Saturn">Saturn</option>
                            <option className={`text-black`} value="Seat">Seat</option>
                            <option className={`text-black`} value="Seres">Seres</option>
                            <option className={`text-black`} value="Shuanghuan">Shuanghuan</option>
                            <option className={`text-black`} value="Skoda">Skoda</option>
                            <option className={`text-black`} value="Skywell">Skywell</option>
                            <option className={`text-black`} value="Smart">Smart</option>
                            <option className={`text-black`} value="SsangYong">SsangYong</option>
                            <option className={`text-black`} value="Subaru">Subaru</option>
                            <option className={`text-black`} value="Suzuki">Suzuki</option>
                            <option className={`text-black`} value="Syrena">Syrena</option>
                            <option className={`text-black`} value="Talbot">Talbot</option>
                            <option className={`text-black`} value="Tarpan">Tarpan</option>
                            <option className={`text-black`} value="Tata">Tata</option>
                            <option className={`text-black`} value="Tatra">Tatra</option>
                            <option className={`text-black`} value="Tavria">Tavria</option>
                            <option className={`text-black`} value="Tesla">Tesla</option>
                            <option className={`text-black`} value="Toyota">Toyota</option>
                            <option className={`text-black`} value="Trabant">Trabant</option>
                            <option className={`text-black`} value="Triumph">Triumph</option>
                            <option className={`text-black`} value="Uaz">Uaz</option>
                            <option className={`text-black`} value="Vanderhall">Vanderhall</option>
                            <option className={`text-black`} value="Vauxhall">Vauxhall</option>
                            <option className={`text-black`} value="VELEX">VELEX</option>
                            <option className={`text-black`} value="Volkswagen">Volkswagen</option>
                            <option className={`text-black`} value="Volvo">Volvo</option>
                            <option className={`text-black`} value="Warszawa">Warszawa</option>
                            <option className={`text-black`} value="Wartburg">Wartburg</option>
                            <option className={`text-black`} value="Wołga">Wołga</option>
                            <option className={`text-black`} value="Yugo">Yugo</option>
                            <option className={`text-black`} value="Zaporożec">Zaporożec</option>
                            <option className={`text-black`} value="Zastava">Zastava</option>
                            <option className={`text-black`} value="Żuk">Żuk</option>
                        </select>
                        {/*<div*/}
                        {/*    className="pointer-events-none absolute inset-y-0 right-1 flex items-center px-2 text-gray-700">*/}
                        {/*    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">*/}
                        {/*        <path fillRule="evenodd" clipRule="evenodd" d="M1.14035 1.14971C1.23411 1.05598 1.36127 1.00332 1.49385 1.00332C1.62643 1.00332 1.75359 1.05598 1.84735 1.14971L3.49385 2.79621L5.14035 1.14971C5.18647 1.10196 5.24165 1.06387 5.30265 1.03766C5.36365 1.01146 5.42926 0.997666 5.49565 0.997089C5.56204 0.996512 5.62788 1.00916 5.68933 1.0343C5.75078 1.05944 5.8066 1.09657 5.85355 1.14352C5.90049 1.19046 5.93762 1.24629 5.96276 1.30774C5.9879 1.36919 6.00055 1.43503 5.99998 1.50142C5.9994 1.5678 5.98561 1.63341 5.9594 1.69442C5.9332 1.75542 5.89511 1.81059 5.84735 1.85671L3.84735 3.85671C3.75359 3.95045 3.62643 4.00311 3.49385 4.00311C3.36127 4.00311 3.23411 3.95045 3.14035 3.85671L1.14035 1.85671C1.04662 1.76295 0.993958 1.6358 0.993958 1.50321C0.993958 1.37063 1.04662 1.24348 1.14035 1.14971Z" fill="black"/>*/}
                        {/*    </svg>*/}
                        {/*</div>*/}
                    </div>
                    {/* Model of car */}
                    <div className={`inline-block relative w-38 ${ selectedModelOption !== '' ? ("text-black") : ("text-main_gray_dark") }`}>
                        <label htmlFor="model" className="block text-left text-black w-full ml-3 mb-1 mt-1.5">Model pojazdu</label>
                        <select
                            value={selectedModelOption} onChange={handleModelSelectChange}
                            id={'model'}
                            className="h-[41px] hover:cursor-pointer transition duration-200 block appearance-none w-full rounded-[30px] bg-base-200 border-0 focus:ring focus:ring-1 focus:ring-slate-600 px-3 py-2 pr-8 leading-tight">
                            <option selected value={''} className={`text-black`}>Model pojazdu</option>
                            {isLoading ? (
                                <option disabled>Ładuje modele pojazdu</option>
                            ) : error ? (
                                <option disabled>Nie udało się znaleźć modeli</option>
                              ) : (
                              data.map((item) => <option className={`text-black`} value={item.version}>{item.version}</option>)
                            )}
                        </select>
                        {/*<div*/}
                        {/*    className="pointer-events-none absolute inset-y-0 right-1 flex items-center px-2 text-gray-700">*/}
                        {/*    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">*/}
                        {/*        <path fillRule="evenodd" clipRule="evenodd" d="M1.14035 1.14971C1.23411 1.05598 1.36127 1.00332 1.49385 1.00332C1.62643 1.00332 1.75359 1.05598 1.84735 1.14971L3.49385 2.79621L5.14035 1.14971C5.18647 1.10196 5.24165 1.06387 5.30265 1.03766C5.36365 1.01146 5.42926 0.997666 5.49565 0.997089C5.56204 0.996512 5.62788 1.00916 5.68933 1.0343C5.75078 1.05944 5.8066 1.09657 5.85355 1.14352C5.90049 1.19046 5.93762 1.24629 5.96276 1.30774C5.9879 1.36919 6.00055 1.43503 5.99998 1.50142C5.9994 1.5678 5.98561 1.63341 5.9594 1.69442C5.9332 1.75542 5.89511 1.81059 5.84735 1.85671L3.84735 3.85671C3.75359 3.95045 3.62643 4.00311 3.49385 4.00311C3.36127 4.00311 3.23411 3.95045 3.14035 3.85671L1.14035 1.85671C1.04662 1.76295 0.993958 1.6358 0.993958 1.50321C0.993958 1.37063 1.04662 1.24348 1.14035 1.14971Z" fill="black"/>*/}
                        {/*    </svg>*/}
                        {/*</div>*/}
                    </div>
                    {/* Body type select */}
                    <div className={`inline-block relative w-38`}>
                        <label htmlFor="body_type" className="block text-left text-black w-full ml-3 mb-1 mt-1.5">Typ nadwozia</label>
                        <select value={bodyType} onChange={handleBodyTypeChange}
                            id={'body_type'}
                            className={`${ bodyType !== "" ? ("text-black") : ("text-main_gray_dark") }
                             h-[41px] hover:cursor-pointer transition duration-200 block appearance-none w-full rounded-[30px] bg-base-200 border-0 focus:ring focus:ring-1 focus:ring-slate-600 px-3 py-2 pr-8 leading-tight`}>
                            <option className={`text-black`} selected value={""}>Typ nadwozia</option>
                            <option className={`text-black`} value={"Auta małe"}>Auta małe</option>
                            <option className={`text-black`} value={"Auta miejskie"}>Auta miejskie</option>
                            <option className={`text-black`} value={"Coupe"}>Coupe</option>
                            <option className={`text-black`} value={"Kabriolet"}>Kabriolet</option>
                            <option className={`text-black`} value={"Kombi"}>Kombi</option>
                            <option className={`text-black`} value={"Kompakt"}>Kompakt</option>
                            <option className={`text-black`} value={"Minivan"}>Minivan</option>
                            <option className={`text-black`} value={"Sedan"}>Sedan</option>
                            <option className={`text-black`} value={"SUV"}>SUV</option>
                        </select>
                        {/*<div*/}
                        {/*    // className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">*/}
                        {/*    // <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"*/}
                        {/*    //      viewBox="0 0 20 20">*/}
                        {/*    //     <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>*/}
                        {/*    // </svg>*/}
                        {/*    className="pointer-events-none absolute inset-y-0 right-1 flex items-center px-2 text-gray-700">*/}
                        {/*    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">*/}
                        {/*        <path fillRule="evenodd" clipRule="evenodd" d="M1.14035 1.14971C1.23411 1.05598 1.36127 1.00332 1.49385 1.00332C1.62643 1.00332 1.75359 1.05598 1.84735 1.14971L3.49385 2.79621L5.14035 1.14971C5.18647 1.10196 5.24165 1.06387 5.30265 1.03766C5.36365 1.01146 5.42926 0.997666 5.49565 0.997089C5.56204 0.996512 5.62788 1.00916 5.68933 1.0343C5.75078 1.05944 5.8066 1.09657 5.85355 1.14352C5.90049 1.19046 5.93762 1.24629 5.96276 1.30774C5.9879 1.36919 6.00055 1.43503 5.99998 1.50142C5.9994 1.5678 5.98561 1.63341 5.9594 1.69442C5.9332 1.75542 5.89511 1.81059 5.84735 1.85671L3.84735 3.85671C3.75359 3.95045 3.62643 4.00311 3.49385 4.00311C3.36127 4.00311 3.23411 3.95045 3.14035 3.85671L1.14035 1.85671C1.04662 1.76295 0.993958 1.6358 0.993958 1.50321C0.993958 1.37063 1.04662 1.24348 1.14035 1.14971Z" fill="black"/>*/}
                        {/*    </svg>*/}
                        {/*</div>*/}
                    </div>
                    {/* Generation select */}
                    <div className={`inline-block relative w-38 ${ selectedGeneration === "" || generationData.length === 0 ? ('text-main_gray_dark') : ('text-black')} }`}>
                        <label htmlFor="generation" className="block text-left text-black w-full ml-3 mb-1 mt-1.5">Generacja</label>
                        <select value={selectedGeneration} onChange={handleSelectedGenerationChange}
                            id={'generation'}
                            className="h-[41px] hover:cursor-pointer transition duration-200 block appearance-none w-full rounded-[30px] bg-base-200 border-0 focus:ring focus:ring-1 focus:ring-slate-600 px-3 py-2 pr-8 leading-tight">
                            <option selected value={""} className={`text-black`}>Generacja</option>
                            {IsGenerationLoading ? (
                                <option disabled>Ładuje generacje modelu pojazdu.</option>
                            ) : generationError ? (
                                <option disabled>Nie udało się znaleźć generacji.</option>
                            ) : (
                                (<option disabled selected value={""}>Generacja</option>) &&
                                generationData.map((item) => <option key={item.id} className={`text-black`} value={item.generation}>{item.generation}</option>)
                            )}
                        </select>
                        {/*<div*/}
                        {/*    className="pointer-events-none absolute inset-y-0 right-1 flex items-center px-2 text-gray-700">*/}
                        {/*    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">*/}
                        {/*        <path fillRule="evenodd" clipRule="evenodd" d="M1.14035 1.14971C1.23411 1.05598 1.36127 1.00332 1.49385 1.00332C1.62643 1.00332 1.75359 1.05598 1.84735 1.14971L3.49385 2.79621L5.14035 1.14971C5.18647 1.10196 5.24165 1.06387 5.30265 1.03766C5.36365 1.01146 5.42926 0.997666 5.49565 0.997089C5.56204 0.996512 5.62788 1.00916 5.68933 1.0343C5.75078 1.05944 5.8066 1.09657 5.85355 1.14352C5.90049 1.19046 5.93762 1.24629 5.96276 1.30774C5.9879 1.36919 6.00055 1.43503 5.99998 1.50142C5.9994 1.5678 5.98561 1.63341 5.9594 1.69442C5.9332 1.75542 5.89511 1.81059 5.84735 1.85671L3.84735 3.85671C3.75359 3.95045 3.62643 4.00311 3.49385 4.00311C3.36127 4.00311 3.23411 3.95045 3.14035 3.85671L1.14035 1.85671C1.04662 1.76295 0.993958 1.6358 0.993958 1.50321C0.993958 1.37063 1.04662 1.24348 1.14035 1.14971Z" fill="black"/>*/}
                        {/*    </svg>*/}
                        {/*</div>*/}
                    </div>
                {/*    SECOND ROW IN LG */}
                {/*    YEAR */}
                    <div className={'grid grid-cols-2 gap-2 items-center'}>
                        <div className="inline-block relative w-38">
                            <label htmlFor="year_from" className="block text-left text-black w-full ml-3 mb-1 mt-1.5">Rok od</label>
                            <select value={yearFrom} onChange={handleYearFromChange}
                                id={'year_from'}
                                className={`${ isYearFromChanged === false ? ("text-main_gray_dark") : ( yearFrom === "Rok od" ? ("text-main_gray_dark") : ("text-black") ) }
                                 h-[41px] hover:cursor-pointer transition duration-200 block appearance-none w-full rounded-[30px] bg-base-200 border-0 focus:ring focus:ring-1 focus:ring-slate-600 px-3 py-2 pr-8 leading-tight`}>
                                <option selected value={null} className={`text-black`}>Rok od</option>

                                {/*{generateYearOptions(1940, yearTo-1)}*/}
                                {yearTo === "Rok do" ? (
                                    generateYearOptions(1940, 2023)
                                ) : (
                                    generateYearOptions(1940, yearTo-1)
                                )}
                            </select>
                            {/*<div*/}
                            {/*    className="pointer-events-none absolute inset-y-0 right-1 flex items-center px-2 text-gray-700">*/}
                            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">*/}
                            {/*        <path fillRule="evenodd" clipRule="evenodd" d="M1.14035 1.14971C1.23411 1.05598 1.36127 1.00332 1.49385 1.00332C1.62643 1.00332 1.75359 1.05598 1.84735 1.14971L3.49385 2.79621L5.14035 1.14971C5.18647 1.10196 5.24165 1.06387 5.30265 1.03766C5.36365 1.01146 5.42926 0.997666 5.49565 0.997089C5.56204 0.996512 5.62788 1.00916 5.68933 1.0343C5.75078 1.05944 5.8066 1.09657 5.85355 1.14352C5.90049 1.19046 5.93762 1.24629 5.96276 1.30774C5.9879 1.36919 6.00055 1.43503 5.99998 1.50142C5.9994 1.5678 5.98561 1.63341 5.9594 1.69442C5.9332 1.75542 5.89511 1.81059 5.84735 1.85671L3.84735 3.85671C3.75359 3.95045 3.62643 4.00311 3.49385 4.00311C3.36127 4.00311 3.23411 3.95045 3.14035 3.85671L1.14035 1.85671C1.04662 1.76295 0.993958 1.6358 0.993958 1.50321C0.993958 1.37063 1.04662 1.24348 1.14035 1.14971Z" fill="black"/>*/}
                            {/*    </svg>*/}
                            {/*</div>*/}
                        </div>
                        <div className="inline-block relative w-38">
                            <label htmlFor="year_to" className="block text-left text-black w-full ml-3 mb-1 mt-1.5">Rok do</label>
                            <select onChange={handleYearToChange}
                                id={'year_to'}
                                className={`${ isYearToChanged === false ? ("text-main_gray_dark") : ( yearTo === "Rok do" ? ("text-main_gray_dark") : ("text-black") ) }
                                 h-[41px] hover:cursor-pointer transition duration-200 block appearance-none w-full rounded-[30px] bg-base-200 border-0 focus:ring focus:ring-1 focus:ring-slate-600 px-3 py-2 pr-8 leading-tight`}>
                                <option selected value={null} className={`text-black`}>Rok do</option>
                                {/*{generateYearOptions(yearFrom, yearTo)}*/}
                                {/*{years.map((year) => (*/}
                                {/*  <option key={year} value={year}>*/}
                                {/*    {year}*/}
                                {/*  </option>*/}
                                {/*))}*/}
                                {years.length > 0 ? (
                                  years.map((year) => (
                                    <option key={year} value={year} className={`text-black`}>
                                      {year}
                                    </option>
                                  ))
                                ) : (
                                  generateYearOptions(1940, 2023)
                                )}
                            </select>
                            {/*<div*/}
                            {/*    className="pointer-events-none absolute inset-y-0 right-1 flex items-center px-2 text-gray-700">*/}
                            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">*/}
                            {/*        <path fillRule="evenodd" clipRule="evenodd" d="M1.14035 1.14971C1.23411 1.05598 1.36127 1.00332 1.49385 1.00332C1.62643 1.00332 1.75359 1.05598 1.84735 1.14971L3.49385 2.79621L5.14035 1.14971C5.18647 1.10196 5.24165 1.06387 5.30265 1.03766C5.36365 1.01146 5.42926 0.997666 5.49565 0.997089C5.56204 0.996512 5.62788 1.00916 5.68933 1.0343C5.75078 1.05944 5.8066 1.09657 5.85355 1.14352C5.90049 1.19046 5.93762 1.24629 5.96276 1.30774C5.9879 1.36919 6.00055 1.43503 5.99998 1.50142C5.9994 1.5678 5.98561 1.63341 5.9594 1.69442C5.9332 1.75542 5.89511 1.81059 5.84735 1.85671L3.84735 3.85671C3.75359 3.95045 3.62643 4.00311 3.49385 4.00311C3.36127 4.00311 3.23411 3.95045 3.14035 3.85671L1.14035 1.85671C1.04662 1.76295 0.993958 1.6358 0.993958 1.50321C0.993958 1.37063 1.04662 1.24348 1.14035 1.14971Z" fill="black"/>*/}
                            {/*    </svg>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                {/* MILEAGE */}
                    <div className={'grid grid-cols-2 gap-2 items-center'}>
                        <div className="inline-block relative w-38">
                            <label htmlFor="mileage_from" className="block text-left text-black w-full ml-3 mb-1 mt-1.5">Przebieg od</label>
                            <select onChange={handleMileageFromChange}
                                id={'mileage_from'}
                                className={`${ mileageFrom === "" || mileageFrom === null ? ("text-main_gray_dark") : ("text-black") }
                                 h-[41px] hover:cursor-pointer transition duration-200 block appearance-none w-full rounded-[30px] bg-base-200 border-0 focus:ring focus:ring-1 focus:ring-slate-600 px-3 py-2 pr-8 leading-tight`}>
                                <option selected value={""} className={`text-black`}>Przebieg od</option>
                                {mileageTo === "" ? (
                                    generateMileageOptions(0, 300000)
                                ) : (
                                    generateMileageOptions(0, mileageTo-100)
                                )}
                            </select>
                            {/*<div*/}
                            {/*    className="pointer-events-none absolute inset-y-0 right-1 flex items-center px-2 text-gray-700">*/}
                            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">*/}
                            {/*        <path fillRule="evenodd" clipRule="evenodd" d="M1.14035 1.14971C1.23411 1.05598 1.36127 1.00332 1.49385 1.00332C1.62643 1.00332 1.75359 1.05598 1.84735 1.14971L3.49385 2.79621L5.14035 1.14971C5.18647 1.10196 5.24165 1.06387 5.30265 1.03766C5.36365 1.01146 5.42926 0.997666 5.49565 0.997089C5.56204 0.996512 5.62788 1.00916 5.68933 1.0343C5.75078 1.05944 5.8066 1.09657 5.85355 1.14352C5.90049 1.19046 5.93762 1.24629 5.96276 1.30774C5.9879 1.36919 6.00055 1.43503 5.99998 1.50142C5.9994 1.5678 5.98561 1.63341 5.9594 1.69442C5.9332 1.75542 5.89511 1.81059 5.84735 1.85671L3.84735 3.85671C3.75359 3.95045 3.62643 4.00311 3.49385 4.00311C3.36127 4.00311 3.23411 3.95045 3.14035 3.85671L1.14035 1.85671C1.04662 1.76295 0.993958 1.6358 0.993958 1.50321C0.993958 1.37063 1.04662 1.24348 1.14035 1.14971Z" fill="black"/>*/}
                            {/*    </svg>*/}
                            {/*</div>*/}
                        </div>
                        <div className="inline-block relative w-38">
                            <label htmlFor="mileage_to" className="block text-left text-black w-full ml-3 mb-1 mt-1.5">Przebieg do</label>
                            <select onChange={handleMileageToChange}
                                id={'mileage_to'}
                                className={`${ mileageTo === "" || isMileageToChanged === false ? ("text-main_gray_dark") : ("text-black")} } 
                                h-[41px] hover:cursor-pointer transition duration-200 block appearance-none w-full rounded-[30px] bg-base-200 border-0 focus:ring focus:ring-1 focus:ring-slate-600 px-3 py-2 pr-8 leading-tight`}>
                                <option selected value={""}>Przebieg do</option>
                                {mileageList.length > 0 ? (
                                    mileageList
                                ) : (
                                  generateMileageOptions(0, 300000)
                                )}

                            </select>
                            {/*<div*/}
                            {/*    className="pointer-events-none absolute inset-y-0 right-1 flex items-center px-2 text-gray-700">*/}
                            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">*/}
                            {/*        <path fillRule="evenodd" clipRule="evenodd" d="M1.14035 1.14971C1.23411 1.05598 1.36127 1.00332 1.49385 1.00332C1.62643 1.00332 1.75359 1.05598 1.84735 1.14971L3.49385 2.79621L5.14035 1.14971C5.18647 1.10196 5.24165 1.06387 5.30265 1.03766C5.36365 1.01146 5.42926 0.997666 5.49565 0.997089C5.56204 0.996512 5.62788 1.00916 5.68933 1.0343C5.75078 1.05944 5.8066 1.09657 5.85355 1.14352C5.90049 1.19046 5.93762 1.24629 5.96276 1.30774C5.9879 1.36919 6.00055 1.43503 5.99998 1.50142C5.9994 1.5678 5.98561 1.63341 5.9594 1.69442C5.9332 1.75542 5.89511 1.81059 5.84735 1.85671L3.84735 3.85671C3.75359 3.95045 3.62643 4.00311 3.49385 4.00311C3.36127 4.00311 3.23411 3.95045 3.14035 3.85671L1.14035 1.85671C1.04662 1.76295 0.993958 1.6358 0.993958 1.50321C0.993958 1.37063 1.04662 1.24348 1.14035 1.14971Z" fill="black"/>*/}
                            {/*    </svg>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    {/* FUEL TYPE */}
                    <div className="inline-block relative w-38">
                        <label htmlFor="fuel_type" className="block text-left text-black w-full ml-3 mb-1 mt-1.5">Rodzaj paliwa</label>
                        <select onChange={handleFuelTypeChange}
                            id={'fuel_type'}
                            className={`${ fuelType === "" || fuelType === null ? ("text-main_gray_dark") : ("text-black") } 
                            h-[41px] hover:cursor-pointer transition duration-200 block appearance-none w-full rounded-[30px] bg-base-200 border-0 focus:ring focus:ring-1 focus:ring-slate-600 px-3 py-2 pr-8 leading-tight`}>
                            <option selected value={""}>Rodzaj paliwa</option>
                            <option className={`text-black`} value={"Benzyna"}>Benzyna</option>
                            <option className={`text-black`} value={"Diesel"}>Diesel</option>
                            <option className={`text-black`} value={"Hybryda"}>Hybryda</option>
                            <option className={`text-black`} value={"Elektryczny"}>Elektryczny</option>
                        </select>
                        {/*<div*/}
                        {/*    className="pointer-events-none absolute inset-y-0 right-1 flex items-center px-2 text-gray-700">*/}
                        {/*    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">*/}
                        {/*        <path fillRule="evenodd" clipRule="evenodd" d="M1.14035 1.14971C1.23411 1.05598 1.36127 1.00332 1.49385 1.00332C1.62643 1.00332 1.75359 1.05598 1.84735 1.14971L3.49385 2.79621L5.14035 1.14971C5.18647 1.10196 5.24165 1.06387 5.30265 1.03766C5.36365 1.01146 5.42926 0.997666 5.49565 0.997089C5.56204 0.996512 5.62788 1.00916 5.68933 1.0343C5.75078 1.05944 5.8066 1.09657 5.85355 1.14352C5.90049 1.19046 5.93762 1.24629 5.96276 1.30774C5.9879 1.36919 6.00055 1.43503 5.99998 1.50142C5.9994 1.5678 5.98561 1.63341 5.9594 1.69442C5.9332 1.75542 5.89511 1.81059 5.84735 1.85671L3.84735 3.85671C3.75359 3.95045 3.62643 4.00311 3.49385 4.00311C3.36127 4.00311 3.23411 3.95045 3.14035 3.85671L1.14035 1.85671C1.04662 1.76295 0.993958 1.6358 0.993958 1.50321C0.993958 1.37063 1.04662 1.24348 1.14035 1.14971Z" fill="black"/>*/}
                        {/*    </svg>*/}
                        {/*</div>*/}
                    </div>


                {/*    PRICE */}
                    <div className={'grid grid-cols-2 gap-2 items-center'}>
                        <div className="inline-block relative">
                            <label htmlFor="price_from" className="block text-left w-full ml-3 text-black mb-1 mt-1.5">Cena od</label>
                            <select onChange={handlePriceFromChange}
                                id={'price_from'}
                                className={`${ priceFrom === "" ? ('text-main_gray_dark') : ( isPriceFromChanged === true ? ("text-black") : ("text-main_gray_dark") ) } 
                                h-[41px] hover:cursor-pointer transition duration-200 block appearance-none w-full rounded-[30px] bg-base-200 border-0 focus:ring focus:ring-1 focus:ring-slate-600 px-3 py-2 pr-8 leading-tight`}>
                                <option selected value={""}>Cena od</option>
                                {priceTo === "" ? (
                                    generatePriceOptions(3000, 2000000)
                                ) : (
                                    generatePriceOptions(3000, priceTo-100)
                                )}
                            </select>
                            {/*<div*/}
                            {/*    className="pointer-events-none absolute inset-y-0 right-1 flex items-center px-2 text-gray-700">*/}
                            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">*/}
                            {/*        <path fillRule="evenodd" clipRule="evenodd" d="M1.14035 1.14971C1.23411 1.05598 1.36127 1.00332 1.49385 1.00332C1.62643 1.00332 1.75359 1.05598 1.84735 1.14971L3.49385 2.79621L5.14035 1.14971C5.18647 1.10196 5.24165 1.06387 5.30265 1.03766C5.36365 1.01146 5.42926 0.997666 5.49565 0.997089C5.56204 0.996512 5.62788 1.00916 5.68933 1.0343C5.75078 1.05944 5.8066 1.09657 5.85355 1.14352C5.90049 1.19046 5.93762 1.24629 5.96276 1.30774C5.9879 1.36919 6.00055 1.43503 5.99998 1.50142C5.9994 1.5678 5.98561 1.63341 5.9594 1.69442C5.9332 1.75542 5.89511 1.81059 5.84735 1.85671L3.84735 3.85671C3.75359 3.95045 3.62643 4.00311 3.49385 4.00311C3.36127 4.00311 3.23411 3.95045 3.14035 3.85671L1.14035 1.85671C1.04662 1.76295 0.993958 1.6358 0.993958 1.50321C0.993958 1.37063 1.04662 1.24348 1.14035 1.14971Z" fill="black"/>*/}
                            {/*    </svg>*/}
                            {/*</div>*/}
                        </div>
                        <div className="inline-block relative">
                            <label htmlFor="price_to" className="block text-left w-full ml-3 text-black mb-1 mt-1.5">Cena do</label>
                            <select onChange={handlePriceToChange}
                                id={'price_to'}
                                className={`${ priceTo === "" ? ('text-main_gray_dark') : ( isPriceToChanged === true ? ("text-black") : ("text-main_gray_dark") ) } 
                                h-[41px] hover:cursor-pointer transition duration-200 block appearance-none w-full rounded-[30px] bg-base-200 border-0 focus:ring focus:ring-1 focus:ring-slate-600 px-3 py-2 pr-8 leading-tight`}>
                                <option selected value={""}>Cena do</option>
                                {priceList.length > 0 ? (
                                    priceList
                                ) : (
                                  generatePriceOptions(3000, 2000000)
                                )}
                            </select>
                            {/*<div*/}
                            {/*    className="pointer-events-none absolute inset-y-0 right-1 flex items-center px-2 text-gray-700">*/}
                            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">*/}
                            {/*        <path fillRule="evenodd" clipRule="evenodd" d="M1.14035 1.14971C1.23411 1.05598 1.36127 1.00332 1.49385 1.00332C1.62643 1.00332 1.75359 1.05598 1.84735 1.14971L3.49385 2.79621L5.14035 1.14971C5.18647 1.10196 5.24165 1.06387 5.30265 1.03766C5.36365 1.01146 5.42926 0.997666 5.49565 0.997089C5.56204 0.996512 5.62788 1.00916 5.68933 1.0343C5.75078 1.05944 5.8066 1.09657 5.85355 1.14352C5.90049 1.19046 5.93762 1.24629 5.96276 1.30774C5.9879 1.36919 6.00055 1.43503 5.99998 1.50142C5.9994 1.5678 5.98561 1.63341 5.9594 1.69442C5.9332 1.75542 5.89511 1.81059 5.84735 1.85671L3.84735 3.85671C3.75359 3.95045 3.62643 4.00311 3.49385 4.00311C3.36127 4.00311 3.23411 3.95045 3.14035 3.85671L1.14035 1.85671C1.04662 1.76295 0.993958 1.6358 0.993958 1.50321C0.993958 1.37063 1.04662 1.24348 1.14035 1.14971Z" fill="black"/>*/}
                            {/*    </svg>*/}
                            {/*</div>*/}
                        </div>
                    </div>


                </div>

                {/* Button and advanced search */}
                <div className={`w-full`}>
                    <div className={`w-full justify-center flex flex-col py-5 md:flex-row lg:flex-row mx-auto md:gap-10 md:pt-4 md:py-6 md:justify-center skeleton-200`}>
                        <Link to={'/auta_osobowe?page=1'} className={`text-main_red-100 my-auto truncate text-center select-none`}>Wyszukiwanie zaawansowane</Link>
                        <div className={`pt-3 md:pt-0 mx-auto md:mx-0`}>
                            {/*TO USE PARAMS IN COMPONENT WE COULD SEND IT BY THIS CODE. */}
                            {/*<Link*/}
                            {/*    to='/auta_osobowe' state={{*/}
                            {/*            brand: selectedBrandOption,*/}
                            {/*            model: selectedModelOption,*/}
                            {/*            generation: selectedGeneration,*/}
                            {/*            year_from: yearFrom,*/}
                            {/*            year_to: yearTo,*/}
                            {/*            body_type: bodyType,*/}
                            {/*            mileage_from: mileageFrom,*/}
                            {/*            mileage_to: mileageTo,*/}
                            {/*            fuel_type: fuelType,*/}
                            {/*            price_from: priceFrom,*/}
                            {/*            price_to: priceTo*/}
                            {/*        }}*/}

                            {/*        // search: {*/}
                            {/*        //     brand: selectedBrandOption*/}
                            {/*        // },*/}
                            {/*    className={"my-auto xl:px-8 sm:px-5 bg-main_red-100 hover:bg-main_red-200 text-white py-2.5 px-4 rounded-[20px] transition duration-200"}>Przejdź dalej</Link>*/}

                            <button onClick={handleSearch} className="my-auto xl:px-8 sm:px-5 bg-main_red-100 hover:bg-main_red-200 text-white py-2 px-4 h-[42px] rounded-[20px] transition duration-200 select-none">
                                Przejdź dalej
                            </button>
                        </div>
                    </div>

                </div>

            </div>

            {/* Display fetched data */}
            {/*{isLoading ? (*/}
            {/*  <div>Loading...</div>*/}
            {/*) : error ? (*/}
            {/*  <div>{error}</div>*/}
            {/*  ) : (*/}
            {/*  data.map((item) => <div key={item.id}>{item.version}</div>)*/}
            {/*)}*/}
        </div>
    );
}

export default LandingPageFiltration;
export { generateYearOptions, generatePriceOptions, generateMileageOptions };


