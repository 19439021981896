import React, {useEffect, useState} from "react";
import Navbar from "../components/Navbar";
import Stripes from "../components/Stripes";
import Footer from "../components/Footer";
import {useLocation, useParams} from "react-router-dom";
import {animateScroll as scroll} from "react-scroll";
import {Link} from "react-router-dom";
import {handleAuctionColor} from "../components/cards/CarAuctionsCard";
import {Helmet} from "react-helmet";
import {provinceOptions, cityDict} from "../components/data/LocalizationData";

function SearchByProvince() {

    const location = useLocation();

    useEffect(() => {
        scroll.scrollToTop({
            duration: 300,
        });
    }, [location]);


    const DESCRIPTION = `Zobacz ogłoszenia samochodów z dowolnego województwa Polski!`;

    return (
    <div className="App">
      {/*<Navbar color={navbar_color}/>*/}
        <Navbar/>
        <Stripes/>

        <Helmet
        onChangeClientState={(newState) => {
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
        metaDescription.setAttribute('content', DESCRIPTION || '');
        }
        }}>
            <title>Samochody wedle województw - Autkotarg.pl</title>
        </Helmet>

        <div className={``}>
            <div className={`w-[98%] xsm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 bg-base-100 rounded-[20px] mt-10 mx-auto geometric-shadow-gray transition duration-300 relative z-10`}>
                <p className={`text-[24px] md:text-[26px] w-11/12 text-center mx-auto pt-5 pb-2`}>Samochody w województwach</p>
                <hr className={`w-11/12 mb-3`}/>
                <div className={`w-11/12 pb-5 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 mx-auto`}>
                    { provinceOptions.map((province) => {
                        console.log(province)
                        return (
                            <div className={`py-3 m-2 rounded-[14px] transition duration-300 ${handleAuctionColor(true, true, false)}`}>
                                <div className={`w-[95%] mx-auto justify-between flex flex-row md:flex-col`}>
                                    <Link to={`/auta_osobowe?page=1&province=${province.value}`} className={`text-center truncate text-[18px] md:text-[20px] underline text-main_green`}>{province.label}</Link>
                                    <Link to={`/samochody_osobowe_w/${province.value}`} className={`my-auto md:mx-auto underline text-[16px] md:text-[18px] mt-0 md:mt-1.5 text-main_gray_dark`}>Miasta</Link>
                                </div>
                            </div>
                        )
                    }) }
                </div>
            </div>
        </div>

        <Footer/>
    </div>
    );
}

function SearchByCity() {

    const location = useLocation();
    const { province } = useParams();
    const capitalizeProvince = province.charAt(0).toUpperCase() + province.slice(1);

    useEffect(() => {
        scroll.scrollToTop({
            duration: 300,
        });
    }, [location]);

    const DESCRIPTION = `Znajdź ogłoszenia samochodów w Miastach województwa: ${capitalizeProvince}`;

    return (
    <div className="App">
      {/*<Navbar color={navbar_color}/>*/}
        <Navbar/>
        <Stripes/>

        <Helmet
        onChangeClientState={(newState) => {
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
        metaDescription.setAttribute('content', DESCRIPTION || '');
        }
        }}>
            <title>Szukaj samochodów w Miastach województwa: {capitalizeProvince} - Autkotarg.pl</title>
        </Helmet>

        <div className={``}>
            <div className={`w-[98%] xsm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 bg-base-100 rounded-[20px] mt-10 mx-auto geometric-shadow-gray transition duration-300 relative z-10`}>
                <p className={`text-[24px] md:text-[26px] w-11/12 text-center mx-auto pt-5 py-2`}>Szukaj ogłoszeń w miastach województwa: {capitalizeProvince}</p>
                <div className={`w-11/12 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mx-auto pb-8`}>
                    { cityDict[province].map((city => {
                        return (
                            <Link to={`/auta_osobowe?page=1&province=${province}&city=${city}&radius=20`} className={`my-2 mx-2 py-2 rounded-[14px] w-[95%] mx-auto transition duration-300 ${handleAuctionColor(true, true, false)}`}>
                                <p className={`my-2 w-11/12 mx-auto text-center text-[16px] text-[18px] md:text-[20px] underline text-main_green`}>{city}</p>
                            </Link>
                        )
                    })) }
                </div>
            </div>
        </div>

        <Footer/>
    </div>
    );
}

export {SearchByProvince, SearchByCity};
