const provinceOptions = [
    { label: 'Opolskie', value: 'opolskie' },
    { label: 'Podlaskie', value: 'podlaskie' },
    { label: 'Lubuskie', value: 'lubuskie' },
    { label: 'Pomorskie', value: 'pomorskie' },
    { label: 'Warmińsko-mazurskie', value: 'warmińsko-mazurskie' },
    { label: 'Świętokrzyskie', value: 'świętokrzyskie' },
    { label: 'Kujawsko-pomorskie', value: 'kujawsko-pomorskie' },
    { label: 'Podkarpackie', value: 'podkarpackie' },
    { label: 'Łódzkie', value: 'łódzkie' },
    { label: 'Zachodniopomorskie', value: 'zachodniopomorskie' },
    { label: 'Lubelskie', value: 'lubelskie' },
    { label: 'Małopolskie', value: 'małopolskie' },
    { label: 'Dolnośląskie', value: 'dolnośląskie' },
    { label: 'Mazowieckie', value: 'mazowieckie' },
    { label: 'Śląskie', value: 'śląskie' },
    { label: 'Wielkopolskie', value: 'wielkopolskie' },
]

const cityDict = {
"opolskie": ['Biała', 'Brzeg', 'Byczyna', 'Dobrodzień', 'Głogówek', 'Głubczyce', 'Głuchołazy', 'Gogolin', 'Gorzów Śląski', 'Grodków', 'Kędzierzyn-Koźle', 'Kietrz', 'Kluczbork', 'Kolonowskie', 'Korfantów', 'Krapkowice', 'Leśnica', 'Lewin Brzeski', 'Namysłów', 'Niemodlin', 'Nysa', 'Olesno', 'Opole', 'Otmuchów', 'Ozimek', 'Paczków', 'Praszka', 'Prószków', 'Prudnik', 'Strzelce Opolskie', 'Tułowice', 'Ujazd (województwo opolskie)', 'Wołczyn', 'Zawadzkie', 'Zdzieszowice'],
"podlaskie": ['Augustów', 'Białystok', 'Bielsk Podlaski', 'Brańsk', 'Choroszcz', 'Ciechanowiec', 'Czarna Białostocka', 'Czyżew', 'Dąbrowa Białostocka', 'Drohiczyn (Polska)', 'Goniądz', 'Grajewo', 'Hajnówka', 'Jedwabne', 'Kleszczele', 'Knyszyn', 'Kolno', 'Krynki', 'Lipsk (Polska)', 'Łapy', 'Łomża', 'Michałowo', 'Mońki', 'Nowogród', 'Rajgród', 'Sejny', 'Siemiatycze', 'Sokółka', 'Stawiski', 'Suchowola', 'Supraśl', 'Suraż', 'Suwałki', 'Szczuczyn (województwo podlaskie)', 'Szepietowo', 'Tykocin', 'Wasilków', 'Wysokie Mazowieckie', 'Zabłudów', 'Zambrów'],
"lubuskie": ['Bytom Odrzański', 'Cybinka', 'Czerwieńsk', 'Dobiegniew', 'Drezdenko', 'Gorzów Wielkopolski', 'Gozdnica', 'Gubin', 'Iłowa', 'Jasień', 'Kargowa', 'Kostrzyn nad Odrą', 'Kożuchów', 'Krosno Odrzańskie', 'Lubniewice', 'Lubsko', 'Łęknica', 'Małomice', 'Międzyrzecz', 'Nowa Sól', 'Nowe Miasteczko', 'Nowogród Bobrzański', 'Ośno Lubuskie', 'Otyń', 'Rzepin', 'Skwierzyna', 'Sława', 'Słubice', 'Strzelce Krajeńskie', 'Sulechów', 'Sulęcin', 'Szlichtyngowa', 'Szprotawa', 'Świebodzin', 'Torzym', 'Trzciel', 'Witnica', 'Wschowa', 'Zbąszynek', 'Zielona Góra', 'Żagań', 'Żary'],
"pomorskie": ['Bytów', 'Chojnice', 'Czarna Woda', 'Czarne', 'Czersk', 'Człuchów', 'Debrzno', 'Dzierzgoń', 'Gdańsk', 'Gdynia', 'Gniew', 'Hel', 'Jastarnia', 'Kartuzy', 'Kępice', 'Kościerzyna', 'Krynica Morska', 'Kwidzyn', 'Lębork', 'Łeba', 'Malbork', 'Miastko', 'Nowy Dwór Gdański', 'Nowy Staw', 'Pelplin', 'Prabuty', 'Pruszcz Gdański', 'Puck', 'Reda', 'Rumia', 'Skarszewy', 'Skórcz', 'Słupsk', 'Sopot', 'Starogard Gdański', 'Sztum', 'Tczew', 'Ustka', 'Wejherowo', 'Władysławowo', 'Żukowo'],
"warmińsko-mazurskie": ['Bartoszyce', 'Biała Piska', 'Biskupiec', 'Bisztynek', 'Braniewo', 'Dobre Miasto', 'Działdowo', 'Elbląg', 'Ełk', 'Frombork', 'Giżycko', 'Gołdap', 'Górowo Iławeckie', 'Iława', 'Jeziorany', 'Kętrzyn', 'Kisielice', 'Korsze', 'Lidzbark', 'Lidzbark Warmiński', 'Lubawa', 'Mikołajki', 'Miłakowo', 'Miłomłyn', 'Młynary', 'Morąg', 'Mrągowo', 'Nidzica', 'Nowe Miasto Lubawskie', 'Olecko', 'Olsztyn', 'Olsztynek', 'Orneta', 'Orzysz', 'Ostróda', 'Pasłęk', 'Pasym', 'Pieniężno', 'Pisz', 'Reszel', 'Ruciane-Nida', 'Ryn', 'Sępopol', 'Susz', 'Szczytno', 'Tolkmicko', 'Węgorzewo', 'Wielbark', 'Zalewo'],
"świętokrzyskie": ['Busko-Zdrój', 'Chęciny', 'Chmielnik (województwo świętokrzyskie)', 'Ćmielów', 'Daleszyce', 'Działoszyce', 'Iwaniska', 'Jędrzejów', 'Kazimierza Wielka', 'Kielce', 'Klimontów', 'Końskie', 'Koprzywnica', 'Kunów', 'Łagów', 'Łopuszno', 'Małogoszcz', 'Morawica', 'Nowa Słupia', 'Nowy Korczyn', 'Oleśnica (województwo świętokrzyskie)', 'Opatowiec', 'Opatów', 'Osiek', 'Ostrowiec Świętokrzyski', 'Ożarów', 'Pacanów', 'Piekoszów', 'Pierzchnica', 'Pińczów', 'Połaniec', 'Radoszyce', 'Sandomierz', 'Sędziszów', 'Skalbmierz', 'Skarżysko-Kamienna', 'Starachowice', 'Staszów', 'Stąporków', 'Stopnica', 'Suchedniów', 'Szydłów', 'Wąchock', 'Wiślica', 'Włoszczowa', 'Wodzisław', 'Zawichost'],
"kujawsko-pomorskie": ['Barcin', 'Brodnica', 'Brześć Kujawski', 'Bydgoszcz', 'Chełmno', 'Chełmża', 'Chodecz', 'Ciechocinek', 'Dobrzyń nad Wisłą', 'Gniewkowo', 'Golub-Dobrzyń', 'Górzno', 'Grudziądz', 'Inowrocław', 'Izbica Kujawska', 'Jabłonowo Pomorskie', 'Janikowo', 'Janowiec Wielkopolski', 'Kamień Krajeński', 'Kcynia', 'Koronowo', 'Kowal', 'Kowalewo Pomorskie', 'Kruszwica', 'Lipno', 'Lubień Kujawski', 'Lubraniec', 'Łabiszyn', 'Łasin', 'Mogilno', 'Mrocza', 'Nakło nad Notecią', 'Nieszawa', 'Nowe', 'Pakość', 'Piotrków Kujawski', 'Pruszcz', 'Radziejów', 'Radzyń Chełmiński', 'Rypin', 'Sępólno Krajeńskie', 'Skępe', 'Solec Kujawski', 'Strzelno', 'Szubin', 'Świecie', 'Toruń', 'Tuchola', 'Wąbrzeźno', 'Więcbork', 'Włocławek', 'Żnin'],
"podkarpackie": ['Błażowa', 'Boguchwała', 'Brzostek', 'Brzozów', 'Cieszanów', 'Dębica', 'Dubiecko', 'Dukla', 'Dynów', 'Głogów Małopolski', 'Iwonicz-Zdrój', 'Jarosław (województwo podkarpackie)', 'Jasło', 'Jedlicze', 'Kańczuga', 'Kolbuszowa', 'Kołaczyce', 'Krosno', 'Lesko', 'Leżajsk', 'Lubaczów', 'Łańcut', 'Mielec', 'Narol', 'Nisko', 'Nowa Dęba', 'Nowa Sarzyna', 'Oleszyce', 'Pilzno (Polska)', 'Pruchnik', 'Przecław', 'Przemyśl', 'Przeworsk', 'Radomyśl Wielki', 'Radymno', 'Ropczyce', 'Rudnik nad Sanem', 'Rymanów', 'Rzeszów', 'Sanok', 'Sędziszów Małopolski', 'Sieniawa', 'Sokołów Małopolski', 'Stalowa Wola', 'Strzyżów', 'Tarnobrzeg', 'Tyczyn', 'Ulanów', 'Ustrzyki Dolne', 'Zagórz', 'Zaklików'],
"łódzkie": ['Bełchatów', 'Biała Rawska', 'Błaszki', 'Bolimów', 'Brzeziny', 'Dąbrowice', 'Drzewica', 'Działoszyn', 'Głowno', 'Jeżów', 'Kamieńsk', 'Koluszki', 'Konstantynów Łódzki', 'Krośniewice', 'Kutno', 'Lutomiersk', 'Lututów', 'Łask', 'Łęczyca', 'Łowicz', 'Łódź', 'Opoczno', 'Ozorków', 'Pabianice', 'Pajęczno', 'Piątek', 'Piotrków Trybunalski', 'Poddębice', 'Przedbórz', 'Radomsko', 'Rawa Mazowiecka', 'Rozprza', 'Rzgów', 'Sieradz', 'Skierniewice', 'Stryków', 'Sulejów', 'Szadek', 'Tomaszów Mazowiecki', 'Tuszyn', 'Ujazd (powiat tomaszowski)', 'Uniejów', 'Warta', 'Wieluń', 'Wieruszów', 'Wolbórz', 'Zduńska Wola', 'Zelów', 'Zgierz', 'Złoczew', 'Żychlin'],
"zachodniopomorskie": ['Barwice', 'Białogard', 'Biały Bór', 'Bobolice', 'Borne Sulinowo', 'Cedynia', 'Chociwel', 'Chojna', 'Choszczno', 'Czaplinek', 'Człopa', 'Darłowo', 'Dębno', 'Dobra (powiat łobeski)', 'Dobrzany', 'Drawno', 'Drawsko Pomorskie', 'Dziwnów', 'Golczewo', 'Goleniów', 'Gościno', 'Gryfice', 'Gryfino', 'Ińsko', 'Kalisz Pomorski', 'Kamień Pomorski', 'Karlino', 'Kołobrzeg', 'Koszalin', 'Lipiany', 'Łobez', 'Maszewo', 'Mielno', 'Mieszkowice', 'Międzyzdroje', 'Mirosławiec', 'Moryń', 'Myślibórz', 'Nowe Warpno', 'Nowogard', 'Pełczyce', 'Płoty', 'Polanów', 'Police', 'Połczyn-Zdrój', 'Pyrzyce', 'Recz', 'Resko', 'Sianów', 'Sławno', 'Stargard', 'Stepnica', 'Suchań', 'Szczecin', 'Szczecinek', 'Świdwin', 'Świnoujście', 'Trzcińsko-Zdrój', 'Trzebiatów', 'Tuczno', 'Tychowo', 'Wałcz', 'Węgorzyno', 'Wolin', 'Złocieniec'],
"lubelskie": ['Bełżyce', 'Biała Podlaska', 'Biłgoraj', 'Bychawa', 'Chełm', 'Dęblin', 'Frampol', 'Goraj', 'Hrubieszów', 'Izbica', 'Janów Lubelski', 'Józefów (powiat biłgorajski)', 'Józefów nad Wisłą', 'Kamionka (powiat lubartowski)', 'Kazimierz Dolny', 'Kock', 'Krasnobród', 'Krasnystaw', 'Kraśnik', 'Lubartów', 'Lublin', 'Lubycza Królewska', 'Łaszczów', 'Łęczna', 'Łuków', 'Międzyrzec Podlaski', 'Modliborzyce', 'Nałęczów', 'Opole Lubelskie', 'Ostrów Lubelski', 'Parczew', 'Piaski', 'Poniatowa', 'Puławy', 'Radzyń Podlaski', 'Rejowiec', 'Rejowiec Fabryczny', 'Ryki', 'Siedliszcze', 'Stoczek Łukowski', 'Szczebrzeszyn', 'Świdnik', 'Tarnogród', 'Terespol', 'Tomaszów Lubelski', 'Tyszowce', 'Urzędów', 'Włodawa', 'Zamość', 'Zwierzyniec'],
"małopolskie": ['Alwernia', 'Andrychów', 'Biecz', 'Bobowa', 'Bochnia', 'Brzesko', 'Brzeszcze', 'Bukowno', 'Chełmek', 'Chrzanów', 'Ciężkowice', 'Czarny Dunajec', 'Czchów', 'Dąbrowa Tarnowska', 'Dobczyce', 'Gorlice', 'Grybów', 'Jordanów', 'Kalwaria Zebrzydowska', 'Kęty', 'Koszyce (województwo małopolskie)', 'Kraków', 'Krynica-Zdrój', 'Krzeszowice', 'Książ Wielki', 'Libiąż', 'Limanowa', 'Maków Podhalański', 'Miechów', 'Mszana Dolna', 'Muszyna', 'Myślenice', 'Niepołomice', 'Nowe Brzesko', 'Nowy Sącz', 'Nowy Targ', 'Nowy Wiśnicz', 'Olkusz', 'Oświęcim', 'Piwniczna-Zdrój', 'Proszowice', 'Rabka-Zdrój', 'Radłów', 'Ryglice', 'Skała', 'Skawina', 'Słomniki', 'Stary Sącz', 'Sucha Beskidzka', 'Sułkowice', 'Szczawnica', 'Szczucin', 'Świątniki Górne', 'Tarnów', 'Trzebinia', 'Tuchów', 'Wadowice', 'Wieliczka', 'Wojnicz', 'Wolbrom', 'Zakliczyn', 'Zakopane', 'Zator', 'Żabno'],
"dolnośląskie": ['Bardo', 'Bielawa', 'Bierutów', 'Bogatynia', 'Boguszów-Gorce', 'Bolesławiec', 'Bolków', 'Brzeg Dolny', 'Bystrzyca Kłodzka', 'Chocianów', 'Chojnów', 'Duszniki-Zdrój', 'Dzierżoniów', 'Głogów', 'Głuszyca', 'Góra', 'Gryfów Śląski', 'Jawor', 'Jaworzyna Śląska', 'Jedlina-Zdrój', 'Jelcz-Laskowice', 'Jelenia Góra', 'Kamieniec Ząbkowicki', 'Kamienna Góra', 'Karpacz', 'Kąty Wrocławskie', 'Kłodzko', 'Kowary', 'Kudowa-Zdrój', 'Lądek-Zdrój', 'Legnica', 'Leśna', 'Lubań (województwo dolnośląskie)', 'Lubawka', 'Lubin', 'Lubomierz', 'Lwówek Śląski', 'Mieroszów', 'Międzybórz', 'Międzylesie', 'Miękinia', 'Milicz', 'Mirsk', 'Niemcza', 'Nowa Ruda', 'Nowogrodziec', 'Oborniki Śląskie', 'Oleśnica', 'Olszyna', 'Oława', 'Piechowice', 'Pieńsk', 'Pieszyce', 'Piława Górna', 'Polanica-Zdrój', 'Polkowice', 'Prochowice', 'Prusice', 'Przemków', 'Radków', 'Siechnice', 'Sobótka', 'Stronie Śląskie', 'Strzegom', 'Strzelin', 'Syców', 'Szczawno-Zdrój', 'Szczytna', 'Szklarska Poręba', 'Ścinawa', 'Środa Śląska', 'Świdnica', 'Świebodzice', 'Świeradów-Zdrój', 'Świerzawa', 'Trzebnica', 'Twardogóra', 'Wałbrzych', 'Wąsosz', 'Węgliniec', 'Wiązów', 'Wleń', 'Wojcieszów', 'Wołów', 'Wrocław', 'Zawidów', 'Ząbkowice Śląskie', 'Zgorzelec', 'Ziębice', 'Złotoryja', 'Złoty Stok', 'Żarów', 'Żmigród'],
"mazowieckie": ['Bieżuń', 'Błonie', 'Bodzanów', 'Brok', 'Brwinów', 'Cegłów', 'Chorzele', 'Ciechanów', 'Czerwińsk nad Wisłą', 'Drobin', 'Garwolin', 'Gąbin', 'Glinojeck', 'Gostynin', 'Góra Kalwaria', 'Grodzisk Mazowiecki', 'Grójec', 'Halinów', 'Iłża', 'Jadów', 'Jastrząb', 'Jedlnia-Letnisko', 'Józefów (powiat otwocki)', 'Kałuszyn', 'Karczew', 'Kobyłka', 'Konstancin-Jeziorna', 'Kosów Lacki', 'Kozienice', 'Latowicz', 'Legionowo', 'Lipsko', 'Lubowidz', 'Łaskarzew', 'Łochów', 'Łomianki', 'Łosice', 'Maków Mazowiecki', 'Marki', 'Milanówek', 'Mińsk Mazowiecki', 'Mława', 'Mogielnica', 'Mordy', 'Mrozy', 'Mszczonów', 'Myszyniec', 'Nasielsk', 'Nowe Miasto (powiat płoński)', 'Nowe Miasto nad Pilicą', 'Nowy Dwór Mazowiecki', 'Ostrołęka', 'Ostrów Mazowiecka', 'Otwock', 'Ożarów Mazowiecki', 'Piaseczno', 'Piastów', 'Pilawa', 'Pionki', 'Płock', 'Płońsk', 'Podkowa Leśna', 'Pruszków', 'Przasnysz', 'Przysucha', 'Pułtusk', 'Raciąż', 'Radom', 'Radzymin', 'Różan', 'Sanniki', 'Serock', 'Siedlce', 'Sierpc', 'Skaryszew', 'Sochaczew', 'Sochocin', 'Sokołów Podlaski', 'Solec nad Wisłą', 'Sulejówek', 'Szydłowiec', 'Tarczyn', 'Tłuszcz', 'Warka', 'Warszawa', 'Węgrów', 'Wiskitki', 'Wołomin', 'Wyszków', 'Wyszogród (województwo mazowieckie)', 'Wyśmierzyce', 'Zakroczym', 'Ząbki', 'Zielonka', 'Zwoleń (powiat zwoleński)', 'Żelechów', 'Żuromin', 'Żyrardów'],
"śląskie": ['Będzin', 'Bielsko-Biała', 'Bieruń', 'Blachownia', 'Bytom', 'Chorzów', 'Cieszyn', 'Czechowice-Dziedzice', 'Czeladź', 'Czerwionka-Leszczyny', 'Częstochowa', 'Dąbrowa Górnicza', 'Gliwice', 'Imielin', 'Jastrzębie-Zdrój', 'Jaworzno', 'Kalety', 'Katowice', 'Kłobuck', 'Knurów', 'Koniecpol', 'Koziegłowy', 'Krzanowice', 'Krzepice', 'Kuźnia Raciborska', 'Lędziny', 'Lubliniec', 'Łaziska Górne', 'Łazy', 'Miasteczko Śląskie', 'Mikołów', 'Mysłowice', 'Myszków', 'Ogrodzieniec', 'Olsztyn (województwo śląskie)', 'Orzesze', 'Piekary Śląskie', 'Pilica', 'Poręba', 'Pszczyna', 'Pszów', 'Pyskowice', 'Racibórz', 'Radlin', 'Radzionków', 'Ruda Śląska', 'Rybnik', 'Rydułtowy', 'Siemianowice Śląskie', 'Siewierz', 'Skoczów', 'Sławków (województwo śląskie)', 'Sosnowiec', 'Sośnicowice', 'Strumień', 'Szczekociny', 'Szczyrk', 'Świętochłowice', 'Tarnowskie Góry', 'Toszek', 'Tychy', 'Ustroń', 'Wilamowice', 'Wisła', 'Włodowice', 'Wodzisław Śląski', 'Wojkowice', 'Woźniki', 'Zabrze', 'Zawiercie', 'Żarki', 'Żory', 'Żywiec'],
"wielkopolskie": ['Bojanowo', 'Borek Wielkopolski', 'Budzyń', 'Buk', 'Chocz', 'Chodzież', 'Czarnków', 'Czempiń', 'Czerniejewo', 'Dąbie', 'Dobra (powiat turecki)', 'Dobrzyca', 'Dolsk', 'Gniezno', 'Golina', 'Gołańcz', 'Gostyń', 'Grabów nad Prosną', 'Grodzisk Wielkopolski', 'Jaraczewo', 'Jarocin', 'Jastrowie', 'Jutrosin', 'Kaczory', 'Kalisz', 'Kępno', 'Kleczew', 'Kłecko', 'Kłodawa', 'Kobylin', 'Koło', 'Konin', 'Kostrzyn', 'Kościan', 'Koźmin Wielkopolski', 'Koźminek', 'Kórnik', 'Krajenka', 'Krobia', 'Krotoszyn', 'Krzywiń', 'Krzyż Wielkopolski', 'Książ Wielkopolski', 'Leszno', 'Luboń', 'Lwówek', 'Łobżenica', 'Margonin', 'Miasteczko Krajeńskie', 'Miejska Górka', 'Międzychód', 'Mikstat', 'Miłosław', 'Mosina', 'Murowana Goślina', 'Nekla', 'Nowe Skalmierzyce', 'Nowy Tomyśl', 'Oborniki', 'Obrzycko', 'Odolanów', 'Okonek', 'Opalenica', 'Opatówek', 'Osieczna', 'Ostroróg', 'Ostrów Wielkopolski', 'Ostrzeszów', 'Piła', 'Pleszew', 'Pniewy', 'Pobiedziska', 'Pogorzela', 'Poniec', 'Poznań', 'Przedecz', 'Puszczykowo', 'Pyzdry', 'Rakoniewice', 'Raszków', 'Rawicz', 'Rogoźno', 'Rychwał', 'Rydzyna', 'Sieraków', 'Skoki', 'Słupca', 'Sompolno', 'Stawiszyn', 'Stęszew', 'Sulmierzyce', 'Swarzędz', 'Szamocin', 'Szamotuły', 'Ślesin', 'Śmigiel', 'Śrem', 'Środa Wielkopolska', 'Trzcianka', 'Trzemeszno', 'Tuliszków', 'Turek', 'Ujście', 'Wągrowiec', 'Wieleń', 'Wielichowo', 'Witkowo', 'Wolsztyn', 'Wronki', 'Września', 'Wyrzysk', 'Wysoka', 'Zagórów', 'Zbąszyń', 'Zduny', 'Złotów', 'Żerków'],
}

export {cityDict, provinceOptions};