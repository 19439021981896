import React, {useEffect, useRef, useState} from "react";
import {Outlet, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/zoom';
import { Pagination, Navigation, Zoom} from 'swiper/modules';
import CarAuctionsCard, {handleAuctionColor} from "../../cards/CarAuctionsCard";
import HighlightedAuctionsCard from "../../homepage_auctions/components/HomepageAuctionsCard";
import {animateScroll as scroll} from "react-scroll";
import axiosInstance from "../../../api/axios";
import {debounce} from "lodash";
import { Helmet} from "react-helmet";
import {TextWithNewlines} from "./CarAuctionAdminComponent";

// import { animateScroll as scroll } from 'react-scroll';

export function formatTimestamp(timestamp, is_hour) {
        const date = new Date(timestamp);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so we add 1
        const year = date.getFullYear();

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let formattedTimestamp; // Declare the variable here without assignment

        if (is_hour === true) {
            formattedTimestamp = `${day}/${month}/${year} ${hours}:${minutes}`;
        } else {
            formattedTimestamp = `${day}/${month}/${year}`;
        }

        return formattedTimestamp;
}

const CarAuctionComponent = () => {
    const navigate = useNavigate();
    // navigate

    const ref = useRef(null);
    const { id } = useParams();
    const location = useLocation();
    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ auctionData, setAuctionData ] = useState([]);
    const [ moreAuctions, setMoreAuctionsData ] = useState([]);
    const [ isLoaded, setIsLoaded ] = useState(false);
    const [ isOtherAuctionsLoaded, setIsOtherAuctionsLoaded ] = useState(false);
    const [ chunkedData, setChunkedData ] = useState([]);
    const [ divClassNameTelephoneNumber, setDivClassNameTelephoneNumber ] = useState(
        'w-11/12 mx-auto text-center mt-3 mb-5 bg-main_red-100 hover:bg-main_red-200 transition duration-200 text-white h-10 text-[16px] rounded-[20px]'
    );
    const [ EquipmentDivClassName, setEquipmentDivClassName ] = useState('-mt-2 hidden md:block');
    const [ ExpandButtonDivClassName, setExpandButtonDivClassName ] = useState('block md:hidden text-[16px] text-main_gray_dark underline flex mx-auto w-full mt-5 gap-x-2 justify-center')
    const [ hideEquipmentPanelDivClassName, setHideEquipmentPanelDivClassName] = useState('hidden')

    const [ auctionCarWish, setAuctionCarWish ] = useState({'wish': null})
    const [ equipmentGroupedByCategory, setEquipmentGroupedByCategory] = useState({});
    const [ fullScreenGallery, setFullScreenGallery] = useState(false);
    const [ notFound, setNotFound ] = useState(false);

    const [copySuccess, setCopySuccess] = useState(false)
    const [showSharePanel, setShowSharePanel] = useState(false)

    // States for car data information and animation.
    const [imgDivHeight, setImgDivHeight] = useState(0);
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const hasMounted = useRef(false);
    let DESCRIPTION = `Ogłoszenie samochodu osobowego`;

    useEffect(() => {
        setAuctionCarWish(prevState => ({
            ...prevState,
            wish: auctionData.wish
        }));
        console.log(auctionCarWish)
    }, [auctionData])

    useEffect(() => {
        setCopySuccess(false)
        setShowSharePanel(false)
    }, [location])

    function showSharePanelFunction() {
        setShowSharePanel(!showSharePanel);
    }

    async function copyToClip() {
        console.log(location)
        await navigator.clipboard.writeText(`https://autkotarg.pl${location.pathname}`);
        setCopySuccess(true);
    }

    const makeWish = debounce(async (id, wish) => {
        console.log(id, wish)
        console.log(auctionCarWish)

        if (wish === null) {
            // navigate('/logowanie')
            navigate(`/logowanie?return_url=auta_osobowe/aukcja/${id}`)
        } else if (wish === false) {
            axiosInstance.post(`/wishlist/car_wish/${id}`)
                .then((data) => {
                    console.log('Follow z suckesem!')

                    setAuctionCarWish(prevState => ({
                        ...prevState,
                        'wish': !prevState.wish
                    }));
                })
                .catch((e) => {
                    console.log('Błąd przy folllow')
            })
            console.log("Follow")


        } else if (wish === true) {
            axiosInstance.delete(`/wishlist/car_wish/${id}`)
                .then((data) => {
                    console.log('Usunieto follow')
                    setAuctionCarWish(prevState => ({
                        ...prevState,
                        'wish': !prevState.wish
                    }));
                })
                .catch((e) => {
                    console.log('Bład przy usuwaniu follow')
            })
            // console.log("Unfollow")
        }

        // setPrevIdWish(id);
        // console.log(wishes)
    }, 200)

    const fetchAuctionData = async (id) => {
        try {
            if (id !== null) {
                // Fetching auction data.
                // const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/passenger/auctions/specific/${id}`)
                const response = await axiosInstance.get(`/passenger/auctions/specific/${id}`)
                setDivClassNameTelephoneNumber('w-11/12 mx-auto text-center mt-3 py-1 mb-4 bg-main_red-100 hover:bg-main_red-200 transition duration-200 text-white text-[16px] rounded-[20px]')
                console.log(response)

                if (response.status !== 200) {
                    throw new Error("Network response was not okay in fetching auction.")
                }

                // const data = await response.json();
                const data = response.data;

                setIsOtherAuctionsLoaded(false)

                setAuctionData(data)

                // document.title = `Aukcja ${data.brand.name} ${data.version.version} ${data.generation.generation}, ${data.price.toLocaleString()} PLN - Autkogiełda.pl`;

                // Setting up equipment panel and chunk it to smaller parts for better showing equipment

                const inputArray = [];
                data.equipment.map((equipment, index) => (inputArray.push(equipment.name)))

                const perChunk = 4 // items per chunk

                const result = inputArray.reduce((resultArray, item, index) => {
                    const chunkIndex = Math.floor(index/perChunk)

                    if(!resultArray[chunkIndex]) {
                        resultArray[chunkIndex] = [] // start a new chunk
                    }

                    resultArray[chunkIndex].push(item)

                    return resultArray
                }, [])

                setChunkedData(result);
                console.log(result);

                const groupedByCategory = data.equipment.reduce((acc, option) => {
                  const { category, name } = option;
                  if (!acc[category]) {
                    acc[category] = [];
                  }
                  acc[category].push({ name });
                  return acc;
                }, {});


                Object.keys(groupedByCategory).map((equipment) => {
                    // console.log(equipment)
                    const equipmentArray = groupedByCategory[equipment];
                    console.log(equipment ,equipmentArray)

                    const result = equipmentArray.reduce((resultArray, item, index) => {
                        const chunkIndex = Math.floor(index / perChunk);

                        if (!resultArray[chunkIndex]) {
                            resultArray[chunkIndex] = []
                        }

                        resultArray[chunkIndex].push(item)

                        return resultArray
                    }, [])

                    console.log(equipment ,result)
                    groupedByCategory[equipment] = result;

                })


                setEquipmentGroupedByCategory(groupedByCategory);
                console.log(groupedByCategory);
                console.log(equipmentGroupedByCategory);


                setIsLoaded(true);
                setIsImageLoaded(false);

            //     Fetching other auctions

                const auctions_response = await axiosInstance.get(`/passenger/auctions/specific/get_more_auctions/?brand=${data.brand.name}&price=${data.price}&year=${data.production_date}&id=${id}`)
                console.log(auctions_response)

                if (auctions_response.status !== 200) {
                    throw new Error("Network response was not okay in fetching other auctions.")
                }

                const auctions_data = await auctions_response.data;

                setMoreAuctionsData(auctions_data)
                setIsOtherAuctionsLoaded(true);

                // DESCRIPTION += `Ogłoszenie samochodu osobowego ${auctionData.brand.name} ${auctionData.version !== null ? `${auctionData.version.version}` : ('')} ${auctionData.generation !== null ? `${auctionData.generation.generation}` : ''}, ${auctionData.price.toLocaleString()} PLN, ${auctionData.mileage !== null ? `${auctionData.mileage} km` : ''} - Autkotarg.pl`

            //     Setting up Meta tags.
            //     const ogImageTag = document.querySelector('meta[property="og:image"]');
            //     if (ogImageTag) {
            //       ogImageTag.setAttribute('content', auctionData.images[0]);
            //     }
            //
            //     const ogDescriptionTag = document.querySelector('meta[property="og:description"]');
            //     if (ogDescriptionTag) {
            //       ogDescriptionTag.setAttribute('content', auctionData.description);
            //     }
            //
            //     const ogSiteTag = document.querySelector('meta[property="og:site"]');
            //     if (ogSiteTag) {
            //       ogSiteTag.setAttribute('content', 'https://www.autkotarg.pl');
            //     }

            } else {
                setIsLoaded(false);
                setIsImageLoaded(false);
            }
        } catch (error) {
            console.log(error)
            setNotFound(true)
        }

        console.log(auctionData);
    }

    function formatDateToYear(timestamp) {
        const date = new Date(timestamp);
        const year = date.getFullYear();

        return year
    }

    const showAuctionContactNumber = () => {
        setDivClassNameTelephoneNumber('hidden');
    };

    const showAllEquipment = () => {
        setEquipmentDivClassName('-mt-4 md:block');
        setExpandButtonDivClassName('hidden');
        setHideEquipmentPanelDivClassName('block md:hidden text-[16px] underline flex mx-auto w-full mt-5 gap-x-2 justify-center')
    };

    const hideAllEquipment = () => {
        setEquipmentDivClassName('-mt-2 hidden md:block')
        setExpandButtonDivClassName('block md:hidden text-[16px] underline flex mx-auto w-full mt-5 gap-x-2 justify-center');
        setHideEquipmentPanelDivClassName('hidden')
        ref.current?.scrollIntoView({ behavior: 'smooth'});
    }

    const showFullScreenSwiper = () => {
        setFullScreenGallery(!fullScreenGallery);
    }

    function formatTimestamp(timestamp, is_hour) {
        const date = new Date(timestamp);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so we add 1
        const year = date.getFullYear();

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let formattedTimestamp; // Declare the variable here without assignment

        if (is_hour === true) {
            formattedTimestamp = `${day}/${month}/${year} ${hours}:${minutes}`;
        } else {
            formattedTimestamp = `${day}/${month}/${year}`;
        }

        return formattedTimestamp;
    }

    // TODO here is problem ;) i cant fetch data 2nd time when page refresh :)

    // useEffect(() => {
    //     if (!hasMounted.current) {
    //         hasMounted.current = true;
    //         // const id = searchParams.get('id');
    //         console.log(id);
    //         fetchAuctionData(id);
    //     }
    // }, [])

    useEffect(() => {
        setIsLoaded(false);
        fetchAuctionData(id);
    }, [location])


    useEffect(() => {
        // window.scrollTo(0, 0);

        scroll.scrollToTop({
            duration: 300,
        });
    }, [location]);


    // window.onpopstate = () => {
    //     window.history.pushState({ prevUrl: url }, '')
        // navigate("/auta_osobowe/");
    // }

    // let DESCRIPTION = `Ogłoszenie samochodu osobowego`;
    
    
    // Functions for car 'data' animation (panel next to the images.)

    function onLoadImage(e) {
        if (!isImageLoaded) {
            setIsImageLoaded(true);
        }
    }

    // imageLoaded is true on end of loading first picture.
    useEffect(() => {
        if (isImageLoaded) {
            // Animation should be only showed on larger devices.
            if (window.screen.width > 1024) {
                if (isLoaded) {
                    const imgElement = document.getElementById("img-id");
                    const bgImgElement = document.getElementById("bg-img-id")
                    if (imgElement && bgImgElement) {
                        // if (imgElement.clientHeight > 450 ) {
                        //     // console.log((imgElement.clientHeight >= 450 && imgElement.clientHeight <= 500), window.screen.width > 1024 && window.screen.width < 1100)
                        //     // Check if its buggy img size on specific resolution - without this 'contact' button was not in div.
                        //     if ((imgElement.clientHeight >= 450 && imgElement.clientHeight <= 500) && (window.screen.width > 1024 && window.screen.width < 1100)) {
                        //         setImgDivHeight(imgElement.clientHeight + 50)
                        //     } else {
                        //         setImgDivHeight(imgElement.clientHeight)
                        //     }
                        // } else {
                        //     setImgDivHeight(470)
                        // }

                        // Getting Swiper clientHeight because img sometimes was not propery working when 
                        if (imgElement.clientHeight > 450 || bgImgElement.clientHeight > 450) {
                            if (imgElement.clientHeight > bgImgElement.clientHeight) {
                                if (imgElement.clientHeight < 470) {
                                    setImgDivHeight(imgElement.clientHeight + 20)
                                } else {
                                    setImgDivHeight(imgElement.clientHeight)
                                }
                            } else {
                                if (imgElement.clientHeight < 470) {
                                    setImgDivHeight(bgImgElement.clientHeight + 20)
                                } else {
                                    setImgDivHeight(bgImgElement.clientHeight)
                                }
                            }
                        } else {
                            setImgDivHeight('auto')
                        }
                    }
                }
            } else {
                setImgDivHeight('auto')
            }
        }
    }, [isImageLoaded])


    return (
        <div>
            { isLoaded ? (
                <div className={'w-full px-1 md:mx-auto md:w-11/12 lg:w-11/12 xl:w-10/12 relative'}>

                    <Helmet
                        onChangeClientState={(newState) => {
                        // Setting up description metatag
                        const metaDescription = document.querySelector('meta[name="description"]');
                        if (metaDescription) {
                            metaDescription.setAttribute('content', `${(auctionData.user.show_more_auctions !== null && auctionData.user.show_more_auctions && auctionData.user) ? (`${ auctionData.user.business_name } - `) : ('')}Ogłoszenie samochodu osobowego ${auctionData.brand.name}${auctionData.version !== null ? ` ${auctionData.version.version}` : ('')}${auctionData.generation !== null ? ` ${auctionData.generation.generation}` : ''}${auctionData.production_date !== null ? (`, Rok produkcji ${auctionData.production_date},`) : (``)}${auctionData.fuel_type.type !== null && ` ${auctionData.fuel_type.type},`}${auctionData.price !== null && (` ${auctionData.price} PLN`)}${auctionData.price_type !== null && (`- ${auctionData.price_type}, `)}${auctionData.mileage !== null ? `Przebieg ${auctionData.mileage} km, ` : ''}${auctionData.gearbox !== null ? (`Skrzynia ${auctionData.gearbox.name}, `) : (``)}${auctionData.body_type.body_type !== null && `Nadwozie typu: ${auctionData.body_type.body_type}, `}${auctionData.title !== null && auctionData.title} - Autkotarg.pl` || '');
                        }
                        // Setting up og:description metatag
                        // const ogMetaDescription = document.querySelector('meta[property="og:description"]');
                        // if (ogMetaDescription) {
                        //     ogMetaDescription.setAttribute('content', `Ogłoszenie samochodu osobowego ${auctionData.brand.name}${auctionData.version !== null ? ` ${auctionData.version.version}` : ('')}${auctionData.generation !== null ? ` ${auctionData.generation.generation}` : ''}${auctionData.production_date !== null ? (`, Rok produkcji ${auctionData.production_date},`) : (``)}${auctionData.fuel_type.type !== null && ` ${auctionData.fuel_type.type},`}${auctionData.price !== null && (` ${auctionData.price} PLN`)}${auctionData.price_type !== null && (`- ${auctionData.price_type}, `)}${auctionData.mileage !== null ? `Przebieg ${auctionData.mileage} km, ` : ''}${auctionData.gearbox !== null ? (`Skrzynia ${auctionData.gearbox.name}, `) : (``)}${auctionData.body_type.body_type !== null && `Nadwozie typu: ${auctionData.body_type.body_type}, `}${auctionData.title !== null && auctionData.title} - Autkotarg.pl` || '');
                        // }
                        // // Setting up og:image metatag
                        // const ogImage = document.querySelector('meta[property="og:image"]');
                        // if (ogImage) {
                        //     ogImage.setAttribute('content', `${auctionData.images ? (auctionData.images[0]) : (``)}`)
                        // }
                    }}>
                        <title>{(auctionData.user.show_more_auctions !== null && auctionData.user.show_more_auctions && auctionData.user) ? (`${ auctionData.user.business_name } - `) : ('')}Aukcja {auctionData.brand.name} {auctionData.version !== null ? (`${auctionData.version.version} `) : ('')}{auctionData.generation !== null ? `${auctionData.generation.generation}, ` : ''}{`- ${auctionData.price.toLocaleString()} PLN, `}{auctionData.mileage !== null ? (`${auctionData.mileage} km`) : ''}{auctionData.title && `, ${auctionData.title}`} - Autkotarg.pl</title>
                        {/* <meta property={'og:image'} content={auctionData.images[0]}/> */}
                        {/*<meta property={'og:title'} content={`${auctionData.brand.name}`}/>*/}
                        {/*<meta property={'og:site_name'} content={`Autkotarg.pl`}/>*/}
                        {/*<meta property={'og:url'} content={`Autkotarg.pl`}/>*/}
                        {/*<meta property={'og:description'} content={`${auctionData.description}`}/>*/}
                    </Helmet>
                    
                    { showSharePanel === true && (
                        <div className={`w-full h-full`}>
                            <div className={`fixed inset-0 flex items-center justify-center z-50 bg-opacity-50 bg-gray-300 backdrop-blur filtration-panel-animation`}>
                                <div className={`bg-base-100 overflow-auto w-11/12 sm:w-9/12 md:w-8/12 lg:w-4/12 2xl:w-4/12 rounded-[20px] mx-1 relative panel-content`} style={{ willChange: 'transform' }}>
                                    <p className="text-[18px] mt-3 mb-4 md:text-[20px] w-11/12 mx-auto text-center">Udostępnij aukcję!</p>
                                    <button onClick={showSharePanelFunction} className={`absolute right-4 top-3`}>
                                        <svg className={`w-7 h-7`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M18 6L6 18M6 6L18 18" stroke="#3D3D3D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </button>
                                    <div className="w-full mx-auto flex">
                                        <button className={`w-11/12 md:w-2/3 lg:w-2/3 py-1 mx-auto text-center mb-4 ${ copySuccess ? ('bg-main_green') : (`bg-main_red-100 hover:bg-main_red-200`)} transition duration-200 text-white text-[16px] rounded-[20px]`} onClick={copyToClip}>
                                            <div className={`mt-2 mb-2 w-full flex justify-center`}>
                                                { copySuccess ? (
                                                    <svg className="mr-2 stroke-white" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>                                                    
                                                ) : (
                                                    <svg className={`mr-2`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path d="M8.38028 8.85335C9.07627 10.303 10.0251 11.6616 11.2266 12.8632C12.4282 14.0648 13.7869 15.0136 15.2365 15.7096C15.3612 15.7694 15.4235 15.7994 15.5024 15.8224C15.7828 15.9041 16.127 15.8454 16.3644 15.6754C16.4313 15.6275 16.4884 15.5704 16.6027 15.4561C16.9523 15.1064 17.1271 14.9316 17.3029 14.8174C17.9658 14.3864 18.8204 14.3864 19.4833 14.8174C19.6591 14.9316 19.8339 15.1064 20.1835 15.4561L20.3783 15.6509C20.9098 16.1824 21.1755 16.4481 21.3198 16.7335C21.6069 17.301 21.6069 17.9713 21.3198 18.5389C21.1755 18.8242 20.9098 19.09 20.3783 19.6214L20.2207 19.779C19.6911 20.3087 19.4263 20.5735 19.0662 20.7757C18.6667 21.0001 18.0462 21.1615 17.588 21.1601C17.1751 21.1589 16.8928 21.0788 16.3284 20.9186C13.295 20.0576 10.4326 18.4332 8.04466 16.0452C5.65668 13.6572 4.03221 10.7948 3.17124 7.76144C3.01103 7.19699 2.93092 6.91477 2.9297 6.50182C2.92833 6.0436 3.08969 5.42311 3.31411 5.0236C3.51636 4.66357 3.78117 4.39876 4.3108 3.86913L4.46843 3.7115C4.99987 3.18006 5.2656 2.91433 5.55098 2.76999C6.11854 2.48292 6.7888 2.48292 7.35636 2.76999C7.64174 2.91433 7.90747 3.18006 8.43891 3.7115L8.63378 3.90637C8.98338 4.25597 9.15819 4.43078 9.27247 4.60655C9.70347 5.26945 9.70347 6.12403 9.27247 6.78692C9.15819 6.96269 8.98338 7.1375 8.63378 7.4871C8.51947 7.60142 8.46231 7.65857 8.41447 7.72538C8.24446 7.96281 8.18576 8.30707 8.26748 8.58743C8.29048 8.66632 8.32041 8.72866 8.38028 8.85335Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                )}
                                                {copySuccess ? ("Skopiowano link!") : ("Kopiuj link")}
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className={`flex text-main_gray_dark gap-x-3 mt-3 justify-center md:justify-start`}>
                        <Link
                            className={`flex gap-x-3`}
                            to={`/auta_osobowe`}>
                            Osobowe
                        </Link>
                        <svg className={`my-auto`}
                            xmlns="http://www.w3.org/2000/svg" width="3" height="4" viewBox="0 0 3 4" fill="none">
                            <circle cx="1.49976" cy="2" r="1.5" fill="#7D7D7D"/>
                        </svg>
                        <Link to={
                            {
                                pathname: `/auta_osobowe`,
                                search: `brand=${auctionData.brand.name}`,
                            }
                        }>
                            {auctionData.brand.name}
                        </Link>

                        { auctionData.version !== null ? (
                            <svg
                                className={`my-auto`}
                                xmlns="http://www.w3.org/2000/svg" width="3" height="4" viewBox="0 0 3 4" fill="none">
                                <circle cx="1.49976" cy="2" r="1.5" fill="#7D7D7D"/>
                            </svg>
                        ) : ('')}
                        { auctionData.version !== null ? (
                            <Link to={
                                {
                                    pathname: `/auta_osobowe`,
                                    search: `brand=${auctionData.brand.name}&model=${auctionData.version.version}`,
                                }
                            }>
                                {auctionData.version.version}
                            </Link>
                        ) : ('')}
                    </div>
                {/*// <p>{auctionData.brand.name}</p>*/}
                    <div className={`grid grid-cols-1 lg:grid-cols-12 xl:grid-cols-8 w-full md:gap-x-[24px] gap-y-[20px] mt-2`}>

                        <div className={`
                        ${ !fullScreenGallery ? (
                            `col-span-1 lg:col-span-8 xl:col-span-5 bg-white rounded-[20px] relative`
                        ) : (
                            `w-full h-full fixed inset-0 flex items-center justify-center z-50 transition duration-300 bg-opacity-50 bg-gray-300 backdrop-blur`
                        ) }
                        `}>
                            <div className={`
                            ${ !fullScreenGallery ? 
                                (`w-full h-full`) 
                                : 
                                (`bg-base-100 w-[95%] h-[95%] md:w-[90%] md:h-[90%] rounded-[20px] relative`)}`}>

                                { fullScreenGallery &&
                                    <svg onClick={showFullScreenSwiper} className={`absolute hover:cursor-pointer stroke-[#3d3d3d] right-5 top-5 z-50`}
                                        xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 14 14" fill="none">
                                        <path d="M13 1L1 13M1 1L13 13" stroke="#3D3D3D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                }
                                <Swiper
                                id="bg-img-id"
                                slidesPerView={1}
                                spaceBetween={30}
                                loop={true}
                                pagination={{
                                  clickable: true,
                                }}
                                lazy={'true'}
                                style={{
                                    "--swiper-pagination-color": "#3d3d3d",
                                    "--swiper-navigation-color": "#3d3d3d",
                                    "--swiper-navigation-border-color": "#000000",
                                    // "--swiper-autoheight": "auto",
                                }}
                                navigation={true}
                                zoom={true}
                                onClick={!fullScreenGallery ? ((e) => showFullScreenSwiper(e)) : null}
                                modules={[Pagination, Navigation, Zoom]}
                                
                                // className="w-full h-[350px] md:h-[420px] lg:h-[420px] xl:h-[500px] 2xl:h-[590px] mx-auto rounded-[20px]"
                                className={`
                                    ${ !fullScreenGallery ? 
                                    (`w-full max-h-[750px] mx-auto rounded-[20px] geometric-shadow-gray transition duration-300 relative`) 
                                    :
                                    (`w-full h-full rounded-[20px] shadow-sm hover:shadow-md transition duration-300`)}`}>
                                    {auctionData.images.map((image, index) => (
                                      <SwiperSlide key={index} className={`my-auto`}>
                                          <div className={`w-full h-full swiper-zoom-container bg-base-100 ${ !fullScreenGallery ? (``) : (`mx-auto`) }`}>

                                            {/*<div className="w-full md:w-4/5 object-contain h-full mt-0 mx-auto my-auto">*/}
                                              {/*<div className="w-full md:w-11/12 object-contain h-full mt-0 mx-auto my-auto">*/}

                                              {/*  <img src={image} alt={image.alt} />*/}
                                              {/*</div>*/}

                                              <div className={`w-full h-full select-none ${ !fullScreenGallery ? (``) : (`mx-auto`) }`}>
                                                  <img onLoad={(e) => onLoadImage(e)} id="img-id" className={`${ !fullScreenGallery ? (`object-cover max-h-[750px] mx-auto`) : (`object-contain mx-auto h-full w-full`) }`} src={image} alt=""/>
                                              </div>

                                          </div>
                                      </SwiperSlide>
                                    ))}

                                    {/*{ auctionData.video_url & (*/}
                                    {/*    <SwiperSlide>*/}
                                    {/*      <div className={`w-full h-full swiper-zoom-container bg-base-100`}>*/}

                                    {/*        /!*<div className="w-full md:w-4/5 object-contain h-full mt-0 mx-auto my-auto">*!/*/}
                                    {/*          /!*<div className="w-full md:w-11/12 object-contain h-full mt-0 mx-auto my-auto">*!/*/}

                                    {/*          /!*  <img src={image} alt={image.alt} />*!/*/}
                                    {/*          /!*</div>*!/*/}

                                    {/*          <div className={`w-full h-full`}>*/}
                                    {/*              <img className={`object-scale-down`} src={image} alt=""/>*/}
                                    {/*          </div>*/}
                                    {/*      </div>*/}
                                    {/*  </SwiperSlide>*/}
                                    {/*) }*/}
                                </Swiper>

                            </div>
                        </div>

                        <div className={`col-span-1 lg:col-span-4 xl:col-span-3 flex flex-col justify-between rounded-[20px] transition duration-300 relative ${handleAuctionColor(auctionData.is_active, auctionData.highlighted, auctionData.homepage_highlight)}`} 
                            // style={{ height: imgDivHeight < 100 ? `480px` : ( imgDivHeight === 'auto' ? `auto` : `${imgDivHeight}px`), transition: 'all 0.5s ease-in-out'}}
                            style={{ height: imgDivHeight < 100 ? `480px` : ( imgDivHeight === 'auto' ? `auto` : `${imgDivHeight}px`), transition: 'all 0.5s ease-in-out'}}
                        >
                            <div className={`px-[16px] py-[16px] skeleton`}>
                                <div className={`flex justify-between`}>
                                    <p className={`text-[24px] font-[500] text-black`}>{auctionData.brand.name} {auctionData.version !== null ? `${auctionData.version.version}` : ''} {auctionData.generation !== null ? `${auctionData.generation.generation}` : ''}</p>
                                    
                                    <div className="flex flex-row gap-x-2.5">

                                        <button onClick={showSharePanelFunction}>
                                            <svg className="stroke-main_green" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.59 13.51L15.42 17.49M15.41 6.51L8.59 10.49M21 5C21 6.65685 19.6569 8 18 8C16.3431 8 15 6.65685 15 5C15 3.34315 16.3431 2 18 2C19.6569 2 21 3.34315 21 5ZM9 12C9 13.6569 7.65685 15 6 15C4.34315 15 3 13.6569 3 12C3 10.3431 4.34315 9 6 9C7.65685 9 9 10.3431 9 12ZM21 19C21 20.6569 19.6569 22 18 22C16.3431 22 15 20.6569 15 19C15 17.3431 16.3431 16 18 16C19.6569 16 21 17.3431 21 19Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </button>

                                        <button onClick={(e) => makeWish(auctionData.id, auctionCarWish['wish'])}>
                                        {auctionCarWish.wish ? (
                                            <svg className={``} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 22" fill="none">
                                                <path d="M6 0.5C9.315 0.5 12 3.1325 12 6.38C12 3.1325 14.685 0.5 18 0.5C21.315 0.5 24 3.1325 24 6.38C24 11.2745 19.149 13.001 12.588 20.792C12.5158 20.8776 12.4257 20.9463 12.3242 20.9935C12.2226 21.0407 12.112 21.0651 12 21.0651C11.888 21.0651 11.7774 21.0407 11.6758 20.9935C11.5743 20.9463 11.4842 20.8776 11.412 20.792C4.851 13.001 0 11.2745 0 6.38C0 3.1325 2.685 0.5 6 0.5Z" fill="#367E18"/>
                                            </svg>
                                        ) : (
                                            <svg className={``}
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20" fill="none">
                                                <path d="M9 6.15H10H11C11 4.01485 12.7708 2.25 15 2.25C17.2292 2.25 19 4.01485 19 6.15C19 7.76879 18.2442 8.89944 16.5909 10.54C16.1637 10.964 15.6785 11.4194 15.1451 11.9201C13.7143 13.263 11.9367 14.9316 10 17.1922C8.06333 14.9316 6.28565 13.263 4.85493 11.9201C4.3215 11.4194 3.8363 10.964 3.40907 10.54C1.75582 8.89944 1 7.76879 1 6.15C1 4.01485 2.77078 2.25 5 2.25C7.22922 2.25 9 4.01485 9 6.15ZM10.0008 17.748L10.0008 17.748L10.0008 17.748Z" stroke="#367E18" strokeWidth="2"/>
                                            </svg>
                                        )}
                                        </button>
                                    </div>
                                </div>
                                <p className={`pt-1 md:pt-1 text-[16px] break-words`}>{auctionData.title}</p>
                                <div className={`flex justify-start gap-1.5 md:gap-2 lg:gap-2`}>
                                    <p className={`truncate`}>{auctionData.production_date}</p>
                                    <div className={`my-auto`}>
                                        <svg className="stroke-none" xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                                            <circle cx="2.5" cy="2.5" r="2.5" fill="#BB2205"/>
                                        </svg>
                                    </div>

                                    <p className={`truncate`}>{auctionData.mileage} km</p>
                                    <div className={`my-auto`}>
                                        <svg className="stroke-none" xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                                            <circle cx="2.5" cy="2.5" r="2.5" fill="#BB2205"/>
                                        </svg>
                                    </div>
                                    <p className={`truncate`}>{auctionData.capacity} cm3</p>
                                    <div className={`my-auto`}>
                                        <svg className="stroke-none" xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                                            <circle cx="2.5" cy="2.5" r="2.5" fill="#BB2205"/>
                                        </svg>
                                    </div>
                                    <p className={`truncate`}>{auctionData.fuel_type.type}</p>
                                </div>
                                <div className={`flex gap-x-3 pt-3`}>
                                    <p className={`text-[24px] text-main_red-100 font-semibold`}>{auctionData.price.toLocaleString()} {auctionData.currency.name}</p>
                                    <p className={`text-[17px] text-main_red-100 pt-[6px]`}>{auctionData.price_type}</p>
                                </div>

                                <hr className={`bg-main_gray_dark h-[2px] mt-2`}/>

                                <div className={`flex flex-col gap-y-[6px] mt-[16px]`}>
                                    <div className={`flex gap-x-1`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                            <path d="M14.7141 14.2142L11.1783 17.75C11.0236 17.9048 10.84 18.0276 10.6379 18.1114C10.4358 18.1952 10.2191 18.2383 10.0003 18.2383C9.78155 18.2383 9.5649 18.1952 9.36279 18.1114C9.16067 18.0276 8.97705 17.9048 8.82242 17.75L5.28576 14.2142C4.35344 13.2818 3.71853 12.094 3.46133 10.8008C3.20412 9.50756 3.33616 8.16714 3.84076 6.94898C4.34535 5.73083 5.19984 4.68966 6.29616 3.95713C7.39248 3.2246 8.68139 2.83362 9.99992 2.83362C11.3184 2.83362 12.6074 3.2246 13.7037 3.95713C14.8 4.68966 15.6545 5.73083 16.1591 6.94898C16.6637 8.16714 16.7957 9.50756 16.5385 10.8008C16.2813 12.094 15.6464 13.2818 14.7141 14.2142Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M12.5 9.5C12.5 10.163 12.2366 10.7989 11.7678 11.2678C11.2989 11.7366 10.663 12 10 12C9.33696 12 8.70107 11.7366 8.23223 11.2678C7.76339 10.7989 7.5 10.163 7.5 9.5C7.5 8.83696 7.76339 8.20107 8.23223 7.73223C8.70107 7.26339 9.33696 7 10 7C10.663 7 11.2989 7.26339 11.7678 7.73223C12.2366 8.20107 12.5 8.83696 12.5 9.5Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        <p className={`mt-[-0.5px]`}>{auctionData.location !== null ? `${auctionData.location.name}` : ``} {auctionData.location !== null ? `(${auctionData.location.province})` : ''}</p>
                                    </div>

                                    <div className={`flex gap-x-1`}>
                                        {/*<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">*/}
                                        {/*    <path d="M4.2675 15.17C6.01618 14.1816 7.99132 13.6637 10 13.6667C12.0833 13.6667 14.0392 14.2125 15.7325 15.17M12.5 8.66671C12.5 9.32975 12.2366 9.96563 11.7678 10.4345C11.2989 10.9033 10.663 11.1667 10 11.1667C9.33696 11.1667 8.70107 10.9033 8.23223 10.4345C7.76339 9.96563 7.5 9.32975 7.5 8.66671C7.5 8.00367 7.76339 7.36778 8.23223 6.89894C8.70107 6.4301 9.33696 6.16671 10 6.16671C10.663 6.16671 11.2989 6.4301 11.7678 6.89894C12.2366 7.36778 12.5 8.00367 12.5 8.66671ZM17.5 10.3334C17.5 11.3183 17.306 12.2936 16.9291 13.2035C16.5522 14.1134 15.9997 14.9402 15.3033 15.6367C14.6069 16.3331 13.7801 16.8856 12.8701 17.2625C11.9602 17.6394 10.9849 17.8334 10 17.8334C9.01509 17.8334 8.03982 17.6394 7.12987 17.2625C6.21993 16.8856 5.39314 16.3331 4.6967 15.6367C4.00026 14.9402 3.44781 14.1134 3.0709 13.2035C2.69399 12.2936 2.5 11.3183 2.5 10.3334C2.5 8.34425 3.29018 6.4366 4.6967 5.03007C6.10322 3.62355 8.01088 2.83337 10 2.83337C11.9891 2.83337 13.8968 3.62355 15.3033 5.03007C16.7098 6.4366 17.5 8.34425 17.5 10.3334Z" stroke="#7D7D7D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
                                        {/*</svg>*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                            <path d="M4.2675 15.17C6.01618 14.1816 7.99132 13.6637 10 13.6667C12.0833 13.6667 14.0392 14.2125 15.7325 15.17M12.5 8.66671C12.5 9.32975 12.2366 9.96563 11.7678 10.4345C11.2989 10.9033 10.663 11.1667 10 11.1667C9.33696 11.1667 8.70107 10.9033 8.23223 10.4345C7.76339 9.96563 7.5 9.32975 7.5 8.66671C7.5 8.00367 7.76339 7.36778 8.23223 6.89894C8.70107 6.4301 9.33696 6.16671 10 6.16671C10.663 6.16671 11.2989 6.4301 11.7678 6.89894C12.2366 7.36778 12.5 8.00367 12.5 8.66671ZM17.5 10.3334C17.5 11.3183 17.306 12.2936 16.9291 13.2035C16.5522 14.1134 15.9997 14.9402 15.3033 15.6367C14.6069 16.3331 13.7801 16.8856 12.8701 17.2625C11.9602 17.6394 10.9849 17.8334 10 17.8334C9.01509 17.8334 8.03982 17.6394 7.12987 17.2625C6.21993 16.8856 5.39314 16.3331 4.6967 15.6367C4.00026 14.9402 3.44781 14.1134 3.0709 13.2035C2.69399 12.2936 2.5 11.3183 2.5 10.3334C2.5 8.34425 3.29018 6.4366 4.6967 5.03007C6.10322 3.62355 8.01088 2.83337 10 2.83337C11.9891 2.83337 13.8968 3.62355 15.3033 5.03007C16.7098 6.4366 17.5 8.34425 17.5 10.3334Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>

                                        <p className={`mt-[-0.5px]`}>Sprzedający na { process.env.REACT_APP_SERVICE_FULL_NAME } od {formatDateToYear(auctionData.user.date_joined)}</p>
                                    </div>

                                    { (auctionData.user.show_more_auctions !== null && auctionData.user.show_more_auctions && auctionData.user) ? (
                                        <div className={`flex gap-x-1`}>
                                            <svg className={``} width="20" height="21" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19 10V6.2C19 5.0799 19 4.51984 18.782 4.09202C18.5903 3.71569 18.2843 3.40973 17.908 3.21799C17.4802 3 16.9201 3 15.8 3H8.2C7.07989 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V10M5 9H2V8M19 9H22V8M6 13.5H6.01M18 13.5H18.01M6.8 10H17.2C18.8802 10 19.7202 10 20.362 10.327C20.9265 10.6146 21.3854 11.0735 21.673 11.638C22 12.2798 22 13.1198 22 14.8V18C22 18.9319 22 19.3978 21.8478 19.7654C21.6448 20.2554 21.2554 20.6448 20.7654 20.8478C20.3978 21 19.9319 21 19 21H18.4C18.0284 21 17.8426 21 17.6871 20.9754C16.8313 20.8398 16.1602 20.1687 16.0246 19.3129C16 19.1574 16 18.9716 16 18.6C16 18.5071 16 18.4606 15.9938 18.4218C15.96 18.2078 15.7922 18.04 15.5782 18.0062C15.5394 18 15.4929 18 15.4 18H8.6C8.5071 18 8.46065 18 8.42178 18.0062C8.20784 18.04 8.04004 18.2078 8.00616 18.4218C8 18.4606 8 18.5071 8 18.6C8 18.9716 8 19.1574 7.97538 19.3129C7.83983 20.1687 7.16865 20.8398 6.31287 20.9754C6.1574 21 5.9716 21 5.6 21H5C4.06812 21 3.60218 21 3.23463 20.8478C2.74458 20.6448 2.35523 20.2554 2.15224 19.7654C2 19.3978 2 18.9319 2 18V14.8C2 13.1198 2 12.2798 2.32698 11.638C2.6146 11.0735 3.07354 10.6146 3.63803 10.327C4.27976 10 5.11984 10 6.8 10ZM6.5 13.5C6.5 13.7761 6.27614 14 6 14C5.72386 14 5.5 13.7761 5.5 13.5C5.5 13.2239 5.72386 13 6 13C6.27614 13 6.5 13.2239 6.5 13.5ZM18.5 13.5C18.5 13.7761 18.2761 14 18 14C17.7239 14 17.5 13.7761 17.5 13.5C17.5 13.2239 17.7239 13 18 13C18.2761 13 18.5 13.2239 18.5 13.5Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>

                                            <p className={`mt-[-0.5px]`}>Zobacz więcej ogłoszeń od: <Link className={`break-words ${ auctionData.homepage_highlight ? ('text-main_red-100') : ('text-main_green') }`} to={`/aukcje_uzytkownika/${auctionData.user.id}?page=1`}>{ auctionData.user.business_name }</Link></p>
                                        </div>
                                    ) : (
                                        <div className="hidden">

                                        </div>
                                    ) }


                                    <div className={`flex gap-x-1`}>
                                        {/*<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">*/}
                                        {/*    <path d="M4.2675 15.17C6.01618 14.1816 7.99132 13.6637 10 13.6667C12.0833 13.6667 14.0392 14.2125 15.7325 15.17M12.5 8.66671C12.5 9.32975 12.2366 9.96563 11.7678 10.4345C11.2989 10.9033 10.663 11.1667 10 11.1667C9.33696 11.1667 8.70107 10.9033 8.23223 10.4345C7.76339 9.96563 7.5 9.32975 7.5 8.66671C7.5 8.00367 7.76339 7.36778 8.23223 6.89894C8.70107 6.4301 9.33696 6.16671 10 6.16671C10.663 6.16671 11.2989 6.4301 11.7678 6.89894C12.2366 7.36778 12.5 8.00367 12.5 8.66671ZM17.5 10.3334C17.5 11.3183 17.306 12.2936 16.9291 13.2035C16.5522 14.1134 15.9997 14.9402 15.3033 15.6367C14.6069 16.3331 13.7801 16.8856 12.8701 17.2625C11.9602 17.6394 10.9849 17.8334 10 17.8334C9.01509 17.8334 8.03982 17.6394 7.12987 17.2625C6.21993 16.8856 5.39314 16.3331 4.6967 15.6367C4.00026 14.9402 3.44781 14.1134 3.0709 13.2035C2.69399 12.2936 2.5 11.3183 2.5 10.3334C2.5 8.34425 3.29018 6.4366 4.6967 5.03007C6.10322 3.62355 8.01088 2.83337 10 2.83337C11.9891 2.83337 13.8968 3.62355 15.3033 5.03007C16.7098 6.4366 17.5 8.34425 17.5 10.3334Z" stroke="#7D7D7D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
                                        {/*</svg>*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 22" fill="none">
                                            <path d="M19 9H1M19 11.5V7.8C19 6.11984 19 5.27976 18.673 4.63803C18.3854 4.07354 17.9265 3.6146 17.362 3.32698C16.7202 3 15.8802 3 14.2 3H5.8C4.11984 3 3.27976 3 2.63803 3.32698C2.07354 3.6146 1.6146 4.07354 1.32698 4.63803C1 5.27976 1 6.11984 1 7.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21H10M14 1V5M6 1V5M12.5 18L14.5 20L19 15.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>

                                        <p className={`mt-[-0.5px]`}>Wystawiono: {formatTimestamp(auctionData.active_payment_date, true)}</p>
                                    </div>

                                    { auctionData.highlighted && (

                                        <div className={`flex gap-x-1`}>
                                            {/*<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 24 24" fill="none">*/}
                                            {/*    <path d="M6.5 13L7.28446 14.5689C7.54995 15.0999 7.68269 15.3654 7.86003 15.5954C8.01739 15.7996 8.20041 15.9826 8.40455 16.14C8.63462 16.3173 8.9001 16.4501 9.43108 16.7155L11 17.5L9.43108 18.2845C8.9001 18.5499 8.63462 18.6827 8.40455 18.86C8.20041 19.0174 8.01739 19.2004 7.86003 19.4046C7.68269 19.6346 7.54995 19.9001 7.28446 20.4311L6.5 22L5.71554 20.4311C5.45005 19.9001 5.31731 19.6346 5.13997 19.4046C4.98261 19.2004 4.79959 19.0174 4.59545 18.86C4.36538 18.6827 4.0999 18.5499 3.56892 18.2845L2 17.5L3.56892 16.7155C4.0999 16.4501 4.36538 16.3173 4.59545 16.14C4.79959 15.9826 4.98261 15.7996 5.13997 15.5954C5.31731 15.3654 5.45005 15.0999 5.71554 14.5689L6.5 13Z" stroke="#7D7D7D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
                                            {/*    <path d="M15 2L16.1786 5.06442C16.4606 5.79765 16.6016 6.16426 16.8209 6.47264C17.0153 6.74595 17.254 6.98475 17.5274 7.17909C17.8357 7.39836 18.2024 7.53937 18.9356 7.82138L22 9L18.9356 10.1786C18.2024 10.4606 17.8357 10.6016 17.5274 10.8209C17.254 11.0153 17.0153 11.254 16.8209 11.5274C16.6016 11.8357 16.4606 12.2024 16.1786 12.9356L15 16L13.8214 12.9356C13.5394 12.2024 13.3984 11.8357 13.1791 11.5274C12.9847 11.254 12.746 11.0153 12.4726 10.8209C12.1643 10.6016 11.7976 10.4606 11.0644 10.1786L8 9L11.0644 7.82138C11.7976 7.53937 12.1643 7.39836 12.4726 7.17909C12.746 6.98475 12.9847 6.74595 13.1791 6.47264C13.3984 6.16426 13.5394 5.79765 13.8214 5.06442L15 2Z" stroke="#7D7D7D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
                                            {/*</svg>*/}
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 22 21" fill="none">
                                                <path d="M10.2827 2.45332C10.5131 1.98638 10.6284 1.75291 10.7848 1.67831C10.9209 1.61341 11.0791 1.61341 11.2152 1.67831C11.3717 1.75291 11.4869 1.98638 11.7174 2.45332L13.9041 6.88328C13.9721 7.02113 14.0061 7.09006 14.0558 7.14358C14.0999 7.19096 14.1527 7.22935 14.2113 7.25662C14.2776 7.28742 14.3536 7.29854 14.5057 7.32077L19.397 8.03571C19.9121 8.11099 20.1696 8.14863 20.2888 8.27444C20.3925 8.38389 20.4412 8.5343 20.4215 8.68377C20.3988 8.85558 20.2124 9.03718 19.8395 9.40038L16.3014 12.8464C16.1912 12.9538 16.136 13.0076 16.1004 13.0715C16.0689 13.128 16.0487 13.1902 16.0409 13.2545C16.0321 13.3271 16.0451 13.403 16.0711 13.5547L16.906 18.4221C16.994 18.9355 17.038 19.1922 16.9553 19.3445C16.8833 19.477 16.7554 19.57 16.6071 19.5975C16.4366 19.6291 16.2061 19.5078 15.7451 19.2654L11.3724 16.9658C11.2361 16.8942 11.168 16.8584 11.0962 16.8443C11.0327 16.8318 10.9673 16.8318 10.9038 16.8443C10.832 16.8584 10.7639 16.8942 10.6277 16.9658L6.25492 19.2654C5.79392 19.5078 5.56341 19.6291 5.39297 19.5975C5.24468 19.57 5.11672 19.477 5.04474 19.3445C4.962 19.1922 5.00603 18.9355 5.09407 18.4221L5.92889 13.5547C5.95491 13.403 5.96793 13.3271 5.95912 13.2545C5.95132 13.1902 5.93111 13.128 5.89961 13.0715C5.86402 13.0076 5.80888 12.9538 5.69859 12.8464L2.16056 9.40038C1.78766 9.03718 1.60121 8.85558 1.57853 8.68377C1.55879 8.5343 1.60755 8.38389 1.71125 8.27444C1.83044 8.14863 2.08797 8.11099 2.60304 8.03571L7.49431 7.32077C7.64642 7.29854 7.72248 7.28742 7.78872 7.25662C7.84736 7.22935 7.90016 7.19096 7.94419 7.14358C7.99391 7.09006 8.02793 7.02113 8.09597 6.88328L10.2827 2.45332Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            <p className={`mt-[-0.5px]`}>Wyrózniono: Tak</p>
                                        </div>
                                    )}

                                    <div className={`flex gap-x-1`}>
                                        {/*<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">*/}
                                        {/*    <path d="M4.2675 15.17C6.01618 14.1816 7.99132 13.6637 10 13.6667C12.0833 13.6667 14.0392 14.2125 15.7325 15.17M12.5 8.66671C12.5 9.32975 12.2366 9.96563 11.7678 10.4345C11.2989 10.9033 10.663 11.1667 10 11.1667C9.33696 11.1667 8.70107 10.9033 8.23223 10.4345C7.76339 9.96563 7.5 9.32975 7.5 8.66671C7.5 8.00367 7.76339 7.36778 8.23223 6.89894C8.70107 6.4301 9.33696 6.16671 10 6.16671C10.663 6.16671 11.2989 6.4301 11.7678 6.89894C12.2366 7.36778 12.5 8.00367 12.5 8.66671ZM17.5 10.3334C17.5 11.3183 17.306 12.2936 16.9291 13.2035C16.5522 14.1134 15.9997 14.9402 15.3033 15.6367C14.6069 16.3331 13.7801 16.8856 12.8701 17.2625C11.9602 17.6394 10.9849 17.8334 10 17.8334C9.01509 17.8334 8.03982 17.6394 7.12987 17.2625C6.21993 16.8856 5.39314 16.3331 4.6967 15.6367C4.00026 14.9402 3.44781 14.1134 3.0709 13.2035C2.69399 12.2936 2.5 11.3183 2.5 10.3334C2.5 8.34425 3.29018 6.4366 4.6967 5.03007C6.10322 3.62355 8.01088 2.83337 10 2.83337C11.9891 2.83337 13.8968 3.62355 15.3033 5.03007C16.7098 6.4366 17.5 8.34425 17.5 10.3334Z" stroke="#7D7D7D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
                                        {/*</svg>*/}
                                        <svg className={`${ auctionData.homepage_highlight ? ('stroke-main_red-100') : ('stroke-main_green') }`} xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="none">
                                            <path d="M14 10L21 3M21 3H15M21 3V9M10 14L3 21M3 21H9M3 21L3 15" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>

                                        <button onClick={showFullScreenSwiper} className={`${ auctionData.homepage_highlight ? ('text-main_red-100') : ('text-main_green') }`}>Zobacz zdjęcia</button>
                                    </div>

                                </div>


                            </div>
                            
                            <div className="flex flex-col">
                                {/*<div className={`bg-black text-center mt-3 mb-4 md:absolute md:bottom-0 w-11/12 mx-auto`}>*/}
                                <button className={divClassNameTelephoneNumber} onClick={showAuctionContactNumber}>
                                    <div className={`mt-2 mb-2 w-full flex justify-center`}>
                                        <svg className={`mr-2`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M8.38028 8.85335C9.07627 10.303 10.0251 11.6616 11.2266 12.8632C12.4282 14.0648 13.7869 15.0136 15.2365 15.7096C15.3612 15.7694 15.4235 15.7994 15.5024 15.8224C15.7828 15.9041 16.127 15.8454 16.3644 15.6754C16.4313 15.6275 16.4884 15.5704 16.6027 15.4561C16.9523 15.1064 17.1271 14.9316 17.3029 14.8174C17.9658 14.3864 18.8204 14.3864 19.4833 14.8174C19.6591 14.9316 19.8339 15.1064 20.1835 15.4561L20.3783 15.6509C20.9098 16.1824 21.1755 16.4481 21.3198 16.7335C21.6069 17.301 21.6069 17.9713 21.3198 18.5389C21.1755 18.8242 20.9098 19.09 20.3783 19.6214L20.2207 19.779C19.6911 20.3087 19.4263 20.5735 19.0662 20.7757C18.6667 21.0001 18.0462 21.1615 17.588 21.1601C17.1751 21.1589 16.8928 21.0788 16.3284 20.9186C13.295 20.0576 10.4326 18.4332 8.04466 16.0452C5.65668 13.6572 4.03221 10.7948 3.17124 7.76144C3.01103 7.19699 2.93092 6.91477 2.9297 6.50182C2.92833 6.0436 3.08969 5.42311 3.31411 5.0236C3.51636 4.66357 3.78117 4.39876 4.3108 3.86913L4.46843 3.7115C4.99987 3.18006 5.2656 2.91433 5.55098 2.76999C6.11854 2.48292 6.7888 2.48292 7.35636 2.76999C7.64174 2.91433 7.90747 3.18006 8.43891 3.7115L8.63378 3.90637C8.98338 4.25597 9.15819 4.43078 9.27247 4.60655C9.70347 5.26945 9.70347 6.12403 9.27247 6.78692C9.15819 6.96269 8.98338 7.1375 8.63378 7.4871C8.51947 7.60142 8.46231 7.65857 8.41447 7.72538C8.24446 7.96281 8.18576 8.30707 8.26748 8.58743C8.29048 8.66632 8.32041 8.72866 8.38028 8.85335Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        Pokaż numer kontaktowy
                                    </div>
                                </button>
                                { divClassNameTelephoneNumber === 'hidden' && (
                                    // <div className={'w-11/12 mx-auto text-center mt-3 mb-5 bg-main_red-100 hover:bg-main_red-200 text-white h-10 text-[16px] rounded-[20px] mt-4 mb-2'}>
                                    <a href={`tel:${auctionData.telephone_number}`} className={'w-11/12 mx-auto text-center mt-3 py-1 mb-4 bg-main_red-100 hover:bg-main_red-200 transition duration-200 text-white text-[16px] rounded-[20px]'}>
                                        <div className={`my-2 w-full flex justify-center`}>Telefon kontaktowy: {auctionData.telephone_number}</div>
                                    </a>
                                    // </div>
                                )}
                            </div>

                        </div>
                    </div>

                    <div className={`mt-5 rounded-[20px] bg-base-100 geometric-shadow-gray transition duration-300 skeleton`}>

                    {/*<div className={`mt-5 rounded-[20px] ${handleAuctionColor(auctionData.is_active, auctionData.highlighted, auctionData.homepage_highlight)}`}>*/}
                        <div className={`px-[16px] py-[16px]`}>
                            <div className={`flex justify-center gap-x-2 md:justify-start md:pl-3`}>
                                <svg className={`mt-1`}
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M5 13H8M2 9L4 10L5.27064 6.18807C5.53292 5.40125 5.66405 5.00784 5.90729 4.71698C6.12208 4.46013 6.39792 4.26132 6.70951 4.13878C7.06236 4 7.47705 4 8.30643 4H15.6936C16.523 4 16.9376 4 17.2905 4.13878C17.6021 4.26132 17.8779 4.46013 18.0927 4.71698C18.3359 5.00784 18.4671 5.40125 18.7294 6.18807L20 10L22 9M16 13H19M6.8 10H17.2C18.8802 10 19.7202 10 20.362 10.327C20.9265 10.6146 21.3854 11.0735 21.673 11.638C22 12.2798 22 13.1198 22 14.8V17.5C22 17.9647 22 18.197 21.9616 18.3902C21.8038 19.1836 21.1836 19.8038 20.3902 19.9616C20.197 20 19.9647 20 19.5 20H19C17.8954 20 17 19.1046 17 18C17 17.7239 16.7761 17.5 16.5 17.5H7.5C7.22386 17.5 7 17.7239 7 18C7 19.1046 6.10457 20 5 20H4.5C4.03534 20 3.80302 20 3.60982 19.9616C2.81644 19.8038 2.19624 19.1836 2.03843 18.3902C2 18.197 2 17.9647 2 17.5V14.8C2 13.1198 2 12.2798 2.32698 11.638C2.6146 11.0735 3.07354 10.6146 3.63803 10.327C4.27976 10 5.11984 10 6.8 10Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <p className={`font-[500] text-[20px] md:text-[22px]`}>Dane Techniczne</p>
                            </div>
                            <div className={`grid grid-cols-1 md:grid-cols-2 w-full text-[16px] gap-x-3 overflow-x-auto no-scrollbar`}>
                                <div className={`mt-[10px]`}>
                                    {/* <div className={`grid grid-cols-2 rounded-[20px] bg-base-100`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Importowany</p>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px] text-center md:text-left`}>{auctionData.is_imported ? (`${auctionData.is_imported}`) : (`-`) }</p>
                                    </div> */}

                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-100`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Oferta z numerem VIN</p>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px] text-center md:text-left`}>{auctionData.vin ? (`Tak`) : (`Nie`) }</p>
                                    </div>

                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-200`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Numer rejestracyjny</p>
                                        <p className={`pl-[15px] pt-[10px] text-center md:text-left`}>{auctionData.show_plate ? (`${auctionData.plate}`) : (`Brak`) }</p>
                                    </div>

                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-100`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Marka pojazdu</p>
                                        <p className={`pl-[15px] pt-[10px] text-center md:text-left`}>{auctionData.brand.name ? (`${auctionData.brand.name}`) : (`Brak`) }</p>
                                    </div>

                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-200`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Model pojazdu</p>
                                        <p className={`pl-[15px] pt-[10px] text-center md:text-left`}>{auctionData.version !== null ? (`${auctionData.version.version}`) : (`Brak`) }</p>
                                    </div>

                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-100`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Generacja</p>
                                        <p className={`pl-[15px] pt-[10px] text-center md:text-left`}>{auctionData.generation !== null ? (`${auctionData.generation.generation}`) : (`Brak`) }</p>
                                    </div>

                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-200`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Rok produkcji</p>
                                        <p className={`pl-[15px] pt-[10px] text-center md:text-left`}>{auctionData.production_date ? (`${auctionData.production_date}`) : (`Brak`) }</p>
                                    </div>

                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-100`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Przebieg</p>
                                        <p className={`pl-[15px] pt-[10px] text-center md:text-left`}>{auctionData.mileage ? (`${auctionData.mileage} km`) : (`Brak`) }</p>
                                    </div>

                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-200`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Pojemność skokowa</p>
                                        <p className={`pl-[15px] pt-[10px] text-center md:text-left`}>{auctionData.capacity ? (`${auctionData.capacity} cm3`) : (`Brak`) }</p>
                                    </div>

                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-100`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Rodzaj paliwa</p>
                                        <p className={`pl-[15px] pt-[10px] text-center md:text-left`}>{auctionData.fuel_type.type ? (`${auctionData.fuel_type.type}`) : (`Brak`) }</p>
                                    </div>

                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-200`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Moc</p>
                                        <p className={`pl-[15px] pt-[10px] text-center md:text-left`}>{auctionData.power ? (`${auctionData.power} km`) : (`Brak`) }</p>
                                    </div>

                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-100`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Skrzynia biegów</p>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px] text-center md:text-left`}>{auctionData.gearbox.name ? (`${auctionData.gearbox.name}`) : (`-`) }</p>
                                    </div>

                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-200`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Napęd</p>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px] text-center md:text-left`}>{auctionData.car_drive ? (`${auctionData.car_drive}`) : (`-`) }</p>
                                    </div>

                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-100`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Kraj pochodzenia</p>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px] text-center md:text-left`}>{auctionData.origin_country ? (`${auctionData.origin_country}`) : (`-`) }</p>
                                    </div>

                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-200`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Data pierwszej rejestracji</p>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px] text-center md:text-left`}>{auctionData.first_car_register_date ? (`${formatTimestamp(auctionData.first_car_register_date, false)}`) : (`-`) }</p>
                                    </div>
                                    {/* <div className={`grid grid-cols-2 rounded-[20px] bg-base-200`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Spalanie poza miastem</p>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px] text-center md:text-left`}>{auctionData.fuel_consumption_outside_city ? (`${auctionData.fuel_consumption_outside_city}`) : (`-`) }</p>
                                    </div>

                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-100`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Spalanie w mieście</p>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px] text-center md:text-left`}>{auctionData.fuel_consumption_inside_city ? (`${auctionData.fuel_consumption_outside_cite}`) : (`-`) }</p>
                                    </div> */}
                                </div>

                                <div className={`mt-1 md:mt-[10px]`}>
                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-100`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>VIN</p>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px] text-center md:text-left -ml-20 sm:ml-0 md:-ml-16 lg:ml-0`}>{auctionData.vin ? (`${auctionData.vin}`) : (`-`) }</p>
                                    </div>
                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-200`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Typ nadwozia</p>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px] text-center md:text-left`}>{auctionData.body_type.body_type ? (`${auctionData.body_type.body_type }`) : (`-`) }</p>
                                    </div>
                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-100`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Liczba drzwi</p>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px] text-center md:text-left`}>{auctionData.doors ? (`${auctionData.doors}`) : (`-`) }</p>
                                    </div>
                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-200`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Liczba miejsc</p>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px] text-center md:text-left`}>{auctionData.seats_amount ? (`${auctionData.seats_amount}`) : (`-`) }</p>
                                    </div>
                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-100`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Kolor</p>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px] text-center md:text-left`}>{auctionData.color.color ? (`${auctionData.color.color}`) : (`-`) }</p>
                                    </div>
                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-200`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Stan</p>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px] text-center md:text-left`}>{auctionData.car_status ? (`${auctionData.car_status}`) : (`-`) }</p>
                                    </div>
                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-100`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Pierwszy właściciel</p>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px] text-center md:text-left`}>{auctionData.first_owner === true ? (`Tak`) : (`Nie`) }</p>
                                    </div>
                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-200`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Wypadkowy</p>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px] text-center md:text-left`}>{auctionData.accident === true ? (`Tak`) : (`Nie`) }</p>
                                    </div>
                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-100`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Serwisowany w ASO</p>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px] text-center md:text-left`}>{auctionData.serviced_in_aso === true ? (`Tak`) : (`Nie`) }</p>
                                    </div>
                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-200`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Zarejestrowany jako klasyk</p>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px] text-center md:text-left`}>{auctionData.registered_as_classic === true ? (`Tak`) : (`Nie`) }</p>
                                    </div>
                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-100`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Tuning</p>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px] text-center md:text-left`}>{auctionData.tuning === true ? (`Tak`) : (`Nie`) }</p>
                                    </div>
                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-200`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Posiada filtr DPF</p>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px] text-center md:text-left`}>{auctionData.is_DPF === true ? (`Tak`) : (`Nie`) }</p>
                                    </div>
                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-100`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Anglik</p>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px] text-center md:text-left`}>{auctionData.is_right_side === true ? (`Tak`) : (`Nie`) }</p>
                                    </div>
                                    <div className={`grid grid-cols-2 rounded-[20px] bg-base-200`}>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Rejestracja</p>
                                        <p className={`pl-[15px] pt-[10px] pb-[10px] text-center md:text-left`}>{auctionData.plate !== null ? (`${auctionData.plate}`) : (`Brak`) }</p>
                                    </div>
                                    {/*<div className={`grid grid-cols-2 rounded-[20px] bg-base-200`}>*/}
                                    {/*    <p className={`pl-[15px] pt-[10px] pb-[10px]`}>Homologacja cieżarowa </p>*/}
                                    {/*    <p className={`pl-[15px] pt-[10px] pb-[10px] text-center md:text-left`}>{auctionData.truck_approval === true ? (`Tak`) : (`Nie`) }</p>*/}
                                    {/*</div>*/}
                                </div>

                            </div>
                        </div>

                    </div>


                    <div className={`mt-5 rounded-[20px] bg-base-100 geometric-shadow-gray transition duration-300`} ref={ref}>
                        <div className={`px-[16px] py-[16px]`}>
                            <div className={`flex justify-center md:justify-start md:pl-3 gap-x-2`}>
                                {/*<svg className={`mt-1`}*/}
                                {/*    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 22 22" fill="none">*/}
                                {/*    <path d="M16.7453 15C17.5362 13.8661 18 12.4872 18 11C18 10.4851 17.9444 9.9832 17.8389 9.49996M5.25469 15C4.46381 13.8662 4 12.4872 4 11C4 7.13401 7.13401 4 11 4C11.4221 4 11.8355 4.03737 12.2371 4.10897M15.4999 6.5L10.9999 11M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11ZM12 11C12 11.5523 11.5523 12 11 12C10.4477 12 10 11.5523 10 11C10 10.4477 10.4477 10 11 10C11.5523 10 12 10.4477 12 11Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>*/}
                                {/*</svg>*/}

                                <svg  className={`mt-1`}
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>

                                <p className={`font-[500] text-[20px] md:text-[22px]`}>Wyposażenie</p>
                                <p className={`text-[20px] md:text-[22px]`}>({ auctionData.equipment.length })</p>
                            </div>
                        
                            <button className={`${ExpandButtonDivClassName}`} onClick={showAllEquipment}>
                                Kliknij aby rozwinąć
                                {/*<svg className={`w-6 h-6`}*/}
                                {/*    xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">*/}
                                {/*    <path d="M7 11L11 15M11 15L15 11M11 15V7M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
                                {/*</svg>*/}
                                <svg className={`stroke-main_gray_dark`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M6 9L12 15L18 9" stroke="" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </button>


                            <div className={`${EquipmentDivClassName}`}>
                                {Object.keys(equipmentGroupedByCategory).map((category, categoryIndex) => (
                                    <div key={category}>
                                      <p className={`text-[19px] font-[500] pt-7 md:pt-3 text-center md:text-left pl-0 md:pl-4 ${categoryIndex === 0 ? 'mt-5' : ''}`}>{category}</p>
                                      <hr className={`w-[98%] mx-auto mt-2 mb-2`}/>
                                          {equipmentGroupedByCategory[category].map((item, index) => (
                                              <div className={`grid grid-cols-1 xsm:grid-cols-2 md:grid-cols-4 gap-x-0 w-full text-[16px] justify-center rounded-[20px] ${ index % 2 === 0 ? ('bg-base-100') : ('bg-base-200 rounded-[20px]')}}`}>
                                                  {item.map((innerItem, innerIndex) => (
                                                      <div className={`flex w-full pl-5 mt-1 mb-1`} key={`${innerItem} - ${innerIndex}`}>
                                                          <div className={`w-5 h-5 my-auto`}>
                                                            <svg className={``} xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none">
                                                                <path d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                            </svg>
                                                          </div>
                                                          <p className={`my-auto pl-2 overflow-hidden font-[400] text-ellipsis text-[14px] md:text-[16px]`} key={innerItem.name}>{innerItem.name}</p>
                                                      </div>
                                                  ))}
                                              </div>
                                          ))}

                                        {/*</div>*/}
                                    </div>
                                ))}

                            </div>

                            <button className={`${hideEquipmentPanelDivClassName}`} onClick={hideAllEquipment}>
                                Kliknij aby zwinąć
                                {/*<svg className={`w-6 h-6`}*/}
                                {/*    xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">*/}
                                {/*    <path d="M7 11L11 15M11 15L15 11M11 15V7M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
                                {/*</svg>*/}
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g transform="rotate(180, 12, 12)">
                                        <path d="M6 9L12 15L18 9" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>
                            </button>


                        </div>
                    </div>


                    <div className={`mt-5 rounded-[20px] bg-base-100 geometric-shadow-gray transition duration-300`}>
                        <div className={`px-[16px] py-[16px]`}>
                            <div className={`flex justify-center md:justify-start md:pl-3 gap-x-2`}>
                                {/*<svg className={`mt-1`}*/}
                                {/*    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 22 22" fill="none">*/}
                                {/*    <path d="M16.7453 15C17.5362 13.8661 18 12.4872 18 11C18 10.4851 17.9444 9.9832 17.8389 9.49996M5.25469 15C4.46381 13.8662 4 12.4872 4 11C4 7.13401 7.13401 4 11 4C11.4221 4 11.8355 4.03737 12.2371 4.10897M15.4999 6.5L10.9999 11M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11ZM12 11C12 11.5523 11.5523 12 11 12C10.4477 12 10 11.5523 10 11C10 10.4477 10.4477 10 11 10C11.5523 10 12 10.4477 12 11Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>*/}
                                {/*</svg>*/}

                                <svg className={`mt-1`}
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path d="M17.7453 16C18.5362 14.8661 19 13.4872 19 12C19 11.4851 18.9444 10.9832 18.8389 10.5M6.25469 16C5.46381 14.8662 5 13.4872 5 12C5 8.13401 8.13401 5 12 5C12.4221 5 12.8355 5.03737 13.2371 5.10897M16.4999 7.5L11.9999 12M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>

                                <p className={`font-[500] text-[20px] md:text-[22px]`}>Opis</p>
                            </div>
                            <div className={`px-[14px] mt-3`}>

                                { auctionData.description !== null ? (

                                    <TextWithNewlines textWithNewlines={auctionData.description} />
                                ) : (
                                    <TextWithNewlines />
                                )}
                            </div>
                        </div>
                    </div>

                    <p className={`text-center w-full text-[24px] md:text-[28px] font-[500] pt-8`}>Sprawdź też</p>


                    {moreAuctions.results && moreAuctions.results.length > 0 && isOtherAuctionsLoaded ? (

                        <HighlightedAuctionsCard data={moreAuctions} divClassName="mt-4 mx-auto w-full 3xl:w-9/12 xl:w-full grid xl:grid-cols-3 md:grid-cols-2 xsm:grid-cols-2 grid-cols-1 gap-4 px-1 md:px-0"/>
                        ) : (
                            <div className={`w-full col-span-3 pb-10 pt-10`}>
                                <p className={'text-[18px] md:text-[20px] mx-8 text-center'}>Brak podobnych ogłoszeń do wyświetlenia!</p>
                            </div>
                        )
                    }

                </div>



            ) : (
                ( notFound ? (
                    <div className={`w-full h-full`}>
                        <p className={`text-center my-48 mx-5 text-[22px] md:text-[26px]`}>Błąd 404! Nie udało się znaleźć aukcji!</p>
                    </div>
                ) : (
                    <div className="flex justify-center my-64">
                        <span className="circle animate-loader"></span>
                        <span className="circle animate-loader animation-delay-200"></span>
                        <span className="circle animate-loader animation-delay-400"></span>
                    </div>
                ))
            ) }

        </div>
    );
};

export default CarAuctionComponent;