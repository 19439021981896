import React, {useEffect, useState, useRef } from "react";
import Navbar from "../components/Navbar";
import Stripes from "../components/Stripes";
import {useLocation, useSearchParams, Link, useNavigate, Outlet} from "react-router-dom";
import CarAuctionsCard from "../components/cards/CarAuctionsCard";
import {MultiSelect} from "react-multi-select-component";
import { animateScroll as scroll } from 'react-scroll';
import {generatePriceOptions, generateYearOptions, generateMileageOptions} from "../../src/components/filters/LandingPageFiltration";
import {cityDict, provinceOptions} from "../components/data/LocalizationData";
import {
    brandOptions,
    equipmentOptions,
    carStatusOptions,
    carDamageOption,
    bodyTypeOptions,
    countryOptions,
    fuelTypeOptions,
    colorOptions,
    doorsOptions,
    seatsAmountOptions,
    gearboxOptions,
    transmissionOptions,
    capacityFromOptions,
    capacityToOptions,
    powerFromOptions, powerToOptions, filtrationOptions
} from "../components/data/CarData";
import Footer from "../components/Footer";
// import axios from "axios";
import axiosInstance from "../api/axios"
import {Helmet} from "react-helmet";


const radiusOptions = [
    { label: "Do 20 kilometrów", value: "20", disabled: true},
    { label: "Do 30 kilometrów", value: "30", disabled: true},
    { label: "Do 50 kilometrów", value: "50", disabled: true},
    { label: "Do 75 kilometrów", value: "75", disabled: true},
    { label: "Do 100 kilometrów", value: "100", disabled: true},
    { label: "Do 125 kilometrów", value: "125", disabled: true},
    { label: "Do 150 kilometrów", value: "150", disabled: true},
    { label: "Do 200 kilometrów", value: "200", disabled: true},
    { label: "Do 250 kilometrów", value: "250", disabled: true},
]

function Home() {

    // const [queryParams, setQueryParams] = useState(null);

    // let { state } = useLocation();

    const location = useLocation();
    const [debouncedLocation, setDebouncedLocation] = useState(location);
    // const navigate = useNavigate();


    const [ searchParams, setSearchParams ] = useSearchParams();

    // const queryParams = new URLSearchParams(location.search);
    const [carsData, setCarsData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Selected Data from filtering
    const [selectedBrand, setSelectedBrand] = useState([]);
    const [modelData, setModelData] = useState([]);
    const [selectedModel, setSelectedModel] = useState([]);
    const [generationData, setGenerationData] = useState([]);
    const [selectedGeneration, setSelectedGeneration] = useState([]);

    const [selectedFuels, setSelectedFuels] = useState([]);
    const [selectedCarStatus, setSelectedCarStatus] = useState([]);
    const [selectedCarDamage, setSelectedCarDamage] = useState([]);
    const [selectedBodyType, setSelectedBodyType] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState([]);

    // Selected data but from other Select panel
    const [yearFrom, setYearFrom] = useState(null);
    const [yearTo, setYearTo] = useState(2023);
    const [years, setYears] = useState([]);
    const [isYearToChanged, setIsYearToChanged] = useState(false);
    const [isYearFromChanged, setIsYearFromChanged] = useState(false);
    const [mileageList, setMileageList] = useState([]);
    const [mileageFrom, setMileageFrom] = useState(null);
    const [mileageTo, setMileageTo] = useState(300000);
    const [isMileageToChanged, setIsMileageToChanged] = useState(false);
    const [priceFrom, setPriceFrom] = useState(null);
    const [priceTo, setPriceTo] = useState(2000000);
    const [priceList, setPriceList] = useState([]);
    const [isPriceFromChanged, setIsPriceFromChanged] = useState(false);
    const [isPriceToChanged, setIsPriceToChanged] = useState(false);

    // Car equipment panel
    const [showEquipmentPanel, setShowEquipmentPanel] = useState(false);
    const [checkboxes, setCheckboxes] = useState(equipmentOptions)
    const [trueEquipmentOptions, setTrueEquipmentOptions] = useState([]);

    // Auction localization panel
    const [showLocalizationPanel, setShowLocalizationPanel] = useState(false);
    const [selectedProvince, setSelectedProvince] = useState([]);
    const [selectedCity, setSelectedCity] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [selectedRadius, setSelectedRadius] = useState([]);

    // Body, color, amount of doors,  etc.
    const [showBodyPanel, setShowBodyPanel] = useState(false);
    const [selectedDoors, setSelectedDoors] = useState([]);
    const [selectedColor, setSelectedColor] = useState([]);
    const [selectedSeatsAmount, setSelectedSeatsAmount] = useState([]);

    // Engine capacity, panel
    const [showEnginePanel, setShowEnginePanel] = useState(false);
    const [selectedGearbox, setSelectedGearbox] = useState([]);
    const [selectedTransmission, setSelectedTransmission] = useState([]);
    const [capacityFrom, setCapacityFrom] = useState([]);
    const [capacityTo, setCapacityTo] = useState([]);
    const [powerFrom, setPowerFrom] = useState([]);
    const [powerTo, setPowerTo] = useState([]);

    const [selectedFiltration, setSelectedFiltration] = useState('');
    const [page, setPage] = useState(1);

    // Fetching data errors.
    const [modelError, setModelError] = useState('');
    const [generationError, setGenerationError] = useState('');

    // Loading variables
    const [isModelLoading, setIsModelLoading] = useState(true);
    const [isGenerationLoading, setIsGenerationLoading] = useState(true);
    const [filtrationPanel, setFiltrationPanel] = useState(false);

    // css Property for Select tag.
    // let cssPropertySmallButtons = "block appearance-none w-full rounded-[30px] bg-base-200 px-4 h-[40px] md:h-[40px] pr-8 rounded leading-tight focus:outline-none focus:shadow-outline";
    let cssPropertySmallButtons = "h-[43px] transition duration-300 block border-0 appearance-none w-full rounded-[30px] bg-base-200 focus:ring focus:ring-2 focus:ring-main_yellow pl-3 py-2 leading-tight"

    const getTrueEquipmentValues = (obj) => {
        const trueValues = [];
        for (const category in obj) {
            for (const option in obj[category]) {
                if (obj[category][option] === true) {
                    trueValues.push(option);
                }
            }
        }
        return trueValues;
    };

    // Function to fetch data from API.
    const fetchModelData = async (brand) => {
        try {
            if (selectedBrand.length === 1){
                const response = await axiosInstance.get(`/car_info/?brand=${brand}`)
                console.log(response)
                if (response.status !== 200) {
                    throw new Error('Network response was not okay in fetching models.');
                }
                const modelJsonData = await response.data;
                // console.log(modelJsonData);
                const transformedData = modelJsonData.map(item => ({
                    label: item.version,
                    value: item.version
                }))

                setModelData(transformedData);

            } else {
                throw new Error('More than 1 brand of car.')
            }
        } catch (error) {
            setModelError('Error fetching data:' + error.message);
        } finally {
            setIsModelLoading(false);
        }
        console.log(modelData);
    }

    const fetchGenerationData = async (brand, model) => {
        try {
            if (selectedModel.length === 1){
                const response = await axiosInstance.get(`/car_info/?brand=${brand}&version=${model}`)
                console.log(response)
                if (response.status !== 200) {
                    throw new Error('Network response was not okay in fetching generations.');
                }
                const generationJsonData = await response.data;
                const transformedGenerationData = generationJsonData.map(item => ({
                    label: `${item.generation} ${item.years}`,
                    value: item.generation
                }))
                setGenerationData(transformedGenerationData);

            } else {
                throw new Error(`More than 1 model of brand ${selectedBrand}`)
            }
        } catch (error) {
            setModelError('Error with fetching generation data:' + error.message)
        } finally {
            setIsGenerationLoading(false);
        }
        console.log(generationData);
    }

    const handleMileageFromChange = (event) => {
        searchParams.set('page', '1');
        setSearchParams(searchParams);
        setMileageFrom(event.target.value);
        // console.log(mileageFrom, mileageTo);

        if (event.target.value === "") {
            searchParams.delete('mileage_from');
        } else {
            searchParams.set('mileage_from', event.target.value);
        }

        setSearchParams(searchParams);
    };

    const handleMileageToChange = (event) => {
        searchParams.set('page', '1');
        setSearchParams(searchParams);
        setMileageTo(event.target.value);
        setIsMileageToChanged(true);
        // console.log(mileageFrom, mileageTo);

        if (event.target.value === "") {
            searchParams.delete('mileage_to');
        } else {
            searchParams.set('mileage_to', event.target.value);
        }

        setSearchParams(searchParams);

    };

    const handleYearFromChange = (event) => {
        searchParams.set('page', '1');
        setSearchParams(searchParams);
        if (event.target.value === "") {
            setYearFrom(1940)
        }
        setYearFrom(event.target.value);
        setIsYearFromChanged(true)

        if (event.target.value === "Rok od") {
            searchParams.delete('year_from');
        } else {
            searchParams.set('year_from', event.target.value);
        }
        setSearchParams(searchParams)

    };

    const handleYearToChange = (event) => {
        searchParams.set('page', '1');
        setSearchParams(searchParams);
        if (event.target.value === "") {
            setYearTo(2023)
        }
        setYearTo(event.target.value);
        setIsYearToChanged(true);

        if (event.target.value === "Rok do") {
            searchParams.delete('year_to');
        } else {
            searchParams.set('year_to', event.target.value);
        }

        setSearchParams(searchParams);
    };

    const handlePriceFromChange = (event) => {
        searchParams.set('page', '1');
        setSearchParams(searchParams);
        setPriceFrom(event.target.value);
        setIsPriceFromChanged(true);

        if (event.target.value === "") {
            searchParams.delete('price_from');
        } else {
            searchParams.set('price_from', event.target.value);
        }

        setSearchParams(searchParams);
    };

    const handlePriceToChange = (event) => {
        searchParams.set('page', '1');
        setSearchParams(searchParams);
        setPriceTo(event.target.value);
        setIsPriceToChanged(true);

        if (event.target.value === "") {
            searchParams.delete('price_to');
        } else {
            searchParams.set('price_to', event.target.value);
        }

        setSearchParams(searchParams);
    };


    const handleShowEnginePanel = () => {
        setShowEnginePanel(true);
    };

    const handleCloseEnginePanel = () => {
        setShowEnginePanel(false);
    }

    // Show equipment panel.
    const handleShowEquipmentPanel = () => {
        setShowEquipmentPanel(!showEquipmentPanel);
    };

    const handleShowLocalizationPanel = () => {
        setShowLocalizationPanel(true);
    }

    const handleCloseLocalizationPanel = () => {
        setShowLocalizationPanel(false);
    };

    const handleShowBodyPanel = () => {
        setShowBodyPanel(true);
    }

    const handleCloseBodyPanel = () => {
        setShowBodyPanel(false);
    }

    // Equipment options, on changes.
    const handleCheckboxChange = (category, option) => (event) => {
        searchParams.set('page', '1');
        setSearchParams(searchParams);


        setCheckboxes({...checkboxes, [category]: {...checkboxes[category],  [option]: event.target.checked,},});

        console.log(event.target.checked)

        if (event.target.checked === false) {
            searchParams.delete('equipment')

            const equipment = getTrueEquipmentValues({...checkboxes, [category]: {...checkboxes[category],  [option]: event.target.checked,},})
            console.log(equipment)

            equipment.forEach((equip) => {
                if (!searchParams.getAll('equipment').includes(equip)) {
                    searchParams.append('equipment', equip);
                }
            })


        } else if (event.target.checked === true) {

            const equipment = getTrueEquipmentValues({...checkboxes, [category]: {...checkboxes[category],  [option]: event.target.checked,},})
            // console.log(equipment)

            equipment.forEach((equip) => {
                if (!searchParams.getAll('equipment').includes(equip)) {
                    searchParams.append('equipment', equip);
                }
            })

        }

        setSearchParams(searchParams);

    };

    // Checkboxes to array, To show user how much they picked options.
    useEffect(() => {
        const trueEquipment = getTrueEquipmentValues(checkboxes);
        setTrueEquipmentOptions(trueEquipment);
    }, [checkboxes])


    // Set selected data from queryparams
    useEffect(() => {
        // const queryParams = new URLSearchParams(location.search);

        // console.log(searchParams);

        const selectedBrandParam = searchParams.getAll('brand');

        if (selectedBrandParam !== null) {
            setSelectedBrand(selectedBrandParam.map((brand) => ({
                label: brand,
                value: brand,
            })))

        } else {
            setSelectedBrand([]);
        }

        // const selectedModelParam = queryParams.get('model');
        const selectedModelParam = searchParams.getAll('model');

        if (selectedModelParam !== null) {

            setSelectedModel(selectedModelParam.map((model) => ({
                label: model,
                value: model,
            })))

        } else {
            setSelectedModel([]);
        }

        const selectedGenerationParam = searchParams.getAll('generation');


        if (selectedGenerationParam !== null) {
            // console.log("Setting generation from queryparams")

            setSelectedGeneration(selectedGenerationParam.map((generation) => ({
                label: generation,
                value: generation,
            })))


            // setSelectedGeneration([{"label": selectedGenerationParam, "value": selectedGenerationParam}])
            // setIsSelectedGenerationFromQueryParams(true);
        } else {
            setSelectedGeneration([]);
        }

        const selectedBodyTypeParam = searchParams.getAll('body_type');

        if (selectedBodyTypeParam !== null) {

            setSelectedBodyType(selectedBodyTypeParam.map((body) => ({
                label: body,
                value: body,
            })))


            // setSelectedBodyType([{"label": selectedBodyTypeParam, "value": selectedBodyTypeParam}])
        } else {
            setSelectedBodyType([]);
        }

        const yearFromParam = searchParams.get('year_from');

        if (yearFromParam !== null) {
            setYearFrom(yearFromParam);
        } else {
            setYearFrom("Rok od");
            setIsYearFromChanged(true);
        }

        const yearToParam = searchParams.get('year_to');

        if (yearToParam !== null) {
            setYearTo(yearToParam);
        } else {
            setYearTo("Rok do");
            setIsYearToChanged(true);
        }

        const mileageFromParam = searchParams.get('mileage_from');

        if (mileageFromParam !== null) {
            setMileageFrom(mileageFromParam);
        } else {
            setMileageFrom("");
        }

        const mileageToParam = searchParams.get('mileage_to');

        if (mileageToParam !== null) {
            setMileageTo(mileageToParam);
        } else {
            setMileageTo("");
        }

        const priceFromParam = searchParams.get('price_from');

        if (priceFromParam !== null) {
            setPriceFrom(priceFromParam);
        } else {
            setPriceFrom("");
        }

        const priceToParam = searchParams.get('price_to');

        if (priceToParam !== null) {
            setPriceTo(priceToParam);
        } else {
            setPriceTo("");
        }

        const fuelTypeParam = searchParams.getAll('fuel_type');

        if (fuelTypeParam !== null) {
            // setSelectedFuels([{"label": fuelTypeParam, "value": fuelTypeParam}])

            setSelectedFuels(fuelTypeParam.map((body) => ({
                label: body,
                value: body,
            })))
        }

        const countryParam = searchParams.getAll('country')

        if (countryParam !== null) {
            setSelectedCountry(countryParam.map((body) => ({
                label: body,
                value: body,
            })))
        }

        const carStatusParam = searchParams.getAll('car_status')

        if (carStatusParam !== null) {
            const selectedCarStatusLabels = carStatusParam.map((value) => {
                const labelObject = carStatusOptions.find((option) => option.value === value)
                return labelObject ? { label: labelObject.label, value } : null;
            })

            const filteredSelectedCarStatusLables = selectedCarStatusLabels.filter((item) => item !== null);
            setSelectedCarStatus(filteredSelectedCarStatusLables);
        }

        const carDamageParam = searchParams.getAll('damage')

        if (carDamageParam !== null) {
            const selectedDamageLabels = carDamageParam.map((value) => {
                const labelObject = carDamageOption.find((option) => option.value === value)
                return labelObject ? { label: labelObject.label, value } : null;
            })

            const filteredCarDamageOptions = selectedDamageLabels.filter((item) => item !== null);
            setSelectedCarDamage(filteredCarDamageOptions)

        }

        const equipmentParam = searchParams.getAll('equipment')

        if (equipmentParam) {

            for (const category in checkboxes) {
                for (const param in checkboxes[category]) {
                    checkboxes[category][param] = false;
                }
            }

            equipmentParam.forEach((param) => {
                for (const category in checkboxes) {
                    // Remove other true params to false
                    if (checkboxes[category][param] !== undefined) {
                        checkboxes[category][param] = true;
                    }
                }
            })

            setCheckboxes(checkboxes)
            // console.log(checkboxes);
        }

        const provinceParam = searchParams.getAll('province')

        if (provinceParam) {
            setSelectedProvince(provinceParam.map((body) => ({
                label: (body.charAt(0).toUpperCase() + body.slice(1)),
                value: body,
            })))

            if (provinceParam.length === 1) {
                const cityArray = []

                for (const city in cityDict[provinceParam[0]]) {
                    cityArray.push({ label: `${ cityDict[provinceParam[0]][city] }`, value: `${ cityDict[provinceParam[0]][city]}` })
                }
                // console.log(cityArray)
                setCityData(cityArray);
            }
        }

        const cityParam = searchParams.getAll('city')

        if (cityParam && provinceParam) {
            if (cityParam.length === 1 && provinceParam.length === 1) {
                setSelectedCity([{ label: cityParam[0], value: cityParam[0], disabled: false}]);

                const cityArray = [];
                // console.log(provinceParam[0], "tu")

                for (const city in cityDict[provinceParam[0]]) {
                    const cityName = cityDict[provinceParam[0]][city]

                    // console.log(cityName, cityParam[0], provinceParam[0])
                    if (cityName !== cityParam[0]) {
                        cityArray.push({ label: `${ cityName.charAt(0).toUpperCase() + cityName.slice(1)}`, value: `${ cityDict[provinceParam[0]][city]}`, disabled: true })
                    } else if (cityName === cityParam[0]) {
                        cityArray.push({ label: `${ cityName.charAt(0).toUpperCase() + cityName.slice(1)}`, value: `${ cityDict[provinceParam[0]][city]}`, disabled: false })
                    }
                }

                setCityData(cityArray);
            }
        }

        const radiusParam = searchParams.getAll('radius')

        if (radiusParam) {
            if (radiusParam.length === 1) {
                setSelectedRadius([{ label:`Do ${radiusParam} kilometrów`, value: radiusParam[0]}]);
                for (const key in radiusOptions) {
                    if (Object.hasOwnProperty.call(radiusOptions, key)) {
                        // console.log(radiusOptions[key].value, radiusParam[0])

                        if (radiusOptions[key].value !== radiusParam[0]) {
                            const element = radiusOptions[key];
                            element.disabled = true;
                        } else if (radiusOptions[key].value === radiusParam[0]) {
                            const element = radiusOptions[key];
                            element.disabled = false;
                        }

                    }
                }

                searchParams.set('radius', radiusParam[0]);
                setSearchParams(searchParams);
            }
        }

        const colorsParam = searchParams.getAll('color')

        if (colorsParam) {
            const colorsArray = []

            colorsParam.forEach((seats) => {
                const searched = colorOptions.find(option => option.value === seats);
                colorsArray.push(searched)
            })

            setSelectedColor(colorsArray)
        }

        const doorsParam = searchParams.getAll('doors')

        if (doorsParam) {
            const doorsArray = []

            doorsParam.forEach((seats) => {
                const searched = doorsOptions.find(option => option.value === seats);
                doorsArray.push(searched)
            })

            setSelectedDoors(doorsArray)
        }

        const seatsParam = searchParams.getAll('seats_amount')

        if (seatsParam) {
            const seatsArray = []

            seatsParam.forEach((seats) => {
                const searched = seatsAmountOptions.find(option => option.value === seats);
                seatsArray.push(searched)
            })

            setSelectedSeatsAmount(seatsArray)
        }


        const gearboxParam = searchParams.getAll('gearbox')

        if (gearboxParam) {
            const gearboxArray = []

            gearboxParam.forEach((seats) => {
                const searched = gearboxOptions.find(option => option.value === seats);
                gearboxArray.push(searched)
            })

            setSelectedGearbox(gearboxArray)
        }

        const transmissionParam = searchParams.getAll('transmission')

        if (transmissionParam) {
            const transmissionArray = []

            transmissionParam.forEach((seats) => {
                const searched = transmissionOptions.find(option => option.value === seats);
                transmissionArray.push(searched)
            })

            setSelectedTransmission(transmissionArray)
        }

        const capacityFromParam = searchParams.getAll('capacity_from');

        if (capacityFromParam) {
            if (capacityFromParam.length === 1) {
                setCapacityFrom([{ label:`${capacityFromParam} cm3`, value: capacityFromParam[0]}]);
                for (const key in capacityFromOptions) {
                    if (Object.hasOwnProperty.call(capacityFromOptions, key)) {
                        // console.log(radiusOptions[key].value, radiusParam[0])

                        if (capacityFromOptions[key].value !== capacityFromParam[0]) {
                            const element = capacityFromOptions[key];
                            element.disabled = true;
                        } else if (capacityFromOptions[key].value === capacityFromParam[0]) {
                            const element = capacityFromOptions[key];
                            element.disabled = false;
                        }

                    }
                }

            }
        }

        const capacityToParam = searchParams.getAll('capacity_to')

        if (capacityToParam) {
            if (capacityToParam.length === 1) {
                setCapacityTo([{ label:`${capacityToParam} cm3`, value: capacityToParam[0]}]);
                for (const key in capacityToOptions) {
                    if (Object.hasOwnProperty.call(capacityToOptions, key)) {
                        // console.log(radiusOptions[key].value, radiusParam[0])

                        if (capacityToOptions[key].value !== capacityToParam[0]) {
                            const element = capacityToOptions[key];
                            element.disabled = true;
                        } else if (capacityToOptions[key].value === capacityToParam[0]) {
                            const element = capacityToOptions[key];
                            element.disabled = false;
                        }
                    }
                }
            }
        }

        const filtrationParam = searchParams.getAll('search')

        if (filtrationParam) {
            if (filtrationParam.length === 1) {
                console.log(filtrationParam)
                const filtrationResult = filtrationOptions.find(option => option.value === filtrationParam[0])
                console.log(filtrationResult, filtrationResult.label)
                setSelectedFiltration(filtrationResult.value);
            }
        }

        const pageParam = searchParams.getAll('page')
        // console.log(pageParam);

        if (pageParam && pageParam.length === 1) {
            setPage(parseInt(pageParam[0]));
        } else if (pageParam.length === 0) {
            setPage(1);
            searchParams.set('page', '1')
            setSearchParams(searchParams)
        }


    }, [])

    // If user select brand then fetch model data to selected brand.
    useEffect(() => {
        if (selectedBrand.length === 1) {
            fetchModelData(selectedBrand[0].label)
        }
    }, [selectedBrand])


    useEffect(() => {
        if (selectedModel.length === 1) {
            // searchParams.delete('generation');
            searchParams.set('model', selectedModel[0].label);
            setSearchParams(searchParams);
            fetchGenerationData(selectedBrand[0].label, selectedModel[0].label)
        }
    }, [selectedModel])


    const handleSelectedModelChange = (newValue) => {
        setSelectedModel(newValue);
        // console.log(newValue);
        searchParams.set('page', '1');
        setSearchParams(searchParams);

        if (newValue.length === 0) {
            setSelectedGeneration([]);
            setGenerationData([]);

            searchParams.delete('model');
            searchParams.delete('generation');

            setSearchParams(searchParams);
        } else if (newValue.length > 1) {
            searchParams.delete('model');
            searchParams.delete('generation');

            newValue.forEach(dict => {
                if (!searchParams.getAll('model').includes(dict.value)) {
                    searchParams.append('model', dict.value);
                }
            })

            setSelectedGeneration([]);
            setGenerationData([]);
            setSearchParams(searchParams);
        } else if (newValue.length === 1) {
            searchParams.set('model', newValue[0].label);
            setSearchParams(searchParams);

        }

    }

    const handleSelectedGenerationChange = (newValue) => {
        setSelectedGeneration(newValue);
        searchParams.set('page', '1');
        setSearchParams(searchParams);
        // console.log(newValue)

        if (newValue.length === 0) {
            searchParams.delete('generation');
            setSearchParams(searchParams);
        } else {
            searchParams.delete('generation');
            // searchParams.delete('model');

            newValue.forEach(dict => {
                if (!searchParams.getAll('generation').includes(dict.value)) {
                searchParams.append('generation', dict.value);
                }
            });
            setSearchParams(searchParams);
        }
    }

    const handleSelectedBrandChange = (newValue) => {
        setSelectedBrand(newValue);
        searchParams.set('page', '1');
        setSearchParams(searchParams);

        if (newValue.length === 0) {
            searchParams.delete('brand');
            searchParams.delete('model');
            searchParams.delete('generation');
            setSearchParams(searchParams);

            setSelectedBrand([]);
            setSelectedModel([]);
            setSelectedGeneration([]);
            setModelData([]);
            setGenerationData([]);
        } else if (newValue.length > 1) {
            searchParams.delete('model');
            searchParams.delete('generation');

            newValue.forEach(dict => {
                if (!searchParams.getAll('brand').includes(dict.value)) {
                    searchParams.append('brand', dict.value);
                }
            });

            setSearchParams(searchParams);

            setSelectedModel([]);
            setSelectedGeneration([]);
            setModelData([]);
            setGenerationData([]);
        } else if (newValue.length === 1) {
            searchParams.set('brand', newValue[0].label);

            setSearchParams(searchParams);
        }
    }

    // Handling selected body type change.
    const handleSelectedBodyTypeChange = (newValue) => {
        setSelectedBodyType(newValue);
        searchParams.set('page', '1');
        setSearchParams(searchParams);

        if (newValue.length === 0) {
            searchParams.delete('body_type');
            setSearchParams(searchParams);
        } else {
            searchParams.delete('body_type');

            newValue.forEach(dict => {
                if (!searchParams.getAll('body_type').includes(dict.value)) {
                    searchParams.append('body_type', dict.value);
                }
            });
            setSearchParams(searchParams);
        }
    }

    // Handling selected province change.
    const handleSelectedProvinceChange = (newValue) => {
        setSelectedProvince(newValue);
        searchParams.set('page', '1');
        setSearchParams(searchParams);

        if (newValue.length === 0) {
            searchParams.delete('province');
            searchParams.delete('city');
            searchParams.delete('radius')
            setSearchParams(searchParams)

            setSelectedCity([]);
            setCityData([]);
            setSelectedRadius([]);


            for (const key in radiusOptions) {
                if (Object.hasOwnProperty.call(radiusOptions, key)) {
                    const element = radiusOptions[key];
                    element.disabled = true;
                }
            }

        } else if (newValue.length > 1) {
            searchParams.delete('province')

            newValue.forEach(dict => {
                if (!searchParams.getAll('province').includes(dict.value)) {
                    searchParams.append('province', dict.value);
                }
            })
            
            setSelectedCity([]);
            setCityData([]);

            searchParams.delete('city');
            searchParams.delete('radius');
            setSearchParams(searchParams);

        } else if (newValue.length === 1) {
            searchParams.delete('province')
            newValue.forEach(dict => {
                if (!searchParams.getAll('province').includes(dict.value)) {
                    searchParams.append('province', dict.value);
                }
            })
            setSearchParams(searchParams);


            const cityArray = []

            for (const city in cityDict[newValue[0].value]) {
                // console.log(city, cityDict[newValue[0].value][city]);
                const cityName = cityDict[newValue[0].value][city]
                cityArray.push({ label: `${ cityName.charAt(0).toUpperCase() + cityName.slice(1)}`, value: `${ cityDict[newValue[0].value][city]}` })
            }

            setCityData(cityArray);
        }


    }


    const handleSelectedCityChange = (newValue) => {
        searchParams.set('page', '1');
        setSearchParams(searchParams);
        if (newValue.length === 1) {
            setSelectedCity(newValue);

            searchParams.set('city', newValue[0].value);
            searchParams.set('radius', 20);
            setSearchParams(searchParams)


            const cityArray = []

            for (const city in cityDict[selectedProvince[0].value]) {
                const cityName = cityDict[selectedProvince[0].value][city]

                if (cityName !== newValue[0].label) {
                    cityArray.push({ label: `${ cityName.charAt(0).toUpperCase() + cityName.slice(1)}`, value: `${ cityDict[selectedProvince[0].value][city]}`, disabled: true })
                } else if (cityName === newValue[0].label) {
                    cityArray.push({ label: `${ cityName.charAt(0).toUpperCase() + cityName.slice(1)}`, value: `${ cityDict[selectedProvince[0].value][city]}`, disabled: false })
                }
            }

            for (const key in radiusOptions) {
                if (Object.hasOwnProperty.call(radiusOptions, key)) {
                    const element = radiusOptions[key];
                    element.disabled = false;
                }
            }

            setCityData(cityArray);
        } else if (newValue.length === 0) {
            setSelectedCity(newValue);

            searchParams.delete('city');
            searchParams.delete('radius');

            setSelectedRadius([]);
            setSearchParams(searchParams);

            for (const key in radiusOptions) {
                if (Object.hasOwnProperty.call(radiusOptions, key)) {
                    const element = radiusOptions[key];
                    element.disabled = true;
                }
            }

            const cityArray = []

            for (const city in cityDict[selectedProvince[0].value]) {
                const cityName = cityDict[selectedProvince[0].value][city]

                cityArray.push({ label: `${ cityName.charAt(0).toUpperCase() + cityName.slice(1)}`, value: `${ cityDict[selectedProvince[0].value][city]}`, disabled: false })
            }

            setCityData(cityArray);
        }
    }

    // Handling selected radius change.
    const handleSelectedRadiusChange = (newValue) => {
        searchParams.set('page', '1');
        setSearchParams(searchParams);
        if (newValue.length === 1) {
            setSelectedRadius(newValue);
            for (const key in radiusOptions) {
                // console.log(key, radiusOptions[key])
                if (Object.hasOwnProperty.call(radiusOptions, key)) {
                    if (radiusOptions[key].value !== newValue[0].value) {
                        const element = radiusOptions[key];
                        element.disabled = true;
                    } else if (radiusOptions[key].value === newValue[0].value) {
                        const element = radiusOptions[key];
                        element.disabled = false;
                    }

                }
            }

            searchParams.set('radius', newValue[0].value);
            setSearchParams(searchParams);
        } else if (newValue.length === 0) {
            setSelectedRadius(newValue);

            for (const key in radiusOptions) {
                if (Object.hasOwnProperty.call(radiusOptions, key)) {
                    const element = radiusOptions[key];
                    element.disabled = false;
                }
            }

            searchParams.delete('radius');
            setSearchParams(searchParams);
        }
    }


    const handlePowerFromChange = (newValue) => {
        searchParams.set('page', '1');
        setSearchParams(searchParams);
        if (newValue.length === 1) {

            if ( powerTo.length === 1 ) {

                if (parseInt(newValue[0].value) > parseInt(powerTo[0].value)) {

                    setPowerTo([]);
                    searchParams.delete('power_to');

                    setPowerFrom(newValue);
                    searchParams.set('power_from', newValue[0].value)

                    for (const key in powerFromOptions) {
                        if (Object.hasOwnProperty.call(powerFromOptions, key)) {
                            if (powerFromOptions[key].value !== newValue[0].value) {
                                const element = powerFromOptions[key];
                                element.disabled = true;
                            } else if (powerFromOptions[key].value === newValue[0].value) {
                                const element = powerFromOptions[key];
                                element.disabled = false;
                            }

                        }
                    }

                    for (const key in powerToOptions) {
                        if (Object.hasOwnProperty.call(powerToOptions, key)) {
                            const element = powerToOptions[key]
                            element.disabled = false;
                        }
                    }

                    setSearchParams(searchParams);

                } else {
                    setPowerFrom(newValue);

                    for (const key in powerFromOptions) {
                        if (Object.hasOwnProperty.call(powerFromOptions, key)) {
                            if (powerFromOptions[key].value !== newValue[0].value) {
                                const element = powerFromOptions[key];
                                element.disabled = true;
                            } else if (powerFromOptions[key].value === newValue[0].value) {
                                const element = powerFromOptions[key];
                                element.disabled = false;
                            }

                        }
                    }
                    searchParams.set('power_from', newValue[0].value)
                    setSearchParams(searchParams)

                }
            } else {
                setPowerFrom(newValue);

                for (const key in powerFromOptions) {
                    if (Object.hasOwnProperty.call(powerFromOptions, key)) {
                        if (powerFromOptions[key].value !== newValue[0].value) {
                            const element = powerFromOptions[key];
                            element.disabled = true;
                        } else if (powerFromOptions[key].value === newValue[0].value) {
                            const element = powerFromOptions[key];
                            element.disabled = false;
                        }

                    }
                }
                searchParams.set('power_from', newValue[0].value)
                setSearchParams(searchParams)
            }

        } else if (newValue.length === 0) {
            setPowerFrom(newValue)

            for (const key in powerFromOptions) {
                if (Object.hasOwnProperty.call(powerFromOptions, key)) {
                    const element = powerFromOptions[key];
                    element.disabled = false;
                }
            }

            searchParams.delete('power_from')
            setSearchParams(searchParams)
        }
    }


    const handlePowerToChange = (newValue) => {
        searchParams.set('page', '1');
        setSearchParams(searchParams);
        if (newValue.length === 1) {
            if ( powerFrom.length === 1 ) {
                // console.log(newValue[0].value, capacityFrom[0].value)
                // console.log(parseInt(newValue[0].value) < parseInt(capacityFrom[0].value))
                if (parseInt(newValue[0].value) < parseInt(powerFrom[0].value)) {
                    // console.log("hehehe")

                    // console.log(newValue[0].value, powerFrom[0].value)

                    setPowerFrom([]);
                    searchParams.delete('power_from');

                    setPowerTo(newValue);
                    searchParams.set('power_to', newValue[0].value)

                    for (const key in powerToOptions) {
                        if (Object.hasOwnProperty.call(powerToOptions, key)) {
                            if (powerToOptions[key].value !== newValue[0].value) {
                                const element = powerToOptions[key];

                                if (typeof element !== 'boolean') {
                                    element.disabled = true;
                                }

                            } else if (powerToOptions[key].value === newValue[0].value) {
                                const element = powerToOptions[key];

                                if (typeof element !== 'boolean') {
                                    element.disabled = false;
                                }
                                // element.disabled = false;
                            }

                        }
                    }

                    for (const key in powerFromOptions) {
                        if (Object.hasOwnProperty.call(powerFromOptions, key)) {
                            const element = powerFromOptions[key]
                            element.disabled = false;
                        }
                    }

                    setSearchParams(searchParams);

                } else {
                    setPowerTo(newValue);
                    // console.log(newValue)
                    for (const key in powerToOptions) {
                        if (Object.hasOwnProperty.call(powerToOptions, key)) {
                            if (powerToOptions[key].value !== newValue[0].value) {
                                const element = powerToOptions[key];

                                if (typeof element !== 'boolean') {
                                    element.disabled = true;
                                }

                            } else if (powerToOptions[key].value === newValue[0].value) {

                                const element = powerToOptions[key];
                                if (typeof element !== 'boolean') {
                                    element.disabled = false;
                                }

                                // element.disabled = false;
                            }

                        }
                    }
                    searchParams.set('power_to', newValue[0].value)
                    setSearchParams(searchParams)

                }
            } else {
                setPowerTo(newValue);

                for (const key in powerToOptions) {
                    if (Object.hasOwnProperty.call(powerToOptions, key)) {
                        if (powerToOptions[key].value !== newValue[0].value) {
                            const element = powerToOptions[key];
                            element.disabled = true;
                        } else if (powerToOptions[key].value === newValue[0].value) {
                            const element = powerToOptions[key];
                            element.disabled = false;
                        }

                    }
                }
                searchParams.set('power_to', newValue[0].value)
                setSearchParams(searchParams)
            }

        } else if (newValue.length === 0) {
            setPowerTo(newValue)

            for (const key in powerToOptions) {
                if (Object.hasOwnProperty.call(powerToOptions, key)) {
                    const element = powerToOptions[key];

                    if (typeof element !== 'boolean') {
                        element.disabled = false;
                    }
                    // element.disabled = false;
                }
            }

            searchParams.delete('power_to')
            setSearchParams(searchParams)
        }
    }

    const handleCapacityFromChange = (newValue) => {
        searchParams.set('page', '1');
        setSearchParams(searchParams);
        if (newValue.length === 1) {

            if ( capacityTo.length === 1 ) {

                if (parseInt(newValue[0].value) > parseInt(capacityTo[0].value)) {

                    setCapacityTo([]);
                    searchParams.delete('capacity_to');

                    setCapacityFrom(newValue);
                    searchParams.set('capacity_from', newValue[0].value)

                    for (const key in capacityFromOptions) {
                        if (Object.hasOwnProperty.call(capacityFromOptions, key)) {
                            if (capacityFromOptions[key].value !== newValue[0].value) {
                                const element = capacityFromOptions[key];
                                element.disabled = true;
                            } else if (capacityFromOptions[key].value === newValue[0].value) {
                                const element = capacityFromOptions[key];
                                element.disabled = false;
                            }

                        }
                    }

                    for (const key in capacityToOptions) {
                        if (Object.hasOwnProperty.call(capacityToOptions, key)) {
                            const element = capacityToOptions[key]
                            element.disabled = false;
                        }
                    }

                    setSearchParams(searchParams);

                } else {
                    setCapacityFrom(newValue);

                    for (const key in capacityFromOptions) {
                        if (Object.hasOwnProperty.call(capacityFromOptions, key)) {
                            if (capacityFromOptions[key].value !== newValue[0].value) {
                                const element = capacityFromOptions[key];
                                element.disabled = true;
                            } else if (capacityFromOptions[key].value === newValue[0].value) {
                                const element = capacityFromOptions[key];
                                element.disabled = false;
                            }

                        }
                    }
                    searchParams.set('capacity_from', newValue[0].value)
                    setSearchParams(searchParams)

                }
            } else {
                setCapacityFrom(newValue);

                for (const key in capacityFromOptions) {
                    if (Object.hasOwnProperty.call(capacityFromOptions, key)) {
                        if (capacityFromOptions[key].value !== newValue[0].value) {
                            const element = capacityFromOptions[key];
                            element.disabled = true;
                        } else if (capacityFromOptions[key].value === newValue[0].value) {
                            const element = capacityFromOptions[key];
                            element.disabled = false;
                        }

                    }
                }
                searchParams.set('capacity_from', newValue[0].value)
                setSearchParams(searchParams)
            }

        } else if (newValue.length === 0) {
            setCapacityFrom(newValue)

            for (const key in capacityFromOptions) {
                if (Object.hasOwnProperty.call(capacityFromOptions, key)) {
                    const element = capacityFromOptions[key];
                    element.disabled = false;
                }
            }

            searchParams.delete('capacity_from')
            setSearchParams(searchParams)
        }
    }


    const handleCapacityToChange = (newValue) => {
        searchParams.set('page', '1');
        setSearchParams(searchParams);
        if (newValue.length === 1) {

            if ( capacityFrom.length === 1 ) {
                console.log(newValue[0].value, capacityFrom[0].value)
                console.log(parseInt(newValue[0].value) < parseInt(capacityFrom[0].value))
                if (parseInt(newValue[0].value) < parseInt(capacityFrom[0].value)) {
                    // console.log("hehehe")

                    console.log(newValue[0].value, capacityFrom[0].value)

                    setCapacityFrom([]);
                    searchParams.delete('capacity_from');

                    setCapacityTo(newValue);
                    searchParams.set('capacity_to', newValue[0].value)

                    for (const key in capacityToOptions) {
                        if (Object.hasOwnProperty.call(capacityToOptions, key)) {
                            if (capacityToOptions[key].value !== newValue[0].value) {
                                const element = capacityToOptions[key];

                                if (typeof element !== 'boolean') {
                                    element.disabled = true;
                                }

                            } else if (capacityToOptions[key].value === newValue[0].value) {
                                const element = capacityToOptions[key];

                                if (typeof element !== 'boolean') {
                                    element.disabled = false;
                                }
                                // element.disabled = false;
                            }

                        }
                    }

                    for (const key in capacityFromOptions) {
                        if (Object.hasOwnProperty.call(capacityFromOptions, key)) {
                            const element = capacityFromOptions[key]
                            element.disabled = false;
                        }
                    }

                    setSearchParams(searchParams);

                } else {
                    setCapacityTo(newValue);
                    // console.log(newValue)
                    for (const key in capacityToOptions) {
                        if (Object.hasOwnProperty.call(capacityToOptions, key)) {
                            if (capacityToOptions[key].value !== newValue[0].value) {
                                const element = capacityToOptions[key];

                                if (typeof element !== 'boolean') {
                                    element.disabled = true;
                                }

                            } else if (capacityToOptions[key].value === newValue[0].value) {

                                const element = capacityToOptions[key];
                                if (typeof element !== 'boolean') {
                                    element.disabled = false;
                                }

                                // element.disabled = false;
                            }

                        }
                    }
                    searchParams.set('capacity_to', newValue[0].value)
                    setSearchParams(searchParams)

                }
            } else {
                setCapacityTo(newValue);

                for (const key in capacityToOptions) {
                    if (Object.hasOwnProperty.call(capacityToOptions, key)) {
                        if (capacityToOptions[key].value !== newValue[0].value) {
                            const element = capacityToOptions[key];
                            element.disabled = true;
                        } else if (capacityToOptions[key].value === newValue[0].value) {
                            const element = capacityToOptions[key];
                            element.disabled = false;
                        }

                    }
                }
                searchParams.set('capacity_to', newValue[0].value)
                setSearchParams(searchParams)
            }

        } else if (newValue.length === 0) {
            setCapacityTo(newValue)

            for (const key in capacityToOptions) {
                if (Object.hasOwnProperty.call(capacityToOptions, key)) {
                    const element = capacityToOptions[key];

                    if (typeof element !== 'boolean') {
                        element.disabled = false;
                    }
                    // element.disabled = false;
                }
            }

            searchParams.delete('capacity_to')
            setSearchParams(searchParams)
        }
    }


    const handleSelectedColorChange = (newValue) => {
        searchParams.set('page', '1');
        setSearchParams(searchParams);
        setSelectedColor(newValue);

        if (newValue.length === 0 ) {
            searchParams.delete('color');
            setSearchParams(searchParams);
        } else {
            searchParams.delete('color');

            newValue.forEach(dict => {
                if (!searchParams.getAll('color').includes(dict.value)) {
                    searchParams.append('color', dict.value);
                }
            });
            setSearchParams(searchParams);
        }
    }

    const handleSelectedDoorsChange = (newValue) => {
        searchParams.set('page', '1');
        setSearchParams(searchParams);
        setSelectedDoors(newValue);

        if (newValue.length === 0 ) {
            searchParams.delete('doors');
            setSearchParams(searchParams);
        } else {
            searchParams.delete('doors');

            newValue.forEach(dict => {
                if (!searchParams.getAll('doors').includes(dict.value)) {
                    searchParams.append('doors', dict.value);
                }
            });
            setSearchParams(searchParams);
        }
    }

    const handleSelectedSeatsAmountChange = (newValue) => {
        searchParams.set('page', '1');
        setSearchParams(searchParams);
        setSelectedSeatsAmount(newValue);

        if (newValue.length === 0 ) {
            searchParams.delete('seats_amount');
            setSearchParams(searchParams);
        } else {
            searchParams.delete('seats_amount');

            newValue.forEach(dict => {
                if (!searchParams.getAll('seats_amount').includes(dict.value)) {
                    searchParams.append('seats_amount', dict.value);
                }
            });
            setSearchParams(searchParams);
        }
    }

    // Handling selected country change.
    const handleSelectedCountryChange = (newValue) => {
        searchParams.set('page', '1');
        setSearchParams(searchParams);
        setSelectedCountry(newValue);

        if (newValue.length === 0) {
            searchParams.delete('country');
            setSearchParams(searchParams);
        } else {
            searchParams.delete('country');

            newValue.forEach(dict => {
                if (!searchParams.getAll('country').includes(dict.value)) {
                    searchParams.append('country', dict.value);
                }
            });
            setSearchParams(searchParams);
        }
    }


    // Handling selected fuels change.
    const handleSelectedFuelsChange = (newValue) => {
        searchParams.set('page', '1');
        setSearchParams(searchParams);
        setSelectedFuels(newValue);

        if (newValue.length === 0) {
            searchParams.delete('fuel_type');
            setSearchParams(searchParams);
        } else {
            searchParams.delete('fuel_type');

            newValue.forEach(dict => {
                if (!searchParams.getAll('fuel_type').includes(dict.value)) {
                    searchParams.append('fuel_type', dict.value);
                }
            });
            setSearchParams(searchParams);
        }
    }

    // Handling selected car status change.
    const handleCarStatusChange = (newValue) => {
        searchParams.set('page', '1');
        setSearchParams(searchParams);
        setSelectedCarStatus(newValue);

        if (newValue.length === 0) {
            searchParams.delete('car_status');
            setSearchParams(searchParams);
        } else {
            searchParams.delete('car_status');

            newValue.forEach(dict => {
                if (!searchParams.getAll('car_status').includes(dict.value)) {
                    searchParams.append('car_status', dict.value);
                }
            });
            setSearchParams(searchParams);
        }
    }

    const handleSelectedCarDamageChange = (newValue) => {
        searchParams.set('page', '1');
        setSearchParams(searchParams);
        setSelectedCarDamage(newValue);

        if (newValue.length === 0) {
            searchParams.delete('damage');
            setSearchParams(searchParams)
        } else {
            searchParams.delete('damage')

            newValue.forEach(dict => {
                if (!searchParams.getAll('damage').includes(dict.value)) {
                    searchParams.append('damage', dict.value);
                }
            });
            setSearchParams(searchParams);
        }
    }

    useEffect(() => {
        const filtrationSearchOption = searchParams.get('search')

        if (filtrationSearchOption !== null) {
            for (const key in filtrationOptions) {
                // console.log(key, radiusOptions[key])
                if (Object.hasOwnProperty.call(filtrationOptions, key)) {
                    if (filtrationOptions[key].value !== filtrationSearchOption) {
                        const element = filtrationOptions[key];
                        element.disabled = true;
                    } else if (filtrationOptions[key].value === filtrationSearchOption) {
                        const element = filtrationOptions[key];
                        element.disabled = false;
                    }
                }
            }
        }
    }, [searchParams.get('search')])

    // const handleSelectedFiltrationChange = (newValue) => {
    //     searchParams.set('page', '1');
    //     setSearchParams(searchParams);
    //     setSelectedFiltration(newValue);
    //
    //     if (newValue.length === 1) {
    //         for (const key in filtrationOptions) {
    //             // console.log(key, radiusOptions[key])
    //             if (Object.hasOwnProperty.call(filtrationOptions, key)) {
    //                 if (filtrationOptions[key].value !== newValue[0].value) {
    //                     const element = filtrationOptions[key];
    //                     element.disabled = true;
    //                 } else if (filtrationOptions[key].value === newValue[0].value) {
    //                     const element = filtrationOptions[key];
    //                     element.disabled = false;
    //                 }
    //             }
    //         }
    //
    //         searchParams.set('search', newValue[0].value)
    //         setSearchParams(searchParams)
    //
    //     } else if (newValue.length === 0) {
    //
    //         for (const key in filtrationOptions) {
    //
    //             if (Object.hasOwnProperty.call(filtrationOptions, key)) {
    //                     const element = filtrationOptions[key];
    //                     element.disabled = false;
    //             }
    //         }
    //         searchParams.delete('search');
    //         setSearchParams(searchParams);
    //     }
    // }

    const handleSelectedFiltrationChangeSelect = (newValue) => {
        console.log(newValue.target.value)

        searchParams.set('page', '1');
        setSearchParams(searchParams);
        setSelectedFiltration(newValue.target.value);

        if (newValue.target.value !== null) {
            searchParams.set('search', newValue.target.value)
            setSearchParams(searchParams)
        }
    }

    const handleSelectedGearboxChange = (newValue) => {
        searchParams.set('page', '1');
        setSearchParams(searchParams);
        setSelectedGearbox(newValue);

        if (newValue.length === 0) {
            searchParams.delete('gearbox');
            setSearchParams(searchParams)
        } else {
            searchParams.delete('gearbox')

            newValue.forEach(dict => {
                if (!searchParams.getAll('gearbox').includes(dict.value)) {
                    searchParams.append('gearbox', dict.value);
                }
            });
            setSearchParams(searchParams);
        }

    }


    const handleSelectedTransmissionChange = (newValue) => {
        searchParams.set('page', '1');
        setSearchParams(searchParams);
        setSelectedTransmission(newValue);

        if (newValue.length === 0) {
            searchParams.delete('transmission');
            setSearchParams(searchParams)
        } else {
            searchParams.delete('transmission')

            newValue.forEach(dict => {
                if (!searchParams.getAll('transmission').includes(dict.value)) {
                    searchParams.append('transmission', dict.value);
                }
            });
            setSearchParams(searchParams);
        }
    }

    useEffect(() => {
        let mileagePlusOne = parseInt(mileageFrom) + 1000;
        const mileageOptions = generateMileageOptions(mileagePlusOne, 300000);
        setMileageList(mileageOptions);
    }, [mileageFrom, mileageTo])

    useEffect(() => {
        let pricePlusOne = parseInt(priceFrom) + 1000;
        const priceOptions = generatePriceOptions(pricePlusOne, 2000000);
        setPriceList(priceOptions);
    }, [priceFrom, priceTo])

    useEffect(() => {
        const yearOptions = [];
        let yearPlusOne = parseInt(yearFrom) + 1;
        let globalYear = 2023;

        for (let year = yearPlusOne; year <= globalYear; year++) {
            yearOptions.push(year);
        }
        setYears(yearOptions)
        // console.log(yearFrom, yearTo);

    }, [yearFrom, yearTo]);


    const generateQueryString = (page) => {
        let queryString = ''

        searchParams.forEach((value, key) => {
            // Append each key-value pair to the query string
            if (queryString.length > 0) {
              queryString += '&'; // Add '&' to separate parameters
            }
            if (key === "page") {
                queryString += `${encodeURIComponent(key)}=${encodeURIComponent(page)}`;
            } else {
                queryString += `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
            }
        });

        // console.log(queryString);

        return queryString;
    }


    useEffect(() => {
        const debounceTimeout = setTimeout(() => {

            if (searchParams.size === 0) {
            axiosInstance.get(`/passenger/auctions/?search=highlighted`)
                  .then(response => response.data)
                  .then(data => {
                    setCarsData(data);
                    setLoading(false);
                  })
                  .catch(error => {
                    console.error('Error fetching data:', error);
                    setLoading(false);
                  });
            } else {
            let requestString = '';

            searchParams.forEach((param, key) => {
                // console.log(key, param);

                // let queryString = '';
                let keyString = '';
                if (key === "model") {
                    keyString = `version=${param}`;

                } else if (key === "car_status") {
                    keyString = `${param}`;

                } else if (key === "country"){
                    keyString = `origin_country=${param}`;

                } else if (key === "city") {
                    keyString = `location=${param}`;

                } else if (key === "damage") {
                    keyString = `accident=${param}`

                } else {
                    keyString = `${key}=${param}`;
                }

                if (requestString.length === 0) {
                    requestString += `${keyString}`
                } else {
                    requestString += `&${keyString}`
                }
            })

            if (!searchParams.has("search")) {
                if (requestString.length === 0) {
                    requestString += `search=highlighted`
                } else {
                    requestString += `&search=highlighted`
                }
            }

            if (!searchParams.has("page")) {
                if (requestString.length === 0) {
                    requestString += `page=1`
                } else {
                    requestString += `&page=1`
                }
            }

            setLoading(true);

            // console.log(requestString)
            // console.log(`${process.env.REACT_APP_BACKEND_IP}/passenger/auctions/?${requestString}`)
            // console.log('ACCESS', localStorage.getItem('access_token'))
            // console.log('REFRESH', localStorage.getItem('refresh_token'))
            // console.log(axiosInstance.defaults.headers.common['Authorization'])

            axiosInstance.get(`/passenger/auctions/?${requestString}`, { withCredentials: true})
                  .then(response => response.data)
                  .then(data => {
                    setCarsData(data);
                    setLoading(false);
                  })
                  .catch(error => {
                    console.error('Error fetching data:', error);
                    setLoading(false);
                  });
        }
        }, 500)

        return () => {
            clearTimeout(debounceTimeout)
        }

    }, [debouncedLocation])


    useEffect(() => {
        // console.log(searchParams.size)

        console.log("Fetching data from queryparams.")

        setDebouncedLocation(location);
        setLoading(true);

        // if (searchParams.size === 0) {
        //     axios.get(`/passenger/auctions/?search=highlighted`)
        //           .then(response => response.data)
        //           .then(data => {
        //             setCarsData(data);
        //             setLoading(false);
        //           })
        //           .catch(error => {
        //             console.error('Error fetching data:', error);
        //             setLoading(false);
        //           });
        // } else {
        //     let requestString = '';
        //
        //     searchParams.forEach((param, key) => {
        //         // console.log(key, param);
        //
        //         // let queryString = '';
        //         let keyString = '';
        //         if (key === "model") {
        //             keyString = `version=${param}`;
        //
        //         } else if (key === "car_status") {
        //             keyString = `${param}`;
        //
        //         } else if (key === "country"){
        //             keyString = `origin_country=${param}`;
        //
        //         } else if (key === "city") {
        //             keyString = `location=${param}`;
        //
        //         } else if (key === "damage") {
        //             keyString = `accident=${param}`
        //
        //         } else {
        //             keyString = `${key}=${param}`;
        //         }
        //
        //         if (requestString.length === 0) {
        //             requestString += `${keyString}`
        //         } else {
        //             requestString += `&${keyString}`
        //         }
        //     })
        //
        //     if (!searchParams.has("search")) {
        //         if (requestString.length === 0) {
        //             requestString += `search=highlighted`
        //         } else {
        //             requestString += `&search=highlighted`
        //         }
        //     }
        //
        //     if (!searchParams.has("page")) {
        //         if (requestString.length === 0) {
        //             requestString += `page=1`
        //         } else {
        //             requestString += `&page=1`
        //         }
        //     }
        //
        //     setLoading(true);
        //
        //     console.log(requestString)
        //     console.log(`${process.env.REACT_APP_BACKEND_IP}/passenger/auctions/?${requestString}`)
        //     axios.get(`/passenger/auctions/?${requestString}`)
        //           .then(response => response.data)
        //           .then(data => {
        //             setCarsData(data);
        //             setLoading(false);
        //           })
        //           .catch(error => {
        //             console.error('Error fetching data:', error);
        //             setLoading(false);
        //           });
        // }

    }, [location])


    useEffect(() => {
        // window.scrollTo(0, 0);

        scroll.scrollToTop({
            duration: 300,
        });
    }, [location]);


    const handleShowFiltrationPanel = () => {
        setFiltrationPanel(!filtrationPanel);
    }

    const removeAllFiltration = () => {
        // const queryParams = new URLSearchParams(location.search);
        // setCarsData([]);
        // setLoading(true);
        // Selected Data from filtering
        setSelectedBrand([]);
        setModelData([]);
        setSelectedModel([]);
        setGenerationData([]);
        setSelectedGeneration([]);
        setSelectedFuels([]);
        setSelectedCarStatus([]);
        setSelectedCarDamage([]);
        setSelectedBodyType([]);
        setSelectedCountry([]);
        // Selected data but from other Select panel
        setYearFrom("Rok od");
        setYearTo("Rok do");
        setYears([]);
        setIsYearToChanged(false);
        setIsYearFromChanged(false);
        setMileageList([]);
        setMileageFrom("");
        setMileageTo("");
        setIsMileageToChanged(false);
        setPriceFrom("");
        setPriceTo("");
        setPriceList([]);
        setIsPriceFromChanged(false);
        setIsPriceToChanged(false);
        // Car equipment panel
        setShowEquipmentPanel(false);
        setCheckboxes(equipmentOptions)
        setTrueEquipmentOptions([]);
        // Auction localization panel
        setShowLocalizationPanel(false);
        setSelectedProvince([]);
        setSelectedCity([]);
        setCityData([]);
        setSelectedRadius([]);
        // Body, color, amount of doors,  etc.
        setShowBodyPanel(false);
        setSelectedDoors([]);
        setSelectedColor([]);
        setSelectedSeatsAmount([]);
        // Engine capacity, panel
        setShowEnginePanel(false);
        setSelectedGearbox([]);
        setSelectedTransmission([]);
        setCapacityFrom([]);
        setCapacityTo([]);
        setPowerFrom([]);
        setPowerTo([]);
        setSelectedFiltration('');
        setPage(1);
        // Fetching data errors.
        setModelError('');
        setGenerationError('');
        // Loading variables
        setIsModelLoading(true);
        setIsGenerationLoading(true);
        // setFiltrationPanel(false);


        setSearchParams({ page: 1});

    }

    const brand = searchParams.getAll("brand");
    const model = searchParams.getAll("model");
    const province = searchParams.getAll("province");
    const capitalizedProvinces = province.map(province => {
        return province.charAt(0).toUpperCase() + province.slice(1);
    });
    const city = searchParams.getAll("city");
    const TITLE = `Autkotarg.pl - Samochody osobowe ${ brand.length === 0 ? `` : `${brand} ` }${ model.length === 0 ? `` : `${model}, ` }${ province.length !== 0 ? `w ${capitalizedProvinces}` : "" } ${ city.length !== 0 ? `w mieście ${city}` : "" }`;
    const DESCRIPTION = `Autkotarg.pl - Szukaj ogłoszeń samochodów osobowych ${ brand.length === 0 ? `` : `${brand} ` }${ model.length === 0 ? `` : `${model}, ` }${ province.length !== 0 ? `w ${capitalizedProvinces}` : "" }${ city.length !== 0 ? ` w mieście ${city}` : "" }`;

    return (
    <div className="App">
        {/*<Outlet></Outlet>*/}
        <Navbar/>
        <Stripes/>
        {/*<CarAuctionsFiltration/>*/}
        <Helmet
        onChangeClientState={(newState) => {
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
        metaDescription.setAttribute('content', DESCRIPTION || '');
        }
        }}>
            <title>{ TITLE }</title>
        </Helmet>

        {/* Filtration mobile panel*/}
        { filtrationPanel && (
            <div className={'w-full h-full'}>
                <div className={`fixed inset-0 flex items-center justify-center z-20 bg-opacity-50 bg-gray-300 backdrop-blur filtration-panel-animation`}>
                    <div className={'w-full h-[95%] md:w-11/12 lg:w-4/12 bg-base-100 md:h-[68%] rounded-[30px] overflow-auto mx-1 relative panel-content'}>
                        <div className={`w-full mx-auto relative flex justify-center`}>

                            { (searchParams.size > 1) && (
                                <div onClick={removeAllFiltration} title={"Usuń wszystkie filtracje"} className={`absolute gap-x-1 flex left-5 top-5 hover:cursor-pointer hover:cursor-pointer`}>
                                    <svg className={`w-8 h-8 stroke-main_red-100`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M9 3H15M3 6H21M19 6L18.2987 16.5193C18.1935 18.0975 18.1409 18.8867 17.8 19.485C17.4999 20.0118 17.0472 20.4353 16.5017 20.6997C15.882 21 15.0911 21 13.5093 21H10.4907C8.90891 21 8.11803 21 7.49834 20.6997C6.95276 20.4353 6.50009 20.0118 6.19998 19.485C5.85911 18.8867 5.8065 18.0975 5.70129 16.5193L5 6M10 10.5V15.5M14 10.5V15.5" stroke="" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <svg className={`w-8 h-8 stroke-black`} xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
                                        <path d="M1 2.6C1 2.03995 1 1.75992 1.10899 1.54601C1.20487 1.35785 1.35785 1.20487 1.54601 1.10899C1.75992 1 2.03995 1 2.6 1H19.4C19.9601 1 20.2401 1 20.454 1.10899C20.6422 1.20487 20.7951 1.35785 20.891 1.54601C21 1.75992 21 2.03995 21 2.6V3.26939C21 3.53819 21 3.67259 20.9672 3.79756C20.938 3.90831 20.8901 4.01323 20.8255 4.10776C20.7526 4.21443 20.651 4.30245 20.4479 4.4785L14.0521 10.0215C13.849 10.1975 13.7474 10.2856 13.6745 10.3922C13.6099 10.4868 13.562 10.5917 13.5328 10.7024C13.5 10.8274 13.5 10.9618 13.5 11.2306V16.4584C13.5 16.6539 13.5 16.7517 13.4685 16.8363C13.4406 16.911 13.3953 16.9779 13.3363 17.0315C13.2695 17.0922 13.1787 17.1285 12.9971 17.2012L9.59711 18.5612C9.22957 18.7082 9.0458 18.7817 8.89827 18.751C8.76927 18.7242 8.65605 18.6476 8.58325 18.5377C8.5 18.4122 8.5 18.2142 8.5 17.8184V11.2306C8.5 10.9618 8.5 10.8274 8.46715 10.7024C8.43805 10.5917 8.39014 10.4868 8.32551 10.3922C8.25258 10.2856 8.15102 10.1975 7.94789 10.0215L1.55211 4.4785C1.34898 4.30245 1.24742 4.21443 1.17449 4.10776C1.10986 4.01323 1.06195 3.90831 1.03285 3.79756C1 3.67259 1 3.53819 1 3.26939V2.6Z" stroke="" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    {/*<svg className={`w-12 h-12 absolute -top-2.5 -left-2 stroke-main_red-100`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">*/}
                                    {/*    <path d="M17 7L7 17M7 7L17 17" stroke="" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
                                    {/*</svg>*/}
                                </div>
                            )}

                            <p className={`text-center text-[22px] my-5`}>Filtracja</p>

                            <svg onClick={handleShowFiltrationPanel} className={`w-10 h-10 absolute right-3 top-3.5 hover:cursor-pointer hover:cursor-pointer`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M17 7L7 17M7 7L17 17" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>

                        </div>

                        <div className={'grid grid-cols-1 mx-auto w-[90%] mb-5 md:w-11/12 gap-x-3 gap-y-0 -mt-3'}>
                            {/* Marka pojazdu */}
                            <div className={`inline-block relative px-2 md:px-0 w-full md:w-full`}>
                                <label className="block text-left text-black w-full ml-2.5 mb-1 mt-1.5 w-full">Marka pojazdu</label>
                                <MultiSelect
                                    className={`rmsc`}
                                    options={brandOptions}
                                    value={selectedBrand}
                                    onChange={handleSelectedBrandChange}
                                    labelledBy="Marka"
                                    hasSelectAll={false}
                                    overrideStrings={{
                                          "allItemsAreSelected": "Wszystko jest zaznaczone.",
                                          "noOptions": "Brak wybranych opcji",
                                          "search": "Szukaj",
                                          "selectAll": "Wybierz wszystko",
                                          "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                          "selectSomeItems": "Marka pojazdu",
                                          "create": "Stwórz",
                                    }}
                                />
                            </div>
                            {/* Model pojazdu */}
                            <div className={`inline-block relative px-2 md:px-0 w-full md:w-full`}>
                                <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Model pojazdu</label>
                                <MultiSelect
                                    options={modelData}
                                    value={selectedModel}
                                    onChange={handleSelectedModelChange}
                                    labelledBy="Model pojazdu"
                                    hasSelectAll={false}
                                    overrideStrings={{
                                          "allItemsAreSelected": "Wszystko jest zaznaczone.",
                                          "noOptions": "Brak wybranych opcji",
                                          "search": "Szukaj",
                                          "selectAll": "Wybierz wszystko",
                                          "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                          "selectSomeItems": "Model pojazdu",
                                          "create": "Stwórz",
                                    }}
                                />
                            </div>
                            {/* Typ nadwozia */}
                            <div className={`inline-block relative px-2 md:px-0 w-full md:w-full`}>
                                <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Typ nadwozia</label>
                                <MultiSelect
                                    options={bodyTypeOptions}
                                    value={selectedBodyType}
                                    disableSearch={true}
                                    onChange={handleSelectedBodyTypeChange}
                                    labelledBy="Typ nadwozia"
                                    hasSelectAll={false}
                                    overrideStrings={{
                                          "allItemsAreSelected": "Wszystko jest zaznaczone.",
                                          "noOptions": "Brak wybranych opcji",
                                          "search": "Szukaj",
                                          "selectAll": "Wybierz wszystko",
                                          "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                          "selectSomeItems": "Typ nadwozia",
                                          "create": "Stwórz",
                                    }}
                                />
                            </div>
                            {/* Generacja */}
                            <div className={`inline-block relative px-2 md:px-0 w-full md:w-full`}>
                                <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Generacja</label>
                                <MultiSelect
                                    options={generationData}
                                    value={selectedGeneration}
                                    disableSearch={true}
                                    onChange={handleSelectedGenerationChange}
                                    labelledBy="Model pojazdu"
                                    hasSelectAll={false}
                                    overrideStrings={{
                                          "allItemsAreSelected": "Wszystko jest zaznaczone.",
                                          "noOptions": "Brak wyników",
                                          "search": "Szukaj",
                                          "selectAll": "Wybierz wszystko",
                                          "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                          "selectSomeItems": "Generacja",
                                          "create": "Stwórz",
                                    }}
                                />
                            </div>

                            <div className={`flex gap-0 md:gap-3`}>
                                <div className={`inline-block relative px-2 md:px-0 w-full md:w-full appearance-none ${ isYearFromChanged === false || yearFrom === "Rok od"  ? ( yearFrom !== "Rok od" ? ('text-black') : ("text-main_gray_dark")) : ("text-black") }`}>
                                    <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Rok od</label>
                                    <select value={yearFrom} onChange={handleYearFromChange}
                                        className={`${cssPropertySmallButtons}`}
                                        id={'year_from'}
                                    >
                                        <option selected value={null}>Rok od</option>

                                        {/*{generateYearOptions(1940, yearTo-1)}*/}
                                        {yearTo === "Rok do" ? (
                                            generateYearOptions(1940, 2024)
                                        ) : (
                                            generateYearOptions(1940, yearTo-1)
                                        )}
                                    </select>
                                    {/*<div*/}
                                    {/*    className="pointer-events-none absolute inset-y-0 right-3 md:right-1 flex items-center px-1.5 text-gray-700 md:px-2 lg:px-1.5">*/}
                                    {/*     <svg*/}
                                    {/*             width="24"*/}
                                    {/*             height="24"*/}
                                    {/*             fill="none"*/}
                                    {/*             stroke="#7D7D7D"*/}
                                    {/*             strokeWidth="2"*/}
                                    {/*             className="dropdown-heading-dropdown-arrow gray"*/}
                                    {/*         >*/}
                                    {/*         <path d={"M6 9L12 15 18 9"} />*/}

                                    {/*     </svg>*/}
                                    {/*</div>*/}
                                </div>
                                <div className={`inline-block relative px-2 md:px-0 w-full md:w-full appearance-none ${ isYearToChanged === false || yearTo === "Rok do"  ? ( yearTo !== "Rok do" ? ('text-black') : ("text-main_gray_dark")) : ("text-black") }`}>
                                    <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Rok do</label>
                                    <select onChange={handleYearToChange} value={yearTo}
                                        className={`${cssPropertySmallButtons}`}
                                        id={'year_to'}
                                    >
                                        <option selected value={null} className={``}>Rok do</option>
                                        {years.length > 0 ? (
                                          years.map((year) => (
                                            <option key={year} value={year} className={`text-black`}>
                                              {year}
                                            </option>
                                          ))
                                        ) : (
                                          generateYearOptions(1940, 2024)
                                        )}
                                    </select>
                                    {/*<div*/}
                                    {/*    className="pointer-events-none absolute inset-y-0 right-3 md:right-1 flex items-center px-1.5 text-gray-700 md:px-2 lg:px-1.5">*/}
                                    {/*    <svg width="24" height="24" fill="none" stroke="#7D7D7D" strokeWidth="2" className="dropdown-heading-dropdown-arrow gray">*/}
                                    {/*         <path d={"M6 9L12 15 18 9"} />*/}
                                    {/*     </svg>*/}
                                    {/*</div>*/}
                                </div>
                            </div>

                            {/*    MILEAGE */}
                            <div className={`flex gap-0 md:gap-3`}>
                                <div className="inline-block relative px-2 md:px-0 w-full md:w-full appearance-none">
                                    <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Przebieg od</label>
                                    <select onChange={handleMileageFromChange} value={mileageFrom}
                                        id={'mileage_from'}
                                        className={`${ mileageFrom === "" || mileageFrom === null ? (( mileageFrom !== "" ? ('text-black text-left') : ("text-main_gray_dark text-left"))) : ("text-black text-left") } ${cssPropertySmallButtons}`}>
                                        <option selected value={""} className={`text-black`}>Przebieg od</option>
                                        {mileageTo === "" ? (
                                            generateMileageOptions(0, 300000)
                                        ) : (
                                            generateMileageOptions(0, mileageTo-100)
                                        )}
                                    </select>
                                    {/*<div*/}
                                    {/*    className="pointer-events-none absolute inset-y-0 right-3 md:right-1 flex items-center px-1.5 text-gray-700 md:px-2 lg:px-1.5">*/}
                                    {/*    <svg width="24" height="24" fill="none" stroke="#7D7D7D" strokeWidth="2" className="dropdown-heading-dropdown-arrow gray">*/}
                                    {/*         <path d={"M6 9L12 15 18 9"} />*/}
                                    {/*     </svg>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="inline-block relative px-2 md:px-0 w-full md:w-full appearance-none">
                                    <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Przebieg do</label>
                                    <select onChange={handleMileageToChange} value={mileageTo}
                                        id={'mileage_to'}
                                        className={`${ mileageTo === "" || isMileageToChanged === false ? (( mileageTo !== "" ? ('text-black') : ("text-main_gray_dark"))) : ("text-black")} ${cssPropertySmallButtons}`}>
                                        <option selected value={""}>Przebieg do</option>
                                        {mileageList.length > 0 ? (
                                            mileageList
                                        ) : (
                                          generateMileageOptions(0, 300000)
                                        )}

                                    </select>
                                    {/*<div*/}
                                    {/*    className="pointer-events-none absolute inset-y-0 right-3 md:right-1 flex items-center px-1.5 text-gray-700 md:px-2 lg:px-1.5">*/}
                                    {/*    <svg width="24" height="24" fill="none" stroke="#7D7D7D" strokeWidth="2" className="dropdown-heading-dropdown-arrow gray">*/}
                                    {/*         <path d={"M6 9L12 15 18 9"} />*/}
                                    {/*     </svg>*/}
                                    {/*</div>*/}
                                </div>

                            </div>

                            {/* Fuel types. */}
                            <div className={`inline-block relative px-2 md:px-0 w-full md:w-full`}>
                                <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Rodzaj paliwa</label>
                                <MultiSelect
                                    options={fuelTypeOptions}
                                    disableSearch={true}
                                    value={selectedFuels}
                                    onChange={handleSelectedFuelsChange}
                                    labelledBy="Paliwa"
                                    hasSelectAll={false}
                                    overrideStrings={{
                                          "allItemsAreSelected": "Wszystko jest zaznaczone.",
                                          "noOptions": "Brak wybranych opcji",
                                          "search": "Szukaj",
                                          "selectAll": "Wybierz wszystko",
                                          "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                          "selectSomeItems": "Rodzaj paliwa",
                                          "create": "Stwórz",
                                    }}
                                />
                            </div>

                        {/*    Price */}
                            <div className={`flex gap-0 md:gap-3`}>
                                <div className="inline-block relative px-2 md:px-0 w-full md:w-full appearance-none">
                                    <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Cena od</label>
                                    <select onChange={handlePriceFromChange} value={priceFrom}
                                        id={'price_from'}
                                        className={`${ priceFrom === "" || isPriceFromChanged === false ? (( priceFrom !== "" ? ('text-black') : ("text-main_gray_dark"))) : ("text-black")} ${cssPropertySmallButtons}`}>
                                        <option selected value={""}>Cena od</option>
                                        {priceTo === "" ? (
                                            generatePriceOptions(3000, 2000000)
                                        ) : (
                                            generatePriceOptions(3000, priceTo-100)
                                        )}
                                    </select>
                                    {/*<div*/}
                                    {/*    className="pointer-events-none absolute inset-y-0 right-3 md:right-1 flex items-center px-1.5 text-gray-700 md:px-2 lg:px-1.5">*/}
                                    {/*    <svg width="24" height="24" fill="none" stroke="#7D7D7D" strokeWidth="2" className="dropdown-heading-dropdown-arrow gray">*/}
                                    {/*         <path d={"M6 9L12 15 18 9"} />*/}
                                    {/*     </svg>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="inline-block relative px-2 md:px-0 w-full md:w-full appearance-none">
                                    <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Cena do</label>
                                    <select onChange={handlePriceToChange} value={priceTo}
                                        id={'price_to'}
                                        className={`${ priceTo === "" || isPriceToChanged === false ? (( priceTo !== "" ? ('text-black') : ("text-main_gray_dark"))) : ("text-black")} ${cssPropertySmallButtons}`}>
                                        <option selected value={""}>Cena do</option>
                                        {priceList.length > 0 ? (
                                            priceList
                                        ) : (
                                          generatePriceOptions(3000, 2000000)
                                        )}
                                    </select>
                                    {/*<div*/}
                                    {/*    className="pointer-events-none absolute inset-y-0 right-3 md:right-1 flex items-center px-1.5 text-gray-700 md:px-2 lg:px-1.5">*/}
                                    {/*    <svg width="24" height="24" fill="none" stroke="#7D7D7D" strokeWidth="2" className="dropdown-heading-dropdown-arrow gray">*/}
                                    {/*         <path d={"M6 9L12 15 18 9"} />*/}
                                    {/*     </svg>*/}
                                    {/*</div>*/}
                                </div>

                            </div>

                            <div className={`inline-block relative px-2 md:px-0 w-full md:w-full`}>
                                <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Status pojazdu</label>
                                <MultiSelect
                                    options={carStatusOptions}
                                    value={selectedCarStatus}
                                    disableSearch={true}
                                    onChange={handleCarStatusChange}
                                    labelledBy="Model pojazdu"
                                    hasSelectAll={false}
                                    overrideStrings={{
                                          "allItemsAreSelected": "Wszystko jest zaznaczone.",
                                          "noOptions": "Brak wybranych opcji",
                                          "search": "Szukaj",
                                          "selectAll": "Wybierz wszystko",
                                          "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                          "selectSomeItems": "Status pojazdu",
                                          "create": "Stwórz",
                                    }}
                                />
                            </div>

                            <div className={`inline-block relative px-2 md:px-0 w-full md:w-full`}>
                                <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Uszkodzenia pojazdu</label>
                                <MultiSelect
                                    options={carDamageOption}
                                    value={selectedCarDamage}
                                    disableSearch={true}
                                    onChange={handleSelectedCarDamageChange}
                                    labelledBy="Model pojazdu"
                                    hasSelectAll={false}
                                    overrideStrings={{
                                          "allItemsAreSelected": "Wszystko jest zaznaczone.",
                                          "noOptions": "Brak wybranych opcji",
                                          "search": "Szukaj",
                                          "selectAll": "Wybierz wszystko",
                                          "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                          "selectSomeItems": "Uszkodzenia pojazdu",
                                          "create": "Stwórz",
                                    }}
                                />
                            </div>

                            <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2 pl-2">Lokalizacja</label>
                            <div className="inline-block px-2 md:px-0 w-full md:w-full flex relative">
                                    <button onClick={handleShowLocalizationPanel} className={`${cssPropertySmallButtons} text-main_gray_dark text-left flex pl-3`}>
                                        <p className={`${ selectedRadius.length === 1 || selectedCity.length === 1 || selectedProvince.length >= 1  ? ('text-black') : ('text-main_gray_dark')} } my-auto text-left`}>Lokalizacja</p>
                                    </button>
                                    <div
                                        className="pointer-events-none absolute inset-y-0 right-2 px-2.5 md:right-0 md:px-3 flex items-center text-gray-700 sm:px-4">
                                        <svg width="24" height="24" fill="none" stroke="#7D7D7D" strokeWidth="2" className="dropdown-heading-dropdown-arrow gray md:-mr-0 lg:mr-0">
                                             <path d={"M6 9L12 15 18 9"} />
                                         </svg>
                                    </div>
                            </div>

                            <div className={`inline-block relative px-2 md:px-0 w-full md:w-full`}>
                                <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Kraj pochodzenia</label>
                                <MultiSelect
                                    options={countryOptions}
                                    value={selectedCountry}
                                    onChange={handleSelectedCountryChange}
                                    labelledBy="Model pojazdu"
                                    hasSelectAll={true}
                                    overrideStrings={{
                                          "allItemsAreSelected": "Wszystko jest zaznaczone.",
                                          "noOptions": "Brak wybranych opcji",
                                          "search": "Szukaj",
                                          "selectAll": "Wybierz wszystko",
                                          "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                          "selectSomeItems": "Kraj pochodzenia",
                                          "create": "Stwórz",
                                    }}
                                />
                            </div>
                                
                            <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2 px-2 md:px-0">Nadwozie</label>
                            <div className="inline-block relative px-2 md:px-0 w-full md:w-full flex relative">
                                    <button onClick={handleShowBodyPanel} className={`${cssPropertySmallButtons} text-main_gray_dark text-left flex`}>
                                        <p className={`${ selectedDoors.length >= 1 || selectedColor.length >= 1 || selectedSeatsAmount.length >= 1 ? ('text-black') : ('text-main_gray_dark')} } my-auto text-left`}>Nadwozie</p>
                                    </button>
                                    <div
                                        className="pointer-events-none absolute inset-y-0 right-2 px-2.5 md:right-0 md:px-3 flex items-center text-gray-700 sm:px-4">
                                        <svg width="24" height="24" fill="none" stroke="#7D7D7D" strokeWidth="2" className="dropdown-heading-dropdown-arrow gray md:-mr-0 lg:mr-0">
                                             <path d={"M6 9L12 15 18 9"} />
                                         </svg>
                                    </div>
                            </div>

                            <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2 px-2 md:px-0">Wyposażenie</label>
                            <div className="inline-block px-2 md:px-0 w-full md:w-full flex relative">
                                    <button onClick={handleShowEquipmentPanel} className={`${cssPropertySmallButtons} text-main_gray_dark text-left flex w-full justify-between`}>
                                        <p className={`${ trueEquipmentOptions.length > 0 ? ("text-black my-auto text-left") : ("text-main_gray_dark text-left my-auto")}`}>Wyposażenie</p>
                                        <p className={`${ trueEquipmentOptions.length > 0 ? ("text-main_red-100 mr-14 my-auto text-[22px]") : ("hidden")}`}>{ trueEquipmentOptions.length}</p>
                                    </button>
                                    {/*<div className={`absolute right-16 top-2.5 ${ trueEquipmentOptions.length > 0 ? ('text-main_red-100') : ('text-main_gray_dark')}`}>*/}
                                    {/*    { trueEquipmentOptions.length }*/}
                                    {/*</div>*/}
                                    <div
                                        className="pointer-events-none absolute inset-y-0 right-2 px-2.5 md:right-0 md:px-3 flex items-center text-gray-700 sm:px-4">
                                        <svg width="24" height="24" fill="none" stroke="#7D7D7D" strokeWidth="2" className="dropdown-heading-dropdown-arrow gray md:-mr-0 lg:mr-0">
                                             <path d={"M6 9L12 15 18 9"} />
                                         </svg>
                                    </div>
                            </div>


                            <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2 px-2 md:px-0">Silnik i napęd</label>
                            <div className="inline-block relative px-2 md:px-0 w-full md:w-full flex relative">
                                    <button onClick={handleShowEnginePanel} className={`${cssPropertySmallButtons} text-main_gray_dark text-left flex`}>
                                        <p className={`${ selectedGearbox.length >= 1 || selectedTransmission.length >= 1 || capacityFrom.length >= 1 || capacityTo.length >= 1 ? ('text-black') : ('text-main_gray_dark')} my-auto text-left`}>Silnik i napęd</p>
                                    </button>
                                    <div
                                        className="pointer-events-none absolute inset-y-0 right-2 px-2.5 md:right-0 md:px-3 flex items-center text-gray-700 sm:px-4">
                                        <svg width="24" height="24" fill="none" stroke="#7D7D7D" strokeWidth="2" className="dropdown-heading-dropdown-arrow gray md:-mr-0 lg:mr-0">
                                             <path d={"M6 9L12 15 18 9"} />
                                         </svg>
                                    </div>
                            </div>


                        </div>

                    </div>
                </div>
            </div>
        )}

        {/* Filtration for larger devices. */}
        <div className={`pt-3 md:pt-7`}>
            <div className={`w-11/12 md:w-full md:rounded-[0px] hidden lg:block rounded-[30px] px-1 mx-auto bg-base-100 pt-3 pb-7 hover:shadow-sm transition duration-300`}>
                <div className={'grid grid-cols-1 lg:grid-cols-5 mx-auto sm:w-11/12 md:w-11/12 3xl:w-9/12 gap-x-3 gap-y-0'}>
                    {/* Marka pojazdu */}
                    <div className={`inline-block relative px-2 md:px-0 w-full md:w-full`}>
                        <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Marka pojazdu</label>
                        <MultiSelect
                            className={`rmsc`}
                            options={brandOptions}
                            value={selectedBrand}
                            onChange={handleSelectedBrandChange}
                            labelledBy="Marka"
                            hasSelectAll={false}
                            overrideStrings={{
                                  "allItemsAreSelected": "Wszystko jest zaznaczone.",
                                  "noOptions": "Brak wybranych opcji",
                                  "search": "Szukaj",
                                  "selectAll": "Wybierz wszystko",
                                  "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                  "selectSomeItems": "Marka pojazdu",
                                  "create": "Stwórz",
                            }}
                        />
                    </div>
                    {/* Model pojazdu */}
                    <div className={`inline-block relative px-2 md:px-0 w-full md:w-full`}>
                        <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Model pojazdu</label>
                        <MultiSelect
                            options={modelData}
                            value={selectedModel}
                            onChange={handleSelectedModelChange}
                            labelledBy="Model pojazdu"
                            hasSelectAll={false}
                            overrideStrings={{
                                  "allItemsAreSelected": "Wszystko jest zaznaczone.",
                                  "noOptions": "Brak wybranych opcji",
                                  "search": "Szukaj",
                                  "selectAll": "Wybierz wszystko",
                                  "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                  "selectSomeItems": "Model pojazdu",
                                  "create": "Stwórz",
                            }}
                        />
                    </div>
                    {/* Typ nadwozia */}
                    <div className={`inline-block relative px-2 md:px-0 w-full md:w-full`}>
                        <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Typ nadwozia</label>
                        <MultiSelect
                            options={bodyTypeOptions}
                            value={selectedBodyType}
                            disableSearch={true}
                            onChange={handleSelectedBodyTypeChange}
                            labelledBy="Typ nadwozia"
                            hasSelectAll={false}
                            overrideStrings={{
                                  "allItemsAreSelected": "Wszystko jest zaznaczone.",
                                  "noOptions": "Brak wybranych opcji",
                                  "search": "Szukaj",
                                  "selectAll": "Wybierz wszystko",
                                  "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                  "selectSomeItems": "Typ nadwozia",
                                  "create": "Stwórz",
                            }}
                        />
                    </div>
                    {/* Generacja */}
                    <div className={`inline-block relative px-2 md:px-0 w-full md:w-full`}>
                        <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Generacja</label>
                        <MultiSelect
                            options={generationData}
                            value={selectedGeneration}
                            onChange={handleSelectedGenerationChange}
                            labelledBy="Model pojazdu"
                            hasSelectAll={false}
                            overrideStrings={{
                                  "allItemsAreSelected": "Wszystko jest zaznaczone.",
                                  "noOptions": "Brak wyników",
                                  "search": "Szukaj",
                                  "selectAll": "Wybierz wszystko",
                                  "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                  "selectSomeItems": "Generacja",
                                  "create": "Stwórz",
                            }}
                        />
                    </div>

                    <div className={`flex gap-0 md:gap-3`}>
                        <div className={`inline-block relative px-2 md:px-0 w-full md:w-full appearance-none ${ isYearFromChanged === false || yearFrom === "Rok od"  ? ( yearFrom !== "Rok od" ? ('text-black') : ("text-main_gray_dark")) : ("text-black") }`}>
                            <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Rok od</label>
                            <select value={yearFrom} onChange={handleYearFromChange}
                                className={`${cssPropertySmallButtons}`}
                                id={'year_from'}
                            >
                                <option selected value={null}>Rok od</option>

                                {/*{generateYearOptions(1940, yearTo-1)}*/}
                                {yearTo === "Rok do" ? (
                                    generateYearOptions(1940, 2024)
                                ) : (
                                    generateYearOptions(1940, yearTo-1)
                                )}
                            </select>
                            {/*<div*/}
                            {/*    className="pointer-events-none absolute inset-y-0 right-3 md:right-1 flex items-center px-1.5 text-gray-700 md:px-2 lg:px-1.5">*/}
                            {/*     <svg*/}
                            {/*             width="24"*/}
                            {/*             height="24"*/}
                            {/*             fill="none"*/}
                            {/*             stroke="#7D7D7D"*/}
                            {/*             strokeWidth="2"*/}
                            {/*             className="dropdown-heading-dropdown-arrow gray"*/}
                            {/*         >*/}
                            {/*         <path d={"M6 9L12 15 18 9"} />*/}

                            {/*     </svg>*/}
                            {/*</div>*/}
                        </div>
                        <div className={`inline-block relative px-2 md:px-0 w-full md:w-full appearance-none ${ isYearToChanged === false || yearTo === "Rok do"  ? ( yearTo !== "Rok do" ? ('text-black') : ("text-main_gray_dark")) : ("text-black") }`}>
                            <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Rok do</label>
                            <select onChange={handleYearToChange} value={yearTo}
                                className={`${cssPropertySmallButtons}`}
                                id={'year_to'}
                            >
                                <option selected value={null} className={``}>Rok do</option>
                                {years.length > 0 ? (
                                  years.map((year) => (
                                    <option key={year} value={year} className={`text-black`}>
                                      {year}
                                    </option>
                                  ))
                                ) : (
                                  generateYearOptions(1940, 2024)
                                )}
                            </select>
                            {/*<div*/}
                            {/*    className="pointer-events-none absolute inset-y-0 right-3 md:right-1 flex items-center px-1.5 text-gray-700 md:px-2 lg:px-1.5">*/}
                            {/*    <svg width="24" height="24" fill="none" stroke="#7D7D7D" strokeWidth="2" className="dropdown-heading-dropdown-arrow gray">*/}
                            {/*         <path d={"M6 9L12 15 18 9"} />*/}
                            {/*     </svg>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    {/*    MILEAGE */}
                    <div className={`flex gap-0 md:gap-3`}>
                        <div className="inline-block relative px-2 md:px-0 w-full md:w-full appearance-none">
                            <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Przebieg od</label>
                            <select onChange={handleMileageFromChange} value={mileageFrom}
                                id={'mileage_from'}
                                className={`${ mileageFrom === "" || mileageFrom === null ? (( mileageFrom !== "" ? ('text-black text-left') : ("text-main_gray_dark text-left"))) : ("text-black text-left") } ${cssPropertySmallButtons}`}>
                                <option selected value={""} className={`text-black`}>Przebieg od</option>
                                {mileageTo === "" ? (
                                    generateMileageOptions(0, 300000)
                                ) : (
                                    generateMileageOptions(0, mileageTo-100)
                                )}
                            </select>
                            {/*<div*/}
                            {/*    className="pointer-events-none absolute inset-y-0 right-3 md:right-1 flex items-center px-1.5 text-gray-700 md:px-2 lg:px-1.5">*/}
                            {/*    <svg width="24" height="24" fill="none" stroke="#7D7D7D" strokeWidth="2" className="dropdown-heading-dropdown-arrow gray">*/}
                            {/*         <path d={"M6 9L12 15 18 9"} />*/}
                            {/*     </svg>*/}
                            {/*</div>*/}
                        </div>
                        <div className="inline-block relative px-2 md:px-0 w-full md:w-full appearance-none">
                            <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Przebieg do</label>
                            <select onChange={handleMileageToChange} value={mileageTo}
                                id={'mileage_to'}
                                className={`${ mileageTo === "" || isMileageToChanged === false ? (( mileageTo !== "" ? ('text-black') : ("text-main_gray_dark"))) : ("text-black")} ${cssPropertySmallButtons}`}>
                                <option selected value={""}>Przebieg do</option>
                                {mileageList.length > 0 ? (
                                    mileageList
                                ) : (
                                  generateMileageOptions(0, 300000)
                                )}

                            </select>
                            {/*<div*/}
                            {/*    className="pointer-events-none absolute inset-y-0 right-3 md:right-1 flex items-center px-1.5 text-gray-700 md:px-2 lg:px-1.5">*/}
                            {/*    <svg width="24" height="24" fill="none" stroke="#7D7D7D" strokeWidth="2" className="dropdown-heading-dropdown-arrow gray">*/}
                            {/*         <path d={"M6 9L12 15 18 9"} />*/}
                            {/*     </svg>*/}
                            {/*</div>*/}
                        </div>

                    </div>

                    {/* Fuel types. */}
                    <div className={`inline-block relative px-2 md:px-0 w-full md:w-full`}>
                        <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Rodzaj paliwa</label>
                        <MultiSelect
                            options={fuelTypeOptions}
                            value={selectedFuels}
                            disableSearch={true}
                            onChange={handleSelectedFuelsChange}
                            labelledBy="Model pojazdu"
                            hasSelectAll={false}
                            overrideStrings={{
                                  "allItemsAreSelected": "Wszystko jest zaznaczone.",
                                  "noOptions": "Brak wybranych opcji",
                                  "search": "Szukaj",
                                  "selectAll": "Wybierz wszystko",
                                  "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                  "selectSomeItems": "Rodzaj paliwa",
                                  "create": "Stwórz",
                            }}
                        />
                    </div>

                {/*    Price */}
                    <div className={`flex gap-0 md:gap-3`}>
                        <div className="inline-block relative px-2 md:px-0 w-full md:w-full appearance-none">
                            <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Cena od</label>
                            <select onChange={handlePriceFromChange} value={priceFrom}
                                id={'price_from'}
                                className={`${ priceFrom === "" || isPriceFromChanged === false ? (( priceFrom !== "" ? ('text-black') : ("text-main_gray_dark"))) : ("text-black")} ${cssPropertySmallButtons}`}>
                                <option selected value={""}>Cena od</option>
                                {priceTo === "" ? (
                                    generatePriceOptions(3000, 2000000)
                                ) : (
                                    generatePriceOptions(3000, priceTo-100)
                                )}
                            </select>
                            {/*<div*/}
                            {/*    className="pointer-events-none absolute inset-y-0 right-3 md:right-1 flex items-center px-1.5 text-gray-700 md:px-2 lg:px-1.5">*/}
                            {/*    <svg width="24" height="24" fill="none" stroke="#7D7D7D" strokeWidth="2" className="dropdown-heading-dropdown-arrow gray">*/}
                            {/*         <path d={"M6 9L12 15 18 9"} />*/}
                            {/*     </svg>*/}
                            {/*</div>*/}
                        </div>
                        <div className="inline-block relative px-2 md:px-0 w-full md:w-full appearance-none">
                            <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Cena do</label>
                            <select onChange={handlePriceToChange} value={priceTo}
                                id={'price_to'}
                                className={`${ priceTo === "" || isPriceToChanged === false ? (( priceTo !== "" ? ('text-black') : ("text-main_gray_dark"))) : ("text-black")} ${cssPropertySmallButtons}`}>
                                <option selected value={""}>Cena do</option>
                                {priceList.length > 0 ? (
                                    priceList
                                ) : (
                                  generatePriceOptions(3000, 2000000)
                                )}
                            </select>
                            {/*<div*/}
                            {/*    className="pointer-events-none absolute inset-y-0 right-3 md:right-1 flex items-center px-1.5 text-gray-700 md:px-2 lg:px-1.5">*/}
                            {/*    <svg width="24" height="24" fill="none" stroke="#7D7D7D" strokeWidth="2" className="dropdown-heading-dropdown-arrow gray">*/}
                            {/*         <path d={"M6 9L12 15 18 9"} />*/}
                            {/*     </svg>*/}
                            {/*</div>*/}
                        </div>

                    </div>

                    <div className={`inline-block relative px-2 md:px-0 w-full md:w-full`}>
                        <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Status pojazdu</label>
                        <MultiSelect
                            options={carStatusOptions}
                            value={selectedCarStatus}
                            disableSearch={true}
                            onChange={handleCarStatusChange}
                            labelledBy="Model pojazdu"
                            hasSelectAll={false}
                            overrideStrings={{
                                  "allItemsAreSelected": "Wszystko jest zaznaczone.",
                                  "noOptions": "Brak wybranych opcji",
                                  "search": "Szukaj",
                                  "selectAll": "Wybierz wszystko",
                                  "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                  "selectSomeItems": "Status pojazdu",
                                  "create": "Stwórz",
                            }}
                        />
                    </div>

                    <div className={`inline-block relative px-2 md:px-0 w-full md:w-full`}>
                        <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Uszkodzenia pojazdu</label>
                        <MultiSelect
                            options={carDamageOption}
                            value={selectedCarDamage}
                            disableSearch={true}
                            onChange={handleSelectedCarDamageChange}
                            labelledBy="Model pojazdu"
                            hasSelectAll={false}
                            overrideStrings={{
                                  "allItemsAreSelected": "Wszystko jest zaznaczone.",
                                  "noOptions": "Brak wybranych opcji",
                                  "search": "Szukaj",
                                  "selectAll": "Wybierz wszystko",
                                  "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                  "selectSomeItems": "Uszkodzenia pojazdu",
                                  "create": "Stwórz",
                            }}
                        />
                    </div>
                    
                    <div className="w-full h-full">
                        <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Lokalizacja</label>
                        <div className="inline-block relative px-2 md:px-0 w-full md:w-full flex relative">
                                <button onClick={handleShowLocalizationPanel} className={`${cssPropertySmallButtons} text-main_gray_dark text-left flex pl-3`}>
                                    <p className={`${ selectedRadius.length === 1 || selectedCity.length === 1 || selectedProvince.length >= 1  ? ('text-black') : ('text-main_gray_dark')} } my-auto text-left`}>Lokalizacja</p>
                                </button>
                                <div
                                    className="pointer-events-none absolute inset-y-0 right-2 px-2.5 md:right-0 md:px-3 flex items-center text-gray-700 sm:px-4">
                                    <svg width="24" height="24" fill="none" stroke="#7D7D7D" strokeWidth="2" className="dropdown-heading-dropdown-arrow gray md:-mr-0 lg:mr-0">
                                        <path d={"M6 9L12 15 18 9"} />
                                    </svg>
                                </div>
                        </div>
                    </div>

                    <div className={`inline-block relative px-2 md:px-0 w-full md:w-full`}>
                        <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Kraj pochodzenia</label>
                        <MultiSelect
                            options={countryOptions}
                            value={selectedCountry}
                            onChange={handleSelectedCountryChange}
                            labelledBy="Model pojazdu"
                            hasSelectAll={true}
                            overrideStrings={{
                                  "allItemsAreSelected": "Wszystko jest zaznaczone.",
                                  "noOptions": "Brak wybranych opcji",
                                  "search": "Szukaj",
                                  "selectAll": "Wybierz wszystko",
                                  "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                  "selectSomeItems": "Kraj pochodzenia",
                                  "create": "Stwórz",
                            }}
                        />
                    </div>


                    <div className="w-full h-full">
                        <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Silnik i napęd</label>
                        <div className="inline-block relative px-2 md:px-0 w-full md:w-full flex relative">
                            <button onClick={handleShowBodyPanel} className={`${cssPropertySmallButtons} text-main_gray_dark text-left flex`}>
                                <p className={`${ selectedDoors.length >= 1 || selectedColor.length >= 1 || selectedSeatsAmount.length >= 1 ? ('text-black') : ('text-main_gray_dark')} } my-auto text-left`}>Nadwozie</p>
                            </button>
                            <div
                                className="pointer-events-none absolute inset-y-0 right-2 px-2.5 md:right-0 md:px-3 flex items-center text-gray-700 sm:px-4">
                                <svg width="24" height="24" fill="none" stroke="#7D7D7D" strokeWidth="2" className="dropdown-heading-dropdown-arrow gray md:-mr-0 lg:mr-0">
                                    <path d={"M6 9L12 15 18 9"} />
                                </svg>
                            </div>
                        </div>
                    </div>


                    <div className="w-full h-full">
                        <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Silnik i napęd</label>
                        <div className="inline-block relative px-2 md:px-0 w-full md:w-full flex relative">
                                <button onClick={handleShowEquipmentPanel} className={`${cssPropertySmallButtons} text-main_gray_dark text-left flex w-full justify-between`}>
                                    <p className={`${ trueEquipmentOptions.length > 0 ? ("text-black my-auto text-left") : ("text-main_gray_dark text-left my-auto")}`}>Wyposażenie</p>
                                    <p className={`${ trueEquipmentOptions.length > 0 ? ("text-main_red-100 mr-14 my-auto text-[22px]") : ("hidden")}`}>{ trueEquipmentOptions.length}</p>
                                </button>
                                {/*<div className={`absolute right-16 top-2.5 ${ trueEquipmentOptions.length > 0 ? ('text-main_red-100') : ('text-main_gray_dark')}`}>*/}
                                {/*    { trueEquipmentOptions.length }*/}
                                {/*</div>*/}
                                <div
                                    className="pointer-events-none absolute inset-y-0 right-2 px-2.5 md:right-0 md:px-3 flex items-center text-gray-700 sm:px-4">
                                    <svg width="24" height="24" fill="none" stroke="#7D7D7D" strokeWidth="2" className="dropdown-heading-dropdown-arrow gray md:-mr-0 lg:mr-0">
                                        <path d={"M6 9L12 15 18 9"} />
                                    </svg>
                                </div>
                        </div>
                    </div>

                    <div className="w-full h-full">
                        <label className="block text-left text-black w-full ml-2.5 mb-1 mt-2">Silnik i napęd</label>
                        <div className="inline-block relative px-2 md:px-0 w-full md:w-full flex relative">
                                <button onClick={handleShowEnginePanel} className={`${cssPropertySmallButtons} text-main_gray_dark text-left flex`}>
                                    <p className={`${ selectedGearbox.length >= 1 || selectedTransmission.length >= 1 || capacityFrom.length >= 1 || capacityTo.length >= 1 ? ('text-black') : ('text-main_gray_dark')} my-auto text-left`}>Silnik i napęd</p>
                                </button>
                                <div
                                    className="pointer-events-none absolute inset-y-0 right-2 px-2.5 md:right-0 md:px-3 flex items-center text-gray-700 sm:px-4">
                                    <svg width="24" height="24" fill="none" stroke="#7D7D7D" strokeWidth="2" className="dropdown-heading-dropdown-arrow gray md:-mr-0 lg:mr-0">
                                        <path d={"M6 9L12 15 18 9"} />
                                    </svg>
                                </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>

            { showEnginePanel && (
                <div className={`w-full h-full`}>
                    <div className={`fixed inset-0 flex items-center justify-center z-30 bg-opacity-50 bg-gray-300 backdrop-blur filtration-panel-animation`}>
                        <div className={`bg-base-100 w-full md:w-8/12 lg:w-5/12 mx-1 rounded-[20px] relative`}>
                            <p className={`text-[22px] text-center mt-5 mb-5`}>Silnik i napęd</p>
                            <div className="sticky w-full flex justify-end -mt-14 pr-3">
                                <svg onClick={handleCloseEnginePanel} className={`w-10 h-10 hover:cursor-pointer hover:cursor-pointer`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M17 7L7 17M7 7L17 17" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                {/*<button onClick={handleCloseEnginePanel} className={`text-center w-12 font-bold text-[25px] backdrop-blur-sm rounded`}>X</button>*/}
                            </div>

                                <div className={`grid grid-cols-1 md:grid-cols-2 w-11/12 mx-auto gap-x-3 gap-y-0 md:gap-y-3 mb-6 pt-3`}>
                                    <MultiSelect
                                            className={`z-60 w-full mx-auto mt-3 md:mt-0`}
                                            options={gearboxOptions}
                                            value={selectedGearbox}
                                            disableSearch={true}
                                            onChange={handleSelectedGearboxChange}
                                            labelledBy="Skrzynia biegów"
                                            hasSelectAll={false}
                                            overrideStrings={{
                                                  "allItemsAreSelected": "Wszystkie skrzynie biegów.",
                                                  "noOptions": "Brak opcji drzwi.",
                                                  "search": "Szukaj",
                                                  "selectAll": "Wybierz wszystko",
                                                  "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                                  "selectSomeItems": "Skrzynia biegów",
                                                  "create": "Stwórz",
                                            }}
                                    />

                                    <MultiSelect
                                            className={`z-60 w-full mx-auto mt-3 md:mt-0`}
                                            options={transmissionOptions}
                                            value={selectedTransmission}
                                            disableSearch={true}
                                            onChange={handleSelectedTransmissionChange}
                                            labelledBy="Napęd"
                                            hasSelectAll={false}
                                            overrideStrings={{
                                                  "allItemsAreSelected": "Wszystkie rodzaje napędów.",
                                                  "noOptions": "Brak opcji drzwi.",
                                                  "search": "Szukaj",
                                                  "selectAll": "Wybierz wszystko",
                                                  "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                                  "selectSomeItems": "Napęd",
                                                  "create": "Stwórz",
                                            }}
                                    />

                                    <MultiSelect
                                            className={`z-60 w-full mx-auto mt-3 md:mt-0`}
                                            options={capacityFromOptions}
                                            disableSearch={true}
                                            value={capacityFrom}
                                            onChange={handleCapacityFromChange}
                                            labelledBy="Pojemność od"
                                            hasSelectAll={false}
                                            overrideStrings={{
                                                  "allItemsAreSelected": "Wszystko",
                                                  "noOptions": "Brak danych o pojemności",
                                                  "search": "Szukaj",
                                                  "selectAll": "Wybierz wszystko",
                                                  "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                                  "selectSomeItems": "Pojemność od",
                                                  "create": "Stwórz",
                                            }}
                                    />

                                    <MultiSelect
                                            className={`z-60 w-full mx-auto mt-3 md:mt-0`}
                                            options={capacityToOptions}
                                            value={capacityTo}
                                            disableSearch={true}
                                            onChange={handleCapacityToChange}
                                            labelledBy="Pojemność do"
                                            hasSelectAll={false}
                                            overrideStrings={{
                                                  "allItemsAreSelected": "Wszystko",
                                                  "noOptions": "Brak danych o pojemności",
                                                  "search": "Szukaj",
                                                  "selectAll": "Wybierz wszystko",
                                                  "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                                  "selectSomeItems": "Pojemność do",
                                                  "create": "Stwórz",
                                            }}
                                    />

                                    <MultiSelect
                                            className={`z-60 w-full mx-auto mt-3 md:mt-0`}
                                            options={powerFromOptions}
                                            value={powerFrom}
                                            disableSearch={true}
                                            onChange={handlePowerFromChange}
                                            labelledBy="Mod od"
                                            hasSelectAll={false}
                                            overrideStrings={{
                                                  "allItemsAreSelected": "Wszystko",
                                                  "noOptions": "Brak danych o pojemności",
                                                  "search": "Szukaj",
                                                  "selectAll": "Wybierz wszystko",
                                                  "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                                  "selectSomeItems": "Moc od",
                                                  "create": "Stwórz",
                                            }}
                                    />

                                    <MultiSelect
                                            className={`z-60 w-full mx-auto mt-3 md:mt-0`}
                                            options={powerToOptions}
                                            value={powerTo}
                                            disableSearch={true}
                                            onChange={handlePowerToChange}
                                            labelledBy="Moc do"
                                            hasSelectAll={false}
                                            overrideStrings={{
                                                  "allItemsAreSelected": "Wszystko",
                                                  "noOptions": "Brak danych o pojemności",
                                                  "search": "Szukaj",
                                                  "selectAll": "Wybierz wszystko",
                                                  "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                                  "selectSomeItems": "Moc do",
                                                  "create": "Stwórz",
                                            }}
                                    />

                                </div>
                        </div>
                    </div>
                </div>
            )}



            { showBodyPanel && (
                <div className={`w-full h-full`}>
                    <div className={`fixed inset-0 flex items-center justify-center z-30 bg-opacity-50 bg-gray-300 backdrop-blur filtration-panel-animation`}>
                        <div className={`bg-base-100 w-full md:w-8/12 lg:w-7/12 mx-1 rounded-[20px] relative`}>
                            <p className={`text-[22px] text-center mt-5 mb-5`}>Szczegóły nadwozia</p>
                            <div className="sticky w-full flex justify-end -mt-14 pr-3">
                                <svg onClick={handleCloseBodyPanel} className={`w-10 h-10 hover:cursor-pointer`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M17 7L7 17M7 7L17 17" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                {/*<button onClick={handleCloseBodyPanel} className={`text-center w-12 font-bold text-[25px] backdrop-blur-sm rounded`}>X</button>*/}
                            </div>

                                <div className={`grid grid-cols-1 md:grid-cols-3 w-11/12 mx-auto gap-x-3 gap-y-0 md:gap-y-3 mb-6 pt-3`}>
                                    <MultiSelect
                                                className={`z-60 w-full mx-auto`}
                                                options={colorOptions}
                                                value={selectedColor}
                                                onChange={handleSelectedColorChange}
                                                labelledBy="Kolor samochodu"
                                                hasSelectAll={false}
                                                overrideStrings={{
                                                      "allItemsAreSelected": "Wszystko jest zaznaczone.",
                                                      "noOptions": "Brak kolorów",
                                                      "search": "Szukaj",
                                                      "selectAll": "Wybierz wszystkko",
                                                      "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                                      "selectSomeItems": "Kolor",
                                                      "create": "Stwórz",
                                                }}
                                    />

                                    <MultiSelect
                                            className={`z-60 w-full mx-auto mt-3 md:mt-0`}
                                            options={doorsOptions}
                                            disableSearch={true}
                                            value={selectedDoors}
                                            onChange={handleSelectedDoorsChange}
                                            labelledBy="Obszar"
                                            hasSelectAll={false}
                                            overrideStrings={{
                                                  "allItemsAreSelected": "Wszystko jest zaznaczone.",
                                                  "noOptions": "Brak opcji drzwi.",
                                                  "search": "Szukaj",
                                                  "selectAll": "Wybierz wszystko",
                                                  "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                                  "selectSomeItems": "Ilość drzwi",
                                                  "create": "Stwórz",
                                            }}
                                    />

                                    <MultiSelect
                                            className={`z-60 w-full mx-auto mt-3 md:mt-0`}
                                            options={seatsAmountOptions}
                                            value={selectedSeatsAmount}
                                            disableSearch={true}
                                            onChange={handleSelectedSeatsAmountChange}
                                            labelledBy="Obszar"
                                            hasSelectAll={false}
                                            overrideStrings={{
                                                  "allItemsAreSelected": "Wszystko jest zaznaczone.",
                                                  "noOptions": "Brak opcji drzwi.",
                                                  "search": "Szukaj",
                                                  "selectAll": "Wybierz wszystko",
                                                  "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                                  "selectSomeItems": "Ilość siedzeń",
                                                  "create": "Stwórz",
                                            }}
                                    />
                                </div>
                        </div>
                    </div>
                </div>
            )}


            { showLocalizationPanel && (
                <div className={`w-full h-full`}>
                    <div className={`fixed inset-0 flex items-center justify-center z-30 bg-opacity-50 bg-gray-300 backdrop-blur filtration-panel-animation`}>
                        <div className={`bg-base-100 w-full md:w-8/12 lg:w-5/12 mx-1 rounded-[20px] relative`}>
                            <p className={`text-[22px] text-center mt-5`}>Lokalizacja</p>
                            <div className="sticky w-full flex justify-end -mt-9 pr-3">
                                <svg onClick={handleCloseLocalizationPanel} className={`w-10 h-10 hover:cursor-pointer`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M17 7L7 17M7 7L17 17" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                {/*<button onClick={handleCloseLocalizationPanel} className={`text-center w-12 font-bold text-[25px] backdrop-blur-sm rounded`}>X</button>*/}
                            </div>
                                <MultiSelect
                                            className={`z-60 w-11/12 mx-auto mt-3 mb-3 md:col-span-2`}
                                            options={provinceOptions}
                                            value={selectedProvince}
                                            onChange={handleSelectedProvinceChange}
                                            labelledBy="Województwo"
                                            hasSelectAll={false}
                                            overrideStrings={{
                                                  "allItemsAreSelected": "Wszystko jest zaznaczone.",
                                                  "noOptions": "Brak wybranych opcji",
                                                  "search": "Szukaj",
                                                  "selectAll": "Wybierz wszystko",
                                                  "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                                  "selectSomeItems": "Województwo",
                                                  "create": "Stwórz",
                                            }}
                                />
                                <div className={`grid grid-cols-1 md:grid-cols-2 w-11/12 mx-auto gap-x-3 gap-y-0 mb-6`}>
                                    <MultiSelect
                                                className={`z-60 w-full mx-auto`}
                                                options={cityData}
                                                value={selectedCity}
                                                onChange={handleSelectedCityChange}
                                                labelledBy="Miasto"
                                                hasSelectAll={false}
                                                overrideStrings={{
                                                      "allItemsAreSelected": "Wszystko jest zaznaczone.",
                                                      "noOptions": "Zaznacz jedno województwo :)",
                                                      "search": "Szukaj",
                                                      "selectAll": "Wybierz wszystkko",
                                                      "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                                      "selectSomeItems": "Miasto",
                                                      "create": "Stwórz",
                                                }}
                                    />

                                    <MultiSelect
                                            className={`z-60 w-full mx-auto mt-3 md:mt-0`}
                                            options={radiusOptions}
                                            value={selectedRadius}
                                            disableSearch={true}
                                            onChange={handleSelectedRadiusChange}
                                            labelledBy="Obszar"
                                            hasSelectAll={false}
                                            overrideStrings={{
                                                  "allItemsAreSelected": "Wszystko jest zaznaczone.",
                                                  "noOptions": "Nie wybrano poszczególnego woj. oraz miasta.",
                                                  "search": "Szukaj",
                                                  "selectAll": "Wybierz wszystko",
                                                  "selectAllFiltered": "Wybierz wszystko (Filtracja)",
                                                  "selectSomeItems": "Obszar",
                                                  "create": "Stwórz",
                                            }}
                                    />
                                </div>
                        </div>
                    </div>
                </div>
            )}



            { showEquipmentPanel && (
                <div className={'w-full h-full'}>
                    <div className={`fixed inset-0 flex items-center justify-center z-50 bg-opacity-50 bg-gray-300 backdrop-blur filtration-panel-animation`}>
                        <div className={'w-full md:w-11/12 lg:w-4/12 bg-base-100 h-[68%] rounded-[30px] overflow-auto mx-1 relative panel-content'}>

                            <div className={"text-center text-[24px] pt-5 flex flex-col"}>
                                <div className="sticky top-5 pr-3 w-full flex justify-end">
                                    {/*<div className="bg-blue-400 w-40">hossein</div>*/}
                                    <svg onClick={handleShowEquipmentPanel} className={`w-10 h-10 hover:cursor-pointer`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M17 7L7 17M7 7L17 17" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    {/*<button onClick={handleShowEquipmentPanel} className={`text-center w-12 font-bold backdrop-blur-sm text-[25px] rounded`}>X</button>*/}
                                </div>

                                <div className={`flex -mt-9`}>
                                    {/*<button onClick={handleClosePanel} className={`font-bold font-[24px] backdrop-blur right-5 md:mr-11 lg:mr-36 xl:mr-48 2xl:absolute w-12 rounded-full fixed`}>X</button>*/}
                                    <div className={`font-[22px] mb-1 grow`}>Wyposażenie</div>
                                    {/*<button onClick={handleClosePanel} className={`text-center w-12 mr-5 md:mr-12 lg:mr-48 xl:mr-58 2xl:mr-80 mb-2 font-bold fixed backdrop-blur-sm rounded-md pr-4 w-12 text-right right-2`}>X</button>*/}
                                </div>
                                {/*<div className={`stripe-1`}></div>*/}
                                {/*<div className={`stripe-2`}></div>*/}
                                {/*<div className={`stripe-3`}></div>*/}
                                <div className={`grid grid-cols-1 inline-flex items-center`}>
                                    {Object.keys(checkboxes).map((category, category_index) => (
                                      <div key={category} className={"grid grid-cols-1"}>
                                          {/*{ category_index !== 0 ? (<div className={`stripe-3`}></div>) : (<div></div>)}*/}
                                          {/*  <h3 className="text-lg pb-2 pt-2 bg-main_red-100 text-white text-[22px]">{category}</h3>*/}
                                          {/*{ category_index !== 0 ? (<div className={`stripe-3`}></div>) : (<div></div>)}*/}


                                            <h3 className={`text-lg py-2 border-b-[1px] border-t-[1px] border-gray-400 bg-gray-white text-black text-[22px]`}>{category}</h3>
                                            {Object.keys(checkboxes[category]).map((option, index) => (
                                                <label
                                                className={`flex items-center space-x-4 pl-2 md:pl-4 text-left py-2.5 ${ index % 2 === 0 ? ('bg-gray-100') : ('bg-white')}`}
                                                key={option}
                                                id={`${category_index} ${index}`}
                                                // id={index}
                                                >

                                                {/*<input*/}
                                                {/*    // className={`appearance-none rounded-full h-6 w-6 border-[1px] border-[#7D7D7D] bg-base-100 checked:bg-blue-500`}*/}
                                                {/*    className={`appearance-none w-6 h-6 border-[1px] border-[#7D7D7D] checked:border-base-100 checked:border-[0px] rounded-full checked:bg-green-600`}*/}
                                                {/*    type="checkbox"*/}
                                                {/*    checked={checkboxes[category][option]}*/}
                                                {/*    onChange={handleCheckboxChange(category, option)}*/}

                                                {/*/>*/}

                                                    {/*<input  */}
                                                    {/*    type="checkbox" */}
                                                    {/*    id={`${category_index} ${index}`}*/}
                                                    {/*    name="A3-confirmation"*/}
                                                    {/*    className="opacity-0 absolute h-8 w-8"*/}
                                                    {/*    checked={checkboxes[category][option]}*/}
                                                    {/*    onChange={handleCheckboxChange(category, option)}*/}
                                                    {/*/>*/}
                                                    {/*<div*/}
                                                    {/*    className="bg-white border-2 rounded-full border-[#7D7D7D] w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-[#7D7D7D]">*/}
                                                    {/*    <svg*/}
                                                    {/*        className="fill-current hidden w-3 h-3 text-green-600 pointer-events-none"*/}
                                                    {/*        version="1.1" viewBox="0 0 17 12"*/}
                                                    {/*        xmlns="http://www.w3.org/2000/svg">*/}
                                                    {/*        <g fill="none" fillRule={"evenodd"}>*/}
                                                    {/*            <g transform="translate(-9 -11)" fill="#BB2205"*/}
                                                    {/*               fillRule={"nonzero"}>*/}
                                                    {/*                <path*/}
                                                    {/*                    d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"/>*/}
                                                    {/*            </g>*/}
                                                    {/*        </g>*/}
                                                    {/*    </svg>*/}
                                                    {/*</div>*/}
                                                    <input
                                                        type="checkbox"
                                                        id={`${category_index} ${index}`}
                                                        className={`rounded-full w-6 h-6 ml-6 md:ml-3 transition duration-50 my-auto border-main_green text-main_green shadow-sm focus:border-main_green focus:ring focus:ring-offset-0 focus:ring-main_green focus:ring-opacity-30`}
                                                        checked={checkboxes[category][option]}
                                                        onChange={handleCheckboxChange(category, option)}
                                                        name="A3-confirmation"
                                                    />

                                                    <span className={`text-[18px]`}>{option}</span>
                                              </label>
                                            ))}
                                      </div>
                                    ))}

                                </div>
                                {/* TODO Scroll to Top Button */}
                            </div>



                            {/*<div className={`mx-auto`}>*/}
                            {/*    <button onClick={handleClosePanel}*/}
                            {/*        className={`block mx-auto appearance-none rounded-[30px] bg-main_red-100 px-4 h-[38px] md:h-[40px] pr-8 rounded leading-tight focus:outline-none focus:shadow-outline`}>Dzień dobry polsko*/}
                            {/*    </button>*/}
                            {/*</div>*/}

                        </div>
                    </div>
                </div>
            )}

        {/* Below filter panel section*/}
        <div className={`w-11/12 3xl:w-9/12 mx-auto flex flex-col md:flex-row -mt-3 lg:mt-0`}>
            <div className={`w-full flex flex-col-reverse md:flex-col pt-3 relative`}>
                <div className={`flex gap-x-3 text-main_gray_dark pt-0 lg:pt-3 justify-center md:justify-start`}>
                    <Link to={"/"}>Strona główna</Link>
                    <div className={`my-auto`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="3" height="3" viewBox="0 0 3 3" fill="none">
                        <circle cx="1.5" cy="1.5" r="1.5" fill="#7D7D7D"/>
                    </svg>
                    </div>
                    <Link to={'/auta_osobowe'}>Osobowe</Link>
                </div>
                <div className={`text-[20px] lg:text-[24px] flex flex-col lg:flex-row gap-x-2 justify-center md:justify-start`}>
                    <p className={`text-center text-[22px] md:text-[20px] lg:text-[24px] md:text-left font-[500]`}>Osobowe</p>
                    { searchParams.getAll('province').length > 0 && (
                        <p className={`text-center md:text-left font-[400] truncate`}>w {searchParams.getAll('province').map(province => province.charAt(0).toUpperCase() + province.slice(1) + `, `)}{searchParams.getAll('city').length > 0 && (`- ${searchParams.getAll('city').map(city => city.charAt(0) + city.slice(1))}`) }</p>
                        // <p className={`text-center md:text-left font-[400]`}>w {capitalizedProvinces}</p>
                    )}
                    <p className={`text-center md:text-left`}>{ carsData.count === 1 ?
                                                                                    (`${carsData.count} wynik`) :
                                                                                    ( carsData.count < 5 && carsData.count !== 0 ? (`${carsData.count} wyniki`) : (`${carsData.count !== undefined ? `${carsData.count} wyników` : ''}`) )}</p>
                </div>
            </div>
            {/*<div className={`md:w-full relative mt-1 md:mt-9 grid grid-cols-2 lg:w-80 lg:grid-cols-1 gap-x-3 transition-all duration-300 ease-in-out`}>*/}
            <div className={`md:w-full relative ${searchParams.size > 1 ? ('lg:w-[50%] lg:grid-cols-2') : ('lg:w-[65%] lg:grid-cols-2')} mt-1 md:mt-9 grid grid-cols-2 gap-x-3 transition-all duration-300 ease-in-out`}>
                <button onClick={handleShowFiltrationPanel} className={'block truncate w-full lg:hidden bg-[#FFF0EB] mx-auto mt-3 md:mt-0 h-[42px] rounded-[13px] border-[#CC6B49] border-2 geometric-shadow-red-sm md:geometric-shadow-red transition duration-300'}>
                    <p className={`mx-1 truncate`}>Filtruj ogłoszenia</p>
                </button>
                    {/*<MultiSelect*/}
                    {/*        className={`z-50 w-full mx-auto rmsc2 geometric-shadow-green-sm md:geometric-shadow-green transition duration-300`}*/}
                    {/*        options={filtrationOptions}*/}
                    {/*        value={selectedFiltration}*/}
                    {/*        onChange={handleSelectedFiltrationChange}*/}
                    {/*        labelledBy="Obszar"*/}
                    {/*        hasSelectAll={false}*/}
                    {/*        // closeOnChangedValue={true}*/}
                    {/*        overrideStrings={{*/}
                    {/*              "allItemsAreSelected": "Wszystko jest zaznaczone.",*/}
                    {/*              "noOptions": "Nie wybrano poszczególnego woj. oraz miasta.",*/}
                    {/*              "search": "Szukaj",*/}
                    {/*              "selectAll": "Wybierz wszystko",*/}
                    {/*              "selectAllFiltered": "Wybierz wszystko (Filtracja)",*/}
                    {/*              "selectSomeItems": "Sortuj ogłoszenia według",*/}
                    {/*              "create": "Stwórz",*/}
                    {/*        }}*/}
                    {/*/>*/}

                {/*{ searchParams.size > 1 && (*/}
                {/*    <button onClick={removeAllFiltration} className={`hidden truncate w-full lg:block bg-[#FFF0EB] mx-auto mt-3 md:mt-0 h-[42px] rounded-[13px] border-[#CC6B49] border-2 geometric-shadow-red-sm md:geometric-shadow-red transition duration-300`}>*/}
                {/*        <p className={`mx-1 truncate`}>Wyczyść filtracje</p>*/}
                {/*    </button>*/}
                {/*)}*/}

                <button disabled={!(searchParams.size > 1)} onClick={removeAllFiltration} className={`hidden w-full lg:${searchParams.size > 1 ? ('block opacity-100') : ('block opacity-0')} bg-[#FFF0EB] mx-auto mt-3 md:mt-0 h-[42px] rounded-[13px] border-[#CC6B49] border-2 geometric-shadow-red-sm md:geometric-shadow-red transition duration-300`}>
                    <p className={`mx-1 truncate`}>Wyczyść filtracje</p>
                </button>

                <div className={`mt-3 md:mt-0 w-full bg-[#F5FFF1] rounded-[13px] h-[42px]`}>

                    <select
                        value={selectedFiltration}
                        onChange={(e) => handleSelectedFiltrationChangeSelect(e)}
                        name="filtration"
                        id="filtration"
                        className={`z-40 w-full h-full mx-auto rounded-[13px] bg-[#F5FFF1] border-[#367E18] border-[2px] geometric-shadow-green-sm transition duration-300
                        focus:outline-none arrow-change transition duration-300 focus:border-[#367E18] ring-0 focus:ring-0`}
                    >
                        {filtrationOptions.map((option, index) => (
                            <option key={option.value} value={option.value} disabled={index === 0} className={`bg-white ${index === 0 ? ('text-center') : ('text-center')}`}>
                                {option.label}
                            </option>
                        ))}
                    </select>


                </div>
            </div>
        </div>

        {loading ? (
            // <div className={`h-[800px]`}>
            //     <p className={'pt-10 text-[56px] mx-8 text-center'}>Ładuję zawartość...</p>
            // </div>
            <div className="flex justify-center my-36 md:my-36 2xl:my-64">
                    <span className="circle animate-loader"></span>
                    <span className="circle animate-loader animation-delay-200"></span>
                    <span className="circle animate-loader animation-delay-400"></span>
            </div>
        ) : (
            <div>
                <CarAuctionsCard carsData={carsData}/>
            </div>
        )}

        { carsData.count >= 1 && (carsData.next !== null || carsData.previous !== null) ? (
        <div className={`${loading ? 'hidden':''} sm:w-full p-4 w-11/12 md:w-11/12 lg:w-10/12 xl:w-10/12 2xl:w-9/12 3xl:w-8/12 mx-auto bg-base-100 mt-8 rounded-[20px] shadow-sm geometric-shadow-gray transition duration-300`}>
            <div className={`flex w-full justify-between px-3 h-full py-auto font-[12px] text-main_gray_dark`}>
                { carsData.previous !== null ? (
                        <Link to={{
                        pathname: '/auta_osobowe',
                        search: `${generateQueryString(parseInt(searchParams.get('page')) - 1)}`}}>

                            <div className={`flex gap-x-3`}>
                                <svg className={`my-auto`}
                                    xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 16 16" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.70679 15.6651C7.51926 15.8795 7.26495 16 6.99979 16C6.73462 16 6.48031 15.8795 6.29279 15.6651L0.292786 8.80182C0.105315 8.58731 0 8.29641 0 7.99309C0 7.68978 0.105315 7.39888 0.292786 7.18437L6.29279 0.3211C6.48139 0.112733 6.73399 -0.00256299 6.99619 4.32416e-05C7.25838 0.00264948 7.5092 0.12295 7.6946 0.335034C7.88001 0.547119 7.98518 0.834018 7.98746 1.13394C7.98974 1.43386 7.88894 1.72281 7.70679 1.93854L3.41379 6.84922H14.9998C15.265 6.84922 15.5194 6.96973 15.7069 7.18425C15.8944 7.39877 15.9998 7.68972 15.9998 7.99309C15.9998 8.29647 15.8944 8.58742 15.7069 8.80194C15.5194 9.01646 15.265 9.13697 14.9998 9.13697H3.41379L7.70679 14.0476C7.89426 14.2622 7.99957 14.5531 7.99957 14.8564C7.99957 15.1597 7.89426 15.4506 7.70679 15.6651Z" fill="#777777"/>
                                </svg>
                                <p className="-mx-1">({parseInt(searchParams.get('page')) - 1})</p>
                                <p className="hidden md:block">Poprzednia</p>
                            </div>
                        </Link>
                ) : (
                    <div></div>
                )}

                { carsData.next !== null ? (
                        <Link to={{
                        pathname: '/auta_osobowe',
                        search: `${generateQueryString(parseInt(searchParams.get('page')) + 1)}`}}>
                        <div className={`flex gap-x-3`}>
                            <p className="hidden md:block">Następna</p>
                            <p className="-mx-1">({parseInt(searchParams.get('page')) + 1})</p>

                            <svg className={`my-auto`}
                                xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.3664 0.334912C10.6008 0.120468 10.9187 0 11.2502 0C11.5816 0 11.8995 0.120468 12.1339 0.334912L19.634 7.19818C19.8684 7.41269 20 7.70359 20 8.00691C20 8.31022 19.8684 8.60112 19.634 8.81563L12.1339 15.6789C11.8982 15.8873 11.5824 16.0026 11.2546 16C10.9269 15.9974 10.6134 15.8771 10.3816 15.665C10.1499 15.4529 10.0184 15.166 10.0155 14.8661C10.0127 14.5661 10.1387 14.2772 10.3664 14.0615L15.7327 9.15078H1.25002C0.918492 9.15078 0.600545 9.03027 0.366121 8.81575C0.131698 8.60123 0 8.31028 0 8.00691C0 7.70353 0.131698 7.41258 0.366121 7.19806C0.600545 6.98354 0.918492 6.86303 1.25002 6.86303H15.7327L10.3664 1.95236C10.132 1.73785 10.0004 1.44695 10.0004 1.14363C10.0004 0.840319 10.132 0.549421 10.3664 0.334912Z" fill="#777777"/>
                            </svg>
                        </div>
                        </Link>
                ) : (

                    <p className={`hidden`}>Brak następnej strony</p>
                ) }
                </div>

        </div>
        ) : (
            <div className={`hidden`}></div>
        )}


        <Footer/>

    </div>
    );
}

export default Home;
