import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {handleAuctionColor} from "../../cards/CarAuctionsCard";
import axiosInstance from "../../../api/axios";
import { debounce } from 'lodash';

const HighlightedAuctionsCard = ({data, divClassName}) => {

    const navigate = useNavigate();
    const [wishes, setWishes] = useState({});
    const { location } = useLocation();

    const handleAuctionClick = (auctionId) => {
        navigate(`/auta_osobowe/aukcja/${auctionId}`);
    }

    useEffect(() => {
        const wishDict = {}

        data.results.forEach((item) => {
            wishDict[`${item.id}`] = item.wish
        })

        console.log(wishDict, "wishDict.");
        setWishes(wishDict);
    }, [])

    const makeWish = debounce(async (id, wish) => {
        console.log(id, wish)

        if (wish === null) {
            navigate(`/logowanie?return_url=auta_osobowe/aukcja/${id}`)
        } else if (wish === false) {
            axiosInstance.post(`/wishlist/car_wish/${id}`)
                .then((data) => {
                    console.log('Follow z suckesem!')
                })
                .catch((e) => {
                    console.log('Błąd przy folllow')
            })
            console.log("Follow")

            setWishes({...wishes, [id]: !wish})

        } else if (wish === true) {
            axiosInstance.delete(`/wishlist/car_wish/${id}`)
                .then((data) => {
                    console.log('Usunieto follow')
                })
                .catch((e) => {
                    console.log('Bład przy usuwaniu follow')
            })
            console.log("Unfollow")

            setWishes({...wishes, [id]: !wish})
        }

        // setPrevIdWish(id);
        // console.log(wishes)
    }, 200)


    return (
        <div className={`${divClassName}`}>
            { data.results.map((item) => (
                              
                <div key={item.id} className={`${handleAuctionColor(item.is_active, item.highlighted, item.homepage_highlight)} relative rounded-[30px] w-[98%] md:w-full mx-auto pb-3 transition duration-300 filtration-panel-animation`}>
                    <div className={`w-full mx-auto`}>


                        {/*    DIV WITH IMAGE NOW ITS BLANK FIELD*/}
                        <div className={`rounded-[20px] mt-2 mx-2 h-full relative shadow-sm`}>
                            {/*<img src={item.image[0]} alt="" className={`rounded-[20px]`}/>*/}
                            {/* TODO carousel, and image fit in div.  */}

                            <div onClick={() => handleAuctionClick(item.id)} className={`bg-base-200 hover:cursor-pointer rounded-[20px] h-80 sm:h-80 max-h-96 md:h-80 lg:h-80 xl:h-96 z-10 relative blur-wrapper`}>
                                {/* OLD Image. (Object cover) */}
                                {/*{item.image && (Array.isArray(item.image) ? <img src={item.image[0]} alt={item.title} className={`w-full rounded-[20px] object-cover mx-auto z-20 h-full`} /> : <img src={item.image} alt={item.title} className={`w-full rounded-[20px] object-cover mx-auto z-20 h-full`} />)}*/}

                                {/*{item.image && (Array.isArray(item.image) ? <img src={item.image[0]} alt={item.title} className={`w-full rounded-[20px] image-blur-wrapper object-cover brightness-50 absolute blur-sm mx-auto z-0 h-full`} /> : <img src={item.image} alt={item.title} className={`w-full rounded-[20px] object-scale-down mx-auto z-20 h-full`} />)}*/}
                                {/*{item.image && (Array.isArray(item.image) ? <img src={item.image[0]} alt={item.title} className={`w-full rounded-[20px] saturate-150 object-scale-down absolute mx-auto z-20 h-full`} /> : <img src={item.image} alt={item.title} className={`w-full rounded-[20px] object-scale-down mx-auto z-20 h-full`} />)}*/}
                                {item.image && (Array.isArray(item.image) ? <img src={item.image[0]} alt={item.title} className={`w-full brightness-100 rounded-[20px] image-blur-wrapper object-cover absolute mx-auto z-0 h-full`} loading="lazy" /> : <img src={item.image} alt={item.title} className={`w-full brightness-50 rounded-[20px] image-blur-wrapper object-cover brightness-100 absolute mx-auto z-0 h-full`} loading="lazy" />)}
                                {item.image && (Array.isArray(item.image) ? <img src={item.image[0]} alt={item.title} className={`w-full rounded-[20px] saturate-150 object-scale-down backdrop-blur-md absolute mx-auto z-20 h-full`} loading="lazy" /> : <img src={item.image} alt={item.title} className={`w-full rounded-[20px] saturate-150 object-scale-down backdrop-blur-md absolute mx-auto z-20 h-full`} loading="lazy" />)}

                            </div>
                        </div>

                        <Link
                            className={`w-full mx-auto`}
                            to={`/auta_osobowe/aukcja/${item.id}`}
                            // to={`/auta_osobowe/${item.id}`}
                        >
                        {/*<div className={`w-full mx-auto`}>*/}
                            <div className={`mx-4 skeleton-200`}>
                                {/* Brand, model and generation */}
                                <div className={`text-[20px] font-[500] pt-2 text-black`}>
                                    {item.brand.name} {item.version !== null ? `${item.version.version}` : ""} {item.generation !== null ? `${item.generation.generation}`: ``}
                                </div>
                                <div className={` break-words line-clamp-2`}>
                                    {item.title}
                                </div>
                                {/* Info about car. */}
                                <div className={`flex justify-start md:gap-2 xl:gap-1  mx-auto`}>
                                    <p className={`truncate`}>{item.production_date}</p>
                                    <div className={`my-auto px-1 md:px-2 lg:px-1`}>
                                        <svg className="stroke-none" xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                                            <circle cx="2.5" cy="2.5" r="2.5" fill="#BB2205"/>
                                        </svg>
                                    </div>
                                    <p className={`truncate`}>{item.mileage} km</p>
                                    {/*<p className={`truncate`}>{Math.round(item.mileage / 1000)}k km</p>*/}
                                    <div className={`my-auto px-1 md:px-2 lg:px-1`}>
                                        <svg className="stroke-none" xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                                            <circle cx="2.5" cy="2.5" r="2.5" fill="#BB2205"/>
                                        </svg>
                                    </div>
                                    <p className={`truncate`}>{item.capacity} cm3</p>
                                    <div className={`my-auto px-1 md:px-2 lg:px-1`}>
                                        <svg className="stroke-none" xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                                            <circle cx="2.5" cy="2.5" r="2.5" fill="#BB2205"/>
                                        </svg>
                                    </div>
                                    <p className={`truncate`}>{item.fuel_type.type}</p>
                                </div>
                                {/* Information about location */}
                                <div className={`pt-1 pb-3 mb-8 flex justify-start gap-2`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M14.7142 13.8806L11.1783 17.4164C11.0237 17.5712 10.8401 17.694 10.638 17.7778C10.4359 17.8616 10.2192 17.9047 10.0004 17.9047C9.78164 17.9047 9.56499 17.8616 9.36288 17.7778C9.16077 17.694 8.97714 17.5712 8.82251 17.4164L5.28585 13.8806C4.35353 12.9482 3.71863 11.7603 3.46142 10.4671C3.20421 9.17395 3.33625 7.83352 3.84085 6.61536C4.34544 5.39721 5.19993 4.35604 6.29625 3.62351C7.39257 2.89098 8.68149 2.5 10 2.5C11.3185 2.5 12.6075 2.89098 13.7038 3.62351C14.8001 4.35604 15.6546 5.39721 16.1592 6.61536C16.6638 7.83352 16.7958 9.17395 16.5386 10.4671C16.2814 11.7603 15.6465 12.9482 14.7142 13.8806Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M12.5 9.1665C12.5 9.82955 12.2366 10.4654 11.7678 10.9343C11.2989 11.4031 10.663 11.6665 10 11.6665C9.33696 11.6665 8.70107 11.4031 8.23223 10.9343C7.76339 10.4654 7.5 9.82955 7.5 9.1665C7.5 8.50346 7.76339 7.86758 8.23223 7.39874C8.70107 6.9299 9.33696 6.6665 10 6.6665C10.663 6.6665 11.2989 6.9299 11.7678 7.39874C12.2366 7.86758 12.5 8.50346 12.5 9.1665Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    {/* TODO RETURN LOCATION AND GOOGLE LOCATION IN API, AND FETCH IT HERE  */}
                                    {/*{ item.google_location.province ? (<p>{item.google_location.province}</p>) : (*/}
                                    {/*    item.location.name ? (<p>{item.location.name}</p>) : (<p>Brak lokalizacji</p>)*/}
                                    {/*)}*/}
                                    <p>{item.location !== null ? `${item.location.name}` : ``} {item.location !== null ? `(${item.location.province})` : ''}</p>

                                </div>

                            </div>
                        {/*</div>*/}
                        </Link>


                        <div className={`flex justify-between absolute bottom-0 w-full px-4 pb-1.5`}>

                            <button className={`flex gap-x-2 my-auto -ml-0.5`} onClick={(e) => makeWish(item.id, wishes[item.id])}>
                                {/* If wish is True then show green heart, create a code that services the observation and removing observation from auction, if user is not logged in then go to login page. */}
                                {wishes[item.id] === true ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
                                        <path d="M6 0.5C9.315 0.5 12 3.1325 12 6.38C12 3.1325 14.685 0.5 18 0.5C21.315 0.5 24 3.1325 24 6.38C24 11.2745 19.149 13.001 12.588 20.792C12.5158 20.8776 12.4257 20.9463 12.3242 20.9935C12.2226 21.0407 12.112 21.0651 12 21.0651C11.888 21.0651 11.7774 21.0407 11.6758 20.9935C11.5743 20.9463 11.4842 20.8776 11.412 20.792C4.851 13.001 0 11.2745 0 6.38C0 3.1325 2.685 0.5 6 0.5Z" fill="#367E18"/>
                                    </svg>
                                ) : (
                                    <svg className={``} xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 20 20" fill="none">
                                        <path d="M9 6.15H10H11C11 4.01485 12.7708 2.25 15 2.25C17.2292 2.25 19 4.01485 19 6.15C19 7.76879 18.2442 8.89944 16.5909 10.54C16.1637 10.964 15.6785 11.4194 15.1451 11.9201C13.7143 13.263 11.9367 14.9316 10 17.1922C8.06333 14.9316 6.28565 13.263 4.85493 11.9201C4.3215 11.4194 3.8363 10.964 3.40907 10.54C1.75582 8.89944 1 7.76879 1 6.15C1 4.01485 2.77078 2.25 5 2.25C7.22922 2.25 9 4.01485 9 6.15ZM10.0008 17.748L10.0008 17.748L10.0008 17.748Z" stroke="#367E18" strokeWidth="2"/>
                                    </svg>
                                )}

                                <p className={`text-main_green`}>Obserwuj</p>
                            </button>
                            <Link to={`/auta_osobowe/aukcja/${item.id}`} className={`text-main_red-100 font-[500] text-[20px] my-auto grow`}>
                                <p className={`text-end`}>{item.price.toLocaleString()} PLN</p>
                            </Link>
                        </div>
                    </div>
              </div>
            ))}
        </div>
    )
}

export default HighlightedAuctionsCard;