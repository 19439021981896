import React, {useEffect, useState} from "react";
import Navbar from "../components/Navbar";
import Stripes from "../components/Stripes";
import Footer from "../components/Footer";
import {useLocation} from "react-router-dom";
import {animateScroll as scroll} from "react-scroll";
import {Link} from "react-router-dom";
import {handleAuctionColor} from "../components/cards/CarAuctionsCard";
import {Helmet} from "react-helmet";

function AboutUs() {

    const location = useLocation();

    useEffect(() => {
        scroll.scrollToTop({
            duration: 300,
        });
    }, [location]);

    const DESCRIPTION = "Co nas motywuje, co chcemy osiągnąć, kim jesteśmy, co tworzymy? Wszystkiego dowiesz się z tej strony";

    return (
    <div className="App">
      {/*<Navbar color={navbar_color}/>*/}
        <Navbar/>
        <Stripes/>

        <Helmet
        onChangeClientState={(newState) => {
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
        metaDescription.setAttribute('content', DESCRIPTION || '');
        }
        }}>
            <title>O naszym zespole - Autkotarg.pl</title>
        </Helmet>

        <div className={``}>
            <div className={`w-[98%] xsm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 bg-base-100 rounded-[20px] mt-10 mx-auto geometric-shadow-gray transition duration-300 relative z-10`}>
            {/*<div className={`w-[98%] xsm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mt-10 mx-auto relative z-10`}>*/}
            {/*    <div className={`${handleAuctionColor(true, true, true)} rounded-[20px] pb-5 transition duration-300`}>*/}
                    <p className={`text-[22px] md:text-[26px] w-11/12 text-center mx-auto pt-5 py-2`}>Kim jesteśmy?</p>
                    <p className={`text-[18px] text-main_gray_dark md:text-[20px] mx-1 mx-auto text-center w-11/12`}>Witaj na naszej platformie ogłoszeniowej dedykowanej pasjonatom motoryzacji! Jesteśmy zespołem entuzjastów, którzy postanowili stworzyć miejsce, gdzie miłośnicy pojazdów mogą łatwo i skutecznie komunikować się, handlować oraz dzielić się swoją pasją. Nasza strona to centralne miejsce dla wszystkich, którzy chcą znaleźć najlepsze oferty samochodów osobowych!</p>
                {/*</div>*/}
                <hr className={`mt-4 w-[90%] mx-auto`}/>
                <p className={`text-[22px] md:text-[26px] w-11/12 text-center mx-auto pt-5 py-2`}>Jaka jest nasza Misja?</p>
                <p className={`text-[18px] text-main_gray_dark md:text-[20px] mx-1 mx-auto text-center w-11/12`}>Naszym celem jest stworzenie dynamicznego i przyjaznego środowiska online, które umożliwia łatwe odnalezienie interesujących ofert związanych z motoryzacją. Chcemy, aby nasi użytkownicy mogli bezpiecznie wymieniać się informacjami, nawiązywać kontakty z innymi pasjonatami oraz sprawnie zawierać transakcje związanymi z pojazdami.</p>
                <hr className={`mt-4 w-[90%] mx-auto`}/>
                <p className={`text-[22px] md:text-[26px] w-11/12 text-center mx-auto pt-5 py-2`}>Dlaczego My?</p>
                <ul className={`mx-auto text-[22px] md:text-[22px] text-main_gray_dark w-[75%] flex flex-col`}>
                    <li className={`underline`}>Pasja</li>
                    <p className={`text-[18px] md:text-[20px] my-1`}>Jesteśmy prawdziwymi miłośnikami motoryzacji. Nasza pasja do samochodów, motocykli i innych pojazdów napędza nas do tworzenia najlepszego miejsca dla społeczności motoryzacyjnej.</p>
                    <li className={`underline`}>Doświadczenie</li>
                    <p className={`text-[18px] md:text-[20px] my-1`}>Zespół naszej platformy to doświadczeni profesjonaliści z branży motoryzacyjnej i nie tylko. Znamy specyfikę rynku, co pozwala nam lepiej zrozumieć potrzeby naszych użytkowników.</p>
                </ul>
                <hr className={`mt-4 w-[90%] mx-auto`}/>

                {/*<div className={`absolute w-full rotate-45 -z-20`}>*/}
                {/*    <div className={`stripe-1`}></div>*/}
                {/*    <div className={`stripe-2`}></div>*/}
                {/*    <div className={`stripe-3`}></div>*/}
                {/*</div>*/}

                <p className={`text-[22px] md:text-[26px] w-11/12 text-center mx-auto pt-5 py-2`}>Co Oferujemy?</p>
                <ul className={`mx-auto text-[22px] md:text-[22px] text-main_gray_dark w-[75%] flex flex-col`}>
                    <li className={`underline`}>Łatwość Używania</li>
                    <p className={`text-[18px] md:text-[20px] my-1`}>Dzięki intuicyjnemu interfejsowi naszej strony, korzystanie z naszych usług jest proste i przyjemne. Bez względu na to, czy jesteś kupującym czy sprzedającym, zapewniamy Ci łatwą nawigację i szybkie znalezienie interesujących ofert.</p>
                    <li className={`underline`}>Społeczność</li>
                    <p className={`text-[18px] md:text-[20px] my-1`}>Tworzymy społeczność, w której pasjonaci motoryzacji mogą wymieniać się informacjami o sprzedaży swoich pojazdów.</p>
                </ul>
                <p className={`text-[18px] text-main_gray_dark md:text-[22px] mx-1 mx-auto text-center w-11/12 py-6 md:py-12`}>Dołącz do naszej społeczności motoryzacyjnej już dziś i odkryj świat pełen fascynujących ofert oraz pasjonujących ludzi!</p>
            </div>
        </div>

        <Footer/>
    </div>
    );
}

export default AboutUs;
