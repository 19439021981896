import React, {useEffect, useState} from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import CarAuctionAdminComponent from "./auction_components/CarAuctionAdminComponent";
import Stripes from "../Stripes";

const CarAuctionAdminPage = () => {

  return (
      <div>
        <Navbar/>
        <Stripes/>
        <CarAuctionAdminComponent/>
        <Footer/>
      </div>
  );
};

export default CarAuctionAdminPage;